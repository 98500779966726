import { Box, Typography, } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = theme => ({
    typo: {
        borderLeft: `3px solid ${theme.palette.primary.main}`,
        paddingLeft: '12px'
    }
})

const SectionHeader = ({ title, variant = "body1", color = "textSecondary", classes, className }) => (
    <Box display="flex" flexDirection="row" className={className} >
        <Typography className={classes.typo} variant={variant} color={color} gutterBottom >{title}</Typography>
    </Box>
);

export default withStyles(styles)(SectionHeader)
