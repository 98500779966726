import { Label } from "@material-ui/icons"

import React from 'react'
import { Box, Typography } from '@material-ui/core'

export default function FormLabel({ label, value }) {
    return (
        <Box display="flex" flexGrow={1} flexDirection="column">
            <Typography variant="caption" color="textSecondary" gutterBottom>{label}</Typography>
            <Typography variant="body1" >{value || '-'}</Typography>
        </Box>
    )
}