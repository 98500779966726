import { red } from '@material-ui/core/colors'
const styles = theme => ({
  wrapper: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default
  },
  noticeContainer: {
    backgroundColor: '#f1f2f6',
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(1) * 3}px`

  },
  notice: {
    margin: '0px auto',
    flexWrap: 'nowrap',
    cursor: 'pointer'
  },
  noticeText: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  colorRed: {
    backgroundColor: red[500],
    color: 'white'
  },
});

export default styles;
