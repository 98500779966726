import React from 'react';
import AuthHeader from './AuthHeader'
import { Box, Button, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from "../styles/authentication.style";
import { Auth } from 'aws-amplify';
import { AuthContext } from 'containers/auth/Authenticator'

const useStyles = makeStyles(styles);
function FinalStage({ onAuthStateChange, data = {} }) {
    const classes = useStyles();
    const { authenticated } = React.useContext(AuthContext)
    const handleBack = async () => {
        if (authenticated)
            await Auth.signOut();
            
        onAuthStateChange && onAuthStateChange('SignIn');
    }
    return (
        <AuthHeader>
            <Box pt={3} pb={1} maxWidth={450}>
                <Box mb={2} px={4}>
                    <h2 className={classes.heading}>{data.heading || "You Made it!"}</h2>
                    <Typography id="message" color="textSecondary" variant="body2">
                        {data.description}
                    </Typography>
                </Box>
                <Box px={4} mb={4} minHeight={250} />
                <Divider />
                <Box px={4} pt={1} display="flex" justifyContent={"space-between"}>
                    <Button id="btn-back" onClick={handleBack}>{'Back to Login'}</Button>
                </Box>
            </Box>
        </AuthHeader >
    );
};

FinalStage.displayName = "FinalStage"
export default FinalStage;
