import React, { useReducer } from 'react'
import { useSelector } from 'react-redux'
import { Box, IconButton, Typography, Collapse, Tooltip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'
import { makeStyles, fade } from '@material-ui/core/styles'
import CustomDialog from 'components/CustomDialog';
import bgImage from 'assets/images/auth-background-3.png'
import { copyToClipboard } from 'utils/WebUtils'
import {
    Code as CodeIcon,
    FileCopy as FileCopyIcon
} from '@material-ui/icons'
import * as UserSelectors from "redux/selectors/user.selector";


const useStyles = makeStyles(theme => ({
    banner: {
        display: 'flex',
        backgroundImage: `linear-gradient(${fade(theme.palette.primary.main, .50)}, ${fade(theme.palette.primary.main, .50)}), url('${bgImage}')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: 200,
        justifyContent: 'flex-end'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%'
    },
    copyBox: {
        display: 'flex',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '5px',
        backgroundColor: theme.palette.grey[200]
    },
    copyIconButton: {
        borderLeft: `1px solid ${theme.palette.primary.main}`
    }
}))

const reducer = (state, newState) => ({
    ...state,
    ...newState
});

const errorMessage = "An error occurred. Please try again"

const APP_LINK = document.location.origin

export default function InviteDialog({ open, onClose }) {
    const classes = useStyles()

    const currentOrg = useSelector(UserSelectors.currentOrganization)

    const [state, setState] = useReducer(reducer, {
        success: '',
        error: ''
    })

    const closeDialog = () => {
        setState({
            success: '',
            error: ''
        })
        onClose()
    }

    const copySchoolCode = async () => {
        try {
            await copyToClipboard(currentOrg.code)
            setState({ success: "School Code Copied to Clipboard!" })
        } catch (err) {
            setState({ error: errorMessage })
        }
    }

    const copyAppLink = async () => {
        try {
            await copyToClipboard(APP_LINK)
            setState({ success: "App Link Copied to Clipboard!" })
        } catch (err) {
            setState({ error: errorMessage })
        }
    }

    return (
        <CustomDialog
            visible={open}
            title={(
                <Box display="flex" alignItems="center">
                    <Box mr={1.5} pt={0.5}>
                        <CodeIcon fontSize="large" color="primary" />
                    </Box>
                    <Typography variant="h4">
                        Invite Code
                    </Typography>
                </Box>
            )}
            contentStyle={{ padding: 0 }}
            content={
                <>
                    <Box className={classes.banner} />
                    <Box className={classes.root} p={5} py={5} px={5}>
                        <Collapse in={state.success}>
                            <Box mb={3}>
                                <Alert severity="success" onClose={() => setState({ success: '' })}>
                                    {state.success}
                                </Alert>
                            </Box>
                        </Collapse>
                        <Collapse in={state.error}>
                            <Box mb={3}>
                                <Alert severity="error" onClose={() => setState({ error: '' })}>
                                    {state.error}
                                </Alert>
                            </Box>
                        </Collapse>
                        <Box mb={4} textAlign="center">
                            <Typography>
                                Send the app link and school code to community members (parents and faculty).
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" mb={3}>
                            <Box display="flex" className={classes.copyBox}>
                                <Box p={1.5}>
                                    <Typography variant="h5" >
                                        {APP_LINK}
                                    </Typography>
                                </Box>
                                <Box className={classes.copyIconButton}>
                                    <Tooltip title="Copy App Link" arrow>
                                        <IconButton onClick={copyAppLink}>
                                            <FileCopyIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Box display="flex" className={classes.copyBox}>
                                <Box p={1.5}>
                                    <Typography variant="h5" >
                                        {currentOrg.code}
                                    </Typography>
                                </Box>
                                <Box className={classes.copyIconButton}>
                                    <Tooltip title="Copy School Code" arrow>
                                        <IconButton onClick={copySchoolCode}>
                                            <FileCopyIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>
            }
            onClose={closeDialog}
        />
    )
}
