import React from 'react'

const iconTypes = {
  'heart': require("./love.png"),
  'heart2': require("./love.png"),
  'engage': require("./engage.png"),
  'schedule': require("./calendar.png"),
  'progress': require("./progress.png")
}

const animatedTypes = {
  'heart': "https://s3.amazonaws.com/content.connect1.io/default/3360-hearts.json",
  'heart2': "https://s3.amazonaws.com/content.connect1.io/default/362-like.json",
  'engage': "https://s3.amazonaws.com/content.connect1.io/default/connected_spokes.json",
  'schedule': "https://s3.amazonaws.com/content.connect1.io/default/5066-meeting-and-stuff.json",
  'progress': "https://s3.amazonaws.com/content.connect1.io/default/connected_spokes.json",
  'heart_filled': "https://s3.amazonaws.com/content.connect1.io/default/28513-heart-filled.json",
  'rainbow': 'https://s3.amazonaws.com/content.connect1.io/default/lf20_lodmufpp.json'
}

const iconOnly = false;

class AnimatedAvatar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {type, height, width} = this.props

    let w = 60, h = 60;

    const src = iconOnly ? iconTypes[type] : animatedTypes[type];

    return (<lottie-player src={src}
                           background="transparent"
                           speed="1"
                           style={{width: width || w, height: height || h}}
                           loop autoplay>

    </lottie-player>);
  }
}

export default AnimatedAvatar;
