import React from 'react';
import { isObject, isNumber, isString } from 'utils/WebUtils';
import { moment } from 'c1-webutils'
import { stripHtml } from 'utils/WebUtils';

export default class WebForm extends React.Component {

  static getStateName(name, discriminator) {
    return discriminator ? `${name}_${discriminator}` : name;
  }

  static conditional = (condition, ...validators) => (name, discriminator, value, state, self) => {
    if (condition()) {
      WebForm.compositeValidator(...validators)(name, discriminator, value, state, self);
    } else {
      let stateName = this.getStateName(name, discriminator);
      state[`${stateName}State`] = ""
    }

  }

  static requireHTML = (name, discriminator, value, state, self) => {
    let stateName = this.getStateName(name, discriminator);

    value = stripHtml(value);

    if (value && value.length > 0) {
      state[`${stateName}State`] = 'success'
    } else {
      state[`${stateName}State`] = 'error'
      state[`${stateName}Error`] = props => `${props.label || name} is a required field`
    }

  }

  static requiredNumber = (min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER) => (name, discriminator, value, state, self) => {
    let stateName = this.getStateName(name, discriminator);
    const hasMin = min !== Number.MIN_SAFE_INTEGER
    const hasMax = max !== Number.MAX_SAFE_INTEGER

    if (isNumber(value) && value >= min && value <= max) {
      state[`${stateName}State`] = 'success'
    } else if (!isNumber(value)) {
      state[`${stateName}State`] = 'error'
      state[`${stateName}Error`] = props => `${props.label || name} should be a valid number`
    } else if (hasMin && value < min) {
      state[`${stateName}State`] = 'error'
      state[`${stateName}Error`] = props => `${props.label || name} should be greater or equal to ${min}`
    } else if (hasMax && value > max) {
      state[`${stateName}State`] = 'error'
      state[`${stateName}Error`] = props => `${props.label || name} should be less or equal to ${min}`
    }

  }

  static required = (name, discriminator, value, state, self) => {
    let stateName = this.getStateName(name, discriminator);

    if (isString(value)) {
      value = value.trim();
    }

    if (value && (isObject(value) || (isNumber(value) && value > 0) ||
      value.length > 0)) {
      state[`${stateName}State`] = 'success'
    } else {
      state[`${stateName}State`] = 'error'
      state[`${stateName}Error`] = props => `${props.label || name} is a required field`
    }

  }

  static future = (name, discriminator, value, state, self) => {
    let stateName = this.getStateName(name, discriminator);
    const _value = moment(value)
    if (_value && moment().diff(_value, 'days') <= 0) {
      state[`${stateName}State`] = 'success'
    } else {
      state[`${stateName}State`] = 'error'
      state[`${stateName}Error`] = props => `${props.label || name} cannot be in past`
    }
  }

  static isValidDate = (name, discriminator, value, state, self) => {
    let stateName = this.getStateName(name, discriminator);
    const _value = moment(value)
    if (_value && _value.isValid())
      state[`${stateName}State`] = 'success'
    else {
      state[`${stateName}State`] = 'error'
      state[`${stateName}Error`] = props => `${props.label || name} is not a valid date`
    }
  }

  static isValidTime = (name, discriminator, value, state, self) => {
    let stateName = this.getStateName(name, discriminator);
    if (value && value.isValid())
      state[`${stateName}State`] = 'success'
    else {
      state[`${stateName}State`] = 'error'
      state[`${stateName}Error`] = props => `${props.label || name} is not a valid Time`
    }
  }

  static isSameOrAfter = (source, { label, unitOfTime } = {}) => (name, discriminator, value, state, self) => {
    let sourceName = label || source
    let stateName = this.getStateName(name, discriminator);
    let sourceStateName = this.getStateName(source, discriminator);
    let sourceDate = moment(self.state[sourceStateName]);
    const _value = moment(value)
    if (sourceDate && _value && _value.isValid() && _value.isSameOrAfter(sourceDate, unitOfTime))
      state[`${stateName}State`] = 'success'
    else {
      state[`${stateName}State`] = 'error'
      state[`${stateName}Error`] = props => `${props.label || name} cannot be less than ${(sourceName.charAt(0).toUpperCase() + sourceName.slice(1)).replace(/([a-z])([A-Z])/g, '$1 $2')}`
    }
  }

  static isEqualTo = source => (name, discriminator, value, state, self) => {
    let stateName = this.getStateName(name, discriminator);
    let sourceStateName = this.getStateName(source, discriminator);
    let sourceValue = self.state[sourceStateName];

    if (sourceValue && value && value === sourceValue)
      state[`${stateName}State`] = 'success'
    else {
      state[`${stateName}State`] = 'error'
      state[`${stateName}Error`] = props => `${props.label || name} did not match.`
    }
  }

  // function that returns true if value is email, false otherwise
  static verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  static email = (name, discriminator, value, state, self) => {
    let stateName = this.getStateName(name, discriminator);

    if (value && WebForm.verifyEmail(value)) {
      state[`${stateName}State`] = 'success'
    } else {
      state[`${stateName}State`] = 'error'
      state[`${stateName}Error`] = props => `Invalid email address.`
    }
  }

  static compositeValidator = (...validators) => (name, discriminator, value, state, self) => {

    let valid = true;

    let stateName = WebForm.getStateName(name, discriminator);

    validators.forEach(validator => {
      if (valid) {
        validator(name, discriminator, value, state, self)
        valid = valid && state[`${stateName}State`] !== 'error';
      }
    });
  }

  static validate(self, validations, discriminators = [""]) {
    let valid = true;
    let state = {};
    Object.keys(validations).forEach(name => {
      discriminators.forEach(discriminator => {
        let stateName = WebForm.getStateName(name, discriminator);
        let value = self.state[stateName];

        let validator = validations[name];

        if (Array.isArray(validator))
          validator = WebForm.compositeValidator(...validator);

        validator(name, discriminator, value, state, self)
        valid = valid && state[`${stateName}State`] !== 'error';

      });
    });

    self.setState(state)

    return valid;
  }

  static toObject(self, fields, discriminator, instance = {}) {

    let stateName;
    fields.forEach(field => {
      stateName = WebForm.getStateName(field, discriminator);
      instance[field] = self.state[stateName]
    });

    return instance;
  }
}
