import { red, blueGrey, grey, blue, orange, deepOrange } from '@material-ui/core/colors';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: orange[700],
    main: orange[500],
    light: orange[300]
  },
  secondary: {
    contrastText: white,
    dark: '#003e59',
    main: '#266986',
    light: '#5a97b6',
  },
  error: {
    contrastText: white,
    dark: red[900],
    main: red[600],
    light: red[400]
  },
  text: {
    primary: blueGrey[900],
    secondary: blueGrey[600],
    link: blue[600]
  },
  link: blue[800],
  icon: blueGrey[600],
  background: {
    default: '#F4F6F8',
    paper: white
  },
  divider: grey[200]
};
