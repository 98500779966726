import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Tooltip } from '@material-ui/core';

import defaultImage from 'assets/images/avatars/saints/defaultSaint.png'

const defaultBackgroundColor = '#C95653'

function encodeSvg(svgString) {
    return svgString.replace('<svg', (~svgString.indexOf('xmlns') ? '<svg' : '<svg xmlns="http://www.w3.org/2000/svg"'))

        //
        //   Encode (may need a few extra replacements)
        //
        .replace(/"/g, '\'')
        .replace(/%/g, '%25')
        .replace(/#/g, '%23')
        .replace(/{/g, '%7B')
        .replace(/}/g, '%7D')
        .replace(/</g, '%3C')
        .replace(/>/g, '%3E')

        .replace(/\s+/g, ' ')
        //
        //    The maybe list (add on documented fail)
        //
        //  .replace(/&/g, '%26')
        //  .replace('|', '%7C')
        //  .replace('[', '%5B')
        //  .replace(']', '%5D')
        //  .replace('^', '%5E')
        //  .replace('`', '%60')
        //  .replace(';', '%3B')
        //  .replace('?', '%3F')
        //  .replace(':', '%3A')
        //  .replace('@', '%40')
        //  .replace('=', '%3D')
        ;
}

const AvatarSvg = ({ imageKey, backgroundColor, useAvatarComponent = false, tooltipTitle, key, ...attributes }) => {
    const [imageUrl, setImageUrl] = useState(imageKey ? `https://connect1.imgix.net/${imageKey}` : defaultImage);
    const isSvgImage = imageKey && imageKey.endsWith('.svg')

    useEffect(() => {
        if (imageKey && imageUrl !== `https://connect1.imgix.net/${imageKey}`) {
            setImageUrl(`https://connect1.imgix.net/${imageKey}`)
        }
    }, [imageKey])

    useEffect(() => {
        if (imageKey && imageUrl && isSvgImage && backgroundColor && backgroundColor.toUpperCase() !== defaultBackgroundColor && !imageUrl.startsWith('data:image/svg')) {
            fetch(imageUrl)
                .then(res => res.text())
                .then(svg => {
                    const div = document.createElement("div");
                    div.innerHTML = svg
                    if (backgroundColor) {
                      const svgCircles = div.querySelectorAll(`svg > g > circle`)
                      if (svgCircles && svgCircles.length) {
                          for (let i = 0; i < svgCircles.length; i++) {
                              const circle = svgCircles[i]
                              circle.style.fill = backgroundColor
                              if (circle.nextElementSibling && circle.nextElementSibling.tagName === 'g') {
                                  if (imageKey !== 'childSaints/St. Andrew the Apostle.svg') {
                                      break
                                  } else if (i > 1) {
                                      break
                                  }
                              }
                          }
                      }
                      else if(div.querySelectorAll(`svg > g > g > circle`).length){
                        const svgCircles = div.querySelectorAll(`svg > g > g > circle`)
                        if (svgCircles && svgCircles.length) {
                            for (let i = 0; i < svgCircles.length; i++) {
                                const circle = svgCircles[i]
                                circle.style.fill = backgroundColor
                                if (circle.nextElementSibling && circle.nextElementSibling.tagName === 'g') {
                                    if (imageKey !== 'childSaints/St. Andrew the Apostle.svg') {
                                        break
                                    } else if (i > 1) {
                                        break
                                    }
                                }
                            }
                        }
                      }
                        else {
                          const svgCircles = div.querySelectorAll(`svg > g > g > g > circle`)
                          if (svgCircles && svgCircles.length) {
                              for (let i = 0; i < svgCircles.length; i++) {
                                  const circle = svgCircles[i]
                                  circle.style.fill = backgroundColor
                                  if (circle.nextElementSibling && circle.nextElementSibling.tagName === 'g') {
                                      if (imageKey !== 'childSaints/St. Andrew the Apostle.svg') {
                                          break
                                      } else if (i > 1) {
                                          break
                                      }
                                  }
                              }
                          }
                        }
                    }
                    setImageUrl(`data:image/svg+xml;utf8,${encodeSvg(div.innerHTML)}`)
                })
        }
    }, [imageUrl, isSvgImage, imageKey, backgroundColor]);

    if (useAvatarComponent) {
        if (tooltipTitle) {
            return (
                <Tooltip key={key} title={tooltipTitle}>
                    <Avatar src={imageUrl} {...attributes} />
                </Tooltip>
            )
        } else {
            return <Avatar key={key} src={imageUrl} {...attributes} />
        }
    } else {
        return <img alt="avatar" key={key} src={imageUrl} {...attributes} />
    }
}

AvatarSvg.propTypes = {
    imageKey: PropTypes.string,
    backgroundColor: PropTypes.string,
    useAvatarComponent: PropTypes.bool
}

export default AvatarSvg

