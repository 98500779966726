import { Utils, moment } from 'c1-webutils'
import { cloneDeep } from "lodash"
import { store } from "../redux/ReduxStore";

const mockdata = {}; //TODO: FIX ME!!!
const { eventData } = mockdata


export const generateCalendarEvent = (event, classes) => {
  const EventClasses = event.classIds ? classes.filter(cl => event.classIds.includes(cl.id)).map(cl => ({
    label: cl.title,
    value: cl.id
  })) : []
  return {
    ...event,
    classes: EventClasses,
    start: event.when.start,
    end: event.when.end,

  }
}

export const isChildClassEvent = (childClassedIds, eventClassIds) => {
  return childClassedIds.filter(id => eventClassIds.includes(id)).length > 0;
}

const findSlot = (event, slotId) => {
  for (const r of event.roles) {
    let slot = r.slots && r.slots.find((s) => s.id == slotId);
    if (slot)
      return { ...slot, roleTitle: r.title };
  }
};

const format = (slot) => {
  const { unit, type, isYearLong } = slot;

  if (isYearLong)
    return "";

  const val = slot.requested || slot.pledged || 0;

  if (type !== 'time')
    return val;

  if (unit === 'mins') {
    const hrs = Math.floor(val / 60);
    const minutes = Math.round(val - hrs * 60);

    if (hrs == 0)
      return `${minutes} mins`;

    return minutes > 0 ? `${hrs} hrs ${minutes} mins` : `${hrs} hrs`;
  }


  return `${val} hrs`;
}

const formatWhen = (slot) => {
  const { when, isYearLong } = slot;

  if (isYearLong)
    return "position";

  return Utils.formatDateCustom(when, 'MM/DD/YY') + ', at ' + Utils.formatDateCustom(when, 'hh:mm a')
}

const isEventExpired = ((e, currentTime) => {
  const isNextEvent = e.lastSlotDate;
  const isYearLong = e.isYearLong
  if (isYearLong) {
    const state = store.getState()
    const org = state.currentOrganization['currentOrganization']
    const expirationDates = e.roles.map(role => new Date(role.expirationDate)).filter(d => d instanceof Date && !isNaN(d))
    if (expirationDates.length > 0) {
      const maxDate = new Date(Math.max(...expirationDates));
      const maxDateTime = maxDate.getTime()
      return currentTime > maxDateTime
    } else if (org?.tenure?.endDate) {
      return currentTime < new Date(org.tenure.endDate).getTime()
    } else {
      const aYearFromStartDate = new Date(e.creationDate);
      aYearFromStartDate.setFullYear(aYearFromStartDate.getFullYear() + 1);
      return currentTime > aYearFromStartDate.getTime()
    }
  }

  if (isNextEvent == null || isNextEvent == undefined)
    return true;

  const expired = currentTime > new Date(isNextEvent).getTime();


  return expired;
});

const createIsActiveFilter = (event, currentTime) => {
  return (s) => {
    const isNextEvent = s.when;
    if (event.isYearLong) {
      const expirationDate = moment(s.expirationDate).toDate()
      return expirationDate.getTime() > currentTime;
    }

    if (isNextEvent == null || isNextEvent == undefined)
      return false;

    const expired = currentTime > new Date(isNextEvent).getTime();

    return !expired;
  }
}


export const event2Days = (event, currentUser, currentTime, filterExpired = true) => {
  const eventType = event.type && event.type.value;
  event.isYearLong = (eventType === "yearLongPosition");
  const isSlotActive = createIsActiveFilter(event, currentTime);
  event = cloneDeep(event);
  //clone the event..

  const days = {}
  for (let role of event.roles) {
    for (let slot of role.slots) {
      let { type, when, unit, requested } = slot;
      if (unit == 'hrs')
        unit = Number(unit) * 60

      if (type == 'time') {
        const day = moment(when).format('YYYY-MM-DD');
        if (!days[day]) {
          days[day] = {
            slots: []
          }
        }
        let slotPledges = event.pledges.find((p) => p.slotId === slot.id);

        slot = {
          start_time: moment(when),
          starts: moment(when).format('hh:mm a'),
          ends: moment(when).add(Number(requested), 'minutes').format('hh:mm a'),
          ...slot,
          slotPledges,
          role
        };
        applySlotAttibutes(slot, role, event, isSlotActive, currentUser)

        days[day].slots.push(slot);
      }
    }
  }

  for (let day in days) {
    days[day].slots.sort((s1, s2) => {
      if (s1.start_time < s2.start_time)
        return -1;
      if (s1.start_time > s2.start_time)
        return 1;
      return 0;
    });
  }
  //sort by when

  return days;
}

function applySlotAttibutes(slot, role, event, isSlotActive, currentUser) {
  slot.isYearLong = event.isYearLong;
  slot.startDate = role.startDate;
  slot.expirationDate = role.expirationDate;
  slot.roleTitle = role.title;
  slot.requirements = role.requirements;
  slot.eventId = event.id;
  slot.pledges = event.pledges.filter((p) => p.slotId === slot.id);
  slot.pledged = slot.pledges.reduce((total, p) => total + Number(p.pledged), 0.0);
  slot.available = isSlotActive(slot) ? Number(slot.quantity) - slot.pledges.length : 0;

  slot.isFull = (slot.pledges.length >= slot.quantity);

  slot.signedUp = slot.pledges.find((p) => p.userId === currentUser.id) !== undefined;
  slot.completed = (event.status === "complete");
  slot.fRequested = format(slot);
  slot.fWhen = formatWhen(slot);
  slot.isExpired = !isSlotActive(slot);


  if (slot.signedUp) {
    role.signedUp = true;
    event.signedUp = true;
    slot.user = slot.pledges
    if (!role.myPledges)
      role.myPledges = []
    if (!event.isYearLong) {
      const factor = (slot.unit == 'mins') ? 'minutes' : 'hours';
      const endTime = moment(slot.when).add(Number(slot.requested), factor);

      role.myPledge = {
        start: slot.when,
        end: endTime.toDate().toISOString(),
        location: slot.location
      }

      role.myPledges.push({
        slotId: slot.id,
        start: slot.when,
        end: endTime.toDate().toISOString(),
        location: slot.location
      })
    }
  }


}

export const denormalizeEvent = (event, currentUser, currentTime, filterExpired = true) => {
  const eventType = event.type && event.type.value;
  event.isYearLong = (eventType === "yearLongPosition");

  const isSlotActive = createIsActiveFilter(event, currentTime);
  event = cloneDeep(event);
  //clone the event..

  event.locations = new Set();
  event.isOwner = event.userId == currentUser.id;

  event.status = event.status || 'active'
  //set status if it is missing..

  const roles = event.roles.map((role) => {
    if (!role.slots)
      throw new Error("Invalid event with no slots found for assignment");

    if (!event.isYearLong && filterExpired)
      role.slots = role.slots.filter(isSlotActive);
    //only show active slots..

    if (role.slots.length == 0)
      return null;

    role.slots.forEach((slot) => {
      applySlotAttibutes(slot, role, event, isSlotActive, currentUser);

      if (slot.location) {
        event.locations.add(slot.location.label);
      }
    });

    role.isYearLong = event.isYearLong;

    role.isFull = role.slots.reduce((isFull, slot) => isFull && slot.isFull, true);

    role.slotsByWeek = role.slots.reduce((weeklySlots, slot) => {
      const weekId = slot.when ? Utils.toWeekId(slot.when) : "All";
      const slots = weeklySlots[weekId] || [];
      slots.push(slot);
      weeklySlots[weekId] = slots;

      return weeklySlots;
    }, {});

    const slotIds = new Set(role.slots.map((s) => s.id));

    role.pledges = event.pledges.filter((p) => slotIds.has(p.slotId));

    role.totalRequested = role.slots.reduce((sum, s) => {
      const { unit, type, quantity, requested } = s;
      sum.unit = sum.unit || unit;
      sum.type = sum.type || type;


      sum.requested = sum.requested + Number(requested) * Number(quantity);
      sum.count += Number(quantity);
      return sum;
    }, { requested: 0.0, count: 0.0 }
    );

    role.fTotalRequested = format(role.totalRequested);
    role.requested = role.totalRequested.count;
    //total amount requested

    role.totalPledged = role.pledges.reduce((sum, p) => {
      const { pledged } = p;
      sum.pledged = sum.pledged + Number(pledged);

      return sum;
    }, { pledged: 0.0, unit: 'mins', type: 'time' }); //TODO: FIX ME.. hardcoded to mins!!
    role.fTotalPledged = format(role.totalPledged);
    //total pledged

    role.assigned = role.pledges.length;
    //people that have signed up

    role.available = role.slots.reduce((available, s) => available + s.available, 0.0);
    role.open = role.available - role.assigned;
    //whats left

    role.isNew = Utils.daysBetween(event.creationDate) < 1;
    role.eventType = event.type;
    role.eventSubType = event.subType;
    role.eventClasses = event.classes;
    role.eventTitle = event.title;
    role.eventCreator = event.userId;
    role.eventStatus = event.status;
    role.eventId = event.id;
    role.image = event.image;
    role.comments = role.comments

    role.slots.sort((s1, s2) => {
      if (s1.when < s2.when)
        return -1;
      if (s1.when > s2.when)
        return 1;
      return 0;
    });

    if (!event.isYearLong) {
      role.nextSlotDate = new Date(role.slots[0].when);
      role.lastSlotDate = new Date(role.slots[role.slots.length - 1].when)
    }


    return role;
  }).filter(r => r != null).sort((r1, r2) => r1.title.localeCompare(r2.title));
  //denormalize the roles

  event.roles = roles;

  const eventDataSearch = (type = 'next') => {
    let searchDate;
    for (let r of event.roles) {
      const d = type === 'next' ? r.nextSlotDate : r.lastSlotDate;
      const compare = (d1, d2) => {
        if (type === 'next') {
          return d1 < d2;
        } else
          return d1 > d2;
      }

      if (!searchDate)
        searchDate = d
      else
        searchDate = compare(d, searchDate) ? d : searchDate;
    }

    return searchDate;
  }

  event.nextSlotDate = eventDataSearch('next')
  event.lastSlotDate = eventDataSearch('last')

  event.pledges && event.pledges.forEach((p) => {
    const slot = findSlot(event, p.slotId);
    if (slot) {
      const factor = (slot.unit == 'mins') ? 'minutes' : 'hours';
      const endTime = moment(slot.when).add(Number(slot.requested), factor);
      p.roleTitle = slot.roleTitle;
      p.start = slot.when
      p.end = endTime.toDate().toISOString()
      p.requested = slot.requested;
      p.unit = slot.unit; //copy the unit
      p.assignedHours = slot.assignedHours;
      p.slot = slot
    }
  });
  event.expired = isEventExpired(event, currentTime);

  event.fLocations = [...event.locations].slice(0, 3).reduce((fLocation, l) => {
    fLocation += l;
    return fLocation;
  }, "");

  return event;
};

export const getYearLongMessage = (event) => {
  const expirationDates = event.roles.map(role => moment(role.expirationDate).toDate()).filter(d => d instanceof Date && !isNaN(d))
  const startDates = event.roles.map(role => moment(role.startDate).toDate()).filter(d => d instanceof Date && !isNaN(d))
  if (expirationDates.length > 0) {
    const maxDate = new Date(Math.max(...expirationDates));
    const minDate = new Date(Math.min(...startDates));
    const currentDate = new Date()
    if (minDate > currentDate) {
      return `Position(s) start on ${Utils.formatDateCustom(minDate, 'MMM DD, YYYY')} and end on ${Utils.formatDateCustom(maxDate, 'MMM DD, YYYY')}`
    } else if (maxDate < currentDate) {
      return `Past Position(s)`
    }
    return `Position(s) end on ${Utils.formatDateCustom(maxDate, 'MMM DD, YYYY')}`
  } else {
    const aYearFromStartDate = new Date(event.creationDate);
    aYearFromStartDate.setFullYear(aYearFromStartDate.getFullYear() + 1);
    return `Position(s) end on ${Utils.formatDateCustom(aYearFromStartDate, 'MMM DD, YYYY')}`
  }
}

export const searchEvents = (searchParams, denormalizedEvents, intersection = true, union = false) => {
  const { createdBy, classEvent, schoolEvent, yearLongPosition, mine } = searchParams;

  let types = { classEvent, schoolEvent, yearLongPosition };
  const typeFilter = new Set();
  Object.keys(types).forEach(t => types[t] && typeFilter.add(t))
  //setup type filter.

  const allEvents = new Set(denormalizedEvents);

  const results = {};
  results['types'] = typeFilter.size > 0 ? new Set(denormalizedEvents.filter(role => typeFilter.has(role.type.value))) : allEvents;
  results['mine'] = mine ? new Set(denormalizedEvents.filter(role => role.signedUp || role.isOwner)) : allEvents;

  if (intersection) {
    let result = Array.from(allEvents);
    Object.values(results).forEach((s) => {
      result = result.filter(x => s.has(x));
    });

    return result;
  }

  if (union) {
    return Array.from(new Set(...results['createdBy'], ...results['types'], ...results['mine']));
  }
};


export const events2Slots = (allEvents, currentTime) => {
  const priorMonth = (m) => {
    const month = m.substring(5);
    const year = m.substring(0, 4);

    let nextMonth, nextYear;

    if (month == 1) {
      nextMonth = 12;
      nextYear = Number(year) - 1
    } else {
      nextMonth = Number(month) - 1;
      nextYear = year;
    }

    if (nextMonth < 10)
      nextMonth = `0${nextMonth}`

    return `${nextYear}-${nextMonth}`;
  };


  let allPledges, allSlots, eventsPerDay, pledgedAndRequested, byMonthPR, eventsByWeek;
  if (allEvents) {
    allSlots = {}
    allPledges = {}
    allEvents && allEvents.forEach((event) => {
      event.roles.forEach((r) => {
        r.slots.forEach((s) => {
          const slotTime = s.when && Utils.toShortForm(new Date(s.when)) || null;
          if (slotTime) {
            if (!allSlots[slotTime])
              allSlots[slotTime] = [];
            allSlots[slotTime].push({ ...s, event: event });
          }
        });
      });

      event.pledges.forEach((p) => {
        const s = findSlot(event, p.slotId);
        const slotTime = s && s.when && Utils.toShortForm(new Date(s.when)) || null;
        if (slotTime) {
          if (!allPledges[slotTime])
            allPledges[slotTime] = [];
          allPledges[slotTime].push({ pledge: Object.assign({}, p, { unit: s.unit }), slot: s, event: event });
        }
      });
    });

    eventsPerDay = []
    for (const day in allSlots) {

      const events = allSlots[day] ? allSlots[day].map((s) => s.event) : []
      const eventByIds = events.reduce((acc, cur) => {
        if (!acc[cur.id])
          acc[cur.id] = cur
        return acc
      }, {})
      const totalEvents = Object.values(eventByIds).length

      eventsPerDay.push({
        day,
        value: totalEvents,
        events: allSlots[day] && allSlots[day].map((s) => s.event)
      });
    }

    const rawEventsByWeekId = {};
    eventsPerDay.forEach((e) => {
      const { day } = e;
      const weekDay = moment(day).format("dddd");

      const weekId = Utils.toWeekId(day);
      if (!rawEventsByWeekId[weekId])
        rawEventsByWeekId[weekId] = [];
      rawEventsByWeekId[weekId].push({ weekId, weekDay, ...e });
    });

    eventsByWeek = Object.keys(rawEventsByWeekId).map((weekId) => {
      const events = rawEventsByWeekId[weekId].reduce((acc, evt) => {
        acc[evt.weekDay] = evt.value;
        acc.events = (acc.events) ? new Set([...acc.events, ...evt.events]) : new Set(evt.events);

        return acc;
      }, { weekId: weekId });

      return events;
    });


    pledgedAndRequested = [];
    byMonthPR = {}
    for (const day in allSlots) {
      const month = day.substring(0, 7);

      if (!byMonthPR[month])
        byMonthPR[month] = { pledged: 0, requested: 0, events: new Set() };

      allSlots[day].forEach((slot) => {
        const { event } = slot;

        const pledges = allPledges[day] && allPledges[day].filter(ap => ap.slot.id === slot.id) || [];
        byMonthPR[month].pledged += pledges.length;
        //add pledge data if available..

        byMonthPR[month].requested += Number(slot.quantity || 1.0);
        //add requests

        byMonthPR[month].events.add(event);
        //add events..
      });
    }

    const currentDate = new Date(currentTime)
    for (const month in byMonthPR) {
      if (
          Number(month?.substring(5)) >= currentDate.getMonth() + 1 || 
          Number(month?.substring(0, 4)) > currentDate.getFullYear()
        ) {
        const { pledged, requested, events } = byMonthPR[month];
        const priorPR = byMonthPR[priorMonth(month)];
        let pledgeChange, requestChange;
        if (priorPR) {
          pledgeChange = (pledged - priorPR.pledged) / priorPR.pledged;
          requestChange = (requested - priorPR.requested) / priorPR.requested;
        }

        pledgedAndRequested.push({
          month,
          pledged: Math.round(pledged),
          needed: Math.round((requested - pledged)),
          pledgeChange,
          requestChange,
          events
        });
      }
    }
  }

  return { allSlots, allPledges, byMonthPR, eventsPerDay, pledgedAndRequested, eventsByWeek };
};



