import { grey, blue, indigo, green, orange, red } from '@material-ui/core/colors';

const buildGradient = (start, end) =>
  `linear-gradient(180deg, ${start} 0%, ${end} 100%)`;

const cGrey = buildGradient(grey[400], grey[600]);
const cBlue = buildGradient(blue[700], blue[900]);
const cIndigo = buildGradient(indigo[400], indigo[600]);
const cGreen = buildGradient(green[400], green[600]);
const cOrange = buildGradient(orange[400], orange[700]);
const cRed = buildGradient(red[500], red[700]);

export default {
  grey: cGrey,
  blue: cBlue,
  indigo: cIndigo,
  green: cGreen,
  orange: cOrange,
  red: cRed
};
