import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import * as UserSelectors from 'redux/selectors/user.selector'
import MenuSidenavItem from '../menu-sidenav-item/menu-sidenav-item.component';
import styles from './menu-sidenav.style';
import { routeConfig as Routes } from 'routes/menu-config'
import UserActions from 'redux/actions/user.actions'
import { getOnboardingRedirectPath } from 'utils/auth'

import EntitySelectors from 'redux/selectors';

const OrgUserSelectors = EntitySelectors.orgUser
class MenuSidenav extends React.Component {
  constructNavItems = (itemsArray, classes, isChild) => {
    const arr = [];
    const { currentOrgUser, currentOrg } = this.props;
    itemsArray.forEach((el) => {
      let disabled = false
      let tooltipMessage = ''
      if (!el.external && getOnboardingRedirectPath(el, currentOrgUser, currentOrg)) {
        disabled = true
        tooltipMessage = 'Complete onboarding registration first to access this feature'
      }
      if (el.isMenu || isChild) {
        arr.push((
          <MenuSidenavItem
            title={el.menuTitle || el.title}
            href={el.path}
            key={el.title}
            icon={el.icon}
            type={el.type}
            onClick={el.onClick}
            childItem={isChild && itemsArray}
            external={el.external}
            isChild={isChild}
            disabled={disabled}
            tooltipMessage={tooltipMessage}
          >
            {el.children && this.constructNavItems(el.children, classes, true)}
          </MenuSidenavItem>));
      }
    });
    return <List className={classes.list}>{arr}</List>;
  }
  onOrgChange(org) {
    UserActions.setCurrentOrg(org)
  }
  render() {
    const { classes, userOrgs, currentOrg } = this.props;
    let _menuItems = Routes.home.routes
    if (userOrgs && currentOrg) {
      const otherOrgs = userOrgs.filter(uo => uo.id !== currentOrg.id)
      let userOrgMenus = {
        title: currentOrg.name,
        isMenu: true,
      }
      if (otherOrgs && otherOrgs.length)
        userOrgMenus.children = otherOrgs.map(org => ({
          title: org.name,
          onClick: () => this.onOrgChange(org)
        }))

      _menuItems = [
        otherOrgs && otherOrgs.length && userOrgMenus,
        ..._menuItems,
      ].filter(Boolean)
    }
    return (
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          {this.constructNavItems(_menuItems, classes, false)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    currentUser: UserSelectors.currentUser(state, props),
    userOrgs: UserSelectors.userOrganizations(state, props),
    currentOrg: UserSelectors.currentOrganization(state, props),
    currentOrgUser: OrgUserSelectors.current(state, props)
  };
}

MenuSidenav.propTypes = {
  classes: PropTypes.shape({}).isRequired
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  withStyles(styles))(MenuSidenav);
