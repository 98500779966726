import React from "react";

const SvgSchool = props => (
  <svg viewBox="0 0 511 511" {...props}>
    <path d="M503.5 476H487V267h16.5a7.501 7.501 0 006.328-11.527l-56-88a7.5 7.5 0 00-6.328-3.474H298.606L263 128.394V99h72.5a7.5 7.5 0 006.24-11.66L328.514 67.5l13.227-19.84A7.5 7.5 0 00335.5 36H263v-8.5a7.5 7.5 0 00-15 0v100.894L212.394 164H63.5a7.5 7.5 0 00-6.328 3.474l-56 88a7.503 7.503 0 00-.247 7.636A7.502 7.502 0 007.5 267H24v209H7.5a7.5 7.5 0 000 15h496a7.5 7.5 0 000-15zm-60.117-297l46.455 73H386.606l-73-73h129.777zM321.486 51l-8.227 12.34a7.497 7.497 0 000 8.32L321.486 84H263V51h58.486zM67.617 179h129.776l-73 73H21.163l46.454-73zM39 267h88.498c.248 0 .496-.013.744-.038.106-.01.21-.03.315-.045.137-.02.274-.036.411-.063.122-.024.24-.058.36-.088.117-.029.235-.056.351-.09.118-.036.233-.081.349-.122.115-.041.23-.079.344-.126.109-.045.213-.098.319-.148.115-.055.232-.106.345-.167.103-.055.2-.118.3-.177.11-.065.222-.128.329-.2.112-.075.217-.158.324-.239.088-.066.179-.127.264-.198a7.23 7.23 0 00.551-.499L255.5 142.106l122.696 122.695c.175.175.36.341.551.499.085.07.175.131.263.197.108.081.214.165.326.24.106.071.217.133.326.198.101.061.199.124.303.179.112.06.227.111.342.165.107.051.213.104.323.15.112.046.226.083.339.124.117.042.233.087.353.124.114.035.23.06.345.089.122.031.242.065.366.089.132.026.265.042.398.061.109.016.217.036.328.047.246.024.493.037.74.037H472v209H311V371.5c0-12.958-10.542-23.5-23.5-23.5h-64c-12.958 0-23.5 10.542-23.5 23.5V476H39V267zm257 113h-81v-8.5c0-4.687 3.813-8.5 8.5-8.5h64c4.687 0 8.5 3.813 8.5 8.5v8.5zm-81 15h33v81h-33v-81zm48 0h33v81h-33v-81z" />
    <path d="M255.5 323c30.603 0 55.5-24.897 55.5-55.5S286.103 212 255.5 212 200 236.897 200 267.5s24.897 55.5 55.5 55.5zm0-96c22.332 0 40.5 18.168 40.5 40.5S277.832 308 255.5 308 215 289.832 215 267.5s18.168-40.5 40.5-40.5z" />
    <path d="M255.5 275a7.5 7.5 0 007.5-7.5v-24a7.5 7.5 0 00-15 0V260h-16.5a7.5 7.5 0 000 15h24zM175.5 388h-40a7.5 7.5 0 00-7.5 7.5v48a7.5 7.5 0 007.5 7.5h40a7.5 7.5 0 007.5-7.5v-48a7.5 7.5 0 00-7.5-7.5zm-7.5 15v9h-25v-9h25zm-25 33v-9h25v9h-25zM103.5 388h-40a7.5 7.5 0 00-7.5 7.5v48a7.5 7.5 0 007.5 7.5h40a7.5 7.5 0 007.5-7.5v-48a7.5 7.5 0 00-7.5-7.5zM96 403v9H71v-9h25zm-25 33v-9h25v9H71zM173 292h-35c-5.514 0-10 4.486-10 10v53.5a7.5 7.5 0 007.5 7.5h40a7.5 7.5 0 007.5-7.5V302c0-5.514-4.486-10-10-10zm-5 15v17h-25v-17h25zm-25 41v-9h25v9h-25zM101 292H66c-5.514 0-10 4.486-10 10v53.5a7.5 7.5 0 007.5 7.5h40a7.5 7.5 0 007.5-7.5V302c0-5.514-4.486-10-10-10zm-5 15v17H71v-17h25zm-25 41v-9h25v9H71zM407.5 451h40a7.5 7.5 0 007.5-7.5v-48a7.5 7.5 0 00-7.5-7.5h-40a7.5 7.5 0 00-7.5 7.5v48a7.5 7.5 0 007.5 7.5zm7.5-15v-9h25v9h-25zm25-33v9h-25v-9h25zM335.5 451h40a7.5 7.5 0 007.5-7.5v-48a7.5 7.5 0 00-7.5-7.5h-40a7.5 7.5 0 00-7.5 7.5v48a7.5 7.5 0 007.5 7.5zm7.5-15v-9h25v9h-25zm25-33v9h-25v-9h25zM407.5 363h40a7.5 7.5 0 007.5-7.5V302c0-5.514-4.486-10-10-10h-35c-5.514 0-10 4.486-10 10v53.5a7.5 7.5 0 007.5 7.5zm7.5-15v-9h25v9h-25zm25-41v17h-25v-17h25zM335.5 363h40a7.5 7.5 0 007.5-7.5V302c0-5.514-4.486-10-10-10h-35c-5.514 0-10 4.486-10 10v53.5a7.5 7.5 0 007.5 7.5zm7.5-15v-9h25v9h-25zm25-41v17h-25v-17h25z" />
  </svg>
);

export default SvgSchool;
