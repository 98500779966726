import React from 'react'
import WebForm from 'components/WebForm';
import { Grid } from '@material-ui/core'
import { Input } from "components/validator.components/index";

export default class SchoolCodeForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.validate = this.validate.bind(this);
    }

    componentDidMount() {
        const authData = JSON.parse(sessionStorage.getItem('AUTH_DATA'));
        if (authData && authData.schoolCode) {
            this.setState({ code: authData.schoolCode })
        }
    }

    getFormData() {
        if (this.validate()) {
            const { code } = this.state
            return { code }
        } else
            return false
    }

    render() {
        const { onCodeChange } = this.props
        return (
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Input
                        id="school-code"
                        name={"code"}
                        label="School Code"
                        required={true}
                        self={this}
                        editable={true}
                        onChange={onCodeChange}
                    />
                </Grid>
            </Grid>
        )
    }

    validate() {

        let { required } = WebForm;
        let validations = {
            code: required
        }
        return WebForm.validate(this, validations)
    }
}
