import { alpha } from '@material-ui/core/styles'
import { red, green } from '@material-ui/core/colors'
import bg from 'assets/images/auth-background-4.png'

const styles = theme => ({
    background: {
        background: 'rgba(0,0,0,.05)',
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            background: theme.palette.primary['50'],
            backgroundImage: `linear-gradient(rgba(255, 243, 224, 0.9), rgba(255, 243, 224, 0.9)),
             url(${bg})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'repeat',
            backgroundPosition: 'center',
        },

    },
    wrapper: {
        height: '100%',
        display: 'flex'
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: theme.spacing(2),
        '& h6': {
            fontWeight: '400',
        }
    },
    logo: {
        height: '90px',
        margin: '10px',
        [theme.breakpoints.down('sm')]: {
            height: '70px',
            margin: '10px'
        },
    },
    primaryCard: {
        backgroundImage: `linear-gradient(rgba(255, 243, 224, 0.9), rgba(255, 243, 224, 0.9)),
             url(${bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '0 !important',
        borderRight: '1px solid #ddd',
        height: '100%',
        minHeight: '200px',
        display: 'flex',
        flexDirection: 'column',
    },
    primaryCardContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            minHeight: 0,
            padding: theme.spacing(2),
        },
    },
    card: {
        height: '100%',
        minHeight: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '0 !important',
        width: 600,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
            width: 'auto',
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
            width: 'auto',
        }
    },

    card2: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0 !important',
        height: '100%',
        width: 600,
        [theme.breakpoints.down('sm')]: {
            height: 'inherit',
            padding: theme.spacing(3),
            width: 'auto',
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
            width: 'auto',
        }
    },
    'card-content': {
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    formContainer: {
        minHeight: 200,
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    formRow: {
        display: 'flex',
        margin: '0px 8px',
        flexDirection: 'row'
    },
    formColumn: {
        display: 'flex',
        margin: '0px 8px',
        flexDirection: 'column'
    },
    cardHeader: {
        padding: theme.spacing(3),
        paddingBottom: 0,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            paddingBottom: 0
        },
    },
    stepperContent: {
        padding: theme.spacing(3),
        paddingTop: 0,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            paddingTop: 0
        },
    },
    loginCard: {
        minHeight: '200px',
        [theme.breakpoints.down('sm')]: {
            minHeight: 0,
        },
    },
    heading: {
        fontWeight: '600',
        margin: 0,
        marginBottom: '1.5em',
        '&::after': {
            content: "''",
            display: 'block',
            height: '3px',
            width: '50px',
            backgroundColor: theme.palette.primary.main,
            position: 'relative',
            top: '8px',
            marginBottom: ' 0.25em',

        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.3em',
        }
    },
    action: {
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        }
    },
    actionSecondary: {
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        paddingTop: 0,
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: theme.spacing(2),
            paddingTop: 0,
            margin: 0,
        },
    },
    secondaryFooter: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing(2)}px `,
        }
    },
    btnPrimary: {
        boxShadow: `0px 1px 5px 0px ${alpha(theme.palette.primary.main, .2)}, 0px 2px 2px 0px ${alpha(theme.palette.primary.main, .14)}, 0px 3px 1px -2px ${alpha(theme.palette.primary.main, .12)}`
    },
    btnSecondary: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        },
    },
    btnSocial: {
        justifyContent: 'flex-start',
        borderRadius: 4,
        backgroundColor: '#4285F4',
        color: '#fff',
        cursor: 'pointer',
        // boxShadow: `0px 1px 5px 0px ${alpha(theme.palette.primary.main, .2)}, 0px 2px 2px 0px ${alpha(theme.palette.primary.main, .14)}, 0px 3px 1px -2px ${alpha(theme.palette.primary.main, .12)}`
        "&:hover":{
            backgroundColor:'#3f7ce8'
        }
    },
    btnSocialIcon: {
        display:'flex',
        backgroundColor: 'white',
        borderRadius: 4,
        padding: 8,
        marginRight: 16,
        minHeight:24,
        minWidth:24
    },
    btnSchoolCode: {
        backgroundColor: '#256986',
        color: 'white',
        border: `1px solid #256986`,
        '&:hover': {
            border: `1px solid #256986`,
            color: '#256986',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1),
        },
    },
    'mt-1': {
        marginTop: theme.spacing(1)
    },
    btnAlreadySignin: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1)
        },

    },
    bullets: {
        fontWeight: '400',
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: theme.spacing(4)
    },
    backBtnContainer: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2),
        },
    },
    height250: {
        height: 250
    },
    paragraph: {
        marginBottom: '1rem'
    },
    roleSelectContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 200,
        padding: `0px ${theme.spacing(3)}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `0px ${theme.spacing(2)}px`
        }
    },
    roleCard: {
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
        borderRadius: 10,
        border: '1px solid rgba(0,0,0,.4)',
        margin: 0,
        marginBottom: 8,
        cursor: 'pointer',

        '&:hover': {
            borderColor: theme.palette.primary.main,
            '& p': {
                color: theme.palette.primary.main
            }
        }
    },
    roleIcon: {
        display: 'flex',
        padding: theme.spacing(2),
        borderRight: `1px solid rgba(0,0,0,.4)`,
        alignItems: 'center'
    },
    roleSelected: {
        borderColor: theme.palette.primary.main,
        '& p': {
            color: theme.palette.primary.dark
        },
        '& #icon': {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            '& svg': {
                fill: 'white',
            },
        }
    },
    schoolCard: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: '1px solid rgba(0,0,0,.3)',
        borderRadius: '10px',
        overflow: 'hidden',
        padding: '8px',
        marginBottom: 16,
        minHeight: 60,
        backgroundColor: alpha(theme.palette.primary.main, .1),
        '& img': {
            paddingRight: theme.spacing(1)
        }
    },
    form: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column'
    },
    fullHeight: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    wrapperSignin: {
        padding: `0px ${theme.spacing(4)}px`,
        [theme.breakpoints.down('xs')]: {
            padding: `0px ${theme.spacing(1)}px`
        }
    },
    codeSigninForm: {
        width: 'auto',
        padding: `0px ${theme.spacing(2)}px`,

    },
    codeSigninRole: {
        [theme.breakpoints.down('xs')]: {
            width: 271,
        }
    },
    codeSigninButton: {
        padding: '8px 40px',
        [theme.breakpoints.down('xs')]: {
            padding: '0px 16px 16px 16px'
        }
    },
    helperText: {
        color: 'rgba(0, 0, 0, 0.54)',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '-8px',
        minHeight: '1em',
        textAlign: 'left',
        fontFamily: '"Roboto", "Source Sans Pro", "Helvetica", "sans-serif"',
        fontWeight: '400',
        lineHeight: '1.2em',
        letterSpacing: '0.03333em',
    }
})

export default styles;
