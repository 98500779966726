import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import SwipeableViews from 'react-swipeable-views';
import {withRouter} from 'react-router-dom';
// Material UI
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {SyncRounded} from "@material-ui/icons"

// Styles
import scss from './notification-sidenav.module.scss';
// Tabs
import NotificationsTab from './notifications-tab.component';
// Actions
import {toggleNotifications} from 'redux/actions/layout.actions';
import EntitySelectors from 'redux/selectors';
import EntityActions from 'redux/actions';

const NotificationSelectors = EntitySelectors.notification;
const NotificationActions = EntityActions.notification

class NotificationSidenav extends React.Component {

  static propTypes = {
    layout: PropTypes.shape({
      notificationsOpen: PropTypes.bool
    }),
    toggleNotifications: PropTypes.func
  };

  static defaultProps = {
    layout: {
      notificationsOpen: false
    },
    toggleNotifications: null
  }

  constructor(props) {
    super(props)

    this.state = {
      currentTabIndex: 0
    }
  }


  handleChangeTab = (event, index) => {
    this.setState({currentTabIndex: index});
  };

  handleChangeTabIndex = (index) => {
    this.setState({currentTabIndex: index});
  };

  render() {
    const {layout, notifications} = this.props;

    return (
      <Drawer
        variant="temporary"
        open={layout.notificationsOpen}
        onClose={this.props.toggleNotifications}
        anchor="right"
        className={scss['portal-notification-sidenav']}
        PaperProps={{
          style: {
            width: '320px'
          }
        }}
        ModalProps={{
          keepMounted: true
        }}
      >
        <AppBar color="default" position="static" elevation={0}>
          <Tabs
            value={this.state.currentTabIndex}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant={'fullWidth'}
          >
            <Tab label="Notifications"/>
            <IconButton onClick={() => NotificationActions.invalidate()}><SyncRounded/></IconButton>
          </Tabs>
        </AppBar>

        <SwipeableViews
          className={scss['portal-notification-sidenav__content']}

          index={this.state.currentTabIndex}
          onChangeIndex={this.handleChangeTabIndex}
        >
          <NotificationsTab notifications={notifications} history={this.props.history}
                            toggleNotifications={this.props.toggleNotifications}/>
        </SwipeableViews>

        <Toolbar
          disableGutters
        >
          <IconButton onClick={this.props.toggleNotifications}>
            <ChevronRightIcon/>
          </IconButton>
        </Toolbar>
      </Drawer>
    );
  }
}

function mapStateToProps(state, props) {
  const notifications = NotificationSelectors.selectAll(state, props);
  const toReturn = {
    layout: {
      notificationsOpen: state.layout.notificationsOpen
    }
  };

  if (notifications)
    toReturn['notifications'] = [...notifications]

  return toReturn;
}

export default compose(
  withRouter,
  connect(mapStateToProps, {
    toggleNotifications
  }))(NotificationSidenav);
