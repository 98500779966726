import React from 'react'

const HandHeart = props => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            d="M12 2v8.166l-.918-.152H11V3H9V16.031l-2.71-1.97-.1-.053a2.424 2.424 0 00-2.782.521l-.336.409 4.98 6.109.07.074c.566.566 1.32.879 2.12.879H17c1.654 0 3-1.346 3-3v-5.807c0-.066-.011-.128-.018-.193H20V5h-2v6.168L17 11V3h-2v7.668l-1-.168V2h-2zm.74 11c.998 0 1.554.854 1.76 1.434.265-.61.813-1.434 1.771-1.434 1.076 0 1.729.999 1.729 2.049 0 1.527-3.5 4.094-3.5 4.094S11 16.567 11 15.049c0-1.05.664-2.049 1.74-2.049z"
        />
    </svg>
)

export default HandHeart
