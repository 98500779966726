import React from 'react'
import { Box, Button, Typography, Divider, LinearProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import AuthHeader from './component/AuthHeader'
import Form from './component/forms/ForgotPasswordForm'
import { AuthContext } from 'containers/auth/Authenticator'
import { Auth } from 'aws-amplify'
import swal from 'sweetalert'
import { STAGES } from './SignUp'
import UserActions from 'redux/actions/user.actions';

const useStyles = makeStyles(theme => ({
    heading: {
        fontWeight: '600',
        margin: 0,
        marginBottom: '1.5em',
        '&::after': {
            content: "''",
            display: 'block',
            height: '3px',
            width: '50px',
            backgroundColor: theme.palette.primary.main,
            position: 'relative',
            top: '8px',
            marginBottom: ' 0.25em',

        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.3em',
        }
    }
}))

export default function ForgotPassword() {
    const classes = useStyles();
    const [delivered, setDelivered] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false);
    const formRef = React.useRef(null);
    const { onAuthStateChange } = React.useContext(AuthContext)

    const handleOnSubmit = () => {
        const formData = formRef.current.getFormData()
        setError(false);
        if (!delivered && formData) {
            setLoading(true);
            const { email } = formData
            const _email = email.toLowerCase().trim()
            Auth.forgotPassword(_email).then(res => {
                setLoading(false);
                setDelivered(true)
            }).catch(async e => {
                if (e.message.includes('verified')) {
                    Auth.resendSignUp(_email).then(() => {
                        setLoading(false)
                        swal("Info", `Your email (${_email}) is not verified. A new verification link is sent to your email. Please confirm your email address.`, "info");
                        sessionStorage.setItem('AUTH_DATA', JSON.stringify({ email: _email, isConfirmed: false }))
                        onAuthStateChange('SignUp', STAGES.ConfirmContact)
                    }).catch(err => {
                        swal("Error", err.message, "error");
                        setLoading(false)
                    })
                  } else {
                    const user = await UserActions.checkIfUserExists(_email)
                    if (user) {
                        if (user.id.includes("Google")) {
                            setError("Please sign in via Google as you have signed up with that option.")
                        } else if (user.id.includes("Facebook")) {
                            setError("Please sign in via Facebook as you have signed up with that option.")
                        } else if (user.id.includes("SignInWithApple")) {
                            setError("Please sign in via Apple as you have signed up with that option.")
                        } else {
                            setError("There seems to be a problem with your account. Please contact support.")
                        }
                    } else {
                        setError(e.message || 'Please check if your email address is correct!');
                    }
                    setLoading(false)
                  }
            })
        }
        if (delivered && formData) {
            const { email, code, password } = formData
            setLoading(true);
            Auth.forgotPasswordSubmit(email.toLowerCase().trim(), code.trim(), password)
                .then(data => {
                    setLoading(false);
                    const finalStageData = {
                        heading: 'Password Changed',
                        description: `You have successfully changed your password. Please go back to login and sign in again.`
                    }
                    onAuthStateChange && onAuthStateChange('FinalStage', null, finalStageData)
                })
                .catch(err => {
                    setLoading(false);
                    setError(err.message);
                });
        }

    }

    const handleCloseError = () => {
        setError(false);
    }

    return (
        <AuthHeader>
            <Box pt={3} pb={1} maxWidth={350}>
                <Box mb={2} px={4}>
                    <h2 className={classes.heading}>{"Forgot Password"}</h2>
                    <Typography color="textSecondary" variant='body2'>
                        {delivered ? `We have sent you a code to your email address, please enter it below.` : `Please enter your registered email address to reset password.`}
                    </Typography>
                </Box>
                <Box px={4} mb={4} minHeight={250} >
                    <form onKeyPress={e => e.key === 'Enter' ? handleOnSubmit() : {}}>
                        <Form ref={formRef} delivered={delivered} />
                    </form>
                    {error && <Box mt={4} >
                        <Alert id="error" severity="error" onClose={handleCloseError} >{error}</Alert>
                    </Box>}
                </Box>
                {loading && <LinearProgress />}
                <Divider />
                <Box px={3} pt={1} display="flex" justifyContent={'space-between'}>
                    <Button id="btn-back" onClick={() => onAuthStateChange && onAuthStateChange('SignIn')}>{'Back'}</Button>
                    <Button id="btn-submit" disabled={loading} color="primary" onClick={handleOnSubmit} >{!delivered ? 'Submit' : 'Save New Password'}</Button>
                </Box>
            </Box>

        </AuthHeader>
    )
}

ForgotPassword.displayName = "ForgotPassword"