import React from "react";
import { Input, FormValidator, Select } from "components/validator.components/index";
import { Grid } from "@material-ui/core";
const PRIMARY_ROLES = [{ label: 'Parent / Community Member', value: ['parent'] }, { label: 'Faculty / School Employee', value: ['school'] }]

class UserDetailForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.valid = this.valid.bind(this);
        this.getFormData = this.getFormData.bind(this);
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        let stateToSet = { ...prevState }
        if (nextProps.invitedFamily) {
            const userDetails = nextProps?.invitedFamily ? { ...nextProps.invitedFamily.user } : {}
            stateToSet = {
                role: PRIMARY_ROLES[0],
                firstName: userDetails?.given_name,
                lastName: userDetails?.family_name,
                email: userDetails?.username
            }
        }
        if (nextProps.data && !prevState.email) {
            stateToSet = Object.assign(stateToSet, {
                firstName: nextProps.data.firstName,
                lastName: nextProps.data.lastName,
                email: nextProps.data.email
            })
        }
        return stateToSet
    }

    componentDidMount() {
        const authData = JSON.parse(sessionStorage.getItem('AUTH_DATA'));
        if (authData && authData.email) {
            this.setState({ email: authData.email })
        }
    }

    getFormData() {
        const { hasRole } = this.props
        if (this.valid()) {
            const { firstName, lastName, email, password, cpassword, role } = this.state
            return hasRole
                ? { firstName, lastName, email, password, cpassword }
                : { firstName, lastName, email, password, cpassword, role: role.value }
        } else
            return false

    }

    render() {
        const { hasRole, invitedFamily, authenticated } = this.props
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Input
                        id="firstName"
                        name={"firstName"}
                        label="First Name"
                        required={true}
                        self={this}
                        editable={true}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        required={true}
                        self={this}
                        editable={true}
                    />
                </Grid>
                <Grid item xs={12} >
                    <Input
                        id="email"
                        name="email"
                        label="Email"
                        required={true}
                        self={this}
                        editable={true}
                    />
                </Grid>
                {!hasRole &&
                    <Grid item xs={12}>
                        <Select
                            id="role"
                            name={'role'}
                            label={'Role'}
                            options={PRIMARY_ROLES}
                            isDisabled={!!invitedFamily}
                            self={this}
                        />
                    </Grid>
                }
                {!authenticated &&
                    <>
                        <Grid item xs={12}>
                            <Input
                                id="password"
                                name="password"
                                label="Password"
                                type="password"
                                required={true}
                                self={this}
                                editable={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                id="confirm-password"
                                name="cpassword"
                                label="Repeat Password"
                                type="password"
                                required={true}
                                self={this}
                                editable={true}
                            />
                        </Grid>
                    </>
                }
            </Grid>
        )
    }

    valid() {
        const self = this;
        const { hasRole, authenticated } = this.props
        let { required, email, isEqualTo, password } = FormValidator;
        let validations = authenticated ? {
            firstName: required,
            lastName: required,
            email: [required, email]
        } : {
            firstName: required,
            lastName: required,
            email: [required, email],
            password: [required, password],
            cpassword: [required, isEqualTo('password')]
        };

        if (!hasRole)
            validations.role = required

        const result = FormValidator.validate(self, validations)
        return result;
    }
}

export default UserDetailForm