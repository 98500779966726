import FetchAction from "../FetchAction";
import UpdateAction from "../UpdateAction";
import * as API from "rest/API";
import { store } from "../ReduxStore";
import { Auth, Logger } from "aws-amplify";

import EntityActions from "redux/actions";
import { getImpersonatedUser } from "rest/API";

const OrgUserActions = EntityActions.orgUser;

const organizationsFetch = () =>
  new FetchAction("ORGANIZATIONS", API.getUserOrganizations);
const organizationUpdate = (remoteAction, reducerType) =>
  new UpdateAction(
    "ORGANIZATIONS",
    remoteAction || API.newOrganization,
    reducerType
  );
const PledgeFetcher = () =>
  new FetchAction("VOLUNTEERING_STATUS", API.getMyFamilyPledges);
const logger = new Logger("User Actions");

export default class User {
  static actions = {
    USERS: "USERS",
    CURRENT_USER: "CURRENT_USER",
    UPDATE_USER: "UPDATE_USER",
    UPDATE_CURRENT_USER: "UPDATE_CURRENT_USER",
    SIGN_OUT: "SIGN_OUT",
    RESET_USER: "RESET_USER",
    SUBSCRIBE: "SUBSCRIBE",
    CURRENT_ORGANIZATION: "CURRENT_ORGANIZATION",
    USER_FAMILY: "USER_FAMILY",
    UPDATE_USER_FAMILY: "UPDATE_USER_FAMILY",
    INVALIDATE_USER_FAMILY: "INVALIDATE_USER_FAMILY"
  };

  /* INVITATIONS ACTIONS */

  static getInvitations = async (params, callback) => {
    try {
      const result = await API.getInvitations(params);
      if ((result == null || result) && callback) {
        callback(result, undefined);
      }
    } catch (error) {
      const err =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        error;
      callback(undefined, err);
    }
  };

  static sendInvitation = async (body, callback) => {
    try {
      const result = await API.sendInvitation(body);
      console.log(result);
      if (result && callback) callback(result, undefined);
    } catch (error) {
      const err =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        error;
      callback(undefined, err);
    }
  };

  /* INVITATION ACTIONS END */

  static signIn = (credentials, callback) => {
    if (credentials) {
      Auth.signIn(credentials.username, credentials.password)
        .then(authUser => {
          this.getAuthUser(authUser, callback);
        })
        .catch(err => {
          callback(undefined, err);
        });
    }
  };

  static signOut = () => {
    store.dispatch({
      type: this.actions.SIGN_OUT
    });
    localStorage.removeItem("demo-welcome");
    localStorage.removeItem("unsaved");
    Auth.signOut();
  };

  static getAuthUser = async (authUser, callback) => {
    localStorage.removeItem("unsaved");
    if (authUser) return parseAuthUser(authUser, callback);

    if (!authUser)
      try {
        const cAuthUser = await Auth.currentUserInfo();
        if (!cAuthUser || !cAuthUser.id) {
          return this.signOut();
        }

        return parseAuthUser(cAuthUser, callback);
      } catch (err) {
        console.error(err);
        this.signOut();
      }
  };

  static updateUser = payload => {
    store.dispatch({
      type: this.actions.UPDATE_USER,
      payload
    });
    store.dispatch({
      type: this.actions.UPDATE_CURRENT_USER,
      payload
    });
  };

  static updateCurrentUser = () => {
    this.getAuthUser();
  }

  static setCurrentOrg = org => {
    store.dispatch({
      type: this.actions.CURRENT_ORGANIZATION,
      payload: org,
      status: "success"
    });
  };

  static checkIfUserExists = async (email) => {
    return API.checkIfUserExists(email)
  }

  static validateToken = async (token) => {
    return API.validateToken(token)
  }

  static refreshOrg() {
    store.dispatch({
      type: 'REFRESH_ORGANIZATIONS'
    })
  }

  static getOrgByCode = async ({ code, email }, callback) => {
    try {
      const result = await API.getOrgByCode({ code, email });
      if (result && callback) {
        callback(result, undefined);
      }
    } catch (error) {
      const err =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        error;
      callback(undefined, err);
    }
  };

  static getOrgById = async ({ orgId }, callback) => {
    try {
      const result = await API.getOrgById({ orgId });
      if (result && callback) {
        callback(result, undefined);
      }
    } catch (error) {
      const err =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        error;
      callback(undefined, err);
    }
  };

  static fetchUserVolunteeringStatus(state, stateFilter, ...args) {
    return PledgeFetcher().doFetch(state, stateFilter, ...args);
  }

  static fetchUserOrganizations(state, stateFilter, ...args) {
    return organizationsFetch().doFetch(state, stateFilter, ...args);
  }

  static updateUserOrganization(updatedOrg, callback, invalidate = true) {
    organizationUpdate(API.updateOrganization).doUpdate(
      invalidate,
      callback,
      updatedOrg
    );
  }

  static patchUserOrganization(updatedOrg, callback, invalidate = true) {
    organizationUpdate(API.patchOrganization).doUpdate(
      invalidate,
      callback,
      updatedOrg
    );
  }

  static registerOrg = async (params, callback) => {
    try {
      const result = await API.registerOrganization(params);
      if (result && callback) {
        callback(result, undefined);
      }
    } catch (error) {
      const err =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        error;

      callback(undefined, err);
    }
  };

  static registerUser = async (params, callback) => {
    try {
      const result = await API.registerUser(params);
      if (result && callback) {
        callback(result, undefined);
      }
    } catch (error) {
      const err =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        error;
      callback(undefined, err);
    }
  };

  static previewEmail = async (params, callback) => {
    try {
      const result = await API.previewEmail(params);
      if (result && callback) {
        callback(result, undefined);
      }
    } catch (error) {
      const err =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        error;
      callback(undefined, err);
    }
  };

  static sendEmail = async (params, callback) => {
    try {
      const result = await API.sendEmail(params);
      if (result && callback) {
        callback(result, undefined);
      }
    } catch (error) {
      const err =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        error;
      callback(undefined, err);
    }
  };
  static startImpersonation = async (email, callback) => {
    const cAuthUser = await Auth.currentAuthenticatedUser();
    Auth.updateUserAttributes(cAuthUser, { "custom:impersonate": email }).then(
      (res, err) => {
        callback && callback(res, err);
      }
    );
  };

  static endImpersonation = async () => {
    const cAuthUser = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(cAuthUser, { "custom:impersonate": "" });
  };
}

const parseAuthUser = async (authUser, callback) => {
  const impersonate = authUser.attributes["custom:impersonate"];
  return impersonate && impersonate !== '0' && impersonate.length > 1
    ? impersonate_parseAuthUser(authUser, callback)
    : reg_parseAuthUser(authUser, callback);
};

const reg_parseAuthUser = async (authUser, callback, originalUser) => {
  if (!authUser) throw new Error("Need an authorized user to parse");
  if (authUser && authUser.email) OrgUserActions.setCurrentId(authUser.email);
  const user = {};
  const hasAuthAttributes = authUser.attributes["custom:auth"]
  if (hasAuthAttributes) {
    const orgsStr = JSON.parse(authUser.attributes["custom:auth"]);
    const roles = orgsStr[0].roles;
    user.orgs = orgsStr;
    user.roles =
      roles &&
      roles.map(r => {
        // if (r.toLowerCase() === 'user')
        //   r = 'parent'
        if (r.toLowerCase() === "leader") r = "creator";

        return r.toLowerCase();
      });

    user.type =
      roles &&
      (roles.includes("teacher")
        ? "teacher"
        : roles.includes("parent") || roles.includes("user")
          ? "parent"
          : roles.includes("admin")
            ? "admin"
            : "parent");
  }

  if (authUser.attributes["custom:preferences"]) {
    const preferences = JSON.parse(authUser.attributes["custom:preferences"]);
    user.preferences = preferences;
  }

  if (authUser.attributes["custom:isRootAccount"]) {
    const isRootUser = authUser.attributes["custom:isRootAccount"] === 1;
    user.isRootUser = isRootUser;
  }
  if (authUser.attributes['identities']) {
    user.identities = JSON.parse(authUser.attributes['identities']);
  }

  user.id = authUser.username;
  user.firstName = authUser.attributes["given_name"];
  user.lastName = authUser.attributes["family_name"];
  user.email = authUser.attributes["email"];
  user.cognitoUser = authUser;
  user.isLoggedIn = true;

  if (originalUser) {
    user.impersonated = true;
    user.originalUser = originalUser;
  }

  if (hasAuthAttributes)
    store.dispatch({
      type: User.actions.CURRENT_USER,
      status: "success",
      payload: user
    });

  callback && callback(user, undefined);
};

const impersonate_parseAuthUser = async (authUser, callback) => {
  if (!authUser) throw new Error("Need an authorized user to parse");

  const username = authUser.attributes["custom:impersonate"];
  try {
    const rawUser = await getImpersonatedUser(username);

    rawUser.attributes = {};
    rawUser.userAttributes.forEach(m => (rawUser.attributes[m.Name] = m.Value));

    rawUser.cognitoUser = {
      username: rawUser.id
    };
    rawUser.username = rawUser.id;

    console.log("Prepared to send rawUser", rawUser);

    return reg_parseAuthUser(rawUser, callback, authUser);
  } catch (e) {
    console.error("Unable to impersonate!", e, e.toString());

    return reg_parseAuthUser(authUser, callback);
  }
};
