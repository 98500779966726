import { fade } from '@material-ui/core/styles/colorManipulator';
import tabStyles from './tab.styles'
const styles = theme => ({
    ...tabStyles(theme),
    toolbar: {
        padding: 0,
        backgroundColor: 'white',
    },
    toolbarContainer: {
        display: 'flex',
        flex: 1,
        position: 'relative',
        alignItems: 'center',
        flexDirection: 'row',
        paddingLeft: '24px',
        paddingRight: '24px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.black, 0.08),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.15),
        },
        marginLeft: 0,
        marginRight: theme.spacing(1),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(1) * 6,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1) * 7,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },
    brand: {
        color: '#FFF',
        backgroundColor: theme.palette.primary.main,
        paddingLeft: '24px',
        paddingRight: '24px',
        height: '64px',
        minWidth: '50px'
    },
    brandImage: {
        maxHeight: '64px',
        maxWidth: '200px',
        padding: '8px 0px',
        boxSizing: 'border-box',
        objectFit: 'contain',
        filter: 'brightness(0) invert(1)'
    },
    titleContainer: {
        display: 'flex',
        height: '64px',
        alignItems: 'center',
        // margin: '0 8px',
        padding: '0 24px 0 16px',
        [theme.breakpoints.down('sm')]: {
            borderRight: 0,
            '& h6': {
                fontSize: '1rem'
            }
        },
        [theme.breakpoints.down('xs')]: {
            borderRight: 0,
            padding: '0 8px',
            '& h6': {
                fontSize: '1rem'
            }
        },

    },

})


export default styles