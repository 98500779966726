import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

import { toggleSidenav } from 'redux/actions/layout.actions';
import { getOrgTheme } from 'redux/selectors/data.selector';
import * as UserSelectors from 'redux/selectors/user.selector';
import scss from './sidenav.module.scss';
import themes from 'themes';
import defaultLogo from 'assets/images/connect1.png'

import { hasCompletedOnboarding } from 'utils/auth'

import EntitySelectors from 'redux/selectors';

const OrgUserSelectors = EntitySelectors.orgUser


const Sidenav = (props) => {
    const { theme, layout, currentOrganization, history, currentOrgUser } = props;

    if (!currentOrganization || !currentOrgUser) return null;

    const sidenavTheme = createTheme(theme.sidenavTheme);
    const dockedClass = layout.sidenavOpen ? scss[`portal-sidenav-${layout.currentLayout}`] : '';

    const completedOnboarding = hasCompletedOnboarding(currentOrgUser, currentOrganization)

    const onLogoClick = completedOnboarding ? () => history && history.push("/") : () => { }

    return (
        <MuiThemeProvider theme={sidenavTheme}>
            <Drawer
                variant={layout.sidenavVariant}
                open={layout.sidenavOpen}
                onClose={props.toggleSidenav}
                classes={{
                    docked: dockedClass,
                    paper: scss[`portal-sidenav-${layout.currentLayout}`]
                }}
            >
                {/* top toolbar with logo */}
                <AppBar
                    elevation={0}
                    classes={{
                        root: scss['app-bar'],
                        colorDefault: scss['app-bar']
                    }}
                    color="default"
                    position="static"
                >
                    <Toolbar className={scss['toolbar']} style={!completedOnboarding ? { cursor: 'default' } : null} >
                        {currentOrganization.logo && currentOrganization.logo.url ?
                            <img src={currentOrganization.logo.url} className={scss['portal-toolbar-brand']} alt="logo"
                                onError={(e) => { e.target.onerror = null; e.target.src = defaultLogo }} onClick={onLogoClick} />
                            :
                            <img src={defaultLogo} className={scss['portal-toolbar-brand']} alt="logo" onClick={onLogoClick} />
                        }
                    </Toolbar>
                </AppBar>
                {/* main menu */}
                {props.children}
            </Drawer>
        </MuiThemeProvider>
    );
};

function mapStateToProps(state, props) {
    const defaultTheme = themes[1].theme;
    return {
        theme: getOrgTheme(state, props) || defaultTheme,
        currentOrganization: UserSelectors.currentOrganization(state, props),
        currentOrgUser: OrgUserSelectors.current(state, props),
        layout: {
            currentLayout: state.layout.currentLayout,
            sidenavOpen: state.layout.sidenavOpen,
            sidenavVariant: state.layout.sidenavVariant,

        }
    };
}

Sidenav.propTypes = {
    toggleSidenav: PropTypes.func.isRequired,
    layout: PropTypes.shape({}).isRequired,
    theme: PropTypes.shape({}).isRequired,
    children: PropTypes.shape({}).isRequired
};

export default compose(
    withRouter,
    connect(mapStateToProps, {
        toggleSidenav
    })
)(Sidenav);
