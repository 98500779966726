import { makeStyles } from '@material-ui/core/styles'
import React from 'react';
import classNames from 'classnames';
import { Typography, Hidden, Box, Paper } from '@material-ui/core';

import scss from '../styles/authentication.module.scss';
import logoImage from 'assets/images/connect1.png';

import themeStyles from '../styles/authentication.style';

const useStyles = makeStyles(themeStyles);

export default function AuthHeader({ children }) {
    const classes = useStyles();
    
    return (
        <Box className={classes.background}>
            <Hidden smUp>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={3} px={3} >
                    <img src={logoImage} className={scss['logo']} alt="logo" />
                    <Typography variant="body1" align="center">
                        {'A smarter way for schools to engage today’s “modern village”'}
                    </Typography>
                </Box>
            </Hidden>
            <Box p={2} display="flex">
                <Box display="flex" component={Paper} overflow="hidden" >
                    <Hidden xsDown>
                        <Box className={classNames(classes.primaryCard)} overflow="hidden" height="100%">
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={3} minHeight={300} height="100%">
                                <img src={logoImage} className={classes.logo} alt="logo" />
                                <Typography variant="body1" align="center">
                                    {'A smarter way for schools to engage today’s “modern village”'}
                                </Typography>
                            </Box>
                        </Box>
                    </Hidden>
                    {children}
                </Box>
            </Box>
        </Box>
    )
}

