import { createSelector } from 'reselect';
import { cloneDeep } from 'lodash'



// Helpers
import * as EventHelper from 'helper/EventHelper'
import UserActions from 'redux/actions/user.actions'


const mockdata = {}; //TODO: FIX ME!!!
// private selectors
const currentUserState = (state) => state.currentUser;
const usersState = (state) => state.users;
const currentClassId = (state, props) => props.classId
const classesState = (state, props) => state.classes

export const currentOrganizationState = (state) => state && state.currentOrganization;
const organizationsState = (state) => state && state.organizations;
export const userHasRole = (state, props) => state.currentUserState.user.includes(props.role)

export const currentUser = createSelector(
  [currentUserState],
  (currentUserState) => {

    if (!currentUserState.user)
      return undefined
    return { ...currentUserState.user }
  }
)

export const isLoggedIn = createSelector(
  [currentUser],
  (currentUser) => {

    // return true
    if (!currentUser || !currentUser.isLoggedIn) {
      return false
    } else
      return true
  }
)
export const allUsers = createSelector(
  [usersState],
  (usersState) => {

    if (!usersState.users) {
      return undefined
    } else
      return usersState.users
  }
)

export const userOrganizations = createSelector(
  [currentUser, currentUserState, organizationsState],
  (currentUser, userState, organizationsState) => {
    if (!currentUser)
      return undefined

    // If current User does not have any orgs pass empty array as params
    if (!currentUser.orgs)
      currentUser.orgs = []

    if (!UserActions.fetchUserOrganizations(organizationsState, () => {
      return organizationsState.lastUpdated !== undefined && organizationsState.lastUpdated >= userState.lastUpdated;
    }, currentUser.orgs))
      return undefined;

    let userOrganizations = organizationsState.organizations

    return userOrganizations
  }
)

export const currentOrganization = createSelector(
  [currentOrganizationState, userOrganizations, organizationsState],
  (currentOrganizationState, userOrganizations, organizationsState) => {

    if (currentOrganizationState.lastUpdated > organizationsState.lastUpdated) {
      return currentOrganizationState.currentOrganization;
    }

    if (!userOrganizations)
      return undefined;

    const defaultSelectOrg = localStorage.getItem('selectedSchool');

    const currentOrg = (defaultSelectOrg ? userOrganizations.find(org => org.id === defaultSelectOrg) : userOrganizations[0])
      || userOrganizations[0];

    UserActions.setCurrentOrg(currentOrg);
    return currentOrg
  }
)
export const userSchool = createSelector(
  [currentUser],
  (currentUser) => {
    if (!currentUser)
      return undefined

    const school = mockdata.schools.find(s => s.id === currentUser.schoolId)
    return school
  }
)

export const isAdmin = createSelector(
  [currentUserState],
  (currentUserState) => {
    const user = currentUserState.user
    if (!user)
      return false
    return user.role && user.role.includes('admin')
  }
)

export const isCreator = createSelector(
  [currentUserState],
  (currentUserState) => {
    const user = currentUserState.user
    if (!user)
      return false
    return user.role && user.role.includes('creator')
  }
)
/**
 * return {
 *    ...userDetails,
 *    children: []
 * }
 */
export const getUserChildren = createSelector(
  [currentUser],
  (currentUser) => {
    if (!currentUser)
      return undefined

    let children = []
    const families = mockdata.families
    let userFamily = families.find(family => {
      return family.guardians && family.guardians.find(g => g.userId === currentUser.id)
    })

    if (userFamily) {
      currentUser.family = userFamily
      if (userFamily.childrenIds && userFamily.childrenIds.length) {
        children = userFamily.childrenIds.map(childId => mockdata.children.find(ch => ch.id === childId))
        userFamily.children = children
      }
    }

    return { ...currentUser, children }
  }
)

export const getAllClasses = createSelector(
  [classesState, currentUser],
  (classesState, currentUser) => {
    if (!classesState)
      return undefined

    return classesState.classes
  }
)

const eventsState = (state, props) => state.events;

export const getAllEvents = createSelector(
  [eventsState, currentOrganization],
  (eventsState, organization) => {
    if (!eventsState.events || !organization)
      return undefined;

    return eventsState.events
  }
)

export const getSingleClass = createSelector(
  [getAllClasses, currentClassId, getAllEvents],
  (classes, classId, allEvents) => {
    if (!classes || !classId || !allEvents)
      return undefined

    let classData = classes.find(cl => cl.id === classId)
    classData.students = mockdata.children.filter(ch => ch.classIds.includes(Number(classId)))
    classData.teacher = mockdata.users.find(u => u.id === classData.teacherId)
    classData.calendar = allEvents.filter(event => {
      return event.type === 'classEvent' && event.subType === 'calendar' && event.classIds.includes(Number(classId))
    }).map(event => EventHelper.generateCalendarEvent(event, classes))
    return classData
  }
)

export const userWithFamilyDetails = createSelector(
  [currentUser, allUsers, getAllEvents, getAllClasses],
  (currentUser, allUsers, allEvents, allClasses) => {

    if (!currentUser || !allUsers || !allEvents || !allClasses) {
      return undefined
    } else
      return parseUserData(currentUser, allUsers, allEvents, allClasses)
  }
)

/**
 * return user = {
 *    user details,
 *    family: {
 *      guardians:[],
 *      children:[]
 *    }
 * }
 */
const parseUserData = (currentUser, allUsers, allEvents, allClasses) => {
  let user = cloneDeep(currentUser)
  const families = cloneDeep(mockdata.families)
  let userFamily = families.find(family => {
    return family.guardians && family.guardians.find(g => g.userId === currentUser.id)
  })

  if (userFamily) {
    // User Guardian Details
    if (userFamily && userFamily.guardians && userFamily.guardians.length) {
      const guardians = userFamily.guardians.map(g => {
        const userDetails = allUsers.find(u => u.id === g.userId)
        return { ...g, ...userDetails }
      })
      userFamily.guardians = guardians
    }
    // Children Details
    if (userFamily.childrenIds && userFamily.childrenIds.length) {
      const children = userFamily.childrenIds.map(childId => {
        const child = mockdata.children.find(ch => ch.id === childId)
        if (child) {
          const childHomeClass = allClasses.find(cl => cl.id === child.mainClassId)
          const childHomeClassTeacher = allUsers.find(u => u.id === childHomeClass.teacherId)
          const childEvents = allEvents.filter(evt => evt.classIds && isChildClassEvent(child.classIds, evt.classIds)) //evt.classIds.includes(child.gradeId))
            .map(evt => {
              // Check if event is School-wide Event Impacting Student
              const hasImpact = evt.type === 'schoolEvent' && evt.classIds && isChildClassEvent(child.classIds, evt.classIds)
              return (EventHelper.generateCalendarEvent({ ...evt, hasImpact }, allClasses))
            })
          const calendar = (childEvents && childEvents.filter(evt => evt.subType === 'calendar')) || []
          const schedule = (childEvents && childEvents.filter(evt => evt.subType === 'schedule')) || []
          return {
            ...child,
            calendar,
            schedule,
            classLabel: childHomeClassTeacher.firstName + " - " + childHomeClass.title
          }
        }

        return undefined

      })
      userFamily.children = children
    }

    user.fullName = user.firstName + " " + user.lastName
    user.family = cloneDeep(userFamily)
  }

  return user

}

const isChildClassEvent = (childClassedIds, eventClassIds) => {
  return childClassedIds.filter(id => eventClassIds.includes(id)).length > 0;
}


