import React from 'react'
import styles from "../styles/authentication.style";
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import { Box, Button, Divider, Typography, Collapse } from '@material-ui/core'
import {
    PersonOutline as Person,
    HomeWorkOutlined as HomeWork,
    ArrowForward,
    KeyboardArrowDown,
    KeyboardArrowUp
} from '@material-ui/icons'
import clsx from 'classnames'
import Pentagon from 'icons/Pentagon'
import { AuthContext } from 'containers/auth/Authenticator'
import { STAGES } from '../SignUp'
import { Auth } from 'aws-amplify';
const TYPES = {
    school: 'SCHOOL',
    user: 'USER'
}
const ROLES = {
    SCHOOL: [
        { id: 'school-leader', label: 'School Leader', value: ['admin', 'school'] },
        { id: 'parent-leader', label: 'Parent Leader', value: ['admin', 'parent'] }
    ],
    USER: [
        { id: 'faculty', label: 'Faculty / School Employee', value: ['school'] },
        { id: 'parent', label: 'Parent / Community Member', value: ['parent'] }
    ]
}
function AccountType({ classes, onStepChange }) {
    const classes2 = useStyles();

    const { onAuthStateChange, authData, hasCustomAuth } = React.useContext(AuthContext)
    const [accountType, setAccountType] = React.useState('');
    const handleAccountTypeSelector = type => {
        // if (authData && hasCustomAuth)
        // onStepChange(STAGES.)
        if (type === accountType)
            setAccountType('')
        if (type === TYPES.school && accountType !== TYPES.school)
            setAccountType(TYPES.school)
        if (type === TYPES.user && accountType !== TYPES.user)
            setAccountType(TYPES.user)
    }

    const handleOnBack = () => {
        if (!hasCustomAuth && authData)
            Auth.signOut();

        onAuthStateChange('SignIn')
    }

    const handleRoleSelector = (role = ['parent']) => {
        let nextStep, authStep

        authStep = accountType === TYPES.school
            ? 'AdminRegister' : 'UserRegister'

        nextStep = accountType === TYPES.school
            ? STAGES.SchoolSelector : STAGES.SchoolCode

        onStepChange && onStepChange({ nextStep, data: { role }, authStep })
    }

    return (

        <Box pt={3} pb={1} maxWidth={450}>
            <Box mb={2} px={4}>
                <h2 className={classes.heading}>{"Select Account Type"}</h2>
                <Typography color="textSecondary" variant="body2">
                    {(!hasCustomAuth && authData)
                        ? `Your account ${authData?.attributes?.email} is not associated with any school in our record. Please select one option to continue.`
                        : `Select account type to continue. Please note that school account holder should have admin right to school records.`
                    }
                </Typography>
            </Box>
            <Box px={4} mb={4} minHeight={350}>
                <Box id="account-selector-user" className={clsx(classes2.option, classes2.primary)} mb={1} p={2} onClick={() => handleAccountTypeSelector(TYPES.user)}>
                    <Box mr={2} ml={1} display="flex" className={classes2.iconContainer}>
                        <Pentagon id={"pentagon"} />
                        <Person id={'icon'} fontSize='small' />
                    </Box>
                    <Box display="flex" flex={1} flexDirection={'column'}>
                        <Typography variant='h6' color="inherit">{`User Account`}</Typography>
                        <Typography variant='body2' color="inherit" >{`Personal account to manage all your activities.`}</Typography>
                    </Box>
                    <Box ml={1} display="flex">
                        {accountType === TYPES.user ? <KeyboardArrowUp color="primary" /> : <KeyboardArrowDown color="primary" />}
                    </Box>
                </Box>
                <Collapse in={accountType === TYPES.user}>
                    <RoleSelector items={ROLES[accountType]} onRoleSelect={handleRoleSelector} />
                </Collapse>
                <Box id="account-selector-school" className={clsx(classes2.option, classes2.primary)} mb={1} p={2} onClick={() => handleAccountTypeSelector(TYPES.school)}>
                    <Box ml={1} mr={2} className={classes2.iconContainer}>
                        <HomeWork id={"icon"} fontSize='small' />
                        <Pentagon id={"pentagon"} />
                    </Box>
                    <Box display="flex" flex={1} flexDirection={'column'} >
                        <Typography variant='h6' color="inherit" >{`Register School`}</Typography>
                        <Typography variant='body2' color="inherit" >{`Create an admin user account for your school.`}</Typography>
                    </Box>
                    <Box ml={1} display="flex">
                        {accountType === TYPES.school ? <KeyboardArrowUp color="primary" /> : <KeyboardArrowDown color="primary" />}
                    </Box>
                </Box>
                <Collapse in={accountType === TYPES.school}>
                    <RoleSelector items={ROLES[accountType]} onRoleSelect={handleRoleSelector} />
                </Collapse>
            </Box>
            <Divider />
            <Box px={4} pt={1}>
                <Button id="btn-back" onClick={handleOnBack}>{'Back'}</Button>
            </Box>
        </Box>
    )
}

const RoleSelector = ({ items = [], onRoleSelect }) => {
    const classes2 = useStyles();
    const theme = useTheme();
    return items.map((item, index) => (
        <Box id={"acs-" + item.id} key={index + '-role'} className={clsx(classes2.option, classes2.secondary)} mb={1} px={2} py={1} onClick={() => onRoleSelect(item.value)}>
            {item?.icon &&
                <Box mr={2} display="flex" className={classes2.iconContainer}>
                    <Pentagon id={"pentagon"} color={theme.palette.secondary.main} />
                    {item?.icon}
                </Box>
            }
            <Box display="flex" flex={1} flexDirection={'column'}>
                <Typography variant='h6' color="inherit">{item.label}</Typography>
            </Box>
            <Box ml={1} display="flex">
                <ArrowForward color="secondary" />
            </Box>
        </Box>
    ))
}

const useStyles = makeStyles(theme => ({
    option: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 6,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#ffe2b8',
        }
    },
    primary: {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: '#FFF6E9',
        '&:hover': {
            backgroundColor: '#ffe2b8',
        }
    },
    secondary: {
        border: `1px solid ${theme.palette.secondary.main}`,
        backgroundColor: '#E8F8FF',
        '&:hover': {
            backgroundColor: '#C5EAFA',
        }
    },
    iconContainer: {
        display: 'flex',
        marginRight: 16,
        position: 'relative',
        width: 45,
        height: 45,
        justifyContent: 'center',
        alignItems: 'center',
        '& #pentagon': {
            position: 'absolute',
            width: 40,
            height: 40,
            zIndex: 1,
            fill: theme.palette.primary.main
        },
        '& #icon': {
            zIndex: 2,
            fill: '#fff'
        }
    },
    pentagon: {
        position: 'relative',
        width: '54px',
        boxSizing: 'content-box',
        borderWidth: ' 50px 18px 0',
        borderStyle: 'solid',
        borderColor: 'red transparent',
        '&:after': {
            content: "",
            position: 'absolute',
            height: 0,
            width: 0,
            top: '30px',
            left: '-0px',
            borderWidth: '0 45px 35px',
            borderStyle: 'solid',
            borderColor: 'transparent transparent red',
        }
    }

}))
export default withStyles(styles)(AccountType);