import React from 'react';
import { bind } from 'utils/WebUtils';
import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { InfoHelpPopover } from "../validator.components/InfoPopup";

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    // margin:8
  },
})

class CustomInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

    bind(this, 'onChange', 'getStateName')
  }

  getStateName() {
    let {
      name,
      discriminator,
    } = this.props;

    return discriminator ? `${name}_${discriminator}` : name;
  }


  onChange(event) {
    let { onChange, name, self, discriminator } = this.props;

    let stateName = this.getStateName()

    let value = event.target.value;

    self.setState({
      [stateName]: value,
    }, () => onChange && onChange({ name, discriminator, value }))
  }


  render() {

    const {
      editable = true,
      infoHelp,
      name,
      placeholder,
      self,
      classes,
      onChange,
      margin,
      type,
      id,
      ...rest
    } = this.props;


    let stateName = this.getStateName()

    let value = self.state[stateName] || ""
    let error = self.state[`${stateName}State`] === 'error'
    let errorMessage = self.state[`${stateName}Error`]

    let isPassword = type === 'password'
    let { showPassword = false } = this.state;

    let fieldType = isPassword ? !showPassword ? 'password' : 'text' :
      type;
    let inputProps = {}
    if (isPassword) {
      inputProps['endAdornment'] = (<InputAdornment position="end">
        <IconButton
          color='default'
          edge="end"
          aria-label="toggle password visibility"
          onClick={() => {
            this.setState({ showPassword: !showPassword })
          }}
          onMouseDown={(event) => {
            event.preventDefault();
          }}>
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>)
    }
    if (infoHelp) {
      inputProps['endAdornment'] = (<InputAdornment position="end">
        <InfoHelpPopover infoHelp={infoHelp} />
      </InputAdornment>)
    }
    return (
      <div className={classes.root}>
        <TextField
          id={id}
          name={name}
          margin={margin || 'none'}
          variant="outlined"
          value={value}
          disabled={!editable}
          onChange={this.onChange}
          fullWidth
          placeholder={placeholder}
          error={error}
          helperText={error && errorMessage(this.props)}
          type={fieldType}
          InputProps={inputProps}
          {...rest}
        />
      </div>
    )
  }
}


export default withStyles(styles, { withTheme: true })(CustomInput);
