import React from 'react'
import {
  Apps as AppsIcons,
  ExitToApp as ExitIcon,
  Favorite as FavoriteIcon,
  Help as HelpIcon,
  PersonOutline as PersonIcon,
  PeopleOutline as PeopleIcon,
  Tune as AdminIcon,
  Dashboard as DashboardIcon
} from '@material-ui/icons';
import { HandHeart as VolunteeringIcon, Pray as PrayIcon } from 'icons/index'
const routes = {
  login: '/login',
  home: '/',
  volunteers: '/volunteers'
}
const pkgConfig = require("../../package.json");
const isCloudDeployment = pkgConfig.config.isCloudDeployment;

const iconStyle = {
  height: 20,
  width: 20,
  fill: '#fff'
};

export const routeConfig = {
  home: {
    path: '/',
    isHome: true,
    routes: [
      {
        title: 'Dashboard',
        isMenu: true,
        path: '/',
        icon: <DashboardIcon />,
        authorized: ['creator', 'admin', 'parent'],
      },
      {
        title: 'My Apps',
        isMenu: true,
        path: '/apps',
        icon: <AppsIcons />,
        authorized: ['creator', 'admin', 'parent'],
      },
      {
        title: 'Volunteer',
        isMenu: true,
        path: '/volunteers/board',
        icon: <VolunteeringIcon style={{ height: 25, width: 25, fill: '#fff' }} />,
      },
      {
        title: 'Prayer Request',
        isMenu: true,
        path: '/prayer-board',
        icon: <PrayIcon height={25} width={25} fill={'#fff'} />,
      },
      {
        title: 'Community',
        isMenu: true,
        path: '/community',
        icon: <PeopleIcon />,
      },
      {
        title: 'Profile',
        path: '/profile',
        isMenu: true,
        icon: <PersonIcon />,
        authorized: ['creator', 'admin', 'parent']
      },
      {
        title: 'Support',
        icon: <HelpIcon style={iconStyle} />,
        external: true,
        path: 'https://help.connect1.io/en/',
        isMenu: true,
        authorized: ['creator', 'admin', 'parent'],
      },
      {
        title: 'Logout',
        path: '/logout',
        isMenu: true,
        icon: <ExitIcon />,
        authorized: ['creator', 'admin', 'parent']
      },
      !isCloudDeployment && {
        title: 'Admin',
        path: '/admin',
        isMenu: true,
        icon: <AdminIcon />,
        authorized: ['admin']
      },
      {
        path: '/school-profile',
        title: 'Your School Profile'
      },
      {
        path: '/community',
        title: 'School Community'
      },
      {
        title: 'Edit Event',
        path: '/volunteers-edit/:id',
        isMenu: false,
        authorized: ['creator', 'admin',],
      }, {
        title: 'Event View',
        path: '/volunteers/:id',
        isMenu: false,
        authorized: ['creator', 'admin',],
      },
    ].filter(Boolean)
  },


}

export default routes
