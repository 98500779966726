import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Utils
import UserActions from 'redux/actions/user.actions'
// components
import Classic from 'layouts/layout-classic/layout-classic.component';
import { LinearProgress } from "@material-ui/core";
import { getOnboardingRedirectPath } from 'utils/auth'

const asyncComponent = importStatement => {
  const Component = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

// Bulk Invite Page
const BulkInviteDialog = asyncComponent(() => import('pages/admin/pages/school-community/components/BulkInvite'));
// Users Pages
const AsyncProfile = asyncComponent(() => import('pages/user/Profile'));
const PublicProfile = asyncComponent(() => import('pages/user/PublicProfile'));
const AsyncNotFound = asyncComponent(() => import('containers/not-found/not-found.component'));
const TalentRegistration = asyncComponent(() => import('pages/user/TalentRegistration'));

// VOLUNTEER PAGES
const Dashboard = asyncComponent(() => import('pages/Dashboard'))
const VolunteerDashboard = asyncComponent(() => import('pages/volunteer/VolunteerDashboard'));
const VolunteerBoard = asyncComponent(() => import('pages/volunteer/VolunteerBoard'));
const VolunteerAdd = asyncComponent(() => import('pages/volunteer/pages/add-volunteer-event/AddVolunteer'));
const VolunteerView = asyncComponent(() => import('pages/volunteer/pages/Volunteer'));

// EVENT PAGES
const EventDetailView = asyncComponent(() => import('pages/event/detail-view/EventView'))

// FAMILY PAGES
const FamilyDashboard = asyncComponent(() => import('pages/family/FamilyDashboard'));
const FamilyRegistration = asyncComponent(() => import('pages/family/registration/FamilyRegistration'));
const RegistrationStatus = asyncComponent(() => import('pages/family/registration/RegistrationStatus'));
const SchoolCommunity = asyncComponent(() => import('pages/admin/pages/school-community/SchoolCommunity'));
const FamilyProfile = asyncComponent(() => import('pages/family/profile/FamilyProfile'));
const ParentOnboarding = asyncComponent(() => import('pages/parent/onboarding/ParentOnboarding'));
const TalentOnboarding = asyncComponent(() => import('pages/parent/onboarding/TalentOnboarding'));

// FACULTY PAGES
const FacultyProfile = asyncComponent(() => import('pages/faculty/profile/FacultyProfile'));
const FacultyOnboarding = asyncComponent(() => import('pages/faculty/onboarding/FacultyOnboarding'));

// ADMIN PAGES
const SchoolProfile = asyncComponent(() => import('pages/admin/pages/school-profile/SchoolProfile'));
const UserCommunity = asyncComponent(() => import('pages/admin/pages/school-community/UserCommunity'));
const UserProfile = asyncComponent(() => import('pages/admin/pages/school-community/UserProfile'))
const ClassSetup = asyncComponent(() => import('pages/admin/pages/class-setup/ClassSetup'))
const AssignFacultyGrade = asyncComponent(() => import('pages/admin/pages/class-setup/AssignFacultyGrade'))
const EditFacultyGrade = asyncComponent(() => import('pages/admin/pages/class-setup/EditFacultyGrade'))
const RootAdmin = asyncComponent(() => import('pages/root/RootAdmin'));
const AdvancedRegistrationConfig = asyncComponent(() => import('pages/admin/pages/config/AdvancedRegistrationConfig'))
const RegistrationConfig = asyncComponent(() => import('pages/admin/pages/config/RegistrationConfig'))
const EmailEditor = asyncComponent(() => import("pages/admin/pages/emailTemplates/Editor"));
const RecognitionBoard = asyncComponent(() => import('pages/admin/pages/recognition/RecognitionBoard'))
const RecognitionView = asyncComponent(() => import('pages/admin/pages/recognition/RecognitionView'))
const AdminOnboarding = asyncComponent(() => import('pages/admin/onboarding/AdminOnboarding'));
const CommunityInvite = asyncComponent(() => import('../components/CommunityInvite/CommunityInvite'))

// OTHER PAGES
const PrayerDashboard = asyncComponent(() => import('pages/prayer/PrayerDashboard'));
const Prayer = asyncComponent(() => import('pages/prayer/Prayer'));


setTimeout(() => {
  Dashboard.preload();
  VolunteerDashboard.preload();
  VolunteerBoard.preload();

  AsyncProfile.preload();
  UserCommunity.preload();
  SchoolCommunity.preload();
  VolunteerView.preload();
  FamilyDashboard.preload();
  FamilyRegistration.preload();
  FamilyProfile.preload();
  SchoolProfile.preload();
  AdvancedRegistrationConfig.preload();
  BulkInviteDialog.preload();
}, 1000);

const adminRoutes = [
  {
    path: "/school-profile",
    title: 'School Profile',
    exact: true,
    component: SchoolProfile,
    authorized: ['admin']
  }, {
    path: "/admin",
    title: 'Root Admin Panel',
    exact: true,
    component: RootAdmin,
    authorized: ['admin']
  }, {
    path: "/advanced-registration-config",
    title: 'User Registration',
    exact: true,
    component: AdvancedRegistrationConfig,
    authorized: ['admin']
  },
  {
    path: "/registration-config",
    title: 'User Registration',
    exact: true,
    component: RegistrationConfig,
    authorized: ['admin']
  },
  {
    path: "/users",
    title: 'School Users',
    component: UserCommunity,
    exact: true,
    authorized: ['admin']
  }, {
    path: "/users/:userId",
    title: 'User Details',
    component: UserProfile,
    goBack: true,
    exact: true,
    authorized: ['admin']
  }, {
    path: "/admin/class-setup",
    title: 'Grade Setup',
    exact: true,
    component: ClassSetup,
    authorized: ['admin'],
  }, {
    path: "/admin/class-setup/new",
    title: 'Grade Setup',
    component: AssignFacultyGrade,
    authorized: ['admin'],
    goBack: true
  }, {
    path: "/admin/class-setup/:facultyId/edit",
    title: 'Grade Setup',
    component: EditFacultyGrade,
    authorized: ['admin'],
    goBack: true
  }, {
    path: "/recognitions",
    title: 'Recognition Center',
    exact: true,
    component: RecognitionBoard,
    authorized: ['admin'],
  }, {
    path: "/recognitions/:recognitionId/edit",
    title: 'Edit Recognition',
    component: RecognitionView,
    authorized: ['admin'],
    goBack: true
  }, {
    path: "/recognitions/new",
    title: 'New Recognition',
    component: RecognitionView,
    authorized: ['admin'],
    goBack: true
  }, {
    path: "/admin-onboarding",
    title: 'Admin Setup',
    component: AdminOnboarding,
    authorized: ['admin'],
    goBack: false
  }, {
    path: "/community-invite",
    title: 'Community Invite',
    component: CommunityInvite,
    authorized: ['admin'],
    goBack: false
  }
]

const parentRoutes = [
  {
    path: "/registration-report",
    title: 'Registration Report',
    component: RegistrationStatus,
    exact: true,
    authorized: ['admin', 'parent', 'creator', 'school']
  },
  {
    path: "/registration",
    title: 'Registration',
    component: FamilyRegistration,
    exact: true,
    authorized: ['admin', 'parent', 'creator', 'school']
  },
  {
    path: "/email",
    component: EmailEditor,
    exact: true,
    authorized: ['admin', 'parent', 'creator', 'school']
  },
  {
    path: "/volunteers",
    title: 'My Dashboard',
    component: VolunteerDashboard,
    exact: true,
    authorized: ['admin', 'parent', 'creator', 'school']
  },
  {
    path: "/volunteers/add",
    title: 'Add Event',
    component: VolunteerAdd,
    exact: true,
    authorized: ['admin', 'creator', 'school']
  },
  {
    path: "/volunteers/board",
    title: 'Volunteer Board',
    component: VolunteerBoard,
    authorized: ['admin', 'parent', 'creator', 'school'],
  },
  {
    path: "/volunteers/:id",
    title: 'Event View',
    component: EventDetailView,
    back: "/volunteers/board",
    exact: true,
    authorized: ['admin', 'parent', 'creator', 'school']
  },
  {
    path: "/volunteers/:id/edit",
    title: 'Edit Event',
    component: VolunteerAdd,
    exact: true,
    authorized: ['admin', 'creator', 'school'],
    goBack: true,
  },
  {
    path: "/community",
    title: 'School Community',
    exact: true,
    component: SchoolCommunity,
    authorized: ['admin', 'parent', 'creator', 'school']
  },
  {
    path: "/community/family/:familyId",
    title: 'Family',
    exact: true,
    // back: "/community",
    goBack: true,
    component: FamilyProfile,
    authorized: ['admin', 'parent', 'creator', 'school']
  },
  {
    path: "/community/faculty/:facultyId",
    title: 'Faculty',
    exact: true,
    // back: "/community",
    goBack: true,
    component: FacultyProfile,
    authorized: ['admin', 'parent', 'creator', 'school']
  },
  {
    path: "/parent-onboarding",
    title: 'Parent Setup',
    component: ParentOnboarding,
    exact: true,
    authorized: ['admin', 'parent', 'creator', 'school']
  },
  {
    path: "/talent-onboarding",
    title: 'Talent Setup',
    component: TalentOnboarding,
    exact: true,
    goBack: false,
    authorized: ['admin', 'parent', 'creator', 'school']
  },
  {
    path: "/faculty-onboarding",
    title: 'Faculty Setup',
    component: FacultyOnboarding,
    exact: true,
    goBack: false,
    authorized: ['admin', 'creator', 'school', 'faculty']
  }
]

const ProtectedRoutes = [
  {
    path: "/apps",
    title: 'My Apps',
    exact: true,
    component: Dashboard,
    authorized: ['parent', 'creator', 'admin', 'school']
  },
  {
    path: "/",
    exact: true,
    title: 'My Dashboard',
    component: VolunteerDashboard,
    authorized: ['parent', 'creator', 'admin', 'school']
  },
  {
    path: "/profile",
    title: 'Profile',
    component: AsyncProfile,
    authorized: ['parent', 'creator', 'admin', 'school']
  },
  {
    path: "/user/:userId",
    title: 'User',
    component: PublicProfile,
    authorized: ['parent', 'creator', 'admin', 'user', 'school']
  },
  {
    path: "/talent-registration",
    title: 'Talent Registration',
    component: TalentRegistration,
    authorized: ['parent', 'creator', 'admin', 'user', 'school']
  },
  {
    path: "/prayer-board",
    title: 'Prayer Board',
    component: PrayerDashboard,
    exact: true,
    authorized: ['parent', 'school']
  },
  {
    path: "/prayer-board/:prayerId",
    title: 'Prayer Board',
    component: Prayer,
    exact: true,
    goBack: true,
    authorized: ['parent', 'school']
  },
  ...adminRoutes,
  ...parentRoutes,

  // Others
  {
    path: "/family",
    component: FamilyDashboard,
    authorized: ['parent', 'creator', 'admin']
  },
  {
    path: "/logout",
    component: (props) => {
      const { history } = props;
      history.push("/");

      UserActions.signOut();
      return null
    },
    authorized: ['parent', 'teacher', 'creator', 'admin', 'school']
  }
]

export const Routes = ProtectedRoutes

const AppRoute = ({ component: Component, layout: Layout, location, isLoggedIn, isAuthorized, onboardingRedirect, path, ...rest }) => {

  if (!isLoggedIn)
    return <LinearProgress />;

  if (onboardingRedirect) {
    return <Redirect to={onboardingRedirect} />
  }

  if (!isAuthorized)
    return <Redirect to={'/'} />

  return (
    <Route path={path} {...rest} location={location} render={props => (
      <Layout>
        <Component {...props}  location={location} />
      </Layout>
    )} />
  )
};

const AppPublicRoute = ({ component: Component, layout: Layout, location, ...rest }) => (
  <Route {...rest}
    render={props => (
      <Layout>
        <Component {...props} location={location} />
      </Layout>
    )}
  />
);

const ClassicLayout = props => (
  <Classic>{props.children}</Classic>
);

// TODO: Consider looping through an object containing all routes
export default ({ childProps, layout, isLoggedIn, currentUser, currentOrganization, currentOrgUser, match, ...rest }) => {
  let activeLayout;
  switch (layout.currentLayout) {
    case 'classic':
      activeLayout = ClassicLayout;
      break;
    default:
      activeLayout = ClassicLayout;
  }

  return (
    <Suspense fallback={<LinearProgress />}>
      <Switch>
        {currentOrganization && ProtectedRoutes.map((route, i) => {
          const isAuthorized = checkAuthorization(route, currentUser);
          const onboardingRedirect = getOnboardingRedirectPath(route, currentOrgUser, currentOrganization)

          return (
            <AppRoute
              key={route.path + '-' + i}
              isLoggedIn={isLoggedIn}
              isAuthorized={isAuthorized}
              onboardingRedirect={onboardingRedirect}
              path={route.path}
              currentUser={currentUser}
              exact={route.exact || false}
              component={route.component}
              props={childProps}
              layout={activeLayout}
              children={route.children}
            />
          )
        })}

        {currentOrganization && <AppPublicRoute component={AsyncNotFound} layout={activeLayout} />}
      </Switch>
    </Suspense>);
};

const checkAuthorization = (route, user) => {
  let authorized = false
  user.roles.forEach(role => {
    if (route.authorized.includes(role))
      authorized = true
  })
  return authorized
}
