import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import {
    Grid,
    Paper,
    Typography,
    ListSubheader,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Dialog,
    Button,
    AppBar,
    Toolbar,
    Divider,
    IconButton,
    Slide,
    Step,
    StepLabel,
    Stepper,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    ExpansionPanelActions,
    Checkbox,
    FormControl,
    Select,
    MenuItem,
    TextField
} from '@material-ui/core';
import classNames from 'classnames';
import {
    ExpandMore as ExpandMoreIcon,
    Add as AddIcon,
    Remove as RemoveIcon,
    Check as CheckIcon
} from '@material-ui/icons';
import {
    red,
    green
} from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';


const _weekCount = {
    'Monday': 15,
    'Tuesday': 16,
    'Wednesday': 16,
    'Thursday': 16,
    'Friday': 12
}

const _week_menus = [
    {
        weekDay: 'Monday',
        description: 'Vocelli\'s Sub',
        menus: [
            { title: 'Turkey Full', value: 'turkeyFull', price: 7.50 },
            { title: 'Turkey Half', value: 'turkeyHalf', price: 5.00 },
            { title: 'Ham Half', value: 'hamFull', price: 7.50 },
            { title: 'Ham Full', value: 'hamHalf', price: 5.00 },
            { title: 'Itelian Full', value: 'itelianFull', price: 7.50 },
            { title: 'Itelian Half', value: 'itellianHaf', price: 5.00 },
        ]
    },
    {
        weekDay: 'Tuesday',
        description: 'Chick-fil-A',
        menus: [
            { title: 'Chicken Sandwich', value: 'chickenSandwich', price: 4.10 },
            { title: 'Spicy Sandwich', value: 'spicySandwich', price: 4.40 },
            { title: 'Char Grilled Wrap', value: 'charGrilledWrap', price: 6.80 },
        ]
    },
    {
        weekDay: 'Wednesday',
        description: 'Vocelli\'s Pizza',
        menus: [
            { title: 'Cheese Slice', value: 'cheese Slice', price: 1.25 },
            { title: 'Peperoni Slice', value: 'peperoniSlice', price: 1.50 }
        ]
    }

]

class LaunchOrderDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            signed: false,
            activeStep: 0,
            expanded: 'Monday',
            selectedMenus: {},
            paymentInfo: {
                card: 'creditCard'
            }
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) { }
    componentDidMount() { }
    componentWillUnmount() {

    }

    resetState() {
        this.setState({
            signed: false,
            activeStep: 0,
            expanded: 'Monday',
            selectedMenus: {},
            paymentInfo: {
                card: 'creditCard'
            }
        })
    }

    handleNext = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    handleExpandChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handlePaymentChange = (event, name) => {
        let { paymentInfo } = this.state
        const value = event.target.value
        paymentInfo[name] = value
        this.setState({ paymentInfo })
    }

    handleMenuToggle = (menu, day) => () => {
        let { selectedMenus } = this.state;
        const dayMenu = (selectedMenus[day.weekDay] && selectedMenus[day.weekDay].menu) || []
        let newDayMenu = new Array(...dayMenu)

        const currentIndex = dayMenu.indexOf(menu)
        if (currentIndex === -1) {
            menu.quantity = 1
            newDayMenu.push(menu)
        } else {
            newDayMenu.splice(currentIndex, 1)
        }

        selectedMenus[day.weekDay] = this.calculateSingleTotal(newDayMenu, day.weekDay)     // { weeklyTotal, yearlyTotal, menu: newDayMenu }
        this.setState({ selectedMenus })
    };

    calculateSingleTotal(dayMenu, day) {
        const weekCount = _weekCount[day]
        let weeklyTotal = 0
        let yearlyTotal = 0

        dayMenu = dayMenu.map(menu => {
            weeklyTotal += menu.price * menu.quantity
            yearlyTotal += weeklyTotal * weekCount
            menu.total = weeklyTotal * weekCount
            return menu
        })
        return { weeklyTotal, yearlyTotal, menu: dayMenu, weekDay: day }
    }

    handleQuantityToggle(menu, day, type) {
        const { selectedMenus } = this.state
        if (type === 'add')
            menu.quantity += 1
        else
            menu.quantity = menu.quantity > 1 ? menu.quantity - 1 : 1
        selectedMenus[day].menu = selectedMenus[day].menu.filter(m => m.value !== menu.value)
        selectedMenus[day].menu.push(menu)
        selectedMenus[day] = this.calculateSingleTotal(selectedMenus[day].menu, day)
        this.setState({ selectedMenus })

    }

    onClose = () => {
        this.resetState()
        if (this.props.onClose)
            this.props.onClose()
    }

    getGrandTotal() {
        const { selectedMenus } = this.state
        let total = 0
        Object.values(selectedMenus).map(day => total += day.yearlyTotal)
        return total.toFixed(2)
    }

    render() {
        const { visible, classes, title } = this.props
        const steps = getSteps();
        const { activeStep } = this.state;
        return (
            <Dialog
                open={visible}
                TransitionComponent={Transition}
                onClose={() => this.onClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <AppBar style={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography variant="body2" color="inherit" style={{ flex: 1, color: 'white' }}>
                            {title}
                        </Typography>
                        <Button style={{ color: '#FFF' }} onClick={() => this.onClose()}>
                            {'Close'}
                        </Button>
                    </Toolbar>
                </AppBar>
                <div style={{ display: 'flex', width: '600px', minHeight: '50vh', overflowX: 'hidden', flexDirection: 'column' }} >
                    <Stepper activeStep={activeStep} >
                        {steps.map(label => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div style={{ padding: '0 1em 1em 1em' }}>
                        {this.state.activeStep === steps.length ? (
                            this.renderFinalView()
                        ) : (
                                <div>
                                    <div style={{ padding: '0 0 1em 0' }} >
                                        {this.getStepContent(activeStep)}
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }} >
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={this.handleBack}
                                            className={classes.backButton}
                                        >
                                            {'Back'}
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={this.handleNext}>
                                            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                                        </Button>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </Dialog>
        )
    }

    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return this.renderMenuViews();
            case 1:
                return this.renderInvoiceViews();
            case 2:
                return this.renderPaymentViews();
            default:
                return 'Uknown stepIndex';
        }
    }

    renderFinalView() {
        const { classes } = this.props
        return (
            <Paper className={classes.paper} >
                <div style={{ width: '100%', height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <Button onClick={() => this.onClose()} variant="fab" color="primary">
                        <CheckIcon fontSize={45} style={{ color: 'white' }} />
                    </Button>
                </div>
            </Paper>
        )
    }
    renderPaymentViews() {
        const { classes } = this.props
        const { paymentInfo } = this.state
        return (
            <Paper className={classes.paper} >
                <form autoComplete="off">
                    <Grid container alignItems="center" >
                        <Grid item xs={6}>
                            <Typography  >{'Select Card'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl} >
                                <Select
                                    value={paymentInfo.card}
                                    onChange={(evt) => this.handlePaymentChange(evt, 'card')}
                                    inputProps={{
                                        name: 'card',
                                        id: 'card-simple',
                                    }}
                                >
                                    <MenuItem value={'creditCard'}>Credit Card</MenuItem>
                                    <MenuItem value={'visaCard'}>Visa Card</MenuItem>
                                    <MenuItem value={'masterCard'}>Master Card</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <TextField
                                id="name"
                                label="Card Name"
                                className={classes.formControl}
                                value={paymentInfo.name}
                                onChange={(evt) => this.handlePaymentChange(evt, 'name')}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="name"
                                label="Card Number"
                                className={classes.formControl}
                                value={paymentInfo.number}
                                onChange={(evt) => this.handlePaymentChange(evt, 'number')}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <TextField
                                id="expiryDate"
                                label="Expiry Date"
                                type="date"
                                value={paymentInfo.expiryDate}
                                onChange={(evt) => this.handlePaymentChange(evt, 'expiryDate')}
                                defaultValue="2017-05-24"
                                className={classes.formControl}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="securityCode"
                                label="Security Code"
                                className={classes.formControl}
                                value={paymentInfo.securityCode}
                                onChange={(evt) => this.handlePaymentChange(evt, 'securityCode')}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        )
    }

    renderInvoiceViews() {
        const { classes } = this.props
        const { selectedMenus } = this.state

        return (
            <Paper className={classes.paper} >
                <List subheader={<ListSubheader>Invoice Detail</ListSubheader>}>
                    <ListItem key={'invoiceId'} role={undefined} dense >
                        <ListItemText clas primary={'Invoice Id'} />
                        <ListItemSecondaryAction>
                            <Typography  >{'#4556'}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem key={'client'} role={undefined} dense>
                        <ListItemText primary={'Client'} />
                        <ListItemSecondaryAction>
                            <Typography>{'John David'}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                <Divider />
                <List>
                    {Object.values(selectedMenus).map(day => this.renderInvoiceItem(day))}
                </List>
                <Divider />
                <List>
                    <ListItem key={'total'} role={undefined} >
                        <ListItemText style={{ fontWeight: '500' }} primary={'Total'} />
                        <ListItemSecondaryAction>
                            <Typography variant="body2" >{'$' + this.getGrandTotal()}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Paper>
        )
    }

    renderInvoiceItem(day) {
        return (
            <ListItem key={'client'} role={undefined} dense>
                <ListItemText primary={day.weekDay} />
                <ListItemSecondaryAction>
                    <Typography>{'$' + day.weeklyTotal.toFixed(2) + ' / $' + day.yearlyTotal.toFixed(2)}</Typography>
                </ListItemSecondaryAction>
            </ListItem>
        )

    }

    renderMenuViews() {
        const { classes } = this.props
        const { expanded, selectedMenus } = this.state;
        return (
            <div>
                <ExpansionPanel
                    key={'first'}
                    expanded={false}>
                    <ExpansionPanelSummary style={{ backgroundColor: 'rgba(0,0,0,.2)' }} >
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
                            <Typography className={classes.heading}>{'Vendor'}</Typography>
                            <Typography className={classes.heading}>{'Costs'}</Typography>
                        </div>
                    </ExpansionPanelSummary>
                </ExpansionPanel>
                {_week_menus.map(day => {
                    const selectedDay = selectedMenus[day.weekDay]
                    const isExpanded = expanded === day.weekDay
                    return (
                        <ExpansionPanel
                            key={day.weekDay + Math.random()}
                            expanded={expanded === day.weekDay}
                            onChange={this.handleExpandChange(day.weekDay)}>
                            <ExpansionPanelSummary
                                classes={isExpanded ? { root: classes.borderbtm } : {}}
                                expandIcon={<ExpandMoreIcon />}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
                                    <Typography className={classes.heading}>{day.weekDay}</Typography>
                                    {!isExpanded && <Typography className={classes.heading}>
                                        {`${(selectedDay && `$` + selectedDay.weeklyTotal.toFixed(2)) || `$0`} / ${(selectedDay && `$` + selectedDay.yearlyTotal.toFixed(2)) || `$0`}`}
                                    </Typography>}
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{ display: 'flex', flexDirection: 'column' }} >
                                <List className={classes.root}>
                                    <ListItem classes={{ root: classes.list }} key={'first'} role={undefined} divider>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }} >
                                            <div style={{ display: 'flex', width: '120px', justifyContent: 'space-between' }} >
                                                <Typography variant="body2">{'Weekly'}</Typography>
                                                <Typography variant="body2">{'Total'}</Typography>
                                            </div>
                                        </div>
                                    </ListItem>
                                    {day.menus.map(menu => {
                                        const selectedMenu = selectedMenus[day.weekDay] && selectedMenus[day.weekDay].menu
                                            && selectedMenus[day.weekDay].menu.find(m => m.value === menu.value)
                                        const isSelected = selectedMenu !== undefined
                                        return (
                                            <ListItem classes={{ root: classes.list }} key={menu.title} role={undefined} divider dense>
                                                {/* <IconButton className={classes.miniIconContainer}> */}
                                                <Checkbox
                                                    classes={{ root: classes.check, checked: classes.checkColorSecondary }}
                                                    checked={isSelected}
                                                    tabIndex={-1}
                                                    onClick={this.handleMenuToggle(menu, day)}
                                                    disableRipple
                                                />
                                                {/* </IconButton> */}
                                                <ListItemText primary={menu.title + ' - $' + menu.price} />
                                                {isSelected && <div style={{ width: '100px' }}>
                                                    {this.renderSelectionView(menu, day.weekDay)}
                                                </div>}
                                                {isSelected &&
                                                    <div style={{ display: 'flex', width: '120px', justifyContent: 'space-between' }} >
                                                        <Typography >{'$' + (selectedMenu.price * selectedMenu.quantity).toFixed(2)}</Typography>
                                                        <Typography >{'$' + (selectedMenu.total).toFixed(2)}</Typography>
                                                    </div>
                                                }
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </ExpansionPanelDetails>
                            <Divider />
                            <ExpansionPanelActions >
                                <div style={{ display: 'flex', flexDirection: 'row' }} >
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingRight: '1em' }}>
                                        <Typography style={{ paddingRight: '.5em', alignItems: 'center' }}  >{'Weekly Total: '}</Typography>
                                        <Typography variant="body2">{(selectedDay && '$' + selectedDay.weeklyTotal.toFixed(2)) || `$0`}</Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingRight: '1em' }}>
                                        <Typography style={{ paddingRight: '.5em', alignItems: 'center' }} >{'Grand Total: '}</Typography>
                                        <Typography variant="body2">{(selectedDay && '$' + selectedDay.yearlyTotal.toFixed(2)) || `$0`}</Typography>
                                    </div>
                                </div>
                            </ExpansionPanelActions>
                        </ExpansionPanel>
                    )
                })}
            </div>
        )
    }

    renderSelectionView(menu, day) {
        const { classes } = this.props
        const { selectedMenus } = this.state
        const selectedMenu = selectedMenus[day] && selectedMenus[day].menu.find(m => m.value === menu.value)
        const isTypeQ = menu.pricingType === 'Q'
        if (!isTypeQ)
            return (
                <div style={{ display: 'flex', alignItems: 'center' }} >
                    <IconButton className={classes.miniIconContainer} aria-label="add"
                        onClick={() => this.handleQuantityToggle(selectedMenu, day, 'add')}>
                        <AddIcon className={classNames(classes.miniIcon, classes.green)} />
                    </IconButton>
                    <Typography style={{ paddingLeft: '.5em', paddingRight: '.5em' }} >{selectedMenu.quantity}</Typography>
                    <IconButton className={classes.miniIconContainer} aria-label="Remove"
                        onClick={() => this.handleQuantityToggle(selectedMenu, day, 'minus')}>
                        <RemoveIcon className={classNames(classes.miniIcon, classes.red)} />
                    </IconButton>
                </div>
            )
    }
}


function getSteps() {
    return ['Menus', 'Invoice', 'Payment'];
}

class Transition extends React.Component {
    render() {
        return <Slide direction="up" {...this.props} />;
    }
}

function mapStateToProps(state) {
    let toReturn = {

    };

    return toReturn;
}

const styles = theme => ({
    borderbtm: {
        borderBottom: '1px solid rgba(0,0,0,0.1)'
    },
    expansionAction: {
        justifyContent: 'flex-start'
    },
    list: {
        paddingLeft: '8px !important',
        paddingRight: '8px !important'
    },
    listContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    check: {
        width: '30px',
        height: '30px',
        padding: '0px'
    },
    checkColorSecondary: {
        color: theme.palette.primary.main + ' !important'
    },
    formControl: {
        width: '90%',
    },
    miniIconContainer: {
        width: '30px',
        height: '30px',
        padding: '0px'
    },
    miniIcon: {
        width: '.8em',
        height: '.8em'
    },
    red: {
        color: red[500]
    },
    green: {
        color: green[500]
    },
    paper: {
        borderRadius: '0px',
        padding: '1em'
    },
    backButton: {
        marginRight: theme.spacing(1),
    }
})


export default compose(
    withStyles(styles, { withTheme: true }),
    connect(mapStateToProps, {})
)(LaunchOrderDialog);
