import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


import logger from 'redux-logger';
import allReducers from './reducers';

const persistConfig = {
  key: 'root',
  storage,
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 25,
  }) : compose;

const pkgConfig = require("../../package.json");
const isProduction = pkgConfig.config.backend === 'prod' && pkgConfig.config.isCloudDeployment;
const usePersist = pkgConfig.config.usePersist === true;

const persistedReducer = persistReducer(persistConfig, allReducers)
const reducers = usePersist ? persistedReducer : allReducers

export const store = isProduction
  ? createStore(reducers)
  : createStore(reducers, composeEnhancer(applyMiddleware(logger)))

export const persistor = (usePersist) ? persistStore(store) : null


