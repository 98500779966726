import React from 'react'
import defaultImage from 'assets/images/logo/defaultLogo.png'

export default function ImageLoader({ imageKey, params, className }) {
  const [image, setImage] = React.useState(null)
  const [error, setError] = React.useState(false)

  React.useEffect(() => {
    if (!image && imageKey)
      setError(false)
  }, [imageKey])

  return (
    <img alt={"image-" + imageKey} className={className}
      src={error ? defaultImage : `https://connect1.imgix.net/${imageKey}?${params}`}
      onError={() => setError(true)} />
  )
}
