import _ from 'lodash';
import cloneDeep from 'lodash.clonedeep';
import { moment as Moment, fromUtc } from "./moment";

require("babel-polyfill");
var util = require('util')

const _decodeUriComponent = require('decode-uri-component');
const _encodeUriComponent = require('strict-uri-encode');

class Utils {

    static PERIOD_FORMAT = 'YYYYMM';

    constructor() {

    }

    static getLevels() {
        const levels = {
            0: {
                min: 0.0,
                max: 0.9,
                label: 'Ante Up',
                value: 0,
            },
            1: {
                min: 1.0,
                max: 10.0,
                label: '10 of Hearts',
                value: 1,
            },
            2: {
                min: 11.0,
                max: 20.0,
                label: 'Jack of Hearts',
                value: 2
            },
            3: {
                min: 21.0,
                max: 30.0,
                label: 'Queen of Hearts',
                value: 3
            },
            4: {
                min: 31.0,
                max: 40.0,
                label: 'King of Hearts',

                value: 4
            },
            5: {
                min: 41.0,
                max: 50.0,
                label: 'Ace of Hearts',
                value: 5
            },
            6: {
                min: 51.0,
                max: 1000.0,
                label: 'Royal Flush',
                value: 6
            }
        };

        return levels;
    }

    static getKeyComponents = (str) => {
        let parts = str.split(":")

        // remove lower case value at the end, only there for searching
        if (parts[4])
            parts.pop()

        // convert forProductFamily to productFamilu
        let fieldName = parts[3].replace(/^for/, "");
        fieldName = `${fieldName[0].toLowerCase()}${fieldName.slice(1)}`;

        // Create result
        let result = {
            clientId: parts[1],
            entity: parts[2],
            fieldName,
            value: parts.splice(4).join(':')
        }
        return result
    }

    static isEqual(object1, object2) {
        return _.isEqual(object1, object2);
    }

    static agg(data, aggregateBy, attributes, beginningFacts, endingFacts, restOfFacts) {

        if (!Array.isArray(beginningFacts)) {
            beginningFacts = [beginningFacts]
        }

        if (!Array.isArray(endingFacts)) {
            endingFacts = [endingFacts]
        }

        var groups = _.groupBy(data, function (value) {
            let toReturn = "";
            aggregateBy.forEach(col => toReturn = `${toReturn}.${value[col]}`)
            return toReturn;
        });

        const sum = (mrrm, group) => group.reduce((result, item) => result + ((item[mrrm] && item[mrrm]) || 0), 0)

        return _.map(groups, function (group) {
            let record = {}

            attributes.forEach(col => record[col] = group[0][col])

            beginningFacts.forEach(beginningFact => {
                record[beginningFact] = group[0][beginningFact];
            })

            restOfFacts.forEach(col => record[col] = sum(col, group))
            endingFacts.forEach(endingFact => {
                record[endingFact] = group[group.length - 1][endingFact]
            })

            return record;
        });
    }

    static parseQueryString(queryString) {

        if (!queryString || queryString.indexOf("?") == -1)
            throw new Error("Invalid query string specified [" + queryString + "]");

        var values = queryString.split("?")[1].split("&");
        var queryParams = {};
        values.forEach((val) => {
            var valueSplit = val.split("=");

            const key = valueSplit[0];
            const value = valueSplit[1];
            queryParams[key] = value;
        });

        return queryParams;
    }

    static capitalize(text, seperator) {
        if (!text)
            return ''

        let _text = text.toLowerCase();
        if (seperator)
            return _text.split(seperator).map(t => `${t[0].toUpperCase()}${t.slice(1)}`).join(' ')

        return `${_text[0].toUpperCase()}${_text.slice(1)}`;
    }

    static truncate(str, length) {
        var dots = str.length > length ? '...' : '';
        return str.substring(0, length) + dots;
    };

    static getDayStatus(date) {
        let today = Moment(Utils.now_str());
        let currentMoment = Moment(date);

        return today.diff(currentMoment, 'days');
    }

    static getDayStatusString(date) {
        let today = Moment(Utils.now_str());
        let currentMoment = Moment(date);

        let dayStatus = today.diff(currentMoment, 'days');
        if (dayStatus == 0) {
            dayStatus = 'Today'
        } else {
            dayStatus = dayStatus + 'd ago'
        }

        return dayStatus;
    }

    static getRelativeTime(date) {
        let today = Moment(Utils.now());
        let currentMoment = Moment(date);
        let diff = today.diff(currentMoment, 'milliseconds');
        let status = "";
        diff = parseInt(diff / 1000)

        if (diff > 86400) {//300000/86400=3.52742
            let d = parseInt(diff / 86400);
            status = d + "d ago";
            diff = diff % 86400//3.523546-3   300000-(300000/86400)*86400=259000
        }
        else if (diff > 3600) {
            let h = parseInt(diff / 3600)
            status = h + "h"
            diff = diff % 3600
        } else if (diff > 60) {
            let m = parseInt(diff / 60)
            status = m + "m ago"
        } else {
            status = "Now"
        }

        return status;


    }

    static daysTo(date) {
        return Moment(date).diff(Moment(), 'days') + 1;
    }

    static daysSince(date) {
        return Moment().diff(Moment(date), 'days') + 1;
    }

    static formatWeekHeader(date) {
        return Moment(date).format("MM/DD") + " - " + Moment(date).add(6, 'days').format("MM/DD");
    }

    static formatWeekHeaderShort(date) {
        return "Week of " + Moment(date).format("MMM Do, YYYY")
    }

    static formatShortNow() {
        return Moment().format("dddd, MMM Do, YYYY");
    }

    static formatLongDate(date) {
        return Moment(date).format("dddd, MMM DD, YYYY");
    }

    static formatLongStandardDate(date) {
        return Moment(date).format("DD MMM, YYYY");
    }

    static formatShortDate(date) {
        return Moment(date).format("MM/DD/YY");
    }

    static formatShortTime(dateTime, format = 'hh:mm') {
        return Moment(dateTime).format(format)
    }

    static formatDate(date) {
        return Moment(date).format("YYYY-MM-DD");
    }

    static formatDayOfWeek(date) {
        return Moment(date).format('dddd');
    }

    static formatShortestDate(date) {
        return Moment(date).format("MM/DD");
    }

    static formatToAccountingPeriod(date) {
        return Moment(date).format(Utils.PERIOD_FORMAT);
    }

    static toAccountingPerid(date) {
        return Moment(date, Utils.PERIOD_FORMAT);
    }

    static formatShortestDateWithYear(date) {
        return Moment(date).format(Utils.PERIOD_FORMAT);
    }

    static nextPeriod(period) {
        return Moment(period, Utils.PERIOD_FORMAT).add(1, 'months').format(Utils.PERIOD_FORMAT);
    }

    static formatPeriodForDisplay(date) {
        return Moment(date, Utils.PERIOD_FORMAT).format('MMM-YYYY');
    }

    static getMonthFromPeriod(date) {
        return Moment(date, Utils.PERIOD_FORMAT).format('MMM');
    }

    static getYear(date) {
        return Moment(date).format("YYYY");
    }

    static getPeriod(dateParts) {
        if (dateParts.month) {
            dateParts.month--
        }
        return Moment(dateParts).format(Utils.PERIOD_FORMAT);
    }

    static getYearFromPeriod(period) {
        return Utils.formatDateCustom(Moment(period, Utils.PERIOD_FORMAT), 'YYYY')
    }

    static getQuarter(period) {
        return Utils.formatDateCustom(Moment(period, Utils.PERIOD_FORMAT), 'YYYYQ')
    }

    static getQuarterDesc(period) {
        return Utils.formatDateCustom(Moment(period, Utils.PERIOD_FORMAT), 'YYYY - [Q]Q')
    }

    static getCurrentPeriod() {
        return Utils.formatDateCustom(Utils.currentDate(), Utils.PERIOD_FORMAT)
    }

    static formatDateCustom(date, format) {
        return Moment(date).format(format);
    }

    static startOfMonth(date) {
        return Moment(date).startOf('month');
    }

    static endOfMonth(date, format) {
        return Moment(date, format).endOf('month');
    }

    static endOfQuarter(date, format) {
        return Moment(date, format).endOf('quarter');
    }


    static nthDayOfMonth(date, n) {
        return Moment(date).startOf('month').add(n - 1 || 0, 'days');
    }

    static currentTenure(format) {
        const currentYear = Moment().format('YYYY')
        const tenureEndDate = Moment([currentYear, 8 - 1]).endOf('month').format(format)
        const tenureStartDate = Moment(tenureEndDate).subtract(11, 'months').startOf('month').format(format)

        return {
            startDate: tenureStartDate,
            endDate: tenureEndDate
        }
    }

    static getTenureDate(startDate, format) {
        const _date = new Date(startDate)
        const month = _date.getMonth() + 1

        let tenureStartDate = ''
        month >= 1 && month <= 8
            ? tenureStartDate = Moment(_date).subtract(month + 3, 'months').startOf('month').format(format)
            : tenureStartDate = Moment(_date).subtract(month - 9, 'months').startOf('month').format(format)
        const tenureEndDate = Moment(tenureStartDate).add(11, 'months').endOf('month').format(format)

        return {
            startDate: tenureStartDate,
            endDate: tenureEndDate
        }
    }

    // Start date of [week, month, year]
    static startOf(date, type, format) {
        return Moment(date).startOf(type).format(format);
    }

    // Dnd date of [week, month, year]
    static endOf(date, type, format) {
        return Moment(date).endOf(type).format(format);
    }


    /**
     * Return Added value to date based on unit types
     * @param {*} dateTime
     * @param {*} value = 1..*
     * @param {*} unit = ['days', 'months','years']
     * @param {*} type = ['add', 'minus','years']
     */
    static customDateTime(dateTime, value, unit, type = "add") {
        switch (type) {
            case 'add':
                return Moment(dateTime).add(value, unit).toISOString();
            case 'minus':
                return Moment(dateTime).subtract(value, unit).toISOString();
        }
    }

    /**
     * Return Substracted value to date based on unit types
     * @param {*} dateTime
     * @param {*} value = 1..*
     * @param {*} unit = ['days', 'months','years']
     */
    static customDateSubstraction(dateTime, value, unit) {
        return Moment(dateTime).subtract(value, unit).format()
    }

    /**
     * Convert a date into a weekId
     * @param date yyyy-MM-dd formatted string for date
     * @returns weekId
     */
    static toWeekId(date) {
        if (!date)
            return null;

        var d = new Date(date);//date.substring(0, 4), date.substring(5, 7) - 1, date.substring(8)
        d.setUTCDate(d.getUTCDate() - d.getUTCDay());
        return d.toISOString().slice(0, 10);
    }

    /**
     * Convert the date to a short firm date in UTC format for e.g., 2016-12-09
     * @param date
     * @returns {null}
     */
    static toShortForm(date) {
        "use strict";
        if (!date)
            return null;

        if (date && Object.prototype.toString.call(date) != '[object Date]')
            throw new Error("Invalid type for object date [" + date + "]")

        return date.toISOString().slice(0, 10);
    }

    static _currentDate = (process.env.CURRENT_DATE && Moment(process.env.CURRENT_DATE)) || new Date();

    /**
     * Right now..
     * @returns {string}
     */
    static currentDate(org) {
        return org && org.date && fromUtc(org.date) || Utils._currentDate;
    }

    /**
     * Right now..
     * @returns {string}
     */
    static now() {
        "use strict";
        return Utils.currentDate().getTime()

    }

    static now_str() {
        return Utils.currentDate().toISOString().slice(0, 10);
    }

    /**
     * Get the current week id
     * @returns {*|weekId}
     */
    static thisWeekId() {
        "use strict";
        return Utils.toWeekId(Utils.currentDate().toISOString().slice(0, 10));
    }

    /**
     * Get the current period id
     * @returns {*}
     */
    static thisPeriodId() {
        "use strict";
        return Utils.thisWeekId(); //@todo: right now the weekid and the period id are the same.
    }

    /**
     * Figure out the weekId for some future week.
     *
     * @param date
     * @param weekCount week in future.
     * @returns weekId for future week that is {weekCount} out from this week.
     */
    static futureWeekId(date, weekCount) {
        "use strict";

        if (!date)
            return null;

        return Moment(date).add(weekCount, 'week').toISOString().slice(0, 10);
    }

    /**
     * Figure out the weekId for some future week.
     *
     * @param date
     * @param months in future.
     * @returns weekId for future week that is {monthCount} out from this week.
     */
    static addMonths(date, monthCount) {
        "use strict";

        if (!date)
            return null;

        return Moment(date).add(monthCount, 'month').toISOString().slice(0, 10);
    }

    static eightWeeksAgo(from) {
        const today = Moment(from);
        return Utils.toShortForm(new Date(today.subtract(8, 'weeks')));
    }

    static getSpecificWeekDay(date, weekCount, plus = 0) {
        if (!date)
            return null;

        var d = new Date(Utils.toWeekId(date));
        d.setUTCDate((d.getUTCDate() - d.getUTCDay()) - 7 * weekCount);
        d.toISOString().slice(0, 10);
        let newDate = Moment(d).add(plus, 'days').format("YYYY-MM-DD")
        return newDate;
    }

    /**
     * Figure out what the previous week id is..
     *
     * @param date
     * @param weekCount
     * @returns {*}
     */
    static previousWeekId(date, weekCount = 1) {
        if (!date)
            return null;

        var d = new Date(Utils.toWeekId(date));
        d.setUTCDate((d.getUTCDate() - d.getUTCDay()) - 7 * weekCount);

        return d.toISOString().slice(0, 10);
    }

    /**
     * Calculate number of weeks between two dates.
     * @param _data1 yyyy-mm-dd date
     * @param _data2 yyyy-mm-dd date
     */
    static weeksBetween(_date1, _date2) {
        return Moment(_date2).diff(_date1, 'weeks');
    }

    static daysBetween(_date1, _date2 = Date.now()) {
        return Math.abs(Moment(_date2).diff(_date1, 'days'));
    }

    /**
     * Figure out the next week id is..
     *
     * @param date
     * @returns {*}
     */
    static nextWeekId(date) {
        "use strict";
        if (!date)
            return null;

        var d = new Date(date);
        d.setUTCDate(d.getUTCDate() + 7);
        return d.toISOString().slice(0, 10);
    }

    /**
     * Returns week name for providing week id number, e.g. sunday for 1
     * @param weekId
     */
    static getWeeksName(weekId) {
        let days = ["", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        return days[weekId];
    }

    static formatCurrency(money, n, x, sign, currency) {
        "use strict";
        if (n === undefined) {
            n = 2;
        }
        if (x == undefined) {
            x = 3;
        }

        if (isNaN(money)) return '-'

        const isNegative = money < 0;
        money = Math.abs(money);

        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
        return (isNegative ? "-" : sign ? "+" : "") + (currency ? currency : "$") + parseFloat(money).toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
    }

    static formatPercentage(number, sign) {
        return ((sign && number > 0) ? '+' : '') + (Math.round(number * 100) / 100) + "%"
    }

    static formatPhoneNumber(s) {
        var s2 = ("" + s).replace(/\D/g, '');
        var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
    }

    static parseCurrency(currency) {
        return Number(currency.replace(/[^0-9\.]+/g, ""));
    }

    static parseAmount(number) {
        return parseFloat((number).toFixed(4));
    }


    /**
     * The cache is considered expired after 1 hour
     *
     * @param lastUpdatedOn
     * @returns {boolean}
     */
    static cacheExpired(lastUpdatedOn) {
        return (lastUpdatedOn) ? (Utils.now() - lastUpdatedOn) > 60 * 60 * 1000 : false;
    }


    /**
     * Deep merge two objects.
     * @param target
     * @param ...sources
     */
    static mergeDeep(target, ...sources) {
        return _mergeDeep(target, ...sources);
    }

    /**
     * Clone value object into a new deep copy
     *
     * @param value
     * @param customizer
     * @param thisArg
     * @returns cloned value
     */
    static cloneDeep(value, customizer, thisArg) {
        return cloneDeep(value, customizer, thisArg)
    }

    static getYearDiff(start, end, round) {
        let years = Moment(end).diff(Moment(start), 'years', true);

        if (round)
            years = Math.round(years);

        return years;
    }

    static getMonthDiff(start, end, round) {
        let months = Moment(end).diff(Moment(start), 'months', true);

        if (round)
            months = Math.round(months);

        return months;
    }

    static getDayDiff(start, end) {
        let days = Moment(end).diff(Moment(start), 'days', true);

        return Math.round(days);
    }

    static daysLeftInMonth(date, inclusive) {
        return Utils.daysLeft(date, inclusive, 'month');
    }

    static daysLeftInQuarter(date, inclusive) {
        return Utils.daysLeft(date, inclusive, 'quarter');
    }

    static daysLeft(date, inclusive, diffType = 'month') {
        let endOfQuarter = Moment(date).endOf(diffType);
        let a = Utils.formatDate(endOfQuarter);
        let b = Utils.formatDate(date);
        return Utils.getDayDiff(Moment(date), endOfQuarter) - (inclusive ? 0 : 1);
    }

    static dayOfMonth(date, inclusive) {
        return Moment(date).date() - (inclusive ? 0 : 1);
    }

    static getProgressStatus(progress) {
        const duration = Moment.duration(Moment(progress.endTime).diff(Moment()))
        const timeDiff = duration.asHours()

        let status = {
            hasWarrning: false,
            hasExpired: false,
            hideProgress: false
        }

        if (timeDiff < 0) {
            status.hasWarrning = true
        }

        if (timeDiff <= -2 || progress.currentStatus && progress.currentStatus == 'Error') {
            status.hasExpired = true
        }

        if (timeDiff <= -24) {
            status.hideProgress = true
        }

        return status
    }

    static dayOfQuarter(date, inclusive) {
        return (Utils.getDayDiff(Moment(date).startOf('quarter'), Moment(date)) + 1) - (inclusive ? 0 : 1);
    }

    /**
     * Get time difference in minutes.
     *
     * @param startTime
     * @param endTime
     */
    static getDiffInMinutes(startTime, endTime) {

        // time difference in ms
        let timeDiff = endTime - startTime;

        // strip the ms
        timeDiff /= 1000;

        // get seconds (Original had 'round' which incorrectly counts 0:28, 0:29, 1:30 ... 1:59, 1:0)
        let seconds = Math.round(timeDiff % 60);

        // remove seconds from the date
        timeDiff = Math.floor(timeDiff / 60);

        // get minutes
        let minutes = Math.round(timeDiff % 60);

        return minutes;
    }

    /**
     * Determine if this object has any of it's own keys..
     *
     * @param obj
     * @returns true if it has keys..
     */
    static objHasKeys(obj) {
        let hasKeys = false;
        for (let i in obj) {
            hasKeys = true;
            break;
        }

        return hasKeys;
    }

    static isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    static isPriorDate(d) {
        return this.isDate(d) && this.daysSince(d) > 1;
    }

    static isDate(d) {
        try {
            const timestamp = Date.parse(d);
            return isNaN(timestamp) == false;
        } catch (error) {
            return false;
        }
    }

    /**
     * Add the given weeks to the given date
     *
     * @param weeks
     * @returns {*}
     */
    static addWeeksTo(date = Utils.currentDate(), weeks) {
        return Moment(date).add(weeks, 'weeks');
    }

    /**
     * Add the given years to the given date
     *
     * @param years
     * @returns {*}
     */
    static addYearsTo(date = Utils.currentDate(), weeks) {
        return Moment(date).add(weeks, 'years');
    }

    static getAbsoulteMonths(date) {
        return (date.month() + 1) + date.year() * 12
    }

    static getAbsoulteQuarters(date) {
        return Math.ceil(Utils.getAbsoulteMonths(Moment(date)) / 4);
    }


    /**
     * Convert string to title case
     * ALABAMA IS => Alabama Is
     * @param str
     * @returns {*}
     */
    static toTitleCase(str) {
        if (str == undefined || str == null)
            return undefined;

        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    /**
     * Shorten a string and add ellipsis
     *
     * @param str
     * @param length
     * @returns {*|string}
     */
    static shortString(str, length) {
        return str && (str.substring(0, length) + '...');
    }


    static getD3CirclePath(cx, cy, r) {
        return `M${cx - r},${cy}a${r},${r} 0 1,0 ${r * 2},0a${r},${r} 0 1,0 -${r * 2},0`;
    }


    /**
     * Computes the schedule and other details for the saving goal
     *
     * @param pastFundings
     * @param fundingAmount
     * @param targetAmount
     * @returns Forcasted savings schedule.
     */
    static computeSchedule(pastFundings, fundingAmount, targetAmount) {

        const schedule = []

        let toDateFunding = 0;
        let outstanding = targetAmount;
        let weekNumber = 0;

        pastFundings = pastFundings.slice();
        pastFundings = pastFundings.sort((f1, f2) => {
            return (f1.fundingPeriodId < f2.fundingPeriodId) ? -1 : (f1.fundingPeriodId > f2.fundingPeriodId) ? 1 : 0;
        })

        let lastPeriod = Utils.previousWeekId(Utils.thisWeekId());
        pastFundings = pastFundings.map((funding, index) => {

            toDateFunding += funding.fundAmount;
            outstanding -= funding.fundAmount;

            let output = {
                fundAmount: funding.fundAmount,
                fundingPeriodId: funding.fundingPeriodId,
                toDateFunding: toDateFunding,
                weekNumber: weekNumber++
            }

            lastPeriod = output.fundingPeriodId && output.fundingPeriodId;

            schedule.push(Object.assign({}, output));

            return output;
        })

        lastPeriod = (lastPeriod == null || !lastPeriod) ? Utils.previousWeekId(Utils.thisWeekId()) : lastPeriod;


        const totalFunded = toDateFunding;


        let nextPeriod = Moment(lastPeriod).add(7, 'days').format("YYYY-MM-DD");
        let toFund = -1;

        do {

            toFund = outstanding < fundingAmount ? outstanding : fundingAmount
            toDateFunding += toFund;
            schedule.push({
                fundAmount: toFund,
                fundingPeriodId: nextPeriod,
                toDateFunding: toDateFunding,
                weekNumber: weekNumber++
            })

            nextPeriod = Moment(nextPeriod).add(7, 'days').format("YYYY-MM-DD")
            outstanding -= toFund
        } while (outstanding > 0)

        return {
            pastFundings,
            schedule,
            totalFunded,
            weeksToGo: schedule.length - pastFundings.length,
            progress: 1 - ((targetAmount - totalFunded) / targetAmount)
        }
    }


    /**
     * Determine the savings if you added a monthly prepayment amount above the interest accumulated
     * that month
     *
     * @param outstanding
     * @param annualRate
     * @param monthlyPrepaymentAmount
     * @returns interestSaved with the prepay as a dollar value
     */
    static computeSavingsWithPrepay(outstanding, annualRate, monthlyPrepaymentAmount) {
        const s1 = Utils.buildCardSchedule(outstanding, annualRate);
        const s2 = Utils.buildCardSchedule(outstanding, annualRate, monthlyPrepaymentAmount);

        const toReturn = {
            estimatedSaving: s1.totalInterestPaid - s2.totalInterestPaid,
            payoffWeeks: s2.monthsToPayoff * 4,
            estInterest: s1.totalInterestPaid,
            withBuckitInterest: s2.totalInterestPaid,
            scheduleNoPrepay: s1.schedule,
            scheduleWithPrepay: s2.schedule,
            fasterPayoff: (s2.monthsToPayoff - s1.monthsToPayoff) * 4
        };

        // console.log("#1 : " + JSON.stringify(toReturn, null, 4));

        return toReturn;
    }

    /**
     * Build a credit card schedule
     *
     * @param outstanding
     * @param annualRate
     * @param monthlyPrepaymentAmount
     * @returns {{totalInterestPaid: number, schedule: Array, monthsToPayoff: number}}
     */
    static buildCardSchedule(outstanding, annualRate, fixedPaymentAmount) {
        const schedule = [];

        let month = 0;
        let accumInterest = 0.0;
        let terminated = false;
        schedule.push({ month, outstanding, interest: 0.0, accumInterest, principal: 0.0, payment: 0.0 })
        do {
            let interest = outstanding * annualRate / 1200.0;
            let minPrincipalPayment = 0.01 * outstanding;
            let minPayment = Math.max((interest + minPrincipalPayment), 25.0);

            let payment = fixedPaymentAmount ? fixedPaymentAmount : minPayment;
            //deal with continued minimal payments
            if (payment > (outstanding + interest))
                payment = outstanding + interest;

            outstanding -= (payment - interest);
            accumInterest += interest;

            month++;

            schedule.push({ month, outstanding, interest, payment, principal: payment - interest, accumInterest });

            if (schedule.length > (20 * 12)) {
                terminated = true;
                break;
            }
            //terminate if you are running forever..

        } while (outstanding > 0)

        const totalInterestPaid =
            schedule.reduce((totalInterestPaid, schedule) => totalInterestPaid + schedule.interest, 0.0)

        return {
            totalInterestPaid, schedule, monthsToPayoff: schedule.length - 1, terminated
        };
    }


    static toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    static capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }


    static parseBillingPeriod(asOfPeriod) {
        if (!asOfPeriod) {
            throw Utils.error("Missing asOfPeriod");
        }

        var period = asOfPeriod.split(' ');

        return {
            start: Moment(period[0]).toDate(),
            end: Moment(period[2]).toDate()
        }
    }

    static formatBillingPeriod(billingPeriod) {
        return Utils.formatDate(billingPeriod.start) + ' - ' + Utils.formatDate(billingPeriod.end);
    }

    static calculateBillingPeriod(asOfPeriod, diff) {

        if (!asOfPeriod) {
            throw Utils.error("Missing asOfPeriod");
        }

        var period;
        try {
            period = Utils.parseBillingPeriod(asOfPeriod);
        } catch (e) {
            throw Utils.error("Invalid billing period [" + asOfPeriod + "]", e)
        }

        var newPeriod = {}

        newPeriod.start = Moment(period.start).add(diff, 'months').format("YYYY-MM-DD");
        newPeriod.end = Moment(period.end).add(diff, 'months').format("YYYY-MM-DD");

        return Utils.formatBillingPeriod(newPeriod);
    }


    // Keep the stack trace of original error and rethrow.
    static error(message, error, log) {

        if (!log) {
            log = { error: str => console.log(str) };
        }

        var e = new Error(message)

        if (error) {
            let fullError = null;
            try {
                fullError = JSON.stringify(error);
            } catch (e) {
                if (e.toString() === 'TypeError: Converting circular structure to JSON') {
                    fullError = util.inspect(error, false, 3, false);
                }
            }

            log.error(message + ", Error :" + error.toString() + ", Full Error :" + fullError);

            e.original = error
            e.stack = e.stack.split('\n').slice(0, 2).join('\n') + '\n' +
                error.stack
        } else {
            console.log("I am here 1");
            log.error("Error : " + message);
        }

        return e;
    }


    static emptyPromise(val = null) {
        return new Promise((resolve) => {
            resolve(val);
        });
    }

    static copy(aObject) {
        if (!aObject || typeof aObject !== "object") {
            return aObject;
        }

        var bObject, v, k;
        bObject = Array.isArray(aObject) ? [] : {};
        for (k in aObject) {
            v = aObject[k];
            bObject[k] = (typeof v === "object") ? Utils.copy(v) : v;
        }
        return bObject;
    }

    static getOrgConfigByKey(orgConfigArray) {
        const orgConfigurations = {}
        if (orgConfigArray && orgConfigArray.length > 0) {
            orgConfigArray.forEach(_config => {
                let id = _config.id.replace(/^.+\//, '')
                orgConfigurations[id] = _config
            })
        }

        return orgConfigurations
    }

    /**
     * Returns a random integer between min (inclusive) and max (inclusive)
     * Using Math.round() will give you a non-uniform distribution!
     */
    static random(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }


    static randomNumbers(count, min, max) {
        let toReturn = Array.apply(0, Array(count)).map(i => Utils.random(min, max))
        console.log("r => " + JSON.stringify(toReturn));
        return toReturn;
    }

    static randomFixedInteger(length) {
        return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));
    }

    static filterObjectKeyKeepStructure(attr, obj) {
        obj = Utils.copy(obj)
        _filterObjectKeyKeepStructure(attr, obj);
        return obj;
    }

    static pathToName(path) {
        return path.substring(path.lastIndexOf("/") + 1, path.length)
    }

    static convertToNormalCaseFromCamelCase(stringToConvert) {
        return stringToConvert
            // insert a space before all caps
            .replace(/([A-Z])/g, ' $1')
            // uppercase the first character
            .replace(/^./, function (str) {
                return str.toUpperCase();
            })
    }

    /* Utility function to run sales order data in slices. */
    static async runSlice(params) {

        let {
            data,
            consumeSlicedData,
            sliceParams
        } = params;

        sliceParams = sliceParams || {
            max: 50,
            start: 0,
            end: 50
        }

        let { start, end, max } = sliceParams

        let slicedData = data.slice(start, end)

        await consumeSlicedData(Object.assign({}, params, {
            data: slicedData
        }));

        if (sliceParams.end < data.length) {
            sliceParams.start += max
            sliceParams.end += max

            sliceParams.end = sliceParams.end <= data.length ? sliceParams.end : data.length;

            await Utils.runSlice(Object.assign({}, params, { sliceParams }));
        }
        ;
    }

    // Org Id conventions.
    static ID_MAX_LENGTH = 7;
    static PROD_ID_PREFIX = '1'
    static TEST_ID_PREFIX = '9'

    static newProdId() {
        return PROD_ID_PREFIX + Utils.randomFixedInteger(ID_MAX_LENGTH).toString();
    }

    static toProdId(id) {
        return PROD_ID_PREFIX + id.substring(1);
    }

    static toTestId(id) {
        return TEST_ID_PREFIX + id.substring(1);
    }

    static isTestId(id) {
        return id.toString().startsWith(TEST_ID_PREFIX);
    }

    static getTerm(start, end) {
        return Utils.getYearDiff(start, end, true)
    }

    static evaluateExpression(expression, objs) {
        let expr = Object.keys(objs).reduce((expr, name) => expr += `var ${name}=objs["${name}"];`, "")
        expr += expression;
        try {
            return eval(expr);
        } catch (e) {
            throw Utils.error(`Error while evaulation expression ${expression}, params : ${util.inspect(objs)}`, e);
        }
    }

    static arrayToObject = (array, keyProvider = (obj => obj), valueProvider = (obj => obj)) => {
        return array.reduce((results, element) => {
            results[keyProvider(element)] = valueProvider(element);
            return results;
        }, {})
    }

    static objectToArray = (obj, arrayElement = (name, value) => ({
        name,
        value
    })) => {
        return Object.keys(obj).map((key, index) => {
            return arrayElement(key, obj[key], index);
        })
    }

    static shuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    static Utf8ArrayToStr = (array) => {
        var out, i, len, c;
        var char2, char3;

        out = "";
        len = array.length;
        i = 0;
        while (i < len) {
            c = array[i++];
            switch (c >> 4) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    // 0xxxxxxx
                    out += String.fromCharCode(c);
                    break;
                case 12:
                case 13:
                    // 110x xxxx   10xx xxxx
                    char2 = array[i++];
                    out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                    break;
                case 14:
                    // 1110 xxxx  10xx xxxx  10xx xxxx
                    char2 = array[i++];
                    char3 = array[i++];
                    out += String.fromCharCode(((c & 0x0F) << 12) |
                        ((char2 & 0x3F) << 6) |
                        ((char3 & 0x3F) << 0));
                    break;
            }
        }

        return out;
    }


    static encodeURIComponent(uriComponent) {
        return _encodeUriComponent(uriComponent);
    }

    static decodeURIComponent(uriComponent) {
        return _decodeUriComponent(uriComponent);
    }

    // array = [2,3,4] => 9
    static sumArray(array) {
        return array.reduce((a, b) => a + b, 0)
    }

    // arr = [2,3,4] => [2,5,9]
    static sumArrayIteration(array) {
        return array.reduce((acc, cur, index) => {
            acc.push(index ? acc[index - 1] + cur : cur)
            return acc;
        }, [])
    }

    // Async iterations
    static async asyncForEach(array, callback) {
        let toReturn = []
        for (let index = 0; index < array.length; index++) {
            const result = await callback(array[index], index, array)
            toReturn.push(result);
        }

        return toReturn
    }
}

function _filterObjectKeyKeepStructure(attr, obj) {
    let id = obj.id;
    let returned = Object.keys(obj).map(key => {
        if (key == attr) {
            if (id) obj.id = id
            return true
        } else if (typeof obj[key] == 'object') {
            let result = _filterObjectKeyKeepStructure(attr, obj[key]);
            if (!result) key != id && delete obj[key];
            return result;
        } else {
            key != id && delete obj[key]
            return false;
        }
    });

    let found = returned.reduce((result, _bool) => {
        result = result || _bool;
        return result;
    }, false)

    return found;
}

function _mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (_isObject(target) && _isObject(source)) {
        for (const key in source) {
            if (_isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                _mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return _mergeDeep(target, ...sources);
}

/**
 * Simple is object check.
 * @param item
 * @returns {boolean}
 */
function _isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}


module.exports = Utils;
