import {actions as imageActions} from "../actions/images.action";

const imagesReducers = (state = {"defaultGallery": null, isFetching: false}, action) => {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case imageActions.ALL_GALLERY_IMAGES: {
      const {galleryType, images} = action;
      if (!newState[galleryType])
        newState[galleryType] = images;
    }
      break;

    case imageActions.NEW_GALLERY_IMAGE: {

      const {galleryType, image} = action;
      if (!newState[galleryType])
        newState[galleryType] = [];

      newState[galleryType].push(image);
    }
      break;

    case imageActions.GALLERY_LOADING_STARTED:
      newState.isFetching = true;
      break;

    case imageActions.GALLERY_LOADING_COMPLETE:
      newState.isFetching = false;
      break;

    default:
      return state;
  }

  return newState;
}

export default imagesReducers;
