import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Auth } from "aws-amplify";
import {
    Button, InputAdornment, LinearProgress, TextField, Typography,
    IconButton, Grid, Divider, Box
} from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import { Visibility } from '@material-ui/icons';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import SocialButton from './component/SocialButton'
import themeStyles from './styles/authentication.style';
import AuthHeader from './component/AuthHeader'
import { STAGES } from './SignUp'
import { AuthContext } from 'containers/auth/Authenticator'
import UserActions from 'redux/actions/user.actions';

const useStyles = makeStyles(themeStyles);
export default function SignIn({ onAuthStateChange }) {
    const [loading, setLoading] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [data, setData] = React.useState({})
    const [error, setError] = React.useState({})
    const classes = useStyles();
    const { error: authError } = React.useContext(AuthContext)

    useEffect(() => {
        if (authError)
            setError(preState => ({ ...preState, service: authError }));
    }, [authError])

    const handleServiceErrorClose = () => {
        setError(preState => ({ ...preState, service: false }))
    }
    const handleChange = event => {
        const { name, value } = event.target
        setData({ ...data, [name]: value })
        if (Object.keys(error).length > 0)
            setError({})
    }

    const onSignInHandler = async () => {
        if (validate()) {
            const email = data.username.toLowerCase().trim()
            setLoading(true)
            Auth.signIn(email, data.password).then(res => {
                setLoading(false)
            }).catch(async err => {
                setLoading(false)
                if (err.code === 'UserNotFoundException') {
                    const user = await UserActions.checkIfUserExists(email)
                    if (user) {
                        if (user.id.includes("Google")) {
                            setError(value => ({...value, service: "Please sign in via Google as you have signed up with that option."}))
                        } else if (user.id.includes("Facebook")) {
                            setError(value => ({...value, service: "Please sign in via Facebook as you have signed up with that option."}))
                        } else if (user.id.includes("SignInWithApple")) {
                            setError(value => ({...value, service: "Please sign in via Apple as you have signed up with that option."}))
                        } else {
                            setError(value => ({ ...value, service: 'There seems to be a problem with your account. Please contact support.' }))
                        }
                    } else {
                        setError(value => ({ ...value, service: 'Please enter a valid email and password.' }))
                    }
                } else if (err.code === 'UserNotConfirmedException') {
                    sessionStorage.setItem('AUTH_DATA', JSON.stringify({ email: data.username, isConfirmed: false }))
                    onAuthStateChange && onAuthStateChange('SignUp', STAGES.ConfirmContact)
                } else {
                    setError(value => ({ ...value, service: err.message }))
                }
            })
        }
    }

    const validate = () => {
        let newError = {}
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!data.username)
            newError.username = 'Email address is required.'
        if (data.username && !emailRegex.test(data.username))
            newError.username = 'Invalid email address.'
        if (!data.password)
            newError.password = 'Password is required.'

        const hasError = Object.keys(newError).length > 0;
        if (hasError)
            setError(newError);
        return hasError ? false : true
    }

    return (
        <AuthHeader>
            <Box maxWidth={400} pb={1}>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight={300} width={'auto'}>

                    <Box display="flex" flexDirection="column" px={4} pt={3}>
                        <h2 className={classes.heading}>{'Sign In'}</h2>
                        <Box width="100%" display="flex" flexDirection="column" alignItems="center" mb={1} >
                            <SocialButton name="google" />
                            <SocialButton name="apple" />
                            <SocialButton name="facebook" />
                        </Box>
                        <Box display="flex" mb={2} alignItems="center">
                            <Box flex={1}>
                                <Divider />
                            </Box>
                            <Box mx={2}>
                                <Typography>OR</Typography>
                            </Box>
                            <Box flex={1}>
                                <Divider />
                            </Box>
                        </Box>
                        <form onKeyPress={e => e.key === 'Enter' ? onSignInHandler() : {}}>
                            <Grid container spacing={2}>
                                {error.service &&
                                    <Grid item xs={12}>
                                        <Alert id="error" severity="error" onClose={handleServiceErrorClose} >{error.service}</Alert>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <TextField
                                        id="email-address"
                                        autoComplete='username'
                                        autoFocus={true}
                                        error={!!error.username}
                                        helperText={error.username}
                                        name='username'
                                        value={data['username']}
                                        label="Email Address"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="password"
                                        autoComplete='current-password'
                                        className={clsx(classes.margin, classes.textField)}
                                        error={!!error.password}
                                        helperText={error.password}
                                        name='password'
                                        label="Password"
                                        variant="outlined"
                                        fullWidth
                                        value={data['password']}
                                        onChange={handleChange}
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (<InputAdornment position="end">
                                                <IconButton
                                                    color='default'
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                    onClick={() => {
                                                        setShowPassword(!showPassword)
                                                    }}
                                                    onMouseDown={(event) => {
                                                        event.preventDefault();
                                                    }}>
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} />
                                <Grid item xs={12} sm={6}>
                                    <Button id="forgot-password" fullWidth disabled={loading} color="primary" onClick={() => onAuthStateChange('ForgotPassword')}>
                                        {'Forgot Password'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <Box width="100%" display="flex" flexDirection="column" alignItems="center" my={3} >
                            <Button
                                id="sign-in"
                                className={classes.btnPrimary}
                                fullWidth
                                disabled={loading}
                                onClick={onSignInHandler}
                                color="primary"
                                variant="contained"
                            >
                                <Box p={1}>
                                    <Typography color="inherit">
                                        Sign In
                                    </Typography>
                                </Box>
                            </Button>
                            <Box mt={2} width="100%">
                                <Button
                                    id="sign-up-code"
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    disabled={loading}
                                    className={classes.btnSchoolCode}
                                    onClick={() => onAuthStateChange("SignUp", STAGES.SchoolCode)}
                                >
                                    <Box p={1} display="flex" alignItems="center">
                                        <Typography color="inherit">{'Sign Up with School Code'}</Typography>
                                    </Box>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {loading && <LinearProgress />}
                <Divider />
                <Box pt={1} px={4}>
                    {
                        <Button id="register-account" fullWidth onClick={() => onAuthStateChange('SignUp')} >
                            {'Register New Account'}
                        </Button>
                    }
                </Box>
            </Box>
        </AuthHeader>
    )
}

SignIn.displayName = "SignIn"