const styles = theme => ({
    tabsFlexContainer: {
        height: '64px'
    },
    tabsIndicator: {
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    scrollButtons: {
        color: "white"
    },
    singleTabContainer: {
        height: '64px',
        display: 'flex'
    },
    tabRoot: {
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.1)',
            '& svg': {
                color: theme.palette.primary.main
            }
        }
    },
    tabWrapper: {
        flexDirection: 'row',
        textTransform: 'capitalize',
        fontSize: 'medium',
        '& svg': {
            marginRight: theme.spacing(1),
            marginBottom: '0px !important'
        },

    },
    tabText: {
        [theme.breakpoints.down('sm')]: {
            color: 'white',
        }
    },
    tabLabelContainer: {
        width: 'auto',
        margin: '0 6px',
        padding: '6px',
        justifyContent: 'flex-start'
    },
    tabLabelIcon: {
        minHeight: '64px',
        padding: '8px',
        // '&:hover': {
        //     background: 'rgba(0, 0, 0, 0.1)',
        //     '& svg': {
        //         color: theme.palette.primary.main
        //     }
        // }
    },
    tabSelected: {
        '& svg': {
            color: theme.palette.primary.main,
            [theme.breakpoints.down('sm')]: {
                color: theme.palette.secondary.main,
            }
        }
    },
    tabLabel: {
        textTransform: 'capitalize',
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
            color: 'white'
        }
    }
})

export default styles