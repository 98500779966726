// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_cognito_identity_pool_id': 'us-east-1:eaa92c97-5982-4cd3-b4f4-68518befecc2',
    'aws_cognito_region': 'us-east-1',
    'aws_content_delivery': 'enable',
    'aws_content_delivery_bucket': 'connectqa-hosting-mobilehub-106855385',
    'aws_content_delivery_bucket_region': 'us-east-1',
    'aws_content_delivery_cloudfront': 'enable',
    'aws_content_delivery_cloudfront_domain': 'd5jeuabkdkxvl.cloudfront.net',
    'aws_mobile_analytics_app_id': '85684d85bdbf44a6ad03893749d80db6',
    'aws_mobile_analytics_app_region': 'us-east-1',
    'aws_project_id': '2bc21250-b015-404a-a471-82955496ca04',
    'aws_project_name': 'connect.qa',
    'aws_project_region': 'us-east-1',
    'aws_resource_name_prefix': 'connectqa-mobilehub-106855385',
    'aws_sign_in_enabled': 'enable',
    'aws_user_files': 'enable',
    'aws_user_files_s3_bucket': 'connectqa-userfiles-mobilehub-106855385',
    'aws_user_files_s3_bucket_region': 'us-east-1',
    'aws_user_pools': 'enable',
    'aws_user_pools_id': 'us-east-1_zKtWwYj3q',
    'aws_user_pools_web_client_id': '42n1h97c542si7lkkk18300isp',
}

export default awsmobile;
