import React from 'react';
import { isObject, isNumber, isString, stripHtml } from 'utils/WebUtils';
import { moment } from 'c1-webutils'

export default class FormValidator extends React.Component {

    static getStateName(name, discriminator) {
        return discriminator ? `${name}_${discriminator}` : name;
    }

    static conditional = (condition, ...validators) => (name, discriminator, value, state, self) => {
        if (condition()) {
            FormValidator.compositeValidator(...validators)(name, discriminator, value, state, self);
        } else {
            let stateName = this.getStateName(name, discriminator);
            state[`${stateName}State`] = ""
        }

    }

    static requireHTML = (name, discriminator, value, state, self) => {
        let stateName = this.getStateName(name, discriminator);

        value = stripHtml(value);

        if (value && value.length > 0) {
            state[`${stateName}State`] = 'success'
        } else {
            state[`${stateName}State`] = 'error'
            state[`${stateName}Error`] = props => `${props.label || name} is a required field`
        }

    }

    static required = (name, discriminator, value, state, self) => {
        let stateName = this.getStateName(name, discriminator);

        if (isString(value)) {
            value = value.trim();
        }

        if (value && (isObject(value) || (isNumber(value) && value > 0) ||
            value.length > 0)) {
            state[`${stateName}State`] = 'success'
        } else {
            state[`${stateName}State`] = 'error'
            state[`${stateName}Error`] = props => `${props.label || name} is a required field`
        }

    }

    static future = (name, discriminator, value, state, self) => {
        let stateName = this.getStateName(name, discriminator);
        if (value && moment().diff(value, 'days') <= 0) {
            state[`${stateName}State`] = 'success'
        } else {
            state[`${stateName}State`] = 'error'
            state[`${stateName}Error`] = props => `${props.label || name} cannot be in past`
        }
    }

    static isSameOrAfter = source => (name, discriminator, value, state, self) => {
        let stateName = this.getStateName(name, discriminator);
        let sourceStateName = this.getStateName(source, discriminator);
        let sourceDate = self.state[sourceStateName];

        if (sourceDate && value && moment(value).isSameOrAfter(sourceDate))
            state[`${stateName}State`] = 'success'
        else {
            state[`${stateName}State`] = 'error'
            state[`${stateName}Error`] = props => `${props.label || name} cannot be less than ${source}`
        }
    }

    static isOptionalValidDate = (name, discriminator, value, state, self) => {
        let stateName = this.getStateName(name, discriminator);
        if (value) {
            this.isValidDate(name, discriminator, value, state, self)
        } else {
            state[`${stateName}State`] = 'success'
        }
    }

    static isValidDate = (name, discriminator, value, state, self) => {
        let stateName = this.getStateName(name, discriminator);
        if (value && moment(value).isValid())
            state[`${stateName}State`] = 'success'
        else {
            state[`${stateName}State`] = 'error'
            state[`${stateName}Error`] = props => `${props.label || name} is not a valid date`
        }
    }

    static isValidTime = (name, discriminator, value, state, self) => {
        let stateName = this.getStateName(name, discriminator);
        if (value && moment(value).isValid())
            state[`${stateName}State`] = 'success'
        else {
            state[`${stateName}State`] = 'error'
            state[`${stateName}Error`] = props => `${props.label || name} is not a valid Time`
        }
    }

    static isEqualTo = source => (name, discriminator, value, state, self) => {
        let stateName = this.getStateName(name, discriminator);
        let sourceStateName = this.getStateName(source, discriminator);
        let sourceValue = self.state[sourceStateName];

        if (sourceValue && value && value === sourceValue)
            state[`${stateName}State`] = 'success'
        else {
            state[`${stateName}State`] = 'error'
            state[`${stateName}Error`] = props => `${props.label || name} did not match.`
        }
    }
    static verifyPassword(value) {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{8,99}$/
        return passwordRegex.test(value) ? true : false
    }

    static password = (name, discriminator, value, state) => {
        let stateName = this.getStateName(name, discriminator);

        if (value && FormValidator.verifyPassword(value)) {
            state[`${stateName}State`] = 'success'
        } else {
            state[`${stateName}State`] = 'error'
            state[`${stateName}Error`] = props => `Password requires min 8 characters, number, uppercase and lowercase.`
        }

    }

    // function that returns true if value is email, false otherwise
    static verifyEmail(value) {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRex.test(value) ? true : false
    }

    static email = (name, discriminator, value, state, self) => {
        let stateName = this.getStateName(name, discriminator);

        if (value && FormValidator.verifyEmail(value)) {
            state[`${stateName}State`] = 'success'
        } else {
            state[`${stateName}State`] = 'error'
            state[`${stateName}Error`] = props => `Invalid email address.`
        }
    }

    static verifyPhone(value) {
        var phoneRegex = RegExp(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/g)
        return phoneRegex.test(value)
    }

    static phone = (name, discriminator, value, state, self) => {
        let stateName = this.getStateName(name, discriminator);

        if (value && FormValidator.verifyPhone(value)) {
            state[`${stateName}State`] = 'success'
        } else {
            state[`${stateName}State`] = 'error'
            state[`${stateName}Error`] = props => `Invalid phone number.`
        }
    }

    static compositeValidator = (...validators) => (name, discriminator, value, state, self) => {

        let valid = true;

        let stateName = FormValidator.getStateName(name, discriminator);

        validators.forEach(validator => {
            if (valid) {
                validator(name, discriminator, value, state, self)
                valid = valid && state[`${stateName}State`] !== 'error';
            }
        });
    }

    static validate(self, validations, discriminators = [""]) {
        let valid = true;
        let state = {};
        Object.keys(validations).forEach(name => {
            discriminators.forEach(discriminator => {
                let stateName = FormValidator.getStateName(name, discriminator);
                let value = self.state[stateName];

                let validator = validations[name];

                if (Array.isArray(validator))
                    validator = FormValidator.compositeValidator(...validator);

                validator(name, discriminator, value, state, self)
                valid = valid && state[`${stateName}State`] !== 'error';

            });
        });

        self.setState(state)

        return valid;
    }

    static getValidatedInfo(self, validations, discriminators = [""]) {
        let valid = true;
        let state = {};
        Object.keys(validations).forEach(name => {
            discriminators.forEach(discriminator => {
                let stateName = FormValidator.getStateName(name, discriminator);
                let value = self.state[stateName];

                let validator = validations[name];

                if (Array.isArray(validator))
                    validator = FormValidator.compositeValidator(...validator);

                validator(name, discriminator, value, state, self)
                valid = valid && state[`${stateName}State`] !== 'error';

            });
        });

        return { state, valid }
    }

    static toObject(self, fields, discriminator, instance = {}) {

        let stateName;
        fields.forEach(field => {
            stateName = FormValidator.getStateName(field, discriminator);
            instance[field] = self.state[stateName]
        });

        return instance;
    }
}
