const { config } = require("../../package.json");

// const debug = true;

const getEnvironment = () => {
  if (config.backend === "prod") {
    return require("./prod/prod");
  }
  if (config.backend === "qa") {
    return require("./qa/qa")
  }
  if (config.backend === "local") {
    return require("./local/local")
  }
  if (config.backend === "alt") {
    return require("./alt/alt")
  }
  else
    return require("./dev/development");
};

const defaultConfig = getEnvironment();

//if (debug)
//console.log("Configuring with", JSON.stringify(defaultConfig));

export default defaultConfig.default;
