import React from 'react';
import { bind } from 'utils/WebUtils';
import {
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { Visibility, Info as InfoIcon } from '@material-ui/icons';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles'
import { InfoHelpPopover } from "./InfoPopup";
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Label from './Label'
import MaskedInput from 'react-text-mask';
import { isNumber } from 'utils/WebUtils';

class CustomInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

    bind(this, 'onChange', 'getStateName')
  }

  getStateName() {
    let {
      name,
      discriminator,
    } = this.props;

    return discriminator ? `${name}_${discriminator}` : name;
  }


  onChange(event) {
    let { onChange, name, self, discriminator, type } = this.props;

    let stateName = this.getStateName()

    let value = (type === 'number' && isNumber(Number(event.target.value)))
      ? Number(event.target.value)
      : event.target.value

    self.setState({
      [stateName]: value,
    }, onChange ? () => {
      onChange({ name, discriminator, value })
    } : undefined)
  }


  render() {

    const {
      infoHelp,
      name,
      placeholder,
      self,
      classes,
      onChange,
      margin,
      type,
      id,
      label,
      required,
      editable = true,
      defaultValue,
      steps,
      ...rest
    } = this.props;


    let stateName = this.getStateName()

    const getValue = () => {
      const value = self.state[stateName];

      if (type === 'number' && isNumber(Number(value)))
        return value;
      else if (value)
        return value;

      return defaultValue;
    }

    let value = type === 'number' ? (getValue() || 0) : (getValue() || '')

    let error = self.state[`${stateName}State`] === 'error'
    let errorMessage = self.state[`${stateName}Error`]

    let isPassword = type === 'password'
    let { showPassword = false } = this.state;

    let fieldType = isPassword ? !showPassword ? 'password' : 'text' :
      type;

    let inputProps = {}

    if(steps){
      inputProps['inputProps'] = steps;
    }
    if (fieldType === 'currency') {
      inputProps['inputComponent'] = CurrencyFormat;
      inputProps['startAdornment'] = <InputAdornment position="start">$</InputAdornment>
    }
    if (fieldType === 'phone')
      inputProps['inputComponent'] = TextMaskCustom;

    if (isPassword) {
      inputProps['endAdornment'] = (<InputAdornment position="end">
        <IconButton
          color='default'
          edge="end"
          aria-label="toggle password visibility"
          onClick={() => {
            this.setState({ showPassword: !showPassword })
          }}
          onMouseDown={(event) => {
            event.preventDefault();
          }}>
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>)
    }

    if (infoHelp) {
      inputProps['endAdornment'] = (<InputAdornment position="end">
        <InfoHelpPopover infoHelp={infoHelp} />
      </InputAdornment>)
    }

    if (!editable)
      return <Label label={label} value={value} />

    return (
      <div className={classes.root}>
        <TextField
          id={id}
          name={name}
          margin={margin || 'none'}
          variant="outlined"
          label={label}
          // defaultValue="-"
          value={value}
          onChange={this.onChange}
          fullWidth
          required={required || false}
          placeholder={placeholder}
          error={error}
          helperText={error && errorMessage(this.props)}
          type={fieldType}
          InputProps={inputProps}
          {...rest}
        />
      </div>
    )
  }
}

function CurrencyFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}


CurrencyFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function TextMaskCustom(props) {
  // http://text-mask.github.io/text-mask/
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      // placeholderChar={'\u2000'}
      showMask
      guide={false}
      keepCharPositions={true}
    />
  );
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  numeric: {
    textAlign: "right"
  }
})

export default withStyles(styles, { withTheme: true })(CustomInput);
