import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import compose from "recompose/compose";
// Material components
import {
  IconButton,
  Toolbar,
  Typography,
  Badge,
  CircularProgress,
  Button,
  Box,
  Tooltip,
  Hidden,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import { withTheme } from "@material-ui/core/styles";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import {
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  Apps as AppsIcon,
  ArrowBack as ArrowBackIcon,
  AdbRounded as ImpersonateIcon,
  MoreVert as MoreVertIcon,
  Code as CodeIcon
} from "@material-ui/icons";
import LaunchOrderDialog from "pages/LaunchOrderDialog";
// Actions & Selectors
import {
  toggleNotifications,
  toggleSidenav,
  updateLayout
} from "redux/actions/layout.actions";
import { changeTheme, changeThemeDirection } from "redux/actions/theme.actions";
import * as UserSelector from "redux/selectors/user.selector";
import UserActions from "redux/actions/user.actions";
// Themes
import styles from "./content-toolbar.styles";
import EntitySelectors from "redux/selectors";
import EntityActions from "redux/actions";
import { Routes } from "routes/routes";
import NotificationsPopover from "./NotificationsPopover/NotificationsPopover";
import InviteDialog from "./InviteDialog/InviteDialog";
import { hasCompletedOnboarding, hasRole } from "utils/auth";
import AvatarSvg from "components/AvatarSvg/AvatarSvg";

const OrgUserActions = EntityActions.orgUser;
const NotificationSelectors = EntitySelectors.notification;
const OrgUsersSelectors = EntitySelectors.orgUser;

const _findRoute = path => {
  return Routes.reduce(function f(acc, val) {
    return val.path === path
      ? val
      : val.children && val.children.length
      ? val.children.reduce(f, acc)
      : acc;
  });
};

const disabledStyle = {
  cursor: "not-allowed",
  opacity: "0.5"
};

class ContentToolbar extends React.Component {
  state = {
    layoutMenuEl: null,
    layoutMenuOpen: false,
    themeMenuEl: null,
    themeMenuOpen: false,
    showLaunchDialog: false,
    profileMenuOpen: false,
    profileMenuEl: null,
    loading: false,
    openNotifications: false,
    openInvite: false,
    openMore: false
  };

  navigate = path => {
    const { history } = this.props;
    history && history.push(path);
  };

  goBack = path => {
    const { history } = this.props;
    if (path) history && history.push(path);
    else history.goBack();
  };

  cancelImpersonation = async () => {
    const { user: currentUser } = this.props;
    this.setState({ loading: true });
    if (currentUser.impersonated) {
      await UserActions.endImpersonation();
      this.setState({ loading: false });
      window.location.reload();
    }
  };
  handleNotificationsOpen() {
    this.setState({ openNotifications: true });
  }
  handleNotificationsClose() {
    this.setState({ openNotifications: false });
  }

  toggleInvitePopover() {
    this.setState({ openInvite: !this.state.openInvite });
  }

  toggleMorePopover() {
    this.setState({ openMore: !this.state.openMore });
  }

  render() {
    const {
      width,
      match,
      classes,
      currentOrganization,
      orgUser,
      user,
      currentFamilyFetching,
      notificationStats
    } = this.props;
    const { loading, openNotifications, openInvite } = this.state;
    const { unread = 0, count = 0 } = notificationStats || {};

    if (!currentOrganization || !orgUser) return null;

    const isSmallScreen = isWidthDown("sm", width);
    const notificationIcon = () => {
      if (currentFamilyFetching)
        return <CircularProgress color="primary" size={20} />;

      const icon = [];
      if (orgUser && orgUser.family && orgUser.family.avatarKey)
        icon.push(
          <AvatarSvg
            width={32}
            height={32}
            imageKey={orgUser.family.avatarKey}
            backgroundColor={orgUser.avatarBackgroundColor}
          />
        );
      else icon.push(<NotificationsIcon key="notificationIcon" />);

      return icon;
    };

    const currentRoute = _findRoute(match.path);
    const isImpersonated = (user && user.impersonated) || false;

    const completedOnboarding = hasCompletedOnboarding(
      orgUser,
      currentOrganization
    );

    const onboardingNotCompleteMessage = (
      <Typography style={{ color: "white" }}>
        Complete onboarding registration first to access this feature
      </Typography>
    );

    const options = (
      <>
        {orgUser && hasRole(["admin"], orgUser) && (
          <Tooltip
            title={!completedOnboarding ? onboardingNotCompleteMessage : ""}
            placement="bottom"
            arrow
          >
            <Box mr={1}>
              <Button
                variant="contained"
                color="primary"
                style={!completedOnboarding ? disabledStyle : null}
                onClick={
                  completedOnboarding
                    ? () => this.toggleInvitePopover()
                    : () => {}
                }
              >
                Invite Code
              </Button>
            </Box>
          </Tooltip>
        )}
        {isImpersonated && (
          <Tooltip title="Cancel Impersonation">
            <Button
              color="secondary"
              aria-label="Impersonate"
              onClick={() => this.cancelImpersonation()}
            >
              <Box mr={1} display="flex">
                {loading ? (
                  <CircularProgress size="18px" />
                ) : (
                  <ImpersonateIcon color="error" />
                )}
              </Box>
              {"Cancel"}
            </Button>
          </Tooltip>
        )}
        <Tooltip
          title={
            !completedOnboarding ? onboardingNotCompleteMessage : "View Apps"
          }
          placement="bottom"
          arrow
        >
          <IconButton
            style={!completedOnboarding ? disabledStyle : null}
            color="inherit"
            aria-label="Apps"
            onClick={
              completedOnboarding ? () => this.navigate("/apps") : () => {}
            }
          >
            <AppsIcon />
          </IconButton>
        </Tooltip>
      </>
    );

    return (
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarContainer}>
          <IconButton
            color="inherit"
            aria-label="open sidenav"
            style={{
              display: isSmallScreen && !currentRoute.back ? "block" : "none"
            }}
            onClick={this.props.toggleSidenav}
          >
            <MenuIcon />
          </IconButton>

          {(currentRoute.back || currentRoute.goBack) && (
            <IconButton
              color="inherit"
              onClick={() => this.goBack(currentRoute.back)}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          {currentRoute && (
            <div className={classes.titleContainer}>
              <Typography
                variant="h4"
                color="inherit"
                noWrap
                id={"toolbar-title"}
              >
                {currentRoute.title}
              </Typography>
            </div>
          )}
          <span className="portal-flex" />
          <Hidden xsDown>{options}</Hidden>
          <IconButton
            ref={rf => (this.notificationRef = rf)}
            color="inherit"
            aria-label="open notifications"
            onClick={() => this.handleNotificationsOpen()}
          >
            {unread > 0 ? (
              <Badge badgeContent={unread} color="secondary">
                {notificationIcon()}
              </Badge>
            ) : (
              notificationIcon()
            )}
          </IconButton>
          <Hidden smUp>
            <IconButton
              ref={rf => (this.moreRef = rf)}
              color="inherit"
              aria-label="More Options"
              onClick={() => this.toggleMorePopover()}
            >
              <MoreVertIcon />
            </IconButton>
          </Hidden>
        </div>
        <Hidden smUp>
          <Popover
            anchorEl={this.moreRef}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            open={this.state.openMore}
            onClose={() => this.toggleMorePopover()}
          >
            {isImpersonated && (
              <Tooltip title="Cancel Impersonation">
                <Button
                  color="secondary"
                  aria-label="Impersonate"
                  onClick={() => this.cancelImpersonation()}
                >
                  <Box mr={1} display="flex">
                    {loading ? (
                      <CircularProgress size="18px" />
                    ) : (
                      <ImpersonateIcon color="error" />
                    )}
                  </Box>
                  {"Cancel"}
                </Button>
              </Tooltip>
            )}

            <List disablePadding>
              <ListItem
                button
                disabled={!completedOnboarding}
                onClick={
                  completedOnboarding ? () => this.navigate("/apps") : () => {}
                }
              >
                <ListItemIcon>
                  <AppsIcon />
                </ListItemIcon>
                <ListItemText primary={"Home Page"} />
              </ListItem>
              {orgUser && hasRole(["admin"], orgUser) && (
                <ListItem
                  button
                  disabled={!completedOnboarding}
                  onClick={() => this.toggleInvitePopover()}
                >
                  <ListItemIcon>
                    <CodeIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Invite Code"} />
                </ListItem>
              )}
              {isImpersonated && (
                <ListItem button onClick={() => this.cancelImpersonation()}>
                  <ListItemIcon>
                    {loading ? (
                      <CircularProgress size="18px" />
                    ) : (
                      <ImpersonateIcon color="error" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={"Cancel"} />
                </ListItem>
              )}
            </List>
          </Popover>
        </Hidden>

        <LaunchOrderDialog
          title={"Prepare Launch Menu"}
          visible={this.state.showLaunchDialog}
          onClose={() => this.setState({ showLaunchDialog: false })}
        />
        <NotificationsPopover
          anchorEl={this.notificationRef}
          currentUser={user}
          onClose={() => this.handleNotificationsClose()}
          open={openNotifications}
        />
        <InviteDialog
          open={openInvite}
          onClose={() => this.toggleInvitePopover()}
          open={openInvite}
        />
      </Toolbar>
    );
  }
}

function mapStateToProps(state, props) {
  const currentUser = UserSelector.currentUser(state, props);
  const orgUserId = OrgUsersSelectors.currentId(state);
  if (
    (currentUser && !orgUserId) ||
    (currentUser && currentUser.id !== orgUserId)
  )
    OrgUserActions.setCurrentId(currentUser.id);
  const orgUser = OrgUsersSelectors.current(state);

  return {
    layout: {
      sidenavOpen: state.layout.sidenavOpen
    },
    orgUser,
    notifications: NotificationSelectors.selectAll(state, props),
    notificationStats: NotificationSelectors.selectAllStats(state, props),
    currentOrganization: UserSelector.currentOrganization(state, props),
    user: currentUser,
    currentFamilyFetching: OrgUsersSelectors.isFetchingCurrent(state, props)
  };
}

ContentToolbar.propTypes = {
  width: PropTypes.string.isRequired,
  layout: PropTypes.shape({
    sidenavOpen: PropTypes.bool
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
  toggleSidenav: PropTypes.func.isRequired,
  toggleNotifications: PropTypes.func.isRequired,
  updateLayout: PropTypes.func.isRequired,
  changeTheme: PropTypes.func.isRequired,
  changeThemeDirection: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired
};

export default compose(
  withTheme,
  withRouter,
  withWidth(),
  withStyles(styles),
  connect(mapStateToProps, {
    toggleSidenav,
    toggleNotifications,
    updateLayout,
    changeTheme,
    changeThemeDirection
  })
)(ContentToolbar);
