import React from "react";

function Icon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 50 50">
            <path d="M23.488 4.008c-.195-.012-.394 0-.597.035-1.157.207-1.942 1.156-2.13 2.219v.004l-2.714 15.39-3.293 5.832v.004a.989.989 0 01-.867.508H10c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-3h6.125a8.007 8.007 0 007.879-6.61L25 29.755l.996 5.637c.672 3.816 4 6.609 7.879 6.609H40v3c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V28c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1h-3.887a.989.989 0 01-.867-.508v-.004l-3.293-5.832-2.715-15.39v-.004c-.187-1.063-.972-2.012-2.129-2.219a2.622 2.622 0 00-2.109.55 2.614 2.614 0 00-1.512-.585zm-.246 2.004c.453-.082.758.218.758.62V23.91l-1.965 11.133A5.984 5.984 0 0116.125 40H10V30h3.887a3.01 3.01 0 002.613-1.527h-.004l3.375-5.98a1.01 1.01 0 00.113-.321L22.73 6.609c.055-.316.29-.558.512-.597zm3.516 0c.222.039.457.281.512.597l2.746 15.563c.02.113.058.223.113.32l3.375 5.98H33.5A3.01 3.01 0 0036.113 30H40v10h-6.125a5.984 5.984 0 01-5.91-4.957L26 23.91V6.633c0-.403.305-.703.758-.621z"></path>
        </svg>
    );
}

export default Icon;
