import React from 'react';
import PropTypes from 'prop-types';
// Material components
import {withStyles} from '@material-ui/core/styles';
import {Typography, CircularProgress} from "@material-ui/core";

import themeStyles from './layout-loader.theme.style';
import AnimatedAvatar from "components/avatar/AnimatedAvatar";


const messages = [
  "the bits are breathing",
  "warming up the flux capacitor",
  "pay no attention to the man behind the curtain",
  "and enjoy the elevator music",
  "while the little elves draw your screen",
  "checking the gravitational constant in your locale",
  "we're testing your patience",
  "while the satellite moves into position",
  "at least you're not on hold",
  "go ahead -- hold your breath",
];

const LayoutLoader = (props) => {
  const {
    classes
  } = props;
  const message = messages[Math.floor(Math.random() * messages.length)];

  return (
    <div className={classes.loaderWrapper}>
      <AnimatedAvatar type={'engage'} height={200} width={200}/>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <CircularProgress className={classes.progress} style={{margin: 10}}/>
        <Typography variant="body1" color="textSecondary">
          Please wait.. {message}.</Typography>
      </div>
    </div>
  );
};

LayoutLoader.propTypes = {
  classes: PropTypes.shape({}).isRequired
};


export default withStyles(themeStyles, {withTheme: true})(LayoutLoader);
