import React, {Component} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from "react-redux";

import {withStyles, makeStyles} from '@material-ui/core/styles';
import {
  Typography,
  Avatar,
  Paper,
  Badge,
  Grid,
  LinearProgress
} from '@material-ui/core';

import {MailOutline, GroupWork, SupervisedUserCircle, Check as CheckIcon} from '@material-ui/icons';
import {School, Classroom, SchoolBuilding} from "icons/index";
import styles from './notifications-tab.style';
import scss from './notifications-tab.module.scss';
import VisibilitySenor from 'react-visibility-sensor';
import {hasSeen} from "rest/API";

const iconStyle = {
  height: 24,
  fill: 'rgba(0,0,0,.4)'
};


const avatars = {
  "family": <SupervisedUserCircle style={iconStyle}/>,
  "prayer": <SupervisedUserCircle style={iconStyle}/>,
  "registration": <CheckIcon style={iconStyle}/>,
  "community": <GroupWork style={iconStyle}/>,
  "event": <SchoolBuilding style={iconStyle}/>,
  "default": <MailOutline style={iconStyle}/>
}

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 16,
    borderRadius: 4
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  caption: {
    color: theme.palette.secondary.main
  }
}));

function _Notification(props) {
  const {history, notification, markSeen} = props;
  const classes = useStyles();

  const [avatar, setAvatar] = React.useState();
  const [markedSeen, setMarkedSeen] = React.useState(false);

  React.useEffect(() => {
    if (notification) {
      const a = avatars[notification.category] || avatars['default'];
      setAvatar(a)
    }
  }, [notification]);


  const notificationHandler = (notification) => {
    const {actionUrl} = notification;

    if (actionUrl) {
      history.push(actionUrl)
    }
  }

  const onSeen = async (isVisible) => {
    if (isVisible && !notification.seen && !markedSeen) {
      setMarkedSeen(notification)
      await hasSeen(notification.id);
      markSeen(notification.id);
    }
  }

  return (



    // <VisibilitySenor onChange={onSeen}>
      <Paper className={classes.card} key={notification.id}
             onClick={notificationHandler}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Avatar className={classes.avatar}>
              {avatar}
            </Avatar>
          </Grid>
          <Grid item xs={9}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
              {notification.seen ?
                <Typography variant="body2">{notification.message}</Typography> :
                <Badge color="secondary" variant="dot" style={{margin: 8}}>
                  <Typography variant="body2">{notification.message}</Typography>
                </Badge>
              }
              <Typography className={classes.caption}
                          variant="caption">{moment(notification.createdOn).fromNow()}</Typography>
            </div>
          </Grid>
        </Grid>
      </Paper>
    // </VisibilitySenor>
    )
}

const Notification = connect(null,
  dispatch => (
    {
      markSeen: (id) => dispatch({type: 'NOTIFICATION_SEEN', id})
    })
)(_Notification);

function NotificationsTab(props) {
  const {notifications} = props;

  if (!notifications)
    return <LinearProgress/>

  const appNotifications = notifications.filter(n => n.type === "app")

  return (
    <div className={scss['portal-notifications-wrapper']}>
      {appNotifications && appNotifications.length ?
        appNotifications.map((notification, index) => (
          <Notification key={index} {...props} notification={notification}/>
        ))
        :
        <Typography variant="body1" align="center">{'No notifications yet!'}</Typography>
      }
    </div>
  );
}

NotificationsTab.propTypes = {
  classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles, {withTheme: true})(NotificationsTab);
