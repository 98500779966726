import AppEntities from './AppEntities'
import GenericReducer from './GenericReducer'

import themeReducer from './reducers/theme.reducer';
import layoutReducer from './reducers/layout.reducer';
import usersReducer from './reducers/users.reducer'
import currentUserReducer from './reducers/currentUser.reducer.js'
import currentOrgReducers from './reducers/currentOrganization.reducer'
import organizations from './reducers/organizations.reducer'
import data from './reducers/data.reducer'
import imagesReducer from './reducers/images.reducer';
import eventUpdateReducer from "./reducers/eventUpdate.reducer";

const appEntitiesReducers = {}
Object.keys(AppEntities).forEach(entityName => Object.assign(appEntitiesReducers, GenericReducer(entityName, AppEntities[entityName])));


function combineReducers(reducers) {
  const reducerKeys = Object.keys(reducers)
  const finalReducers = {}
  for (let i = 0; i < reducerKeys.length; i++) {
    const key = reducerKeys[i]

    if (typeof reducers[key] === 'function') {
      finalReducers[key] = reducers[key]
    }
  }
  const finalReducerKeys = Object.keys(finalReducers)


  return function combination(state = {}, action) {
    let hasChanged = true;
    const nextState = Object.assign({}, state);
    for (let i = 0; i < finalReducerKeys.length; i++) {
      const key = finalReducerKeys[i]
      const reducer = finalReducers[key]
      const previousStateForKey = state[key]

      const nextStateForKey = reducer(previousStateForKey, action, nextState);

      if (typeof nextStateForKey === 'undefined') {
        const errorMessage = "Undefined state returned!";
        throw new Error(errorMessage)
      }

      nextState[key] = nextStateForKey

      hasChanged = hasChanged || nextStateForKey !== previousStateForKey
    }

    return hasChanged ? nextState : state
  }
}

const appReducer = combineReducers({
  images: imagesReducer,
  theme: themeReducer,
  layout: layoutReducer,
  data: data,
  users: usersReducer,
  currentUser: currentUserReducer,
  currentOrganization: currentOrgReducers,
  organizations: organizations,
  ...appEntitiesReducers,
  eventUpdate: eventUpdateReducer
});


const rootReducer = (state, action) => {

  if (action.type === 'SIGN_OUT') {
    state = undefined;
  }

  if (action.type === 'NOTIFICATION_SEEN') {
    const notificationPages = state && state.notification && state.notification.notification
    if (notificationPages) {
      for (let page of Object.keys(notificationPages)) {
        notificationPages[page] = notificationPages[page].map((n) => {
          if (n.id === action.id) {
            n.seen = true;
            return { ...n }
          }

          return n;
        });
      }

      state.notification.notification = Object.assign({}, notificationPages);

      if (state.notification.unread)
        state.notification.unread -= 1;
      state.notification = Object.assign({}, state.notification);

      return Object.assign({}, state);
    }
  }

  return appReducer(state, action);
};

export default rootReducer;
