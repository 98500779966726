import React, { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import {
    SignIn,
    SignUp,
    ForgotPassword,
    ExistingUser,
    FinalStage
} from "containers/auth";
import LoadingLayout from 'layouts/components/layout-loader/layout-loader.component'
import { STAGES } from './SignUp'
import swal from 'sweetalert'
export const AuthContext = React.createContext();

const DEFAULT_VALIDATION = { authenticated: false, hasCustomAuth: false }
const Authenticator = ({ children, authState }) => {
    console.log('authstate: ' + authState)
    return children.find(child => child.type.displayName === authState)
}
const withAuthentication = (ComposedComponent) => (props) => {
    const [user, setUser] = useState(null);
    const [finalStageData, setFinalStageData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [authState, setAuthState] = useState('SignIn');
    const [authSubState, setAuthSubState] = useState('');
    const [validation, setValidation] = useState(DEFAULT_VALIDATION);
    const [existingUser, setExistingUser] = useState();
    const [error, setError] = useState(false);
    const searchParams = new URLSearchParams(window.location.search);
    const authQuery = searchParams.get('authState');
    const schoolCodeQuery = searchParams.get('schoolCode');

    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    getUser()
                    break;
                case 'signOut':
                    setAuthState('SignIn')
                    setUser(null);
                    setValidation(DEFAULT_VALIDATION)
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    parseError({ event, data })
                    break;
            }
        });
        return () => {
            Hub.remove('auth');
        };
    }, []);

    useEffect(() => {
        if (authQuery === "registerSchool") {
            setAuthState('SignUp');
        }
        if (authQuery === 'signUpSchoolCode') {
            handleAuthStateChange('SignUp', STAGES.SchoolCode);
        }
        if (authQuery === 'forgotPassword')
            setAuthState('ForgotPassword')
        if (schoolCodeQuery) {
            const email = searchParams.get('login');
            sessionStorage.setItem('AUTH_DATA', JSON.stringify({ schoolCode: schoolCodeQuery, email }))
            handleAuthStateChange('SignUp', STAGES.SchoolCode);
        }

        if (!user)
            getUser();
    }, [authQuery, user])

    const parseError = (errorPayload) => {
        const { data } = errorPayload
        const errorMsg = decodeURIComponent(data.message);
        const userAlreadyExists = errorMsg.indexOf('alreadyExists') !== -1;
        const emailDoesNotExist = errorMsg.indexOf('attributes+required:+[email]') !== -1;
        const isDisabledUser = errorMsg.indexOf("User+is+not+enabled") !== -1;
        const permissionDenied = errorMsg.indexOf("access_denied") !== -1;
        if (userAlreadyExists) {
            const [msg, username, email] = errorMsg.replace(/([+])/g, " ").split('-')
            setExistingUser({ username, email })
            setAuthState('ExistingUser')
        }
        else if (isDisabledUser)
            setError('You account has been disabled, please contact admin.');
        else if (emailDoesNotExist) {
            setLoading(false);
            const newFinalStageData = {
                heading: 'Email Does not exist',
                description: 'Please sign in with account that has associated email address. To try again go back to sign in page.'
            }
            handleAuthStateChange('FinalStage', null, newFinalStageData)
        } else if (permissionDenied) {
            setError('Permission error: Access denied');
        } else {
            setError(errorMsg);
        }
    }

    async function getUser() {
        if (!loading) {
            setLoading(true);
            Auth.currentUserInfo().then(async userInfo => {
                setLoading(false);
                if (userInfo) {
                    setUser(userInfo);
                    const customAuth = userInfo && userInfo.attributes["custom:auth"] && JSON.parse(userInfo.attributes["custom:auth"]);
                    const isVerified = userInfo && userInfo.attributes["email_verified"]
                    if (customAuth && isVerified)
                        setValidation({ authenticated: true, hasCustomAuth: true })
                    else if (!isVerified) {
                        sessionStorage.setItem('AUTH_DATA', JSON.stringify({ email: userInfo.attributes['email'], verifyEmail: true, cognito: userInfo }))
                        handleAuthStateChange('SignUp', STAGES.ConfirmContact)
                    } else
                        setAuthState('SignUp')
                }
            }).catch(e => {
                setLoading(false)
            });
        }
    }

    const handleAuthStateChange = (newAuthState, newSubState = '', newData) => {
        if (newData)
            setFinalStageData(newData)
        localStorage.setItem('c1-auth-step', newAuthState)
        setAuthState(newAuthState)
        setAuthSubState(newSubState)

    }

    if (loading)
        return <LoadingLayout />
    if (validation.authenticated)
        return <ComposedComponent {...props} />

    return (
        <AuthContext.Provider value={{
            authData: user,
            authState,
            authSubState,
            authenticated: !!user,
            onAuthStateChange: handleAuthStateChange,
            hasCustomAuth: validation.hasCustomAuth,
            error
        }}>
            <Authenticator authState={authState} >
                <SignIn onAuthStateChange={handleAuthStateChange} />
                <SignUp onAuthStateChange={handleAuthStateChange} />
                <ForgotPassword onAuthStateChange={handleAuthStateChange} />
                <ExistingUser user={existingUser} onAuthStateChange={handleAuthStateChange} />
                <FinalStage data={finalStageData} onAuthStateChange={handleAuthStateChange} />
            </Authenticator>
        </AuthContext.Provider>
    )
}

export default withAuthentication