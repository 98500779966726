import EntitySelectors from 'redux/selectors';

import { createSelector } from 'reselect';
import { currentOrganization } from './user.selector';
import { createOrgTheme } from "themes"

export const newEvent = (state) => state.data && state.data.newEvent;
export const genericState = (state, props) => state[props.name]
export const genericStateName = (state, props) => props.name
export const currentUser = (state) => state.currentUser
export const currentFaculty = (state) => state.faculty_current

export const orgConfig = EntitySelectors.orgConfig.selectAll;
export const defaultTheme = (state) => state.theme && state.theme;
const grades = [
  { value: 'PK-2', label: 'Pre-K - 2' }, { value: 'PK-3', label: 'Pre-K - 3' }, { value: 'PK-4', label: 'Pre-K - 4' }, { value: 'K', label: 'Kindergarten' },
  { value: '1', label: 'Grade 1' }, { value: '2', label: 'Grade 2' }, { value: '3', label: 'Grade 3' }, { value: '4', label: 'Grade 4' }, { value: '5', label: 'Grade 5' },
  { value: '6', label: 'Grade 6' }, { value: '7', label: 'Grade 7' }, { value: '8', label: 'Grade 8' }, { value: '9', label: 'Grade 9' }, { value: '10', label: 'Grade 10' },
  { value: '11', label: 'Grade 11' }, { value: '12', label: 'Grade 12' }
]
const faculty = [
  { value: 'ADMINISTRATOR', label: 'Administrator' }, { value: 'HR-TEACHER', label: 'Homeroom Teacher' }, { value: 'HR-TEACHER-AID', label: 'Homeroom Teacher Aid' },
  { value: 'SPECIALTY-TEACHER', label: 'Specialty Teacher' }, { value: 'SUPPORT', label: 'Support' }, { value: 'OTHER', label: 'Other' }
]
const categories = [
  { "label": "Fundraising Events ", "value": "fundraising-events" },
  { "label": "Social Events ", "value": "social-events" },
  { "label": "Traditions ", "value": "traditions" },
  { "label": "School Operational", "value": "school-operational" },
  { "label": "CYO / Coaching / Extra-curricular", "value": "CYO" },
  { "label": "Classroom Events ", "value": "classroom-events" },
  { "label": "Expertise on Strategic Tasks", "value": "expertise-strategic" },
  { "label": "Leadership", "value": "leadership" },
];

const eventTypes = [
  { label: 'Class Event', value: 'classEvent' },
  { label: 'School Event', value: 'schoolEvent' },
  { label: 'Position(s)', value: 'yearLongPosition' }]

export const getOrgTheme = createSelector(
  [currentOrganization, defaultTheme],
  (currentOrganization, defaultTheme) => {
    if (!defaultTheme || !currentOrganization)
      return undefined;

    const orgTheme = createOrgTheme(currentOrganization, defaultTheme);

    return orgTheme;
  }
);

export const getDisabledFeatures = createSelector(
  [orgConfig],
  (orgConfig) => {
    const disabledFeatures = orgConfig && orgConfig.find(cfg => cfg.id === "disabledfeatures");
    return disabledFeatures?.value || [];
  }
)

export const getReferenceData = createSelector(
  [orgConfig],
  (orgConfig) => {

    let subCategories = orgConfig && orgConfig.find(cfg => cfg.id === 'referencedata/sub-categories');
    let schoolClasses = orgConfig && orgConfig.find(cfg => cfg.id === 'referencedata/school-classes');
    let locations = orgConfig && orgConfig.find(cfg => cfg.id === 'referencedata/locations');

    return {
      subCategories: (subCategories && subCategories.value) || [],
      schoolClasses: (schoolClasses && schoolClasses.value) || [],
      locations: (locations && locations.value) || [],
      categories: categories || [],
      eventTypes: eventTypes || [],
      grades: grades,
      faculty: faculty || []
    }
  }
);

export const getStateStatus = createSelector(
  [genericState, genericStateName],
  (genericState, genericStateName) => {
    let toReturn = undefined
    if (!genericState || !genericState.lastUpdated)
      toReturn = {
        isFirstLoad: true,
        isloading: true,
        name: genericStateName
      }
    else
      toReturn = {
        isFirstLoad: false,
        isloading: genericState.isFetching ? true : false,
        name: genericStateName
      }
    return toReturn
  }
)

export const getCurrentUser = createSelector(
  [currentUser],
  (currentUser) => currentUser ? currentUser.user : null
)

export const getCurrentFaculty = createSelector(
  [currentFaculty],
  (currentFaculty) => currentFaculty ? currentFaculty.faculty : null
)
