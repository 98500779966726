import React from 'react'
import CustomInput from 'components/CustomInput/CustomInput';
import WebForm from 'components/WebForm';
import { Grid } from '@material-ui/core'


export default class ForgotPasswordForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.handleChange = this.handleChange.bind(this);
        this.valid = this.valid.bind(this);
    }

    getFormData() {
        const { delivered } = this.props
        if (this.valid()) {
            const { email, password, cpassword, code, } = this.state
            if (delivered)
                return { email, password, cpassword, code }
            else
                return { email }
        } else
            return false

    }

    handleChange(event) {
        if (event)
            this.props.onChange && this.props.onChange({ target: event })
    }
    render() {
        const { onKeyDown, delivered } = this.props
        const { code } = this.state
        const hasEnteredCode = (code !== "" && code)
        return (
            <Grid container spacing={2} >
                {!delivered && <Grid item xs={12}>
                    <CustomInput
                        id="email"
                        name={'email'}
                        label="Email Address"
                        self={this}
                        onChange={this.handleChange}
                    />
                </Grid>}
                {delivered && <Grid item xs={12}>
                    <CustomInput
                        id="code"
                        name={'code'}
                        label="Code"
                        self={this}
                    />
                </Grid>}
                {hasEnteredCode &&
                    <>
                        <Grid item xs={12}>
                            <CustomInput
                                id="password"
                                name={'password'}
                                label="New Password"
                                self={this}
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomInput
                                id="confirm-password"
                                name={'cpassword'}
                                label="Confirm New Password"
                                self={this}
                                type="password"
                                onKeyDown={e => onKeyDown && onKeyDown(e)}

                            />
                        </Grid>
                    </>
                }
            </Grid>
        )
    }

    valid() {
        const { delivered } = this.props
        let { required, isEqualTo, email } = WebForm;
        let validations = delivered
            ? {
                code: required,
                password: required,
                cpassword: [required, isEqualTo('password')]
            }
            : {
                email: [required, email]
            }
        return WebForm.validate(this, validations)
    }
}
