import shortid from 'shortid'
import { Utils } from 'c1-webutils'

const initialState = {
    newEvent: {
        "id": shortid.generate(),
        "creationDate": Utils.now_str(),
        "title": "",
        "description": "",
        "image": undefined,
        "category": undefined,
        "type": undefined,
        "subType": undefined,
        "slots": [
        ]
    },
    assignedFaculty_searchParams: { assigned: "true" },
    unassignedFaculty_searchParams: { assigned: "false" }
};

const data = (state = initialState, action) => {

    var newstate = Object.assign({}, state);

    switch (action.type) {
        case "SET_FLAG":
            newstate[action.name] = true;
            return newstate;
        case "UNSET":
            newstate[action.name] = (initialState[action.name] && Object.assign({}, initialState[action.name])) || undefined;
            return newstate;
        case "SET_DATA":
            newstate[action.name] = action.value;
            return newstate;
        case "SELECT_ORGANIZATION":
            newstate = selectOrganization(newstate, action);
            return newstate;
        case "UPDATE_ORGANIZATION":
            newstate = updateOrganization(newstate, action);
            return newstate;
        case 'UNASSIGNED_CHILDREN':
            if (action.status === "success") {
                if (!newstate["unassignedChildren_searchParams"])
                    newstate["unassignedChildren_searchParams"] = {};

                // newstate["children_searchParams"].cursor = action.response && action.response.cursor;
                // newstate["children_searchParams"].count = action.response && action.response.count;

                return newstate;
            }
            return state;
        case 'GUARDIANS':
            if (action.status === "success") {
                if (!newstate["guardians_searchParams"])
                    newstate["guardians_searchParams"] = {};

                return newstate;
            }
            return state;
        case "EVENT":
            if (action.status === "success") {
                if (!newstate["event_searchParams"])
                    newstate["event_searchParams"] = {};

                newstate["event_searchParams"].cursor = action.response && action.response.cursor;
                newstate["event_searchParams"].count = action.response && action.response.count;

                return newstate;
            }

            return state;
        case "CURRENT_EVENT":
            if (action.status === "success") {
                if (!newstate["event_currentId"])
                    newstate["event_currentId"] = action.response.id

                return newstate;
            }
            return state;
        case "CHILDREN":
            if (action.status === "success") {
                if (!newstate["children_searchParams"])
                    newstate["children_searchParams"] = {};

                newstate["children_searchParams"].cursor = action.response && action.response.cursor;
                newstate["children_searchParams"].count = action.response && action.response.count;

                return newstate;
            }

            return state;
        case "FACULTY":
            if (action.status === "success") {
                if (!newstate["faculty_searchParams"])
                    newstate["faculty_searchParams"] = {};

                newstate["faculty_searchParams"].cursor = action.response && action.response.cursor;
                newstate["faculty_searchParams"].count = action.response && action.response.count;

                return newstate;
            }

            return state;
        case "FAMILY":
            if (action.status === "success") {
                if (!newstate["family_searchParams"])
                    newstate["family_searchParams"] = {};

                newstate["family_searchParams"].cursor = action.response && action.response.cursor;
                newstate["family_searchParams"].count = action.response && action.response.count;

                return newstate;
            }

            return state;
        case "PRAYER":
            if (action.status === "success") {
                if (!newstate["prayer_searchParams"])
                    newstate["prayer_searchParams"] = {};

                newstate["prayer_searchParams"].cursor = action.response && action.response.cursor;
                newstate["prayer_searchParams"].count = action.response && action.response.count;

                return newstate;
            }

            return state;
        case "RECOGNITION":
            if (action.status === "success") {
                if (!newstate["recognition_searchParams"])
                    newstate["recognition_searchParams"] = {};

                newstate["recognition_searchParams"].cursor = action.response && action.response.cursor;
                newstate["recognition_searchParams"].count = action.response && action.response.count;

                return newstate;
            }

            return state;
        case "VOLUNTEER_INTEREST_MAP":
            if (action.status === "success") {
                if (!newstate["volunteerInterestMap_searchParams"])
                    newstate["volunteerInterestMap_searchParams"] = {};

                newstate["volunteerInterestMap_searchParams"].cursor = action.response && action.response.cursor;
                newstate["volunteerInterestMap_searchParams"].count = action.response && action.response.count;

                return newstate;
            }

            return state;
        case "NOTIFICATION":
            if (action.status === "success") {
                if (!newstate["notification_searchParams"])
                    newstate["notification_searchParams"] = {};

                newstate["notification_searchParams"].cursor = action.response && action.response.cursor;
                newstate["notification_searchParams"].count = action.response && action.response.count;

                return newstate;
            }

            return state;
        case "ORG_USER":
            if (action.status === "success") {
                if (!newstate["orgUser_searchParams"])
                    newstate["orgUser_searchParams"] = {};

                newstate["orgUser_searchParams"].cursor = action.response && action.response.cursor;
                newstate["orgUser_searchParams"].count = action.response && action.response.count;

                return newstate;
            }

            return state;
        case "CURRENT_FAMILY":
            if (action.status === "success") {
                if (!newstate["family_currentId"])
                    newstate["family_currentId"] = action.response.id

                return newstate;
            }
            return state;
        case "PROFILE":
            if (action.status === "success") {
                if (!newstate["profile_searchParams"])
                    newstate["profile_searchParams"] = {};

                newstate["profile_searchParams"].nextPageNumber = action.response && action.response.nextPageNumber;

                return newstate;
            }

            return state;
        default:
            return state;
    }
};

const selectOrganization = (newstate, action) => {
    let organizations = newstate.organizations;
    organizations = organizations.map(org => {
        if (org.id === action.value.id)
            org.isSelected = org.isSelected ? false : true;
        else
            org.isSelected = false;
        return org;
    });
    newstate.organizations = organizations;
    return newstate;
};

const updateOrganization = (newstate, action) => {
    let organizations = newstate.organizations;
    const updatedOrg = action.value;
    organizations = organizations.map(org => {
        if (org.id === updatedOrg.id) {
            org = Object.assign({}, updatedOrg);
        }
        return org;
    });
    // organizations = organizations.map(org => Object.assign({}, org))
    newstate.organizations = organizations;
    return newstate;
};

export default data;
