import { store } from "./ReduxStore";
const pkgConfig = require("../../package.json");
const isProduction = pkgConfig.config.backend === 'prod' && pkgConfig.config.isCloudDeployment;
const isDebugEnabled = isProduction ? false : true;
export const RETRY_WAIT = 500;
export const MAX_RETRY_COUNT = 3;
export const MAX_ERROR_TIMEOUT = 1000 * 5;

const expiredTokenHandler = async () => {
    console.log("Handle expired token here.");
};

export default class FetchAction {

    constructor(actionType, remoteAction, errorHandler, expirationTime = 60 * 60 * 1000, selector) {
        this.actionType = actionType;
        this.remoteAction = remoteAction;
        this.errorHandler = errorHandler;
        this.expirationTime = expirationTime;
        this.selector = selector;

        this.performAsyncFetch = this.performAsyncFetch.bind(this);
    }


    /**
     * Determine if I need to do a fetch, and if I do perform the fetch
     *
     * @param fetchParams
     * @return
     * TRUE if the current state is valid and should be returned to the user
     * 1) can't be performed (not logged in or currently busy fetching..
     * 2) should not be performed or has already been performed and there is nothing new to do
     *
     * FALSE if the current state is invalid and you should let the user know that he cant use
     * any item in the state (most likely by returning an undefined or empty response)
     *
     */
    doFetch(reduxState, dataFilter, ...fetchParams) {
        const hasError = reduxState && reduxState.error !== undefined;
        const retryCount = reduxState && reduxState.retryCount;
        const neverFetched = reduxState === undefined || reduxState.lastUpdated === undefined;
        const isFetching = reduxState && reduxState.isFetching;
        const isInvalidated = reduxState && reduxState.didInvalidate;
        const shouldRefresh = reduxState?.refresh
        const isExpired = reduxState && this.cacheExpired(reduxState.lastUpdated);
        const hasData = dataFilter(reduxState);
        const finalErrorAttempt = (retryCount === 0);

        let performedFetch = false;

        if (!isFetching && (shouldRefresh || isInvalidated || neverFetched || isExpired || !hasData)) {
            const wait = (hasError) ? (finalErrorAttempt ? MAX_ERROR_TIMEOUT : RETRY_WAIT) : (isInvalidated ? RETRY_WAIT : 0);
            if (!hasError || (hasError && retryCount >= 0) || (hasError && finalErrorAttempt)) {
                performedFetch = true;

                if (isDebugEnabled)
                    console.log("Fetching [" + this.actionType + "] (isStateValidToUse, performedFetch, hasError, neverFetched, isFetching, isInvalidated, isExpired, hasData, retryCount, wait) (" +
                        hasData + "," + performedFetch + "," + hasError + "," + neverFetched + "," + isFetching + "," + isInvalidated + "," + isExpired + "," + hasData + "," + retryCount + "," + wait + ") with params [" + JSON.stringify(fetchParams) + "]");

                this.performAsyncFetch(wait, finalErrorAttempt, ...fetchParams);
                //go ahead and asynchronously fetch this data..
            }
        }

        return hasData;
    }

    async performAsyncFetch(wait, finalErrorAttempt, ...fetchParams) {
        try {
            if (this.selector) {
                const state = store.getState();

                const eState =
                    state[this.selector]
                if (eState && eState.isFetching && isDebugEnabled) {
                    console.log(`Already fetching[${this.actionType}]=>${this.selector}... skipping re-fetch`);
                    return;
                }
            }


            store.dispatch(this.requestAction(...fetchParams));
            //dispatch request

            var response =
                await this.doTimedRemoteCall(wait, ...fetchParams);

            store.dispatch(this.successAction(response, ...fetchParams));
        } catch (error) {

            if (isDebugEnabled)
                console.log("Fetch error [" + finalErrorAttempt + "]  [" + error.toString() + "], [Full Error :" + JSON.stringify(error) + "] [ stack :- " + error.stack + "]");

            if (error.code === 403) {
                try {
                    await expiredTokenHandler();
                    const e = new Error("Handled failed authentication");
                    e.code = 500;

                    store.dispatch(this.errorAction(e));
                }
                catch (error) {
                    store.dispatch(this.errorAction(error));
                }

            }
            else if (this.errorHandler) {
                try {
                    await this.errorHandler(error, ...fetchParams);
                }
                catch (error) {
                    store.dispatch(this.errorAction(error));
                }
            } else
                store.dispatch(this.errorAction(error));

            if (finalErrorAttempt && isDebugEnabled)
                console.log("Shoot.. something is not working on our servers. Maybe try later?");
        }
    }

    async doTimedRemoteCall(wait, ...fetchParams) {
        return new Promise((resolve, reject) =>
            setTimeout(() => {
                try {
                    resolve(this.remoteAction(store.getState(), ...fetchParams))
                } catch (err) {
                    reject(err);
                }
            }, wait));
    }

    requestAction(...fetchParams) {
        const params = (fetchParams) ? fetchParams[0] : undefined;
        return {
            type: this.actionType,
            status: "request",
            params
        }
    }

    successAction(response, ...fetchParams) {
        const params = (fetchParams) ? fetchParams[0] : undefined;
        return {
            type: this.actionType,
            status: "success",
            params,
            response
        }
    }

    errorAction(error) {
        return {
            type: this.actionType,
            status: "error",
            error
        }
    }

    cacheExpired(lastUpdatedOn) {
        const hasExpired = (lastUpdatedOn) ? (Date.now() - lastUpdatedOn) > this.expirationTime : false;

        if (isDebugEnabled)
            console.log("Fetcher: Has Expired:" + hasExpired + ":" + (Date.now() - lastUpdatedOn) + ":" + this.expirationTime);

        return hasExpired;
    }
}
