import React from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Popover, CardHeader, CardActions, Divider, Button, LinearProgress, CircularProgress } from '@material-ui/core';
import { grey } from '@material-ui/core/colors'
import { NotificationList, EmptyList } from './components';
import EntitySelectors from 'redux/selectors';
import EntityActions from 'redux/actions';
import isEqual from 'lodash/isEqual'
const NotificationSelectors = EntitySelectors.notification;
const NotificationActions = EntityActions.notification

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 350,
    maxWidth: '100%'
  },
  actions: {
    backgroundColor: grey[50],
    justifyContent: 'center'
  }
}));

const NotificationsPopover = props => {
  const { notifications: notificationsProps, anchorEl, isFetching, currentUser, page = 0, count, ...rest } = props;
  const [notifications, setNotifications] = React.useState([])
  const total = notifications ? notifications.length : 0
  const classes = useStyles();
  React.useEffect(() => {
    if (!isEqual(notifications, notificationsProps))
      setNotifications(notificationsProps)

  }, [notificationsProps])

  const handleLoadMore = () => {
    if (total < count)
      NotificationActions.setCurrentPage(page + 1)
  }

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <div className={classes.root}>
        <CardHeader title="Notifications" />
        <Divider />
        {notifications && notifications.length > 0 ? (
          <NotificationList notifications={notifications} currentUser={currentUser} />
        ) : (
          <EmptyList />
        )}
        <Divider />
        {total !== count && <CardActions className={classes.actions}>
          {isFetching && <CircularProgress />}
          <Button
            size="small"
            onClick={handleLoadMore}
          >
            Load More
          </Button>
        </CardActions>}
      </div>
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  notifications: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};


function mapStateToProps(state, props) {
  const search = NotificationSelectors.searchParams(state)
  return {
    notifications: NotificationSelectors.selectAll(state),
    isFetching: NotificationSelectors.isFetching(state),
    page: NotificationSelectors.currentPage(state),
    count: search && search.count
  }
}

export default compose(
  connect(mapStateToProps)
)(NotificationsPopover)

