import React from 'react'
import styles from "../styles/authentication.style";
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Divider, Grid, Typography, LinearProgress } from '@material-ui/core'
import { AuthContext } from 'containers/auth/Authenticator'
import LocationSearch from 'components/select/LocationSearch';
import SchoolDetailForm from './forms/SchoolDetailForm'
import { STAGES } from '../SignUp'
import UserActions from "redux/actions/user.actions";
import swal from 'sweetalert'
import shortid from 'shortid'
import { Auth } from 'aws-amplify';
import { Alert } from '@material-ui/lab';
const useStyles = makeStyles(styles)

export default function SchoolSelector({ data: dataProps, onStepChange, onRegister }) {
    const classes = useStyles()
    const { onAuthStateChange, authData, authenticated } = React.useContext(AuthContext)
    const [data, setData] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const formRef = React.useRef(null)
    const handleSchoolSelection = (value) => {
        if (value)
            setData(value);

        if (!value && data)
            setData({})
    }

    const handleSubmit = () => {
        const formData = formRef.current.getFormData()
        const orgId = formData.id || shortid.generate();
        if (formData) {
            setLoading(true)

            // Check orgId if already exists on Connect1
            UserActions.getOrgById({ orgId }, (res, err) => {

                // If user not authenticated 
                if (err && !authenticated)
                    onStepChange({ nextStep: STAGES.UserDetails, data: { org: { ...formData, name: data?.org_details?.name } } })

                // If user is authenticated and direclty register it with school.
                if (err && authenticated) {
                    const { role } = dataProps
                    const params = {
                        username: authData.username,
                        givenName: authData.attributes.given_name,
                        familyName: authData.attributes.family_name,
                        authorization: [{
                            org: orgId,
                            roles: role
                        }]
                    }
                    const school = {
                        name: data?.org_details?.name,
                        rootUserId: authData.username,
                        id: orgId,
                        address: {
                            street: formData.street,
                            city: formData.city,
                            state: formData.state,
                            zipCode: formData.zipCode
                        },
                    };
                    onRegister && onRegister(params, school, (success) => {
                        setLoading(false);
                        Auth.signOut();
                        if (success) {
                            const finalStageData = {
                                heading: 'Success',
                                description: `Congratulations! You have successfully complete registration for ${school.name}. Please go back to login and sign in again to access the portal.`
                            }
                            onAuthStateChange('FinalStage', null, finalStageData);
                        }
                    });
                }
                if (res) {
                    setLoading(false)
                    const { name, rootUserName, rootUserEmail } = res;
                    setData({ ...data, adminUser: { name, rootUserName, rootUserEmail }, alreadyExists: true })

                    // swal("Exists", `${name} is already on Connect1!. You can get a code to sign up by contacting ${rootUserName} <${rootUserEmail}>`, "error");
                }
            })
        }
    }

    return (
        <Box pt={3} pb={1} maxWidth={450}>
            <Box mb={2} px={4}>
                <h2 className={classes.heading}>{"Choose your school"}</h2>
                <Typography color="textSecondary" variant='body2'>
                    {`All you need to do is search your school name and select.`}
                </Typography>
            </Box>
            <Box px={4} mb={4} minHeight={250}>
                <form onKeyPress={e => e.key === 'Enter' ? handleSubmit() : {}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <LocationSearch
                                id="location-search-input"
                                name={"org"}
                                label={"School"}
                                searchOpts={{ types: ['school'] }}
                                placeholder={'Search School...'}
                                onChange={handleSchoolSelection}
                            />
                        </Grid>
                        {(!data.alreadyExists || data.org_address) &&
                            <SchoolDetailForm ref={formRef} data={data['org_address']} disabled={false} />
                        }
                        {data.adminUser &&
                            <Grid item xs={12}>
                                <Alert id="school-error" severity="error">
                                    {`${data.adminUser.name} is already on Connect1!. You can get a code to sign up by contacting ${data.adminUser.rootUserName} <${data.adminUser.rootUserEmail}>`}
                                </Alert>
                            </Grid>
                        }
                    </Grid>
                </form>
            </Box>
            {loading && <LinearProgress />}
            <Divider />
            <Box px={4} pt={1} display="flex" justifyContent={"space-between"}>
                <Button id="btn-back" disabled={loading} onClick={() => onAuthStateChange('SignIn')}>{'Back'}</Button>
                <Button id="btn-submit" color="primary" disabled={loading || (data && data.alreadyExists)} onClick={handleSubmit} >{authenticated ? 'Submit' : 'Continue'}</Button>
            </Box>
        </Box>
    )
}