const {config} = require('../../../package.json');

function getApiEndpoint() {
  return (config['isCloudDeployment'] && "https://api.connect1.io") || "http://localhost:3002"
}

export default {
  AWS: {
    Auth: {
      identityPoolId: 'us-east-1:60cfcda8-ee17-4215-a1a7-710f48e5b54b',
      region: 'us-east-1',
      userPoolId: 'us-east-1_t4iZlRHLP',
      userPoolWebClientId: '5c9fgnlmuv0apsncektoubhnro',
      mandatorySignIn: true
    },
    API: {
      endpoints: [
        {
          name: "c1_api",
          endpoint: getApiEndpoint(),
        },
        {
          name: "c1_public_api",
          endpoint: getApiEndpoint(),
          custom_header: async () => {
            return {'x-api-key': 'T3MGLN8NHw8XGuXLzuUh52kDr19hwCXg3whaPFSy'}
          }
        }
      ]
    },
    oauth: {
      domain: 'prod.auth.connect1.io',
      redirectSignOut: `${window.location.origin}`,
      redirectSignIn: `${window.location.origin}`,
      scope: ['email', 'profile', 'openid', "aws.cognito.signin.user.admin"],
      responseType: "token"
    },
    Analytics: {
      disabled: true
    },
    Storage: {
      bucket: 'connectprod-userfiles-mobilehub-1888819846',
      region: 'us-east-1',
    }
  },
  AWS_Exports: require("./aws-exports").default,
  development: false
}
