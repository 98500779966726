// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_cognito_identity_pool_id': 'us-east-1:b24a7610-1f91-439d-a351-6a7d8640f0bf',
    'aws_cognito_region': 'us-east-1',
    'aws_content_delivery': 'enable',
    'aws_content_delivery_bucket': 'connect-hosting-mobilehub-1305085251',
    'aws_content_delivery_bucket_region': 'us-east-1',
    'aws_content_delivery_cloudfront': 'enable',
    'aws_content_delivery_cloudfront_domain': 'dtdncnioltzb6.cloudfront.net',
    'aws_mobile_analytics_app_id': 'dad171e4e0cb40358842e8a2ff3085ba',
    'aws_mobile_analytics_app_region': 'us-east-1',
    'aws_project_id': '549af7be-5426-498d-a490-6539e234c8fb',
    'aws_project_name': 'Connect1',
    'aws_project_region': 'us-east-1',
    'aws_resource_name_prefix': 'connect-mobilehub-1305085251',
    'aws_sign_in_enabled': 'enable',
    'aws_user_files': 'enable',
    'aws_user_files_s3_bucket': 'connect-userfiles-mobilehub-1305085251',
    'aws_user_files_s3_bucket_region': 'us-east-1',
    'aws_user_pools': 'enable',
    'aws_user_pools_id': 'us-east-1_OOVxAZxA4',
    'aws_user_pools_web_client_id': '3t7d35bbs9q0sh69pet1tb7so9'
}

export default awsmobile;
