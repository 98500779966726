import AppEntities from './AppEntities'
import GenericSelectors from './GenericSelectors';

let selectors = {}

Object.keys(AppEntities).forEach(entityName => selectors[entityName] = GenericSelectors(entityName));

export function registrationStatus(state) {
  return state && state.registrationStatus && state.registrationStatus.data
}

export default selectors;
