import React from 'react';
import { AuthContext } from 'containers/auth/Authenticator'
import AuthHeader from './component/AuthHeader'
import AccountTypeSelector from './component/AccountTypeSelector';
import SchoolSelector from './component/SchoolSelector'
import UserDetails from './component/UserDetails'
import SchoolCode from './component/SchoolCode'
import ConfirmContact from './component/ConfirmContact'
import ReactGA from 'react-ga';
import swal from 'sweetalert'
import UserActions from 'redux/actions/user.actions';

const _infoTexts = {
    school: 'I have access to all data, including sensitive information of all school community members.',
    parent: 'I have access to contact information that will enter into the Directory and Volunteering features, but do NOT have access to sensitive information.'
}

export const STAGES = {
    AccountSelector: 'ACCOUNT_SELECTOR',
    AdminRegister: 'ADMIN_REGISTER',
    SchoolSelector: 'SCHOOL_SELECTOR',
    UserDetails: 'USER_DETAILS',
    SchoolCode: 'SCHOOL_CODE',
    ConfirmContact: 'CONFIRM_CONTACT',
    Final: 'FINAL'
}
function SignUp({ }) {
    const [step, setStep] = React.useState(STAGES.AccountSelector)
    const [data, setData] = React.useState({})
    const { authSubState } = React.useContext(AuthContext)

    const handleStepChange = (params = {}) => {
        const { nextStep, data: newData, authStep } = params

        if (newData)
            setData({ ...data, ...newData })
        if (nextStep)
            setStep(nextStep)
        if (authStep)
            localStorage.setItem('c1-auth-step', authStep)
    }

    React.useEffect(() => {
        if (authSubState)
            setStep(authSubState)

    }, [authSubState])

    const handleRegistration = (userParams, schoolParams, callback) => {
        UserActions.registerUser({ user: userParams }, (res, err) => {
            //GMAP API => AIzaSyArwSMiFbyxodimw694_ChdBIxhGjJqOg4
            // If error
            if (err) {
                swal("Error", "Was unable to register your account " + err.errorMessage, "error").then((yes) => {
                    callback && callback(false);
                });
            }
            if (!schoolParams && !err) {
                callback(true);
            }
            // If successfull
            if (res && schoolParams) {
                UserActions.registerOrg({ org: schoolParams }, (res, err) => {
                    if (res) {
                        //stop loading on success and go back to confirm page?
                        ReactGA.event({
                            category: 'User',
                            action: 'Created Admin Account',
                            value: 99
                        });
                        callback && callback(true);
                    }
                    if (err) {
                        swal("Error", err.errorMessage, 'error');
                        callback && callback(false);
                    }
                });

            }
        });
    }
    return (
        <AuthHeader>
            {STAGES.AccountSelector === step && <AccountTypeSelector onStepChange={handleStepChange} />}
            {STAGES.UserDetails === step && <UserDetails data={data} onStepChange={handleStepChange} onRegister={handleRegistration} />}
            {STAGES.SchoolSelector === step && <SchoolSelector data={data} onStepChange={handleStepChange} onRegister={handleRegistration} />}
            {STAGES.SchoolCode === step && <SchoolCode data={data} onStepChange={handleStepChange} onRegister={handleRegistration} />}
            {STAGES.ConfirmContact === step && <ConfirmContact data={data} onStepChange={handleStepChange} />}
        </AuthHeader>
    );
};
SignUp.displayName = "SignUp"
export default SignUp;
