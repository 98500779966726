import React from "react";

function Icon({ width, height, primary }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || "511pt"}
            height={height || "511pt"}
            viewBox="-66 0 511 511.999"
        >
            <g fill={primary || "#020202"}>
                <path d="M40.5 190c0 82.71 67.29 150 150 150s150-67.29 150-150-67.29-150-150-150-150 67.29-150 150zm150-130c71.684 0 130 58.316 130 130s-58.316 130-130 130-130-58.316-130-130 58.316-130 130-130zm0 0"></path>
                <path d="M97.89 172.172l38.762 38.191-8.918 53.676a9.997 9.997 0 003.989 9.73 10.004 10.004 0 0010.484.786l48.293-25.078 48.29 25.078a9.996 9.996 0 0010.483-.785 9.997 9.997 0 003.989-9.73l-8.918-53.677 38.761-38.191a9.993 9.993 0 002.493-10.215 10.007 10.007 0 00-8.024-6.797l-53.793-8.097-24.34-48.676a9.997 9.997 0 00-17.886 0l-24.34 48.676-53.793 8.097a10.001 10.001 0 00-5.531 17.012zm67.438-6.106c3.219-.484 6-2.504 7.457-5.414L190.5 125.22l17.715 35.433a10.004 10.004 0 007.457 5.414l39.152 5.895-28.215 27.793a10.018 10.018 0 00-2.847 8.766l6.492 39.066-35.149-18.25a10.003 10.003 0 00-9.215 0l-35.148 18.25 6.492-39.066a9.996 9.996 0 00-2.847-8.766l-28.211-27.793zm0 0M200.5 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10zm0 0"></path>
                <path d="M143.371 5.895C59.25 27.367.5 103.075.5 190c0 53.988 22.7 104.86 62.547 140.887L2.8 437.062a10.002 10.002 0 0010.73 14.727l68.418-14.207 24.047 67.762a9.997 9.997 0 0018.113 1.597L190.5 390.223l66.387 116.718a9.996 9.996 0 008.687 5.055c.293 0 .59-.012.883-.035a10.014 10.014 0 008.547-6.617l24.043-67.762 68.418 14.207c3.844.797 7.8-.73 10.113-3.902a9.994 9.994 0 00.617-10.825L317.95 330.887c39.848-36.028 62.547-86.899 62.547-140.887 0-86.926-58.75-162.633-142.867-184.105a9.998 9.998 0 00-12.164 7.214c-1.367 5.352 1.863 10.797 7.215 12.164C307.937 44.484 360.5 112.223 360.5 190c0 94.66-76.879 170-170 170-93.188 0-170-75.402-170-170 0-77.777 52.559-145.516 127.816-164.727 5.352-1.367 8.582-6.812 7.22-12.164a9.998 9.998 0 00-12.165-7.214zm-25.836 472.16l-19.652-55.38a10 10 0 00-11.457-6.448l-55.324 11.488 47.68-84.031c27.8 20.21 60.718 32.523 94.948 35.57zm177.035-61.828a10.003 10.003 0 00-11.457 6.449l-19.648 55.379-56.2-98.801c34.235-3.047 67.15-15.36 94.954-35.57l47.68 84.03zm0 0"></path>
            </g>
        </svg>
    );
}

export default Icon;
