import { createSelector } from 'reselect';
import { currentOrganization, currentUser } from "./user.selector";
import { loadGallery } from 'redux/actions/images.action'

const gallery = (state) => state.images && state.images.defaultGallery;
const saints = (state) => state.images && state.images.saints;
const heroes = (state) => state.images && state.images.heroes;
const saints2 = (state) => state.images && state.images.saints2;
const childSaints = (state) => state.images && state.images.childSaints;
const childSaintsV2 = (state) => state.images && state.images.childSaintsV2;

export const isGalleryFetching = (state) => state.images && state.images.isFetching;

export const galleryImages = createSelector(
  [currentUser, currentOrganization, gallery, isGalleryFetching],
  (currentUser, currentOrganization, gallery, isFetching) => {

    if (!currentOrganization || !currentUser)
      return null;

    if (!isFetching && !gallery) {
      loadGallery(['defaultGallery/'])
      return null;
    }

    if (isFetching || !gallery)
      return null;

    return gallery;
  }
);

export const saintAvatars = createSelector(
  [currentUser, currentOrganization, saints, isGalleryFetching],
  (currentUser, currentOrganization, avatar, isFetching) => {
    if (!currentOrganization || !currentUser)
      return null;

    if (isFetching && !avatar)
      return null;

    if (!isFetching && !avatar) {
      loadGallery(['saints/'])
      return null;
    }

    return avatar;

  }
);


export const heroAvatars = createSelector(
  [currentUser, currentOrganization, heroes, isGalleryFetching],
  (currentUser, currentOrganization, avatar, isFetching) => {

    if (!currentOrganization || !currentUser)
      return null;

    if (!isFetching && !avatar) {
      loadGallery(['heroes/'])
      return null;
    }

    if (isFetching || !avatar)
      return null;

    return avatar;
  }
);

export const saint2Avatars = createSelector(
  [currentUser, currentOrganization, saints2, isGalleryFetching],
  (currentUser, currentOrganization, avatar, isFetching) => {

    if (!currentOrganization || !currentUser)
      return null;

    if (!isFetching && !avatar) {
      loadGallery(['saints2/'])
      return null;
    }

    if (isFetching || !avatar)
      return null;

    return avatar;
  }
);

export const childSaintAvatars = createSelector(
  [currentUser, currentOrganization, childSaints, isGalleryFetching],
  (currentUser, currentOrganization, avatar, isFetching) => {

    if (!currentOrganization || !currentUser)
      return null;

    if (!isFetching && !avatar) {
      loadGallery(['childSaints/'])
      return null;
    }

    if (isFetching || !avatar)
      return null;

    return avatar;
  }
);

export const childSaintAvatars2 = createSelector(
  [currentUser, currentOrganization, childSaintsV2, isGalleryFetching],
  (currentUser, currentOrganization, avatar, isFetching) => {

    if (!currentOrganization || !currentUser)
      return null;

    if (isFetching || !avatar) {
      loadGallery(['childSaintsV2/'])
      return null;
    }

    return avatar;
  }
);
