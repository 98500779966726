import React from "react";

function Icon({ primary, secondar, width, height }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || "728.346"}
            height={height || "523.591"}
            data-name="Layer 1"
            viewBox="0 0 728.346 523.591"
        >
            <path
                fill="#3f3d56"
                d="M947.173 711.205h-437a17.02 17.02 0 01-17-17v-489a17.02 17.02 0 0117-17h437a17.02 17.02 0 0117 17v489a17.02 17.02 0 01-17 17zm-437-521a15.017 15.017 0 00-15 15v489a15.017 15.017 0 0015 15h437a15.017 15.017 0 0015-15v-489a15.017 15.017 0 00-15-15z"
                transform="translate(-235.827 -188.205)"
            ></path>
            <path fill="#3f3d56" d="M258.346 41.59H727.346V43.59H258.346z"></path>
            <circle cx="281.346" cy="22" r="8" fill={primary}></circle>
            <circle cx="306.346" cy="22" r="8" fill={primary}></circle>
            <circle cx="331.346" cy="22" r="8" fill={primary}></circle>
            <path
                fill="#3f3d56"
                d="M884.673 303.205h-312a13 13 0 010-26h312a13 13 0 010 26zm-312-24a11 11 0 000 22h312a11 11 0 000-22z"
                transform="translate(-235.827 -188.205)"
            ></path>
            <path
                fill="#ccc"
                d="M807.528 405.795h-142a8 8 0 010-16h142a8 8 0 010 16zM889.528 432.795h-224a8 8 0 010-16h224a8 8 0 010 16zM612.319 434.295h-43a9.51 9.51 0 01-9.5-9.5v-27a9.51 9.51 0 019.5-9.5h43a9.51 9.51 0 019.5 9.5v27a9.51 9.51 0 01-9.5 9.5zm-43-44a7.508 7.508 0 00-7.5 7.5v27a7.508 7.508 0 007.5 7.5h43a7.508 7.508 0 007.5-7.5v-27a7.508 7.508 0 00-7.5-7.5zM807.528 500.795h-142a8 8 0 010-16h142a8 8 0 010 16zM889.528 527.795h-224a8 8 0 110-16h224a8 8 0 010 16zM612.319 529.295h-43a9.51 9.51 0 01-9.5-9.5v-27a9.51 9.51 0 019.5-9.5h43a9.51 9.51 0 019.5 9.5v27a9.51 9.51 0 01-9.5 9.5zm-43-44a7.508 7.508 0 00-7.5 7.5v27a7.508 7.508 0 007.5 7.5h43a7.508 7.508 0 007.5-7.5v-27a7.508 7.508 0 00-7.5-7.5z"
                transform="translate(-235.827 -188.205)"
            ></path>
            <path
                fill="#f2f2f2"
                d="M807.528 595.795h-142a8 8 0 010-16h142a8 8 0 010 16zM889.528 622.795h-224a8 8 0 010-16h224a8 8 0 010 16zM612.319 624.295h-43a9.51 9.51 0 01-9.5-9.5v-27a9.51 9.51 0 019.5-9.5h43a9.51 9.51 0 019.5 9.5v27a9.51 9.51 0 01-9.5 9.5z"
                transform="translate(-235.827 -188.205)"
            ></path>
            <path
                fill={primary}
                d="M590.819 426.034l-2.316-2.09c-8.222-7.549-13.65-12.448-13.65-18.552a8.683 8.683 0 018.781-8.835 9.445 9.445 0 017.185 3.373 9.445 9.445 0 017.185-3.373 8.683 8.683 0 018.781 8.835c0 6.104-5.428 11.004-13.65 18.553zM590.819 521.034l-2.316-2.09c-8.222-7.548-13.65-12.448-13.65-18.552a8.683 8.683 0 018.781-8.835 9.445 9.445 0 017.185 3.373 9.445 9.445 0 017.185-3.373 8.683 8.683 0 018.781 8.835c0 6.104-5.428 11.004-13.65 18.553z"
                transform="translate(-235.827 -188.205)"
            ></path>
            <path
                fill="#3f3d56"
                d="M409.157 505.052h-43a9.51 9.51 0 01-9.5-9.5v-27a9.51 9.51 0 019.5-9.5h43a9.51 9.51 0 019.5 9.5v27a9.51 9.51 0 01-9.5 9.5zm-43-44a7.508 7.508 0 00-7.5 7.5v27a7.508 7.508 0 007.5 7.5h43a7.508 7.508 0 007.5-7.5v-27a7.508 7.508 0 00-7.5-7.5z"
                transform="translate(-235.827 -188.205)"
            ></path>
            <path
                fill={primary}
                d="M387.657 496.79l-2.315-2.088c-8.223-7.55-13.651-12.45-13.651-18.553a8.683 8.683 0 018.782-8.835 9.445 9.445 0 017.185 3.373 9.445 9.445 0 017.184-3.373 8.683 8.683 0 018.782 8.835c0 6.104-5.428 11.003-13.651 18.553z"
                transform="translate(-235.827 -188.205)"
            ></path>
            <path
                fill="#ffb8b8"
                d="M126.965 512.106L138.087 509.46 133.175 465.302 116.76 469.207 126.965 512.106z"
            ></path>
            <path
                fill="#2f2e41"
                d="M360.77 693.354h35.93v13.882h-22.047a13.882 13.882 0 01-13.882-13.882z"
                transform="rotate(166.619 271.86 592.361)"
            ></path>
            <path
                fill="#2f2e41"
                d="M351.875 671.877l-24.156-82.786 30.186-7.488 17.708 87.677a4.5 4.5 0 01-4.278 5.389l-15.007.446a6.1 6.1 0 01-.135.002 4.5 4.5 0 01-4.318-3.24z"
                transform="translate(-235.827 -188.205)"
            ></path>
            <path
                fill="#ffb8b8"
                d="M58.09 512.384L69.522 512.383 74.959 468.287 58.086 468.289 58.09 512.384z"
            ></path>
            <path
                fill="#2f2e41"
                d="M291.467 697.321h35.93v13.882H305.35a13.882 13.882 0 01-13.882-13.882z"
                transform="rotate(179.995 191.523 610.154)"
            ></path>
            <path
                fill="#2f2e41"
                d="M291.91 683.456a4.415 4.415 0 01-1.345-3.13l-10.416-82.585a4.506 4.506 0 014.468-4.522l23.248-.169h.033a4.512 4.512 0 014.5 4.431l1.235 81.355a4.497 4.497 0 01-4.396 4.566l-14.113 1.341h-.065a4.468 4.468 0 01-3.15-1.287z"
                transform="translate(-235.827 -188.205)"
            ></path>
            <circle cx="61.065" cy="197.348" r="24.561" fill="#ffb8b8"></circle>
            <path
                fill={primary}
                d="M264.534 593.427c-15.98-12.486-15.81-27.053-10.358-46.51 1.536-5.48 3.194-11.036 4.95-16.917 8.154-27.325 17.397-58.296 13.888-83.868l.495-.068-.495.068c-.961-7.004 1.489-14.371 6.721-20.212 5.233-5.842 12.276-9.079 19.356-8.896 7.067.188 13.94 3.801 18.854 9.912 4.89 6.082 7.225 27.466 7.917 30.531 3.176 14.067 5.094 22.558 6.469 36.103 1.626 16.013 21.102 63.89 27.308 71.92a19 19 0 013.677 15.133 17.805 17.805 0 01-8.455 12.135 101.648 101.648 0 01-32.706 12.87 80.174 80.174 0 01-15.227 1.466c-15.857 0-31.009-4.771-42.394-13.667z"
                transform="translate(-235.827 -188.205)"
            ></path>
            <path
                fill="#ffb8b8"
                d="M366.672 500.894a9.377 9.377 0 00-14.049-3.062l-18.61-10.62-9.213 9.736 26.493 14.628a9.428 9.428 0 0015.379-10.682z"
                transform="translate(-235.827 -188.205)"
            ></path>
            <path
                fill="#2f2e41"
                d="M338.3 508.093l-33.357-10.48a22.961 22.961 0 01-16.01-20.827l-1.434-32.412a13.571 13.571 0 1127.123-.182l-.918 29.82 35.502 14.478a4.5 4.5 0 011.978 6.76l-7.857 11.144a4.488 4.488 0 01-5.027 1.7zM257.309 416.116c1.122-6.731 1.08-13.708 1.04-20.455-.01-1.831-.02-3.663-.01-5.49.053-8.494.58-17.496 4.052-25.784 3.78-9.02 10.778-15.533 18.721-17.422 8.956-2.133 18.673 2.582 21.599 10.345a15.007 15.007 0 119.149 27.97l-.376.032-2.926-7.605-.049.404c-.186 1.577-.379 3.207-1.68 4.15-1.626 1.175-3.65 5.045-3.059 8.965a33.802 33.802 0 001.642 5.881 32.277 32.277 0 011.742 6.48 11.065 11.065 0 01-2.072 8.014 6.186 6.186 0 01-4.238 2.432l-32.988 3.41-1.475-11.018-9.383 11.557z"
                transform="translate(-235.827 -188.205)"
            ></path>
            <path
                fill="#ccc"
                d="M428.827 711.795h-192a1 1 0 010-2h192a1 1 0 010 2z"
                transform="translate(-235.827 -188.205)"
            ></path>
        </svg>
    );
}

export default Icon;
