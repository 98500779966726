import { DateRange as DateRangeIcon } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import Label from './Label'
import MomentUtils from '@date-io/moment';
import { bind } from 'utils/WebUtils';
import { Utils } from 'c1-webutils'
import scss from './DatePicker.module.scss';
import { moment } from 'c1-webutils'

const { formatDate } = Utils
export default class DatePicker extends React.Component {

    constructor(props) {
        super(props);

        bind(this, 'onChange')
    }

    onChange(value) {
        let { onChange, name, minDate, maxDate, self } = this.props;
        let error = undefined
        if (minDate && moment(value).isBefore(moment(minDate))) {
            error = `Selected date cannot be before ${moment(minDate).format('MM/DD/YYYY')}.`
        }
        if (maxDate && moment(value).isAfter(moment(maxDate))) {
            error = `Selected date cannot be after ${moment(maxDate).format('MM/DD/YYYY')}.`
        }
        self.setState({
            [name]: value,
            [`${name}State`]: error ? 'error' : undefined,
            [`${name}Error`]: error
        }, () => {
            if (onChange) {
                onChange({ name, value })
            }
        })
    }

    render() {
        const {
            name,
            placeholder,
            self,
            margin,
            disableToolbar,
            editable=true,
            label,
            ...rest
        } = this.props;

        let value = self.state[name]
        let error = self.state[`${name}State`] === 'error'
        let errorMessage = self.state[`${name}Error`]
        const errorType = typeof errorMessage
        if (!editable)
            return <Label label={label} value={value ? formatDate(value) : '-'} />

        return (
            <div className={scss['datepicker-root']}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        {...rest}
                        label={label}
                        disableToolbar={disableToolbar || false}
                        style={{ display: 'flex' }}
                        InputProps={{
                            placeholder: placeholder,
                            classes: {
                                adornedEnd: scss['adornedEnd'],
                            }
                        }}
                        inputVariant="outlined"
                        name={name}
                        // margin={margin || 'none'}
                        format={'MM/DD/YY'}
                        onChange={this.onChange}
                        // onDismiss={() => {console.log("dismissed...");}}
                        keyboardIcon={<DateRangeIcon size="small" color={(error && 'error') || 'inherit'} />}
                        value={value || null}
                        error={error}
                        helperText={error && errorType === 'function' ? errorMessage(this.props) : errorMessage}
                    />
                </MuiPickersUtilsProvider>
            </div>

        )
    }
}
