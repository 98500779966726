import "normalize.css";
import "intersection-observer";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Amplify } from "aws-amplify";
import awsConfig from "./cfg/aws-amplify";
import "./index.css";
import App from "./app.component";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/ReduxStore";
import { unregister } from "./registerServiceWorker";
import WebFont from "webfontloader";
import GA from "utils/GoogleAnalytics";
import ReactPixel from "react-facebook-pixel";
import { PersistGate } from "redux-persist/integration/react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";


const pkgConfig = require("../package.json");
const usePersist = pkgConfig.config.usePersist === true;

console.debug('pkgConfig.config.environment', pkgConfig.config.environment)

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  maxBreadcrumbs: 2,
  releaseStage: process.env.NODE_ENV === 'development' ? 'local' : pkgConfig.config.environment
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);


ReactPixel.init("498461581018723");

Amplify.configure(awsConfig);
WebFont.load({
  google: {
    families: ["Roboto"]
  }
});

ReactDOM.render(
  //<ErrorBoundary>
    <Provider store={store}>
      {usePersist && (
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            {GA.init() && <GA.RouteTracker />}
            <App />
          </Router>
        </PersistGate>
      )}
      {!usePersist && (
        <Router>
          {GA.init() && <GA.RouteTracker />}
          <App />
        </Router>
      )}
    </Provider>,
  //</ErrorBoundary>,
  document.getElementById("root")
);

unregister();
