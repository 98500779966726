import { Auth } from "aws-amplify";
import connect1AwsConfig from "./config";


const { AWS, AWS_Exports } = connect1AwsConfig;

if (AWS.API)
  AWS.API.endpoints.forEach(endpoint => {
    if (!endpoint.custom_header) {
      endpoint.custom_header = async () => {
        const user = await Auth.currentAuthenticatedUser();
        return {
          "X-User-Id": user.username,
          "Authorization": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        };
      };
    }
  });

const awsAmplifyConfig = { ...AWS, ...AWS_Exports };

export default awsAmplifyConfig;
