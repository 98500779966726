import { store } from "./ReduxStore";

export const RETRY_WAIT = 500;

const expiredTokenHandler = async () => {
    // handle expired token here.
};

export default class Updater {

    constructor(actionType, remoteAction, reducerType, errorHandler) {
        this.actionType = actionType;
        this.remoteAction = remoteAction;
        this.reducerType = reducerType;
        this.errorHandler = errorHandler;
    }


    /**
     * Sends and update using the AWS api.
     *
     * @param update parameters
     * @return TRUE Update process started successfully.
     *         FALSE Updated can not be sent.
     *
     */
    doUpdate(doInvalidate, callback, ...updateParams) {
        this.performAsyncUpdate(RETRY_WAIT, doInvalidate, callback, ...updateParams);
    }

    async performAsyncUpdate(wait, doInvalidate, callback, ...updateParams) {

        const params = (updateParams) ? updateParams[0] : undefined;

        try {

            store.dispatch({
                type: this.actionType,
                status: 'update_inprocess',
                reducerType: this.reducerType,
                params
            });

            var response =
                await this.doTimedRemoteCall(wait, ...updateParams);

            store.dispatch({
                type: this.actionType,
                status: 'update_complete',
                params,
                reducerType: this.reducerType,
                response
            });

            if (doInvalidate) {
                store.dispatch({
                    type: this.actionType,
                    status: 'invalid',
                });
            }

            if (callback)
                callback(response, undefined);

        } catch (error) {
            if (error && Number(error.code) === 403) {
                try {
                    await expiredTokenHandler();
                    const e = new Error("Handled failed authentication");
                    e.code = 500;

                    store.dispatch(this.errorAction(e));
                }
                catch (error) {
                    store.dispatch(this.errorAction(error));
                }
            } else if (this.errorHandler) {
                try {
                    await this.errorHandler(...updateParams);
                }
                catch (error) {
                    store.dispatch(this.errorAction(error));
                }
            } else {
                store.dispatch(this.errorAction(error));
            }

            const err = error && error.response && error.response.data && error.response.data.errorMessage || error;

            console.log("Got error [", err, "]");

            if (callback)
                callback(undefined, err);
            else
                console.log("Shoot.. something is not working on our servers. Maybe try later?");
        }
    }

    async doTimedRemoteCall(wait, ...updateParams) {
        return new Promise((resolve, reject) => {
            try {
                resolve(this.remoteAction(store.getState(), ...updateParams))
            } catch (err) {
                reject(err);
            }
        })
    }

    errorAction(error) {
        return {
            type: this.actionType,
            status: "error",
            error
        }
    }
}
