import RemoteEntityReducer from "./RemoteEntityReducer";
import { AppEntityConfig, entityTypes } from './AppEntities'
import cloneDeep  from "lodash.clonedeep";

const GenericReducer = (entityName, reduxElement) => {

  const config = AppEntityConfig(entityName);

  const currentEntityUpdater = (entityName) => (state, action, parentState) => {
    const { response, status } = action;

    const performListUpdate = (entityName, updatedEntity) => {
      const data = parentState[entityName] && parentState[entityName][entityName];

      for (const key in data) {
        let v = data[key];
        if (v && v.filter) {
          v = v && v.filter((e) => e.id != updatedEntity.id);
          v.push(updatedEntity);
        }

        data[key] = v;
      }

      parentState[entityName] = Object.assign({}, parentState[entityName]);
    }

    if (response && status === 'update_complete' && parentState.currentUser && parentState.currentUser.user) {
      const { result, user } = response;

      if (result) {
        let updatedEntity = result;
        performListUpdate(entityName, updatedEntity);
        //update list entries

        if ((entityName == 'orgUser' && result.id == parentState.currentUser.user.id) || entityName !== 'orgUser') {
          state[entityName] = updatedEntity;
          parentState[`${entityName}_current`] = cloneDeep(parentState[`${entityName}_current`]);
        }
        //update current entity
      }

      if (user && parentState.currentUser.user.id === user.id) {
        performListUpdate('orgUser', user);
        parentState['orgUser_current'] = Object.assign(parentState['orgUser_current'], { orgUser: user });
      }
    }
  }

  const listReducer = () => new RemoteEntityReducer((state, action) => {
    if (!action.response && action.status !== 'success')
      throw new Error("Cannot reduce with no response");

    let { paging, infiniteScroll } = config;

    if (paging) {
      let { currentPage } = action.params;
      let { result, count, unread } = action.response;

      if (infiniteScroll) {
        let allEntities = state[entityName] || [];
        allEntities = allEntities.slice();

        result.forEach(entity => allEntities.find(_entity => entity.id === _entity.id) || allEntities.push(entity))

        return Object.assign(state, { [entityName]: allEntities, currentPage });
      } else {
        let entityByPageId = state[entityName] || {};
        entityByPageId[currentPage] = result;

        state.count = count;
        state.unread = unread;

        // console.log(entityName + " loaded : " + JSON.stringify({ currentPage, list } ));
        return Object.assign(state, { [entityName]: entityByPageId });
      }
    } else {
      let entity = action.response || [];
      // console.log(entityName + " loaded : " + JSON.stringify(entity));
      return Object.assign(state, { [entityName]: entity });
    }
  }, undefined, (state) => delete state[entityName]);

  const currentReducer = () => new RemoteEntityReducer((state, action) => {
    if (!action.response && action.status !== 'success')
      throw new Error("Cannot reduce with no response");

    let entity = action.response || null;
    return Object.assign(state, { [entityName]: entity, entityId: action.params });

  }, currentEntityUpdater(entityName), (state) => delete state[entityName]);


  const entityReducer = listReducer();
  const currentEntityReducer = currentReducer();
  const isAloneEntity = entityTypes.alone_entity.includes(entityName)
  if (isAloneEntity)
    return {
      [entityName]: (state = {}, action, parentState) => {
        var newstate = Object.assign({}, state);

        switch (action.type) {
          case reduxElement:
            return entityReducer.doReduction(newstate, action, parentState);
          default:
            return state;
        }
      }
    }

  return {
    [entityName]: (state = {}, action, parentState) => {
      var newstate = Object.assign({}, state);

      switch (action.type) {
        case reduxElement:
          return entityReducer.doReduction(newstate, action, parentState);
        default:
          return state;
      }
    },
    [`${entityName}_current`]: (state = {}, action, parentState) => {
      var newstate = Object.assign({}, state);

      switch (action.type) {
        case `CURRENT_${reduxElement}`:
          return currentEntityReducer.doReduction(newstate, action, parentState);
        default:
          return newstate;
      }
    }
  }
}

export default GenericReducer
