

export function defaultOrgId(orgIdPrefix, idSuffix) {
    return `${orgIdPrefix}${idSuffix}`;
}


const organizations = [
    {
        idSuffix: "999999",
        name: "Connect1",
        code: 'connect1',
        tosUrl: "https://www.connect1.io/terms-of-use",
        privacyUrl: "https://www.connect1.io/privacy",
        contacts: [
            {
                name: "Umar syyid",
                phone: "301-909-1111",
                email: "umar.syyid@connect1.io"
            }
        ],
        address: "8819 Ridge Rd. Bethesda MD 20817",
    },
    {
        idSuffix: "444444",
        name: "c1 Next",
        code: 'cdnext',
        tosUrl: "https://www.connect1.io/terms-of-use",
        privacyUrl: "https://www.connect1.io/privacy",
        contacts: [
            {
                name: "Umar syyid",
                phone: "301-909-1111",
                email: "umar.syyid@connect1.io"
            }
        ],
        address: "8819 Ridge Rd. Bethesda MD 20817",
    }
]

class OrgUtils {
    static init = (orgIdPrefix) => {
        organizations.forEach(org => {
            org.id = defaultOrgId(orgIdPrefix, org.idSuffix);
        })
    }

    static getOrgByCode = (orgCode) => organizations.find(org => org.code === orgCode);
    static getOrgById = (orgId) => organizations.find(org => org.id === orgId);
}

module.exports = OrgUtils;
