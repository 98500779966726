import React from "react";
import { Input, FormValidator } from "components/validator.components/index";
import { Grid } from "@material-ui/core";

class SchoolDetailForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.valid = this.valid.bind(this);
        this.getFormData = this.getFormData.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.data) !== JSON.stringify(state.prevData)) {
            return props.data;
        }
        return null;
    }

    getFormData() {
        if (this.valid()) {
            const { street, city, state, zipCode, id } = this.state
            return { street, city, state, zipCode, id }
        } else
            return false

    }

    render() {
        const { disabled } = this.props
        return (
            <>
                <Grid item xs={12} sm={12}>
                    <Input
                        id="street"
                        name={"street"}
                        label="Street Address"
                        required={true}
                        self={this}
                        editable={true}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        id="city"
                        name="city"
                        label="City"
                        required={true}
                        self={this}
                        editable={true}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        id="state"
                        name="state"
                        label="State"
                        required={true}
                        self={this}
                        editable={true}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        id="zipcode"
                        name="zipCode"
                        label="Zip Code"
                        required={true}
                        self={this}
                        editable={true}
                        disabled={disabled}
                    />
                </Grid>
            </>
        )
    }

    valid() {
        const self = this;
        let { required, email, isEqualTo } = FormValidator;
        let validations = {
            street: required,
            city: required,
            state: required,
            zipCode: required,
        };
        const result = FormValidator.validate(self, validations)
        return result;
    }
}

export default SchoolDetailForm