import React from 'react'
import styles from "../styles/authentication.style";
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Divider, LinearProgress, Typography, Checkbox, FormControlLabel } from '@material-ui/core'
import { AuthContext } from 'containers/auth/Authenticator'
import { STAGES } from '../SignUp'
import UserDetailForm from './forms/UserDetailForm'
import shortid from 'shortid'
import { Auth } from 'aws-amplify';
import { Alert } from '@material-ui/lab';
import swal from 'sweetalert'
import { alpha } from '@material-ui/core/styles'
import ImageLoader from "components/image/ImageLoader";

import UserActions from 'redux/actions/user.actions'
const useStyles = makeStyles(styles)

export default function UserDetail({ data: dataProps, onStepChange, onRegister }) {
    const classes = useStyles()
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false);
    const [invitedFamily, setInvitedFamily] = React.useState(null);
    const [inviteRequest, setInviteRequest] = React.useState(false)
    const { onAuthStateChange, authenticated } = React.useContext(AuthContext)
    const formRef = React.useRef(null)
    const authData = JSON.parse(sessionStorage.getItem('AUTH_DATA'));
    const authStep = localStorage.getItem('c1-auth-step')
    const isAdminRegister = authStep === 'AdminRegister'
    const isSchoolCodeFlow = authStep === 'SchoolCode'

    React.useEffect(() => {
        if (authData?.email && !inviteRequest && !invitedFamily) {
            const { org } = dataProps;
            setLoading(true);
            setInviteRequest(true);
            UserActions.getInvitations({ clientId: org?.id, email: authData?.email },
                (resp, err) => {
                    setLoading(false);
                    if (err) {
                        swal("Error", err.errorMessage, "error");
                        return;
                    }
                    const familyAttribute = resp?.attributes?.family
                    if (resp && familyAttribute) {
                        setInvitedFamily({
                            user: {
                                given_name: resp?.attributes?.firstName,
                                family_name: resp?.attributes?.lastName,
                                username: resp?.email,
                            },
                            id: familyAttribute.id,
                            avatar: familyAttribute.avatarKey,
                            name: familyAttribute.name,
                            householdId: resp?.attributes?.householdId,
                            totalGuardians: familyAttribute.guardians?.length || 0,
                            totalChildren: familyAttribute?.children.length || 0,
                            accepted: true
                        })
                    }
                }
            );
        }
    }, [authData])

    const handleInviteResponse = (event) => {
        setInvitedFamily({ ...invitedFamily, accepted: event.target.checked })
    }

    const handleSubmit = () => {
        const formData = formRef.current?.getFormData()
        if (formData) {
            let { org, role } = dataProps;

            const userRoles = role || formData.role
            const orgId = org.id || shortid.generate()
            const userParams = {
                username: formData.email.toLowerCase().trim(),
                givenName: formData.firstName,
                familyName: formData.lastName,
                authorization: [{
                    org: orgId,
                    roles: userRoles
                }]
            }

            // If user has been invited as guardian
            if (invitedFamily) {
                userParams['originalEmail'] = authData.email
                if (invitedFamily.accepted) {
                    userParams['familyId'] = invitedFamily?.id
                    userParams['householdId'] = invitedFamily?.householdId
                } else {
                    userParams['rejectedFamilyId'] = invitedFamily?.id
                    userParams['rejectedHouseholdId'] = invitedFamily?.householdId
                }
            }

            let schoolParams;
            if (formData && isAdminRegister) {
                const { street, city, state, zipCode, } = org;
                schoolParams = {
                    name: org.name,
                    id: orgId || shortid.generate(),
                    address: {
                        street,
                        city,
                        state,
                        zipCode
                    },
                };
            }

            if (authenticated) {
                userParams.username = dataProps.user.username
                setLoading(true);
                onRegister && onRegister(userParams, null, (success) => {
                    setLoading(false)
                    if (success) {
                        const finalStageData = {
                            heading: 'Success',
                            description: `Congratulations! You have successfully signed up for ${org.name}. Please go back to login and sign in again to access the portal.`
                        }
                        onAuthStateChange && onAuthStateChange('FinalStage', null, finalStageData)
                    }
                });
            }

            if (!authenticated) {
                const signup_info = {
                    username: userParams.username,
                    password: formData.password,
                    attributes: {
                        'email': userParams.username,
                        'given_name': userParams.givenName,
                        'family_name': userParams.familyName,
                        'custom:auth': JSON.stringify(userParams.authorization),
                        'custom:signUpType': 'admin'
                    }
                }
                if (isAdminRegister) {
                    signup_info.attributes['custom:signUpType'] = 'admin'
                    signup_info.attributes['custom:isRootAccount'] = "1"
                }
                if (isSchoolCodeFlow)
                    signup_info.attributes['custom:signUpType'] = 'school-code'

                setLoading(true)
                Auth.signUp(signup_info).then(data => {
                    if (isAdminRegister)
                        schoolParams.rootUserId = data.userSub

                    onRegister && onRegister(userParams, schoolParams, (success) => {
                        setLoading(false)
                        if (success) {
                            sessionStorage.removeItem('AUTH_DATA');
                            onStepChange({ nextStep: STAGES.ConfirmContact, data: { user: { id: data.userSub, username: userParams.username, ...formData } } })
                        }
                    });
                }).catch(e => {
                    setLoading(false);
                    if (e.code === 'UserLambdaValidationException' && e?.message?.indexOf('alreadyExists') !== -1)
                        setError('A user account with this email address already exists.');
                    else
                        setError(e.message);
                })
            }
        }
    }

    return (
        <Box pt={3} pb={1} maxWidth={450}>
            <Box mb={2} px={4}>
                <h2 className={classes.heading}>{"What's your contact info?"}</h2>
                {isAdminRegister &&
                    <Typography color="textSecondary" variant='body2' >
                        You will be the default administrator for your school. This will allow you to invite your school community, make changes, access support.
                    </Typography>
                }
            </Box>
            {invitedFamily &&
                <FamilyCard family={invitedFamily} onResponse={handleInviteResponse} />
            }
            <Box px={4} mb={4} minHeight={250}>
                <form onKeyPress={e => e.key === 'Enter' ? handleSubmit() : {}}>
                    <UserDetailForm
                        ref={formRef}
                        data={dataProps?.user}
                        hasRole={!!dataProps.role}
                        authenticated={authenticated}
                        invitedFamily={invitedFamily} />
                </form>
            </Box>
            {error &&
                <Box px={4} mb={4}>
                    <Alert id="error" severity="error">{error}</Alert>
                </Box>
            }
            <Box px={4} mb={2}>
                <Button
                    id="btn-submit"
                    variant="outlined"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmit}
                    fullWidth
                >
                    {authenticated ? 'Submit' : 'Register Account'}
                </Button>
            </Box>
            {loading && <LinearProgress />}
            <Divider />
            <Box px={4} pt={1} display="flex" justifyContent={"space-between"}>
                <Button id="btn-back" onClick={() => onAuthStateChange('SignIn')}>{'Back'}</Button>
            </Box>
        </Box>
    )
}

const useStyle2 = makeStyles(theme => ({
    card: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: '1px solid rgba(0,0,0,.3)',
        borderRadius: '10px',
        overflow: 'hidden',
        padding: '8px',
        minHeight: 60,
        marginBottom: 8,
        minWidth: 350,
        backgroundColor: alpha(theme.palette.primary.main, .1),
        '& img': {
            paddingRight: theme.spacing(1),
            width: 50
        }
    },
    title: {
        textTransform: 'capitalize'
    }
}))

const FamilyCard = ({ family, onResponse }) => {
    const classes = useStyle2();
    const secondaryLabel = `${family.totalGuardians} Guardians & ${family.totalChildren} Children`
    if (!family)
        return

    return (
        <Box id="family-card" px={4} mb={2}>
            <Box mb={2}>
                <Typography variant='body2'>{'You have been invited to following family.'}</Typography>
            </Box>
            <Box className={classes.card}>
                {family.avatar && (
                    <ImageLoader
                        imageKey={family.avatar}
                        params={`h=20&w=20`}
                        alt={"header"}
                    />
                )}
                <Box pl={family.avatar ? 1 : 2}>
                    <Typography className={classes.title} variant="h6">{family.name} Family</Typography>
                    <Typography variant="body2">{secondaryLabel}</Typography>
                </Box>
            </Box>
            <Box display="flex" mb={1}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id="invite-response"
                            checked={family?.accepted}
                            onChange={onResponse}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Do you want to be part above family?"
                />
            </Box>
            <Divider />

        </Box>
    )
}