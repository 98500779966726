import { MenuItem, Paper, TextField, Popper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import PlacesAutocomplete, { getDetailsByPlaceId } from './PlacesAutocomplete';
import { bind } from 'utils/WebUtils';

class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { school: '' };

        bind(this, 'handleChange', 'handleSelect', 'getStateName');
    }


    getStateName() {
        let {
            name,
            discriminator,
        } = this.props;

        return discriminator ? `${name}_${discriminator}` : name;
    }


    handleChange(value, callback) {
        let { self } = this.props;

        let stateName = this.getStateName()

        self.setState({
            [stateName]: value,
            [`${stateName}_details`]: undefined,
            [`${stateName}_address`]: undefined
        }, callback)
    }

    handleSelect(value, placeId) {
        let { self } = this.props;
        let stateName = this.getStateName();
        this.handleChange(value, () =>
            getDetailsByPlaceId(placeId)
                .then(results => {
                    results.id = placeId

                    self.setState({
                        [`${stateName}_details`]: results,
                        [`${stateName}_address`]: this.getAddressComponents(results)
                    })
                    // console.log(JSON.stringify(results, null, 4));

                })
                .catch(error => console.error('Error', error)));
    };

    render() {
        const { name,
            service,
            placeholder,
            self,
            classes,
            onChange,
            margin,
            type,
            searchOpts,
            ...rest
        } = this.props;

        let stateName = this.getStateName()
        let variant = this.props.variant || "outlined";

        let value = self.state[stateName]
        let error = self.state[`${stateName}State`] === 'error'
        let errorMessage = self.state[`${stateName}Error`]

        let address = self.state[`${stateName}_address`];

        const helperText = error
            ? errorMessage(this.props)
            : (address && address.id && `${address.city}, ${address.state} ${address.zipCode ? address.zipCode : ''}`) || undefined

        let popoverNode = React.createRef();

        return (
            <PlacesAutocomplete
                service={service}
                value={value}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={Object.assign({}, { componentRestrictions: { country: 'us' } }, searchOpts)}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading, ...rest }) => {

                    const anchorEL = popoverNode.current;
                    return <div className={classes.container} >
                        <TextField
                            autoComplete="off"
                            value={value}
                            variant={variant}
                            fullWidth
                            margin={margin || 'none'}
                            inputRef={popoverNode}
                            error={error}
                            helperText={helperText}
                            {...getInputProps({
                                placeholder: placeholder || 'Search Places ...',
                                className: 'location-search-input',
                            })}
                            {...rest}
                        />
                        {anchorEL &&
                            <Popper open={anchorEL} style={{ width: (anchorEL && anchorEL.offsetWidth) || -1 }} anchorEl={anchorEL}>
                                <Paper square className={classes.paper} >
                                    {loading && <MenuItem style={{ fontWeight: 400 }} >
                                        <div className={classes.secondaryText}>{`Loading...`}</div>
                                    </MenuItem>}
                                    {suggestions.map((suggestion, index) => {

                                        if (service !== 'autocomplete' && !suggestion.types.includes('school')) return null;

                                        let { mainText, secondaryText } = suggestion.formattedSuggestion;
                                        secondaryText = secondaryText && secondaryText.replace(/, USA/gi, "");
                                        return (
                                            <MenuItem
                                                {...getSuggestionItemProps(suggestion, {
                                                    selected: suggestion.active,
                                                    component: "div",
                                                    style: {
                                                        fontWeight: suggestion.active ? 500 : 400,
                                                        display: 'flex',
                                                        flexDirection: "column",
                                                        alignItems: "flex-start"
                                                    }
                                                })}
                                            >
                                                <div className={classes.mainText} id={`suggestion-${index}`}>{`${mainText}`}&nbsp;</div>
                                                <div className={classes.secondaryText}>{`${secondaryText}`}</div>
                                            </MenuItem>
                                        );
                                    })}
                                </Paper>
                            </Popper>
                        }
                    </div>
                }
                }
            </PlacesAutocomplete >
        );
    }

    getAddressComponents(details) {
        // let { org_details } = this.state;

        if (!details) return {
            street: "",
            city: "",
            state: "",
            zipCode: "",
            name: "",
            id: ""
        }

        let get = (part) => {
            let _part = details.address_components.find(A => A.types.includes(part))
            return _part && _part.long_name;
        };

        const getStreetAddress = () => {
            const addressComponents = details.formatted_address.split(',')
            if (addressComponents[0]) {
                return addressComponents[0]
            }
            return ''
        }

        return {
            street: getStreetAddress(),
            city: get('locality'),
            state: get('administrative_area_level_1'),
            zipCode: get('postal_code'),
            name: details.name,
            id: details.id
        }
    }
}


const styles = theme => ({
 
    paper: {
        zIndex: 5,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
        maxHeight: 200,
        overflowY: 'scroll'
    },
    mainText: {
        fontSize: "0.875rem",
        color: 'gray',
        fontWeight: 'bold'
    },
    secondaryText: {
        fontSize: "0.750rem",
        color: 'gray'
    },
    hidden: {
        display: 'none'
    }
});

export default withStyles(styles, { withTheme: true })(LocationSearchInput)
