import { SecurityUtils } from 'c1-webutils'
import { store } from 'redux/ReduxStore'

export function getOrg() {
  const state = store.getState();
  return state && state.currentOrganization['currentOrganization'];
}

export function orgId() {
  const org = getOrg();
  return org && org.id;
}


function getUser() {
  const state = store.getState();
  const user = state && state.currentUser && state.currentUser.user
  if (!user) {
    throw Error("No user selected, error while fetching current user.");
  }

  return user;
}

export function hasRole(roles, user) {
  let org = getOrg();

  if (!user)
    user = getUser();

  if (!Array.isArray(roles)) {
    roles = [roles]
  }

  let _hasRole = false;
  org && roles.forEach(role => {
    _hasRole = _hasRole || SecurityUtils.hasRole(org.id, role, user);
  })

  return _hasRole;
}



export function getOnboardingRedirectPath(route, currentOrgUser, currentOrg) {
  const onboardingPaths = ['/logout', '/parent-onboarding', '/admin-onboarding', '/talent-onboarding', '/faculty-onboarding', '/registration', '/registration-config']
  if (!onboardingPaths.includes(route.path)) {
    if (hasRole(["admin"], currentOrgUser) && (!currentOrg?.theme?.primaryColor || !currentOrg?.theme?.secondaryColor || !currentOrg?.logo?.url)) {
      return '/admin-onboarding'
    }

    if (hasRole(['parent'], currentOrgUser) && currentOrgUser.stage.workflow === "registration") {
      return '/registration'
    }

    if (currentOrgUser.stage && currentOrgUser.stage.registrationConfigTrigger) {
      return '/registration-config'
    }

    if (currentOrgUser.forceOnboarding) {
      if (hasRole(['parent'], currentOrgUser)) {
        return '/parent-onboarding'
      }
      if (hasRole(['faculty', 'school'])) {
        return '/faculty-onboarding'
      }
    }

    if (hasRole(['parent'], currentOrgUser) && !currentOrgUser.stage.hasCompletedParentOnboarding && currentOrgUser.stage.workflow !== "registration") {
      return '/parent-onboarding'
    }

    if (hasRole(['parent'], currentOrgUser) && !currentOrgUser.stage.hasCompletedTalentOnboarding && currentOrgUser.stage.workflow !== "registration") {
      return '/talent-onboarding'
    }

    if (hasRole(['faculty', 'school'], currentOrgUser) && currentOrgUser.stage.completed < 70) {
      return '/faculty-onboarding'
    }

  }
  return ''
}

export function hasCompletedOnboarding(currentOrgUser, currentOrg) {
  if (hasRole(["admin"], currentOrgUser) && (!currentOrg?.theme?.primaryColor || !currentOrg?.theme?.secondaryColor || !currentOrg?.logo?.url)) {
    return false
  }
  if (hasRole(['parent'], currentOrgUser) && currentOrgUser.stage.workflow === "registration") {
    return false
  }
  if (hasRole(['parent'], currentOrgUser) && !currentOrgUser.stage.hasCompletedParentOnboarding) {
    return false
  }
  if (hasRole(['parent'], currentOrgUser) && !currentOrgUser.stage.hasCompletedTalentOnboarding) {
    return false
  }
  if (hasRole(['faculty', 'school'], currentOrgUser) && currentOrgUser.stage.completed < 70) {
    return false
  }
  return true
}
