import React from 'react';
import AuthHeader from './component/AuthHeader'
import styles from "./styles/authentication.style";
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Divider, Typography, LinearProgress } from '@material-ui/core'
import swal from 'sweetalert'
import *  as API from 'rest/API'
import { Auth } from 'aws-amplify';

const useStyles = makeStyles(styles);

function ExistingUser({ onAuthStateChange, user }) {
    const classes = useStyles();
    const source = user?.username?.split('_')[0]
    const [loading, setLoading] = React.useState(false);
    const [linked, setLinked] = React.useState(false);
    const email = user?.email || ""
    const handleLinkUser = () => {
        swal({
            title: 'Confirmation',
            text: `Are you sure, you want to link your ${source.toLowerCase()} account?`,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async yes => {
            if (yes) {
                setLoading(true)
                const res = await API.linkUser(user).then(res => {
                    setLoading(false)
                    setLinked(true);
                }).catch(e => {
                    setLoading(false)
                    swal('Error', 'Linking your user account was unsuccessful, try again or contact adminstrator.', 'error');
                });
            }
        })
    }
    const handleBack = () => {
        Auth.signOut();
        onAuthStateChange && onAuthStateChange('SignIn')
    }

    return (
        <AuthHeader>
            <Box pt={3} pb={1} maxWidth={450}>
                <Box mb={2} px={4}>
                    <h2 className={classes.heading}>{linked ? "Account Linked" : "Account Already Exists"}</h2>
                    {linked ?
                        <Typography color="textSecondary" variant="body2">
                            Your account has been successfully linked, please go back and login again to continue.
                        </Typography>
                        :
                        <Typography color="textSecondary" variant="body2">
                            An account with email <b>{email}</b> already exists in our system. Do you want to link your {source} Account with it?
                        </Typography>
                    }
                </Box>
                <Box px={4} mb={4} minHeight={250} />
                {loading && <LinearProgress />}
                <Divider />
                <Box px={4} pt={1} display="flex" justifyContent={"space-between"}>
                    <Button id="btn-back" onClick={handleBack}>{linked ? 'Back to login' : 'No, Thanks'}</Button>
                    {!linked && <Button id="btn-submit" variant="outlined" color="primary" onClick={handleLinkUser} >{'Yes, Please'}</Button>}
                </Box>
            </Box>
        </AuthHeader >
    );
};

ExistingUser.displayName = "ExistingUser"
export default ExistingUser;
