const { config } = require('../../../package.json');

function getApiEndpoint() {
  return (config['isCloudDeployment'] && "https://qa-api.connect1.io") || "http://localhost:3002"
}

export default {
  AWS: {
    Auth: {
      identityPoolId: 'us-east-1:eaa92c97-5982-4cd3-b4f4-68518befecc2',
      region: 'us-east-1',
      userPoolId: 'us-east-1_zKtWwYj3q',
      userPoolWebClientId: '42n1h97c542si7lkkk18300isp',
      mandatorySignIn: true
    },
    API: {
      endpoints: [
        {
          name: "c1_api",
          endpoint: getApiEndpoint(),
        },
        {
          name: "c1_public_api",
          endpoint: getApiEndpoint(),
          custom_header: async () => {
            return { 'x-api-key': 'T3MGLN8NHw8XGuXLzuUh52kDr19hwCXg3whaPFSy' }
          }
        }
      ]
    },
    Analytics: {
      disabled: true
    },
    oauth: {
      domain: 'qa.auth.connect1.io',
      redirectSignOut: config['isCloudDeployment'] ? 'https://qa-app.connect1.io' : 'http://localhost:4444',
      redirectSignIn: config['isCloudDeployment'] ? 'https://qa-app.connect1.io' : 'http://localhost:4444',
      scope: ['email', 'profile', 'openid', "aws.cognito.signin.user.admin"],
      responseType: "token"
    },
    Storage: {
      bucket: 'connectqa-userfiles-mobilehub-106855385',
      region: 'us-east-1',
    }
  },
  AWS_Exports: require("./aws-exports").default,
  development: true
}
