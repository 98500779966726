import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip'
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import { NavLink, withRouter } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import ClassNames from 'classnames'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './menu-sidenav-item.style';

class MenuSidenavItem extends React.Component {
  state = { open: false };

  handleClick = () => {
    if (!this.props.disabled) {
      const { onClick } = this.props
      this.setState({ open: !this.state.open });
      if (onClick)
        this.props.onClick()
    }
  };

  render() {
    const {
      children, classes, href, title, icon, type, isChild, external, location, childItem, disabled, tooltipMessage
    } = this.props;

    if (type && type === 'header') {
      return (<ListSubheader disableSticky className={classes.root}>{title}</ListSubheader>);
    }


    let listItemAttrs = {};
    if (!disabled && href && !children) {
      listItemAttrs.to = href;
      listItemAttrs.component = NavLink;
      listItemAttrs.exact = true;
      listItemAttrs.activeClassName = classes.listItemActive;
    }
    let isActive = false
    // FIXME: Sub-Menu Child collapse on active URL
    if (childItem && childItem.length)
      isActive = childItem.find(item => item.href === location.pathname)
    if (href && !children && external) {
      listItemAttrs.component = 'a';
      listItemAttrs.href = href
      listItemAttrs.target = '_blank';
    }

    const iconStyle = {
      fontSize: 16
    };

    const rootClasses = ClassNames({
      [classes.root]: true,
      [classes.listItemChild]: isChild
    })
    const open = isActive || this.state.open

    const tooltipTitle = <Typography style={{ color: 'white' }}>{tooltipMessage}</Typography>

    return (
      <Tooltip title={tooltipMessage ? tooltipTitle : ""} placement="right" arrow>
        <ListItem  {...listItemAttrs} className={rootClasses} disableGutters disabled={disabled}>
          <Button
            style={disabled ? { cursor: 'not-allowed' } : null}
            onClick={this.handleClick}
            classes={{
              root: classes.listItem,
              label: classes.listItemButtonLabel
            }}
          >
            {icon && <span className={classes.listIcon}>{icon}</span>}
            <Typography variant="button" color="inherit" className={classes.listItemText}>{title}</Typography>
            {children && (open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />)}
          </Button>
          {children &&
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              className={classes.nested}
            >
              {children}
            </Collapse>}
        </ListItem>
      </Tooltip>
    );
  }
}

MenuSidenavItem.defaultProps = {
  children: null,
  href: null,
  icon: null,
  type: null
};

MenuSidenavItem.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.shape({}),
  href: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.shape({}),
  type: PropTypes.string
};

export default withRouter(withStyles(styles)(MenuSidenavItem));
