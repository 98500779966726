import { AccessTime as TimeIcon } from '@material-ui/icons';
import { TimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import React from 'react';

import scss from './DatePicker.module.scss';
import { bind } from 'utils/WebUtils';
import { moment } from 'c1-webutils'
import WebForms from '../WebForm';
export default class DatePickerValidator extends React.Component {

    constructor(props) {
        super(props);
        bind(this, 'onChange', 'getStateName')
        WebForms.decorate(this, ['alert', 'hideAlert']);
    }

    getStateName() {
        let {
            name,
            discriminator,
        } = this.props;

        return discriminator ? `${name}_${discriminator}` : name;
    }

    onChange(value) {
        let {
            onChange,
            minDate,
            name,
            discriminator,
            self
        } = this.props;

        let stateName = this.getStateName()

        if (minDate && moment(value).isBefore(minDate)) {

            this.alert({
                text: `Selected Time is before minimun allowed time ${moment(minDate).format('hh:mm a')}. `,
                button: "Ok",
                error: true,
                showConfirm: true,
                onConfirm: () => this.hideAlert()
            });

            return false;
        } else {
            self.setState({
                [stateName]: value
            }, () => onChange && onChange({ name, value, discriminator }))
        }
    }

    render() {
        const {
            id,
            name,
            placeholder,
            self,
            margin,
            ...rest
        } = this.props;

        const stateName = this.getStateName()
        const value = self.state[stateName]

        let error = self.state[`${stateName}State`] === 'error'
        let errorMessage = self.state[`${stateName}Error`]

        return (
            <div className={scss['datepicker-root']}>
                <KeyboardTimePicker
                    id={id}
                    {...rest}
                    style={{ display: 'flex' }}
                    InputProps={{
                        placeholder: placeholder,
                    }}
                    inputVariant="outlined"
                    name={stateName}
                    margin={margin || 'none'}
                    onChange={this.onChange}
                    keyboardIcon={<TimeIcon fontSize="small" />}
                    value={value || null}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                    error={error}
                    helperText={error && errorMessage(this.props)}

                />
            </div>
        )
    }
}
