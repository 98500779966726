import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {withStyles} from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'
import { toggleNotifications } from 'redux/actions/layout.actions';

import EntitySelectors from 'redux/selectors';

const NotificationSelectors = EntitySelectors.notification;

class ImportantNotice extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            allNotices: [],
            prevAllNotices: []
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.notifications !== state.prevAllNotices) {
            const allNotices = props.notifications;
            return {
                allNotices: allNotices,
                prevAllNotices: allNotices
            }
        }
        return null
    }

    handleClose = (event, noticeId) => {
        const { allNotices } = this.state
        event.preventDefault();
        this.setState(allNotices)
    };

    render() {
        const { classes } = this.props;
        const { allNotices } = this.state;

        const importantNotices = allNotices && allNotices.filter(n => n.priority === 'HIGH' && n.visible);
        const currentNotice = importantNotices && importantNotices[0]

        if (!currentNotice)
            return null
        return (
            <Alert
                square={true}
                variant="filled"
                severity="error"
                onClick={() => this.props.toggleNotifications()}
                onClose={(event) => this.handleClose(event, currentNotice.id)}
                className={classes.alert}
            >
                {currentNotice.message}
            </Alert>
        )
    }
}


function mapStateToProps(state, props) {
    return {
        notifications: NotificationSelectors.selectAll(state, props),
    };
}

export default compose(
    connect(mapStateToProps, { toggleNotifications }),
    withStyles(theme => ({
        alert: {
            borderRadius: 0
        }
    }))
)(ImportantNotice);
