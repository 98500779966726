import React from "react";

function Icon({ primary, width, height }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || "806.007"}
            height={height || "609.716"}
            data-name="Layer 1"
            viewBox="0 0 806.007 609.716"
        >
            <path
                fill="#f2f2f2"
                d="M854.566 579.69H213.728a16.519 16.519 0 01-16.5-16.5V168.036a12.102 12.102 0 0112.088-12.088h649.453a12.311 12.311 0 0112.297 12.297V563.19a16.519 16.519 0 01-16.5 16.5z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <path
                fill="#fff"
                d="M833.847 558.858H236.602c-8.755 0-15.878-6.67-15.878-14.868V199.885c0-6.08 5.278-11.027 11.766-11.027h605.274c6.595 0 11.96 5.028 11.96 11.208V543.99c0 8.198-7.123 14.868-15.877 14.868z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <path
                fill="#3f3d56"
                d="M870.837 170.092h-673.84v-8.4a16.574 16.574 0 0116.56-16.55h640.72a16.574 16.574 0 0116.56 16.55z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <circle cx="30.095" cy="12.5" r="4.283" fill="#fff"></circle>
            <circle cx="46.354" cy="12.5" r="4.283" fill="#fff"></circle>
            <circle cx="62.613" cy="12.5" r="4.283" fill="#fff"></circle>
            <path
                fill={primary || "#F9A826"}
                d="M389.344 242.908h-105.55a15.996 15.996 0 00-16 16v71.83a15.996 15.996 0 0016 16h105.55a16.002 16.002 0 0016-16v-71.83a16.002 16.002 0 00-16-16zM463.793 258.904v71.838a16 16 0 0016 16H585.34a16 16 0 0016-16v-71.838a16 16 0 00-16-16H479.793a16 16 0 00-16 16z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <path
                fill="#e6e6e6"
                d="M659.793 258.904v71.838a16 16 0 0016 16H781.34a16 16 0 0016-16v-71.838a16 16 0 00-16-16H675.793a16 16 0 00-16 16zM267.793 417.904v71.838a16 16 0 0016 16H389.34a16 16 0 0016-16v-71.838a16 16 0 00-16-16H283.793a16 16 0 00-16 16zM463.793 417.904v71.838a16 16 0 0016 16H585.34a16 16 0 0016-16v-71.838a16 16 0 00-16-16H479.793a16 16 0 00-16 16z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <path
                fill={primary || "#F9A826"}
                d="M659.793 417.904v71.838a16 16 0 0016 16H781.34a16 16 0 0016-16v-71.838a16 16 0 00-16-16H675.793a16 16 0 00-16 16z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <circle cx="139.57" cy="149.681" r="22" fill="#fff"></circle>
            <path
                fill="#fff"
                d="M370.184 348.738h-67.24a33.368 33.368 0 016.01-8.22 32.891 32.891 0 0123.33-9.66h8.56a32.988 32.988 0 0129.34 17.88z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <circle cx="335.57" cy="149.681" r="22" fill="#fff"></circle>
            <path
                fill="#fff"
                d="M566.184 348.738h-67.24a33.368 33.368 0 016.01-8.22 32.891 32.891 0 0123.33-9.66h8.56a32.988 32.988 0 0129.34 17.88z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <circle cx="531.57" cy="149.681" r="22" fill="#fff"></circle>
            <path
                fill="#fff"
                d="M762.184 348.738h-67.24a33.368 33.368 0 016.01-8.22 32.891 32.891 0 0123.33-9.66h8.56a32.988 32.988 0 0129.34 17.88z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <circle cx="139.57" cy="308.681" r="22" fill="#fff"></circle>
            <path
                fill="#fff"
                d="M370.184 507.738h-67.24a33.368 33.368 0 016.01-8.22 32.891 32.891 0 0123.33-9.66h8.56a32.988 32.988 0 0129.34 17.88z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <circle cx="335.57" cy="308.681" r="22" fill="#fff"></circle>
            <path
                fill="#fff"
                d="M566.184 507.738h-67.24a33.368 33.368 0 016.01-8.22 32.891 32.891 0 0123.33-9.66h8.56a32.988 32.988 0 0129.34 17.88z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <circle cx="531.57" cy="308.681" r="22" fill="#fff"></circle>
            <path
                fill="#fff"
                d="M762.184 507.738h-67.24a33.368 33.368 0 016.01-8.22 32.891 32.891 0 0123.33-9.66h8.56a32.988 32.988 0 0129.34 17.88z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <path
                fill="#3f3d56"
                d="M1002.004 754.858h-261a1 1 0 010-2h261a1 1 0 010 2z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <path
                fill="#a0616a"
                d="M670.716 597.522L658.457 597.521 652.624 550.233 670.718 550.234 670.716 597.522z"
            ></path>
            <path
                fill="#2f2e41"
                d="M649.7 594.018h23.643v14.887h-38.53a14.887 14.887 0 0114.886-14.887z"
            ></path>
            <path
                fill="#a0616a"
                d="M716.716 597.522L704.457 597.521 698.624 550.233 716.718 550.234 716.716 597.522z"
            ></path>
            <path
                fill="#2f2e41"
                d="M695.7 594.018h23.643v14.887h-38.53a14.887 14.887 0 0114.886-14.887z"
            ></path>
            <path
                fill="#a0616a"
                d="M912.215 601.826a10.743 10.743 0 01-2.062-16.343l-8.073-114.558 23.253 2.255.64 112.186a10.8 10.8 0 01-13.758 16.46z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <path
                fill="#2f2e41"
                d="M867.133 718.841l-13.496-.643a4.499 4.499 0 01-4.286-4.463c-3.558-54.92-8.486-113.807-.942-136.557a4.501 4.501 0 015.147-4.485l53.993 7.838a4.474 4.474 0 013.854 4.42c6.893 26.936 7.205 75.782 6.944 126.534a4.5 4.5 0 01-4.5 4.534h-14.55a4.479 4.479 0 01-4.445-3.8l-8.977-57.068a3.5 3.5 0 00-6.933.128l-7.126 59.602a4.517 4.517 0 01-4.469 3.966q-.106 0-.214-.006z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <path
                fill="#3f3d56"
                d="M876.634 584.956c-11.9-6.61-21.197-8.348-25.68-8.795a4.418 4.418 0 01-3.053-1.673 4.478 4.478 0 01-.931-3.402l12.937-96.05a33.22 33.22 0 0119.364-25.957 32.306 32.306 0 0131.395 2.46q.665.443 1.305.904a33.178 33.178 0 0112.637 34.573c-7.934 32.455-10.659 85.662-11.125 95.999a4.465 4.465 0 01-2.918 4.005 45.085 45.085 0 01-15.225 2.71 38.124 38.124 0 01-18.706-4.774z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <path
                fill="#3f3d56"
                d="M907.344 509.418a4.482 4.482 0 01-1.859-3.401l-1.703-30.876a12.399 12.399 0 0124.346-3.927l7.485 27.605a4.505 4.505 0 01-3.166 5.52l-21.29 5.773a4.483 4.483 0 01-3.813-.694z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <circle cx="690.46" cy="266.333" r="24.561" fill="#a0616a"></circle>
            <path
                fill="#a0616a"
                d="M798.452 459.73a10.526 10.526 0 01.24 1.64l42.957 24.782 10.441-6.01 11.132 14.572-22.338 15.92-49.007-38.662a10.496 10.496 0 116.575-12.242z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <path
                fill="#3f3d56"
                d="M843.183 484.045a4.482 4.482 0 011.293-3.653l21.864-21.869a12.399 12.399 0 0119.168 15.516l-15.57 23.993a4.505 4.505 0 01-6.225 1.325l-18.504-12.009a4.483 4.483 0 01-2.026-3.303z"
                transform="translate(-196.996 -145.142)"
            ></path>
            <path
                fill="#2f2e41"
                d="M904.837 430.907c-4.582 4.881-13.092 2.26-13.689-4.407a8.054 8.054 0 01.01-1.556c.309-2.953 2.015-5.635 1.606-8.753a4.59 4.59 0 00-.84-2.15c-3.651-4.888-12.222 2.188-15.668-2.238-2.113-2.714.37-6.988-1.25-10.02-2.14-4.005-8.48-2.03-12.455-4.222-4.422-2.44-4.158-9.225-1.246-13.353 3.55-5.033 9.775-7.72 15.923-8.106s12.253 1.274 17.992 3.511c6.521 2.541 12.988 6.054 17 11.788 4.881 6.973 5.35 16.348 2.91 24.501-1.485 4.96-6.552 11.02-10.293 15.005z"
                transform="translate(-196.996 -145.142)"
            ></path>
        </svg>
    );
}

export default Icon;
