/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Creatable from 'react-select/creatable'
import ReactSelect from 'react-select'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { Close as CancelIcon } from '@material-ui/icons';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Popper from '@material-ui/core/Popper';
import FormValidator from './FormValidator'
import AsyncSelect from 'react-select/async'
import { InputAdornment } from '@material-ui/core';
import { InfoHelpPopover } from './InfoPopup';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        flex: 1
    },
    input: {
        display: 'flex'
    },
    inputMulti: {
        display: 'flex',
        height: "unset !important"
    },
    valueContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    chip: {
        height: 25,
        marginRight: `${theme.spacing(1) / 2}px`,
        marginBottom: `${theme.spacing(1) / 2}px`
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        // left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        // marginTop: theme.spacing(1),
        left: 0,
        right: 0,
        zIndex: 10000
    },
    divider: {
        height: theme.spacing(1) * 2,
    },
});

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    const textFieldProps = props.selectProps.textFieldProps
    const { isMulti } = props.selectProps;

    return (
        <TextField
            fullWidth
            variant="outlined"
            InputProps={{
                inputComponent,
                inputProps: {
                    className: isMulti ? props.selectProps.classes.inputMulti : props.selectProps.classes.input,
                    inputRef: textFieldProps.inputRef,
                    children: props.children,
                    ...props.innerProps,
                },
                endAdornment: textFieldProps.infoHelp
                ? (
                    <InputAdornment position="end">
                        <InfoHelpPopover infoHelp={textFieldProps.infoHelp} />
                    </InputAdornment>
                ) : undefined
            }}
            {...textFieldProps}
        />)
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}

            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon fontSize="small" {...props.removeProps} />}
        />
    );
}

function Menu(props) {

    let popoverNode = props.selectProps.popoverNode.current;
    return <Popper open={true} style={{ width: (popoverNode && popoverNode.offsetWidth) || -1 }} anchorEl={popoverNode} className={props.selectProps.classes.paper}  {...props.innerProps}>
        <Paper square >
            {props.children}
        </Paper>
    </Popper>

}

let components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer
};

const IntegrationReactSelect = props => {

    const handleChange = value => {
        const { name, onChange } = props;
        if (onChange)
            onChange({ name, value })
    };
    let {
        id,
        classes,
        theme,
        name,
        isCreatable,
        isClearable,
        isMulti,
        self,
        label,
        placeholder,
        onChange,
        margin,
        isAsync,
        helperText,
        infoHelp,
        value,
        shrinkLabel,
        ...rest
    } = props;

    const selectStyles = {
        input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
            margin: 0

        }),
        dropdownIndicator: base => ({
            ...base,
            padding: '0',
            paddingLeft: '8px'
        }),
        clearIndicator: base => ({
            ...base,
            padding: '0 8px',
            paddingLeft: '8px'
        })
    };

    let popoverNode = React.createRef();
    let SelectComponent = isAsync ? AsyncSelect : (isCreatable ? Creatable : ReactSelect)
    isClearable = isClearable || (isMulti ? false : true)

    const _components = { ...components }
    if (isCreatable) _components.DropdownIndicator = null;

    return (
        <div className={classes.root}>
            <NoSsr>
                <SelectComponent
                    id={id}
                    classes={classes}
                    styles={selectStyles}
                    textFieldProps={{
                        label,
                        InputLabelProps: {
                            shrink: shrinkLabel || value,
                        },
                        infoHelp,
                        helperText: helperText,
                        inputRef: popoverNode,
                        margin: margin || "none",
                        // onChange: event => {
                        //     this.setState({
                        //         shrinkLabel: (event.target.value && event.target.value.length > 0) ? true : false
                        //     })
                        // }
                    }}

                    components={_components}
                    value={value}
                    onChange={handleChange}
                    isClearable={isClearable}
                    isMulti={isMulti}
                    popoverNode={popoverNode}
                    placeholder={""}
                    {...rest}
                />
            </NoSsr>
        </div>
    );
}

IntegrationReactSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

IntegrationReactSelect.defaultProps = {
    isMulti: false,
    isSearchable: true,
    isCreatable: false,
}

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);