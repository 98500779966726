const MAX_RETRY_COUNT = 1

export default class RemoteEntityReducer {
  constructor(entityExtractor, entityUpdater, entityInvalidator) {
    this.entityExtractor = entityExtractor;
    this.entityUpdater = entityUpdater;
    this.entityInvalidator = entityInvalidator;
  }

  doReduction(newstate, action, parentState) {
    if (!newstate)
      newstate = {}

    switch (action.status) {

      // Fetch status.
      case 'request':
        return this.requestEntity(newstate, action);
      case 'success':
        return this.receiveEntity(newstate, action);
      case 'error':
        return this.errorEntity(newstate, action);

      // update status
      case 'update_inprocess':
        return this.updateStarted(newstate, action);
      case 'update_complete':
        return this.updateCompleted(newstate, action, parentState);
      case 'invalid':
        return this.invalidate(newstate, action);
      case 'refresh':
        return this.refresh(newstate, action);
      case 'list_merge':
        return this.listMerge(newstate, action);
      default:

    }
    return newstate;
  }

  listMerge(newstate, action) {
    const { payload, entityName } = action;

    newstate[entityName] = payload;
    return newstate;
  }

  requestEntity(newstate, action) {
    newstate.isFetching = true;
    newstate.didInvalidate = false;
    newstate.retryCount = (newstate.error) ? newstate.retryCount - 1 : MAX_RETRY_COUNT;
    newstate.updateStatus = undefined;

    newstate.requestAction = action;

    return Object.assign({}, newstate);
  }

  receiveEntity(newstate, action) {

    newstate.lastUpdated = Date.now();
    newstate.isFetching = false;
    newstate.didInvalidate = false;
    newstate.refresh = false;
    newstate.error = undefined;
    newstate.retryCount = undefined;
    newstate.updateStatus = undefined;

    this.entityExtractor(newstate, action);

    delete newstate.requestAction;

    return Object.assign({}, newstate);
  }


  updateStarted(newstate, action) {

    newstate.lastUpdated = Date.now();
    newstate.isFetching = false;
    newstate.didInvalidate = false;
    newstate.error = undefined;
    newstate.retryCount = undefined;
    newstate.updateStatus = 'inprogress';

    this.entityUpdater && this.entityUpdater(newstate, action);

    return Object.assign({}, newstate);
  }

  updateCompleted(newstate, action, parentState) {

    newstate.lastUpdated = Date.now();
    newstate.isFetching = false;
    newstate.didInvalidate = false;
    newstate.error = undefined;
    newstate.retryCount = undefined;
    newstate.updateStatus = 'completed';

    this.entityUpdater && this.entityUpdater(newstate, action, parentState);
    return Object.assign({}, newstate);
  }

  refresh(newState, action) {
    newState.refresh = true
    return Object.assign({}, newState);
  }

  invalidate(newstate, action) {
    newstate.lastUpdated = Date.now();
    newstate.isFetching = false;
    newstate.didInvalidate = true;
    newstate.error = undefined;
    newstate.retryCount = undefined;
    newstate.updateStatus = 'completed';

    if (this.entityInvalidator)
      this.entityInvalidator(newstate, action)

    return Object.assign({}, newstate);
  }

  errorEntity(newstate, action) {
    newstate.lastUpdated = Date.now();
    newstate.isFetching = false;
    newstate.didInvalidate = false;

    if (newstate.updateStatus && newstate.updateStatus === 'inprogress') {
      newstate.updateStatus = 'error';
      newstate.updateError = action.error
    } else {
      newstate.error = action.error;
      newstate.success = false;
      newstate.updateStatus = undefined;
    }

    return Object.assign({}, newstate);
  }

}
