import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import React from "react";
import { createGenerateClassName, jss, JssProvider } from "react-jss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  LinearProgress,
  Button,
  Card,
  CardContent,
  Typography
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { moment as _moment } from "c1-webutils";
import { withAuthTheme } from "./components/AuthTheme";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import CustomAuthenticator from 'containers/auth/Authenticator'

import UserActions from "redux/actions/user.actions";
import {
  galleryImages,
  isGalleryFetching
} from "redux/selectors/images.selector";
import { getOrgTheme } from "redux/selectors/data.selector";
import * as UserSelectors from "redux/selectors/user.selector";
import Routes from "./routes/routes";
import themes from "./themes";
import logoImage from "./assets/images/connect1.png";
import joanOfArc from "./assets/images/joanOfArc_pose2.png";
import { Replay } from "@material-ui/icons";
import "assets/styles/Sweet-alert.scss";
import GA from "utils/GoogleAnalytics";
import Bugsnag from "@bugsnag/js";
import EntitySelectors from 'redux/selectors';
import EntityActions from 'redux/actions';
import { generateRoleName } from 'utils/WebUtils';

const OrgUserSelectors = EntitySelectors.orgUser;
const OrgUserActions = EntityActions.orgUser;

// Selectors & Actions
const generateClassName = createGenerateClassName();

let moment = function (date, ...args) {
  if (!date) {
    date = new Date();
    date.setHours(8);
    date.setMinutes(0);
  }

  return _moment(date, ...args);
};


class App extends React.Component {
  constructor(props, state) {
    super(props)
    this.state = {
      authenticated: false,
      hasCustomAuth: true
    };
    this.init = this.init.bind(this);
  }


  componentDidMount() {
    this.init(this.props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.themeConfig && document.body)
      document.body.dir =
        this.props.themeConfig?.contentTheme?.direction || "ltr";

    if (this.props.currentUser && this.props.currentOrganization &&
      (!prevProps.currentUser || !prevProps.currentOrganization)) {
      GA.setField({ dimension1: this.props.currentOrganization.id }); //organizationId
      if (this.props.currentUser.impersonated) {
        const originalUserAttributes = this.props.currentUser.originalUser.attributes
        GA.setField({ dimension2: originalUserAttributes.sub }); //userId
        GA.setField({ dimension3: originalUserAttributes.email }); //email

        const customAuth = JSON.parse(originalUserAttributes["custom:auth"])
        const roles = customAuth[0].roles
        GA.setField({ dimension4: generateRoleName(roles) }); //roles
      } else {
        const currentUser = this.props.currentUser
        GA.setField({ dimension2: currentUser.id }); //userId
        GA.setField({ dimension3: currentUser.email }); //email
        GA.setField({ dimension4: generateRoleName(currentUser.roles) }); //roles
      }
      GA.setField({ dimension5: this.props.currentOrganization.name }); //organization name
    }
  }

  async init(props) {
    // Check for Signed in User
    const cAuthUser = await Auth.currentUserInfo();
    const customAuth = cAuthUser && cAuthUser.attributes["custom:auth"] && JSON.parse(cAuthUser.attributes["custom:auth"]);
    const _state = {}
    if (!customAuth && cAuthUser) {
      _state.hasCustomAuth = false
    }
    if (cAuthUser)
      _state.authenticated = true

    this.setState(_state)
    Auth.currentUserCredentials()
      .then(cognitoUser => {
        if (cognitoUser.expired) {
          UserActions.signOut();
        }
        UserActions.getAuthUser(false, ((user) => {
          if (!user.orgs || !user.roles) {
            console.log(props);
          }

        }));
      })
      .catch(err => {
        console.log(err);
        UserActions.signOut();
      });
  }

  render() {
    const childProps = {};
    const { authenticated } = this.state
    const {
      themeConfig,
      layoutConfig,
      isLoggedIn,
      currentUser,
      currentOrganization,
      currentOrgUser
    } = this.props;

    if (!authenticated || !currentOrgUser) return <LinearProgress />;

    if (currentOrganization && currentOrganization.id === "invalid-org")
      return <FailedToConnect />;

    const materialTheme = createTheme(themeConfig.contentTheme);

    return (
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <MuiThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <Routes
              childProps={childProps}
              layout={layoutConfig}
              isLoggedIn={isLoggedIn}
              currentUser={currentUser}
              currentOrganization={currentOrganization}
              currentOrgUser={currentOrgUser}
            />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </JssProvider>
    );
  }
}

function mapStateToProps(state, props) {
  const defaultTheme = themes[1].theme;
  const currentUser = UserSelectors.currentUser(state, props);
  const orgUserId = OrgUserSelectors.currentId(state, props);

  if (currentUser && (!orgUserId || currentUser.id !== orgUserId))
    OrgUserActions.setCurrentId(currentUser.id);

  if (currentUser) {
    Bugsnag.setUser({
      id: currentUser.id,
      email: currentUser.email,
      name: `${currentUser.firstName} ${currentUser.lastName}`
    });
  }

  const currentOrganization = UserSelectors.currentOrganization(state, props);

  if (currentOrganization) {
    Bugsnag.addMetadata("organization", {
      organizationId: currentOrganization.id,
      name: currentOrganization.name
    });
  }

  return {
    themeConfig: getOrgTheme(state, props) || defaultTheme,
    layoutConfig: state.layout,
    currentUser,
    currentOrgUser: OrgUserSelectors.current(state, props),
    currentOrganization,
    isLoggedIn: UserSelectors.isLoggedIn(state, props),
    galleryImages: galleryImages(state, props),
    isGalleryFetching: isGalleryFetching(state, props)
  };
}

App.propTypes = {
  themeConfig: PropTypes.shape({
    contentTheme: PropTypes.shape({
      direction: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  layoutConfig: PropTypes.shape({}).isRequired
};


const FailedToConnect = org => {
  return (
    <div
      style={{
        height: "80%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        flex: 1,
        alignItems: "center"
      }}
    >
      <Card style={{ maxWidth: "80%" }}>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <img src={logoImage} alt="logo" style={{ height: 100, margin: 10 }} />
          <Typography variant="body1" align="center">
            {"Connect School and Parents as ONE Community."}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: 15,
              alignItems: "center"
            }}
          >
            <Typography variant="body2">
              Unfortunately we are having trouble connecting to our servers.
              Please check your network connection and try again.
            </Typography>
            <img alt="Server Error" src={joanOfArc} />
          </div>
          <Button
            style={{ marginTop: 20 }}
            variant="contained"
            color="primary"
            onClick={() => window.location.reload(true)}
          >
            Retry
            <Replay />
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

const appWithAuth = withAuthTheme(CustomAuthenticator(App));

export default compose(withRouter, connect(mapStateToProps))(appWithAuth);
