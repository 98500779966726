
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = {
  direction: 'ltr',
  palette,
  typography,
  overrides
}

export default theme;
