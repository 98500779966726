import React from 'react';

import {
  Home as HomeIcon,
  Dashboard as DashboardIcon,
  CalendarToday as CalenderIcon,
  Settings as SettingIcon,
  Book as BookIcon,
  ContactPhone as ContactPhoneIcon
} from '@material-ui/icons';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import GroupIcon from '@material-ui/icons/Group';
import EventNoteIcon from '@material-ui/icons/EventNote';
import themes from './themes';

export const configuredTheme = themes[1].theme;

export const configuredLayout = {
  currentLayout: 'classic',
  notificationsOpen: false
};

const iconStyle = {
  fontSize: 16
};

export const menuItems = {
  parent: [
    {
      title: 'Parent Portal',
      menuTitle: 'Home',
      isMenu: true,
      href: '/',
      icon: <HomeIcon style={iconStyle} />
    }, {
      title: 'Preferences',
      isMenu: true,
      href: '/theming',
      icon: <SettingIcon style={iconStyle} />
    }, {
      title: 'My Dashboard',
      isMenu: true,
      // icon: <DashboardIcon style={iconStyle} />,
      children: [{
        title: 'Family',
        isMenu: true,
        href: '/family',
        icon: <GroupIcon style={iconStyle} />
      }, {
        title: 'Volunteering',
        isMenu: true,
        href: '/volunteers',
        icon: <EventNoteIcon style={iconStyle} />
      }, {
        title: 'My School Finances',
        isMenu: true,
        href: '/dashboards/crypto',
        icon: <AttachMoneyIcon style={iconStyle} />
      }, {
        title: 'Class Sites',
        isMenu: true,
        icon: <DashboardIcon style={iconStyle} />,
        href: '/class-sites'
      }]
    },
    {
      title: 'School Resources',
      isMenu: true,
      children: [{
        title: 'School Directory',
        href: '/no-url-calender',
        icon: <ContactPhoneIcon style={iconStyle} />
      }, {
        title: 'School Calender',
        href: '/school/calendar',
        icon: <CalenderIcon style={iconStyle} />
      }, {
        title: 'Documents & Forms',
        href: '/documents',
        icon: <BookIcon style={iconStyle} />
      }],
    },
    {
      title: 'My Links',
      isMenu: true,
      children: [{
        title: 'Power School',
        href: 'https://arlingtondiocese.powerschool.com/public/',
        external: true
      }, {
        title: 'Faith Direct',
        href: 'https://www.faithdirect.net/',
        external: true
      }, {
        title: 'SmugMug',
        href: 'https://stluke.smugmug.com/2018-2019',
        external: true
      }]
    },

    // Non Menu Routes
    {
      title: 'Add Volunteer Opportunity',
      isMenu: false,
      href: '/volunteers/add'
    },
    {
      title: 'Add Volunteer Opportunity',
      isMenu: false,
      href: '/volunteers/board'
    },
    {
      title: 'Edit Volunteer Opportunity',
      isMenu: false,
      href: '/volunteers/:id/edit'
    },
    {
      title: 'Profile',
      isMenu: false,
      href: '/profile'
    }
  ],
  teacher: [
    {
      title: 'Teacher Portal',
      menuTitle: 'Home',
      isMenu: true,
      href: '/',
      icon: <HomeIcon style={iconStyle} />
    }, {
      title: 'Preferences',
      isMenu: true,
      href: '/theming',
      icon: <SettingIcon style={iconStyle} />
    }, {
      title: 'My Dashboard',
      isMenu: true,
      // icon: <DashboardIcon style={iconStyle} />,
      children: [{
        title: 'My Profile',
        isMenu: true,
        href: '/profile',
        icon: <GroupIcon style={iconStyle} />
      }, {
        title: 'My Classes',
        isMenu: true,
        href: '/my-classes',
        icon: <EventNoteIcon style={iconStyle} />
      }]
    },
    {
      title: 'School Resources',
      isMenu: true,
      children: [{
        title: 'School Directory',
        href: '/no-url-calender',
        icon: <ContactPhoneIcon style={iconStyle} />
      }, {
        title: 'School Calender',
        href: '/school/calendar',
        icon: <CalenderIcon style={iconStyle} />
      }, {
        title: 'Documents & Forms',
        href: '/documents',
        icon: <BookIcon style={iconStyle} />
      }],
    },
    {
      title: 'My Links',
      isMenu: true,
      children: [{
        title: 'Power School',
        href: 'https://arlingtondiocese.powerschool.com/public/',
        external: true
      }, {
        title: 'Faith Direct',
        href: 'https://www.faithdirect.net/',
        external: true
      }, {
        title: 'SmugMug',
        href: 'https://stluke.smugmug.com/2018-2019',
        external: true
      }]
    },
  ]
};
