import RemoteEntityReducer from "../RemoteEntityReducer";


const entityReducer = new RemoteEntityReducer((state, action) => {

  if (!action.payload)
    throw new Error("Cannot reduce with no user");

  let {payload} = action;

  return Object.assign(state, {user: payload});

});

const currentUser = (state = {}, action) => {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case 'CURRENT_USER':
      return entityReducer.doReduction(newState, action);
    case 'UPDATE_CURRENT_USER':
      return updateUser(newState, action)
    default:
      return state;
  }
}

const updateUser = (newState, action) => {
  newState = entityUpdater(newState)
  const {payload} = action
  newState.user = {...payload}

  return {...newState}
}

const entityUpdater = (newstate) => {
  newstate.lastUpdated = Date.now();
  newstate.isFetching = false;
  newstate.didInvalidate = false;
  newstate.error = undefined;
  newstate.retryCount = undefined;
  newstate.updateStatus = 'completed';

  return Object.assign({}, newstate);
}

export default currentUser
