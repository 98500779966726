import React from 'react';

import {
    Typography,
    Dialog,
    Box,
    DialogContent,
    AppBar,
    Toolbar,
    IconButton,
    Slide
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CloseOutlined as CloseIcon } from '@material-ui/icons';

let styles = theme => ({
    paper: {
        [theme.breakpoints.down('xs')]: {
            // width: 'unset !important',
            // maxWidth: 'unset !important',
            // maxHeight: 'unset !important',
            // margin: '0px !important'
        }
    }
})

function Transition(props) {
    return <Slide direction="up" {...props} />;
}
class CustomDialog extends React.Component {
    render() {
        let { 
            title, 
            classes, 
            visible, 
            onClose, 
            content, 
            contentStyle, 
            disableClose, 
            fullWidth = true, 
            closeable = true,
            showHeader = true 
    } = this.props
        visible = visible || false;

        return (
            <Dialog
                fullWidth={fullWidth}
                maxWidth="md"
                open={visible}
                TransitionComponent={Transition}
                onClose={disableClose ? undefined : onClose}
                style={{ overflow: 'visible' }}
                classes={{
                    paper: classes.paper
                }}
            >
                {
                    showHeader && (
                        <AppBar elevation={1} color="default" style={{ position: 'relative', backgroundColor: 'white' }}>
                            <Toolbar>
                                <Typography variant="h5" color="inherit" style={{ flex: 1 }}>
                                    {title}
                                </Typography>
                                {
                                    closeable && 
                                    <IconButton onClick={onClose} disabled={disableClose}>
                                        <CloseIcon />
                                    </IconButton>
                                }
                            </Toolbar>
                        </AppBar>
                    )
                }
                {
                    !showHeader && closeable && (
                        <Box style={{display: "flex", marginLeft: "auto"}}>
                            <IconButton onClick={onClose} disabled={disableClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    )
                }
                <DialogContent
                    style={{ overflow: 'visible', ...contentStyle }}
                >
                    {content}
                </DialogContent>
            </Dialog>
        )
    }
}

export default withStyles(styles)(CustomDialog);

