import moment from "moment-timezone";
moment.tz.setDefault("America/New_York");
const tzMoment = moment.tz

const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
//set the default timezone

const fromUtc = (datetime, format = DATETIME_FORMAT, targetTimezon = timezone) =>  {
    if (typeof datetime === 'string')
        datetime = tzMoment(datetime, format, "UTC")
    
    return datetime.tz(targetTimezon).format(format)
}

const toUtc = (datetime, format = DATETIME_FORMAT, targetTimezone = timezone) =>  {
    if (typeof datetime === 'string')
        datetime = tzMoment(datetime, format, targetTimezone)
    
    return datetime.tz("UTC").format(format)
}

export { moment, fromUtc, toUtc };
