import React from 'react'
import styles from "../styles/authentication.style";
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Typography, Box, Divider, Button, LinearProgress } from '@material-ui/core'
import { AuthContext } from 'containers/auth/Authenticator'
import swal from 'sweetalert'
import { Auth } from 'aws-amplify';

const useStyles = makeStyles(styles)
export default function SchoolCode({ onStepChange, data = {} }) {
    const classes = useStyles()
    const [code, setCode] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const { onAuthStateChange, authenticated, authData } = React.useContext(AuthContext)
    const authSessionData = JSON.parse(sessionStorage.getItem('AUTH_DATA'));
    const verifyEmail = authSessionData?.verifyEmail || false;

    const handleResend = () => {
        const username = data?.user?.username || authSessionData?.email
        if (username && !verifyEmail) {
            setLoading(true)
            Auth.resendSignUp(username).then(() => {
                setLoading(false)
                swal("Info", `We sent you a new code, please check your email (${username}) for the code.`, "info");
            }).catch(err => {
                swal("Error", err.message, "error");
                setLoading(false)
            })
        }
        if (verifyEmail) {
            setLoading(true)
            Auth.verifyCurrentUserAttribute('email').then(() => {
                setLoading(false)
                swal("Info", `We sent you a new code, please check your email (${username}) for the code.`, "info");
            }).catch(err => {

            })
        }
    }
    const handleSubmit = () => {
        const username = data?.user?.username || authSessionData.email
        if (!code || code.length === 0 || !username)
            setError(true)

        else {
            setLoading(true)
            if (verifyEmail) {
                // If user email is not verify, this function verify by submitting the code.
                Auth.verifyCurrentUserAttributeSubmit('email', code.trim()).then((response) => {
                    if (response === 'SUCCESS') {
                        setLoading(false)
                        sessionStorage.removeItem('AUTH_DATA');
                        const finalStageData = {
                            heading: 'Account Verified',
                            description: `You have successfully verified your email. Please go back to login and sign in again to access the portal.`
                        }
                        onAuthStateChange && onAuthStateChange('FinalStage', null, finalStageData)
                    }
                })
            } else {
                 // If user account status is not confirmed, this function verify by submitting the code.
                Auth.confirmSignUp(username, code.trim())
                    .then((response) => {
                        if (response === 'SUCCESS') {
                            setLoading(false)
                            sessionStorage.removeItem('AUTH_DATA');
                            const finalStageData = {
                                heading: 'Success',
                                description: `Congratulations! You have successfully completed the sign up process. Please go back to login and sign in again to access the portal.`
                            }
                            onAuthStateChange && onAuthStateChange('FinalStage', null, finalStageData)
                        }
                    }).catch((err) => {
                        setLoading(false)
                        swal('Error', err.message, 'error')
                    });
            }
        }
    }

    const handleBack = async () => {
        if (authenticated)
            await Auth.signOut();

        onAuthStateChange && onAuthStateChange('SignIn');
    }

    return (
        <Box pt={3} pb={1} maxWidth={450}>
            <Box mb={2} px={4}>
                <h2 className={classes.heading}>{verifyEmail ? "Verify Email" : "You are almost there!"}</h2>
                <Typography color="textSecondary" variant='body2'>
                    {verifyEmail
                        ? 'Your email is not verified, please enter the code we sent to your email to verify your account.'
                        : `All you have to do next is confirm your account information with the code we sent you. ${authData ? authData.email : 'It might take a sec for it to come through.'}`
                    }
                </Typography>
            </Box>
            <Box px={4} mb={4} minHeight={250}>
                <Box mb={2}>
                    <TextField
                        id="code"
                        error={error}
                        name="code"
                        variant="outlined"
                        label="Confirmation Code"
                        value={code || ""}
                        fullWidth
                        onChange={e => setCode(e.target.value)}
                    />
                </Box>
                <Typography variant="body2" color={error ? 'error' : 'textSecondary'} gutterBottom>
                    {authData ? 'If you have not recieved it yet, click resend code.' : 'We sent you a confirmation code to your email. Please enter it here once you get it.'}
                </Typography>
                <Box mt={2}>
                    <Button id="btn-resend-code" onClick={handleResend} disabled={loading} color="secondary" >{'Resend Code'}</Button>
                </Box>
            </Box>
            {loading && <LinearProgress />}
            <Divider />
            <Box px={3} pt={1} display="flex" justifyContent={"space-between"}>
                <Button id="btn-back" onClick={handleBack}>{'Back'}</Button>
                <Button id="btn-submit" color="primary" onClick={handleSubmit} disabled={loading}>{'Submit'}</Button>
            </Box>
        </Box>
    )
}
