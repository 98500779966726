const { config } = require('../../../package.json');

function getApiEndpoint() {
  return (config['isCloudDeployment'] && "https://dev-api.connect1.io")
    || (config['isAndroidLocal'] ? "http://10.0.2.2:3002" : "http://localhost:3002");
}

export default {
  AWS: {
    Auth: {
      identityPoolId: 'us-east-1:ba730f8a-d0d3-44bc-954b-7cb0f60220e6',
      region: 'us-east-1',
      userPoolId: 'us-east-1_oCnNkcdQT',
      userPoolWebClientId: '2e75pgt5n6dbvvga27i24c1rb',
      mandatorySignIn: true
    },
    API: {
      endpoints: [
        {
          name: "c1_api",
          endpoint: getApiEndpoint(),
        }, {
          name: "c1_public_api",
          endpoint: getApiEndpoint(),
          custom_header: async () => {
            return { 'x-api-key': 'T3MGLN8NHw8XGuXLzuUh52kDr19hwCXg3whaPFSy' }
          }
        },
      ]
    },
    Analytics: {
      disabled: true
    },
    oauth: {
      domain: 'dev.auth.connect1.io',
      clientId: '1077754953433-g2duuvs64ia7vhdpua7ic926fu7l83eq.apps.googleusercontent.com',
      redirectSignOut: config['isCloudDeployment'] ? 'https://dev-app.connect1.io' : 'http://localhost:4444',
      redirectSignIn: config['isCloudDeployment'] ? 'https://dev-app.connect1.io' : 'http://localhost:4444',
      scope: ['email', 'profile', 'openid', "aws.cognito.signin.user.admin"],
      responseType: "token"
    },
    Storage: {
      bucket: 'c1-dev-userfiles',
      region: 'us-east-1',
    }
  },
  AWS_Exports: require("./aws-exports").default,
  development: true
}
