const { config } = require('../../../package.json');

function getApiEndpoint() {
  return "http://localhost:3002"
}

export default {
  AWS: {
    Auth: {
      identityPoolId: 'us-east-1:b24a7610-1f91-439d-a351-6a7d8640f0bf',
      region: 'us-east-1',
      userPoolId: 'us-east-1_OOVxAZxA4',
      userPoolWebClientId: '3t7d35bbs9q0sh69pet1tb7so9',
      mandatorySignIn: true
    },
    API: {
      endpoints: [
        {
          name: "c1_api",
          endpoint: getApiEndpoint(),
        },
        {
          name: "c1_public_api",
          endpoint: getApiEndpoint(),
          custom_header: async () => {
            return { 'x-api-key': 'T3MGLN8NHw8XGuXLzuUh52kDr19hwCXg3whaPFSy' }
          }
        }
      ]
    },
    Analytics: {
      disabled: true
    },
    oauth: {
      domain: 'local.auth.connect1.io',
      clientId: '1077754953433-g2duuvs64ia7vhdpua7ic926fu7l83eq.apps.googleusercontent.com',
      redirectSignOut: 'http://localhost:4444',
      redirectSignIn: 'http://localhost:4444',
      scope: ["email", "profile", "openid","aws.cognito.signin.user.admin" ],
      responseType: "token"
    },
    Storage: {
      bucket: 'connect-userfiles-mobilehub-1305085251',
      region: 'us-east-1',
    }
  },
  AWS_Exports: require("./aws-exports").default,
  development: true
}
