import { MenuItem, Paper, TextField, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PlacesAutocomplete, { getDetailsByPlaceId } from './PlacesAutocomplete';


const useStyles = makeStyles(theme => ({
    paper: {
        zIndex: 5,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
        maxHeight: 200,
        overflowY: 'scroll'
    },
    mainText: {
        fontSize: "0.875rem",
        color: 'gray',
        fontWeight: 'bold'
    },
    secondaryText: {
        fontSize: "0.750rem",
        color: 'gray'
    },
    hidden: {
        display: 'none'
    }
}))
export default function LocationSearch({ id ="auto-select", name, searchOpts, onChange, placeholder, helperText, service }) {
    const classes = useStyles();

    const [value, setValue] = React.useState('')
    const handleChange = (value, callback) => {
        setValue(value)
        callback && callback();
        onChange && onChange();
    }

    const handleSelect = (value, placeId) => {
        handleChange(value, () => {
            getDetailsByPlaceId(placeId)
                .then(results => {
                    results.id = placeId

                    onChange && onChange({
                        [`${name}_details`]: results,
                        [`${name}_address`]: getAddressComponents(results)
                    })
                    // console.log(JSON.stringify(results, null, 4));

                })
                .catch(error => console.error('Error', error));
        })

    };

    const getAddressComponents = (details) => {

        if (!details) return {
            street: "",
            city: "",
            state: "",
            zipCode: "",
            name: "",
            id: ""
        }

        let get = (part) => {
            let _part = details.address_components.find(A => A.types.includes(part))
            return _part && _part.long_name;
        };

        const getStreetAddress = () => {
            const addressComponents = details.formatted_address.split(',')
            if (addressComponents[0]) {
                return addressComponents[0]
            }
            return ''
        }

        return {
            street: getStreetAddress(),
            city: get('locality'),
            state: get('administrative_area_level_1'),
            zipCode: get('postal_code'),
            name: details.name,
            id: details.id
        }
    }
    const popoverNode = React.useRef();

    return (
        <PlacesAutocomplete
            value={value}
            onChange={handleChange}
            onSelect={handleSelect}
            searchOptions={Object.assign({}, { componentRestrictions: { country: 'us' } }, searchOpts)}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading, ...rest }) => {

                const anchorEL = popoverNode.current;
                return <div className={classes.container} >
                    <TextField
                        id={id}
                        autoComplete="off"
                        value={value}
                        variant={'outlined'}
                        fullWidth
                        inputRef={popoverNode}
                        // error={error}
                        helperText={helperText}
                        {...getInputProps({
                            placeholder: placeholder || 'Search Places ...',
                            className: 'location-search-input',
                        })}
                        {...rest}
                    />
                    {anchorEL &&
                        <Popper open={anchorEL} style={{ width: (anchorEL && anchorEL.offsetWidth) || -1, zIndex: 10 }} anchorEl={anchorEL}>
                            <Paper square className={classes.paper} >
                                {loading && <MenuItem style={{ fontWeight: 400 }} >
                                    <div className={classes.secondaryText}>{`Loading...`}</div>
                                </MenuItem>}
                                {suggestions.map((suggestion, index) => {

                                    if (service !== 'autocomplete' && !suggestion.types.includes('school')) return null;

                                    let { mainText, secondaryText } = suggestion.formattedSuggestion;
                                    secondaryText = secondaryText && secondaryText.replace(/, USA/gi, "");
                                    return (
                                        <MenuItem
                                            {...getSuggestionItemProps(suggestion, {
                                                selected: suggestion.active,
                                                component: "div",
                                                style: {
                                                    fontWeight: suggestion.active ? 500 : 400,
                                                    display: 'flex',
                                                    flexDirection: "column",
                                                    alignItems: "flex-start"
                                                }
                                            })}
                                        >
                                            <div className={classes.mainText} id={`suggestion-${index}`}>{`${mainText}`}&nbsp;</div>
                                            <div className={classes.secondaryText}>{`${secondaryText}`}</div>
                                        </MenuItem>
                                    );
                                })}
                            </Paper>
                        </Popper>
                    }
                </div>
            }}
        </PlacesAutocomplete >
    )
}