import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import AppBar from '@material-ui/core/AppBar';

import Sidenav from '../components/sidenav/sidenav.component';
import NotificationSidenav from '../components/notification-sidenav/notification-sidenav.component';
import ContentToolbar from '../components/content-toolbar/content-toolbar.component';
import MenuSidenav from '../components/menu-sidenav/menu-sidenav.component';
import ImportantNotification from './ImportantNotice'
// Actions
import { setSidenavOpen, toggleSidenav, toggleSidenavVariant } from 'redux/actions/layout.actions';

import scss from './layout-classic.module.scss';
import styles from './layout-classic.style';

import IntersectionVisible from "react-intersection-visible";
import { BrowserView, MobileView } from "react-device-detect";
import Alert from '@material-ui/lab/Alert';
import platform from 'platform';
import swal from "sweetalert";

const MobileOnScrollHandler = (props) => {
  const onShow = (e) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'refresh',
          params: {
            enableRefresh: true
          }
        }),
      );
    }
  };

  const onHide = (e) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'refresh',
          params: { enableRefresh: false }
        }),
      );
    }
  };

  return <IntersectionVisible onShow={onShow} onHide={onHide}>
    <div style={{ height: 1 }} />
  </IntersectionVisible>
};

class ClassicLayout extends React.Component {
  // Set the initial layout state when the layout is initialised
  constructor(props) {
    super(props);
    const variant = isWidthDown('sm', props.width) ? 'temporary' : 'persistent';
    props.toggleSidenavVariant(variant);
    props.setSidenavOpen(variant === 'persistent');
    this.state = {
      dismissDownload: false
    }
  }

  // Update the layout state when a going from mobile to desktop and vice versa
  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((isWidthDown('sm', this.props.width) && isWidthUp('md', nextProps.width))) {
      this.props.toggleSidenavVariant('persistent');
      this.props.setSidenavOpen(true);
    } else if ((isWidthDown('sm', nextProps.width) && isWidthUp('md', this.props.width))) {
      this.props.toggleSidenavVariant('temporary');
      this.props.setSidenavOpen(false);
    }
  }

  componentDidMount() {
    document.title = 'Connect One'
  }

  render() {
    const { children, classes } = this.props;
    const { dismissDownload } = this.state;

    const getBrowserMessage = () => {
      let commandButton = 'Ctrl';
      if (platform.os.family && platform.os.family === 'OS X') {
        commandButton = 'Command (⌘)';
      }

      return (<Alert severity="warning" onClose={onDismiss}>
        <span>Press <strong>{commandButton} + D</strong> to add this page to your bookmarks.</span>
      </Alert>);
    }

    const onDismiss = (e) => {
      e.preventDefault();
      this.setState({ dismissDownload: true })

      swal({
        icon: 'warning',
        text: "Have you bookmarked or downloaded the app and hence don't need this reminder anymore?",
        dangerMode: true,
        buttons: {
          ok: "Yes",
          cancel: "No"
        }
      }).then((res) => {
        if (res && res == 'ok') {
          localStorage.setItem('preferences.mobile', true);
        }
      });
    }

    const getMobileMessage = () => {
      let link = "https://play.google.com/store/apps/details?id=com.connect1mobile";
      if (platform.manufacturer && platform.manufacturer.toLowerCase() === "apple"
        || platform.os && platform.os.family && platform.os.family.match(/iOS|OS X/i)) {
        link = "https://apps.apple.com/app/id1486241374"
      }

      const downloadApp = () => {
        window.open(link);
        this.setState({ dismissDownload: true })
      }

      return <Alert severity="warning" onClick={downloadApp} onClose={onDismiss}>
        <span>For the best experience get our app</span>
      </Alert>
    }

    const showDownloadLink = () => {
      return !dismissDownload && !window.ReactNativeWebView && !localStorage.getItem('preferences.mobile')
    }

    let downloadLink = showDownloadLink();

    return (
      <div className={classNames(scss['layout-classic-wrapper'], classes.wrapper)}>
        <Sidenav>
          <MenuSidenav />
        </Sidenav>
        <main className={scss['layout-classic-main']}>
          {downloadLink &&
            <>
              <MobileView>
                {getMobileMessage()}
              </MobileView>
              <BrowserView>
                {getBrowserMessage()}
              </BrowserView>
            </>
          }
          <AppBar elevation={0} classes={{ root: scss['app-bar'] }} color="default" position="sticky">
            <ContentToolbar />
          </AppBar>
          <ImportantNotification />

          <div id="layout-classic-wrapper" className={scss['layout-classic-content-wrapper']}>
            <div id="layout-content" className={scss['layout-classic-content']}>
              <MobileOnScrollHandler />
              {children}
            </div>
          </div>
          {/* <ContentFooter /> */}
        </main>
        <NotificationSidenav />
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    layout: {
      sidenavOpen: state.layout.sidenavOpen
    }
  };
}

ClassicLayout.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.shape({}).isRequired,
  width: PropTypes.string.isRequired,
  toggleSidenavVariant: PropTypes.func.isRequired,
  setSidenavOpen: PropTypes.func.isRequired
};

export default compose(
  withWidth(),
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {
    toggleSidenav,
    toggleSidenavVariant,
    setSidenavOpen
  })
)(ClassicLayout);
