import RemoteEntityReducer from "../RemoteEntityReducer";

const invalidOrganization = {
  id: 'invalid-org'
};

const entityReducer = new RemoteEntityReducer((state, action) => {

  if (!action.payload)
    return Object.assign(state, { currentOrganization: invalidOrganization });

  let currentOrganization = Object.assign({}, action.payload);
  return Object.assign(state, { currentOrganization });

});

const main = (state = {}, action) => {
  var newState = Object.assign({}, state);
  switch (action.type) {
    case 'CURRENT_ORGANIZATION':
      return Object.assign(newState, entityReducer.doReduction(newState, action));
    default:
      return state;
  }
}

export default main
