import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import React from 'react';
import themes from 'themes';
import queryString from 'query-string'
import UserActions from "redux/actions/user.actions";


export function withAuthTheme(ComposedComponent, themeName) {
  class C extends React.Component {

    componentDidMount() {
      const {currentOrganization} = this.props;
      if (!currentOrganization) {
        const params = queryString.parse(this.props.location.search)
        if (params.orgId) {
          console.log("Getting organization for [" + params.orgId + "]");

          UserActions.getOrgById({orgId: params.orgId}, (res, err) => {
            if (res)
              UserActions.setCurrentOrg(res);
            else
              console.error(err);
          })
        }
      }
    }

    render() {
      const configuredTheme = themes[1].theme;
      const materialTheme = createTheme(configuredTheme.contentTheme);

      return (
        <MuiThemeProvider theme={materialTheme}>
          <ComposedComponent {...this.props} />
        </MuiThemeProvider>
      );
    }
  };
  return C
}
