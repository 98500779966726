import React from "react";
import { Storage } from "aws-amplify";
import { store } from '../ReduxStore'
import c1Config from "cfg/config";

export const actions = {
  ALL_GALLERY_IMAGES: "ALL_GALLERY_IMAGES",
  NEW_GALLERY_IMAGE: "NEW_GALLERY_IMAGE",
  GALLERY_LOADING_COMPLETE: "GALLERY_LOADING_COMPLETE",
  GALLERY_LOADING_STARTED: "GALLERY_LOADING_STARTED"
};

export const loadGallery = async (galleries = ['defaultGallery/', 'saints/', 'heroes/', 'saints2/', 'childSaints/' , 'childSaintsV2/' ], onError, bulkLoad = true) => {
  store.dispatch({
    type: actions.GALLERY_LOADING_STARTED
  });

  try {
    let imageKeys = [];

    const config = Storage.configure();
    const newConfig = { ...config, bucket: "content.connect1.io" };
    try {
      await Promise.all(galleries.map(async (g) => {

        Storage.configure(newConfig)

        const result = await Storage.list(g, {
          customPrefix: {
            public: ""
          }
        });

        const keys = Object.values(result).filter(r => r.key != g && r.size > 0).map(r => r.key);
        imageKeys = imageKeys.concat(keys);

      }));
    } finally {
      Storage.configure(c1Config.AWS.Storage);
    }
    //look in the public directory for the items

    const images = {}
    await Promise.all(imageKeys.map(async (key) => {
      const galleryType = key.match(/([^\/]*)/g)[0];

      const imageParams = {
        "defaultGallery": {
          original: "fit=fill&fill=blur&h=256&w=500&fit=fill&fill=blur",
          thumbnail: "h=64&w=64&fit=fill&fill=blur"
        },
        "saints": { thumbnail: "h=64&w=64", original: "h=256" },
        "heroes": { thumbnail: "h=64&w=64", original: "h=256" },
        "saints2": { thumbnail: "h=64&w=64", original: "h=256" },
        "childSaints": { thumbnail: "h=64&w=64", original: "h=256" },
        "childSaintsV2": { thumbnail: "h=64&w=64", original: "h=256" },
      };

      const { thumbnail, original } = imageParams[galleryType];

      const originalImageUrl = encodeURI(`https://connect1.imgix.net/${key}?${original}`);
      const thumbnailImageUrl = encodeURI(`https://connect1.imgix.net/${key}?${thumbnail}`);

      const loadImages = async () => {
        const cachedImage = await new Promise((resolve, reject) => {
          const cachedImage = new Image();
          cachedImage.src = originalImageUrl;
          cachedImage.onload = () => resolve(cachedImage);
          cachedImage.onerror = () => resolve(cachedImage);
        });

        const cachedThumbnail = await new Promise((resolve, reject) => {
          const cachedThumbnail = new Image();
          cachedThumbnail.src = thumbnailImageUrl;
          cachedThumbnail.onload = () => resolve(cachedThumbnail);
          cachedThumbnail.onerror = () => resolve(cachedImage);
        });

        return { cachedImage, cachedThumbnail };
      };


      const { cachedImage, cachedThumbnail } = await loadImages();


      if (bulkLoad) {
        if (!images[galleryType])
          images[galleryType] = [];

        images[galleryType].push({
          key,
          original: originalImageUrl,
          thumbnail: thumbnailImageUrl,
          galleryType,
          cachedImage,
          cachedThumbnail
        });
      }

      if (!bulkLoad)
        store.dispatch({
          type: actions.NEW_GALLERY_IMAGE,
          galleryType,
          image: {
            key,
            original: originalImageUrl,
            thumbnail: thumbnailImageUrl,
          }
        });

    }));

    if (bulkLoad)
      Object.keys(images).forEach((galleryType) => {
        store.dispatch({
          type: actions.ALL_GALLERY_IMAGES,
          galleryType,
          images: images[galleryType]
                                  .sort((a,b)=>{
                                      if(a.key < b.key)
                                        { return -1; }
                                      if(a.key > b.key)
                                        { return 1; }
                                      return 0;
                                  })
        });
      });

    store.dispatch({
      type: actions.GALLERY_LOADING_COMPLETE
    });
  } catch (err) {
    // console.log(err)

    onError && onError(err);
  }

}
