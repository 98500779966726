import { Checkbox, FormControlLabel, FormHelperText, Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import InfoPopup, { InfoHelpPopover } from "./InfoPopup";

import { bind } from 'utils/WebUtils';

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center'
  },
})

class CustomCheckBox extends React.Component {
  state = {
    helpPopup: false
  };

  constructor(props) {
    super(props);

    bind(this, 'onChange')
  }

  onChange(event) {
    let { onChange, name, self } = this.props;

    let value = event.target.checked
    self.setState({
      [name]: this.props.notValue ? !value : value,
    }, () => onChange && onChange({ name, value: this.props.notValue ? !value : value }))
  }

  render() {

    const {
      infoHelp,
      labelHelp,
      name,
      placeholder,
      self,
      classes,
      color = 'primary',
      label,
      editable = true,
      notValue,
      ...rest
    } = this.props;

    let value = self.state[name]
    let error = self.state[`${name}State`] === 'error'
    let errorMessage = self.state[`${name}Error`]

    const { helpPopup, infoEl } = this.state;
    if (!editable)
      return (
        <Box display="flex" flexGrow={1} flexDirection="column" >
          <Typography variant="caption" color="textSecondary" gutterBottom >{label}</Typography>
          <Box ml={-1.5}>
            <Checkbox
              checked={notValue ? !value : value}
              value={name}
              color={color}
            />
          </Box>
        </Box>
      )
    return (
      <Box className={classes.root}>
        <Box display="flex">
          <FormControlLabel
            control={
              <Checkbox
                checked={notValue ? !value : value}
                onChange={this.onChange}
                value={name}
                color={color}
              />
            }
            label={
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                {label}
                {/* {infoHelp && <InfoPopup infoHelp={infoHelp} />} */}
              </div>}
            {...rest}
          />
          {infoHelp && <InfoHelpPopover infoHelp={infoHelp} />}
        </Box>
        {labelHelp &&
          <FormHelperText>{labelHelp}</FormHelperText>
        }
      </Box>

    )
  }
}

export default withStyles(styles, { withTheme: true })(CustomCheckBox);
