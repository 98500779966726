import React from "react";
import { Popper, Fade, Paper, Typography, Popover, Box, IconButton } from "@material-ui/core";
import { Info as InfoIcon } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";

export default function InfoHelp(props) {
  const { infoHelp } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (!infoHelp)
    return null;

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  const open = Boolean(anchorEl);

  return (<>
    <InfoIcon style={{ padding: 10, color: grey['A700'] }} onClick={handleClick} />
    <Popper placement="bottom"
      open={open} anchorEl={anchorEl} transition style={{ zIndex: 1400 }}>
      {({ TransitionProps }) => {
        return (
          <Fade {...TransitionProps} timeout={350}>
            <Paper style={{ maxWidth: 350, backgroundColor: grey[200], padding: 10, fontSize: '0.65rem' }}>
              {infoHelp}
            </Paper>
          </Fade>
        );
      }}
    </Popper>
  </>)
}



export function InfoHelpPopover({ infoHelp, style }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <IconButton
        style={style}
        aria-describedby={id}
        color='default'
        edge="end"
        aria-label="toggle password visibility"
        onClick={handleClick}
        onMouseDown={(event) => {
          event.preventDefault();
        }}>
        <InfoIcon fontSize="small" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}

        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box py={1.5} px={2} maxWidth={300}  >
          <Typography variant="caption" component="p" style={{ whiteSpace: 'pre-wrap' }} >{infoHelp}</Typography>
        </Box>
      </Popover>
    </>
  )
}
