import RemoteEntityReducer from "../RemoteEntityReducer";
import { cloneDeep } from 'lodash'

const entityReducer = new RemoteEntityReducer((state, action) => {
    if (!action.payload)
        throw new Error("Cannot reduce with no user");
    let { payload } = action;
    return Object.assign(state, { users: payload });

});

const userReducer = (state = {}, action) => {
    var newState = Object.assign({}, state);

    switch (action.type) {
        case 'USERS':
            return entityReducer.doReduction(newState, action);
        case 'UPDATE_USER':
            return updateUser(newState, action)
        default:
            return state;
    }
}

const updateUser = (newState, action) => {
    newState = entityUpdater(newState)

    const { payload } = action
    let user = newState.users.find(u => u.id === payload.id)
    const userIndex = newState.users.indexOf(user)

    let users = [...newState.users]
    users[userIndex] = cloneDeep(payload)
    newState.users = users
    return newState
}

const entityUpdater = (newstate) => {
    newstate.lastUpdated = Date.now();
    newstate.isFetching = false;
    newstate.didInvalidate = false;
    newstate.error = undefined;
    newstate.retryCount = undefined;
    newstate.updateStatus = 'completed';

    return Object.assign({}, newstate);
}

export default userReducer
