const eventPatchHandler = (state = {}, action, parentState) => {
  
  switch (action.type) {
    case "PATCH_EVENT":
      const event = action.payload;
      parentState['event_current'].event = event
      
      let events =
        parentState['event'].event['0']
      
      events = events.filter((e) => e.id === event.id)
      events.push(event);
      
      Object.assign(parentState['event'], {[event]: [...events]});

      break;
  }
  
  return state;
}

export default eventPatchHandler
