import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import {Route} from 'react-router-dom'
import c1Config from "cfg/config";

class GoogleAnalytics extends Component {
  componentDidMount() {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    )
  }

  componentDidUpdate({location: prevLocation}) {
    const {location: {pathname, search}} = this.props
    const isDifferentPathname = pathname !== prevLocation.pathname
    const isDifferentSearch = search !== prevLocation.search

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search)
    }
  }

  logPageChange(pathname, search = '') {
    const page = pathname + search
    const {location} = window
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options
    })
    ReactGA.pageview(page)
  }

  render() {
    return null
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  options: PropTypes.object
}

const RouteTracker = () =>
  <Route component={GoogleAnalytics}/>

const init = (options = {}) => {
  const {environment} = c1Config;

  const env = window._env_ || {REACT_APP_GA_TRACKING_ID: 'UA-149840738-1', REACT_APP_GA_DEBUG: 'true'};
  const isGAEnabled = true || (environment == "production" && !!env.REACT_APP_GA_TRACKING_ID);

  if (!isGAEnabled)
    console.log("Google Analytics is disabled");

  if (isGAEnabled) {
    ReactGA.initialize(
      env.REACT_APP_GA_TRACKING_ID, {
        debug: env.REACT_APP_GA_DEBUG === 'true',
        ...options
      }
    )
  }

  return isGAEnabled
}

const setField = (params) => {
  const ga = ReactGA.ga();
  for(let key of Object.keys(params)){
    ga('set', key, params[key]);
  }
  //ReactGA.set(params);
}

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
  setField
}
