const AppEntities = {
  event: "EVENT",
  orgConfig: "ORG_CONFIG",
  family: 'FAMILY',
  orgUser: 'ORG_USER',
  notification: 'NOTIFICATION',
  communityReport: 'COMMUNITY_REPORT',
  registrationReport: 'REGISTRATION_REPORT',
  faculty: 'FACULTY',
  assignedFaculty: 'ASSIGNED_FACULTY',
  unassignedFaculty: 'UNASSIGNED_FACULTY',
  aidTeacher: 'AID_TEAHCER',
  guardians: 'GUARDIANS',
  unassignedChildren: 'UNASSIGNED_CHILDREN',
  children: 'CHILDREN',
  prayer: 'PRAYER',
  volunteerInterestMap: 'VOLUNTEER_INTEREST_MAP',
  recognition: 'RECOGNITION',
  eventReport: 'EVENT_REPORT',
}

export const entityTypes = {
  entity_list: ['orgConfig'],
  paged_entity_list: ['family', 'event', 'orgUser', 'faculty', 'prayer', 'recognition'],
  infinite_scroll_entity_list: ['notification'],
  single_entity: [],
  alone_entity: ['eventReport','communityReport', 'registrationReport', 'assignedFaculty', 'unassignedFaculty', 'guardians', 'unassignedChildren', 'children', 'aidTeacher', 'volunteerInterestMap']
}

const ALONE_ENTITY_CONFIG = entityName => {

  switch (entityName) {
    case 'eventReport':
      return {
        getAllPath: 'report/eventReports'
      }
    case 'communityReport':
      return {
        getAllPath: 'report/communityReport'
      }
    case 'registrationReport':
      return {
        getAllPath: 'families?summary=true'
      }

    case 'assignedFaculty': {
      return {
        getAllPath: 'faculties?assigned=true'
      }
    }
    case 'unassignedFaculty':
      return {
        getAllPath: 'faculties?assigned=false'
      }
    case 'aidTeacher':
      return {
        getAllPath: 'faculties?type=HR-TEACHER-AID'
      }
    case 'guardians': {
      return {
        getAllPath: 'guardian'
      }
    }
    case 'unassignedChildren': {
      return {
        getAllPath: 'children?assigned=false'
      }
    }
    case 'children': {
      return {
        getAllPath: 'children',
        paging: true,
        maxPerPage: maxPerPage(entityName)
      }
    }
    case 'volunteerInterestMap': {
      return {
        getAllPath: 'report/volunteeringInterest'
      }
    }
  }
}

const ENTITY_LIST_CONFIG = entityName => ({
  getAllPath: getAllPath(entityName),
  getPath: `${entityName}`,
  updatePath: `${entityName}`,
  loadCurrentFromRedux: false // Load current item from redux instead of making a remote fetch call.
})

const maxPerPage = entityName => {
  if (entityName == 'event') {
    return 12;
  }

  return 10;
};

const getAllPath = entityName => {
  if (entityName == 'family' || entityName == 'faculty') {
    return entityName;
  }

  return entityName + 's';
};

const PAGED_ENTITY_LIST_CONFIG = entityName => (
  Object.assign({}, ENTITY_LIST_CONFIG(entityName), {
    paging: true,
    maxPerPage: maxPerPage(entityName),
    // loadCurrentFromRedux: false
  })
)

const INFINITE_SCROLL_ENTITY_LIST_CONFIG = entityName => (
  Object.assign({}, PAGED_ENTITY_LIST_CONFIG(entityName), {
    infiniteScroll: true
  })
)

const SINGLE_ENTITY_CONFIG = entityName => ({
  getAllPath: `${entityName}`,
  getPath: `${entityName}`,
  updatePath: `${entityName}`
})

export const AppEntityConfig = (entityName) => {

  let entityType = Object.keys(entityTypes).find(type => entityTypes[type].includes(entityName));

  switch (entityType) {
    case 'single_entity':
      return SINGLE_ENTITY_CONFIG(entityName);
    case 'paged_entity_list':
      return PAGED_ENTITY_LIST_CONFIG(entityName);
    case 'infinite_scroll_entity_list':
      return INFINITE_SCROLL_ENTITY_LIST_CONFIG(entityName);
    case 'alone_entity':
      return ALONE_ENTITY_CONFIG(entityName)
    default:
      return ENTITY_LIST_CONFIG(entityName);

  }
}

export default AppEntities;
