// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_cognito_identity_pool_id': 'us-east-1:60cfcda8-ee17-4215-a1a7-710f48e5b54b',
    'aws_cognito_region': 'us-east-1',
    'aws_mobile_analytics_app_id': '213aa0b177f64e97966043517e977697',
    'aws_mobile_analytics_app_region': 'us-east-1',
    'aws_project_id': 'fe0493dd-76e2-4860-bb20-46beb9b8b834',
    'aws_project_name': 'connect1.prod',
    'aws_project_region': 'us-east-1',
    'aws_resource_name_prefix': 'connectprod-mobilehub-1888819846',
    'aws_sign_in_enabled': 'enable',
    'aws_user_files': 'enable',
    'aws_user_files_s3_bucket': 'connectprod-userfiles-mobilehub-1888819846',
    'aws_user_files_s3_bucket_region': 'us-east-1',
    'aws_user_pools': 'enable',
    'aws_user_pools_id': 'us-east-1_t4iZlRHLP',
    'aws_user_pools_web_client_id': '5c9fgnlmuv0apsncektoubhnro',
}

export default awsmobile;
