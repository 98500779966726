import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  lightBlue,
  pink,
  green,
  deepOrange,
  blue,
  indigo,
  orange,
  red
} from '@material-ui/core/colors';

import Color from 'color';
import materialpalette from 'material-palette';
import colorConvert from 'color-convert';
import { Utils } from 'c1-webutils';
import themeConfig from './theme'
import { createTheme } from '@material-ui/core/styles';

green.contrastText = '#fff'

const defaultSidenavTheme = {
  ...themeConfig,
  palette: {
    ...themeConfig.palette,
    type: 'dark',
    background: {
      default: green[500]
    }
  },
  // overrides: {
  //   MuiAppBar: {
  //     colorDefault: {
  //       backgroundColor: green[500],
  //       color: '#FFF'
  //     }
  //   }
  // }
};

const themes = [{
  id: 'classic-green-orange',
  name: 'Classic - Green / Orange',
  theme: {
    contentTheme: themeConfig,
    sidenavTheme: defaultSidenavTheme
  },
  typography: {
    fontFamily: '"Roboto", "Source Sans Pro", "Helvetica", "sans-serif"'
  }
}, {
  id: 'classic-orange-deep_orange',
  name: 'Classic - Green / Deep Orange',
  theme: {
    contentTheme: themeConfig,
    sidenavTheme: defaultSidenavTheme
  }
}];


export const createOrgTheme = (currentOrg, defaultTheme = themes[1]) => {
  let { theme } = currentOrg

  let orgTheme = Utils.copy(defaultTheme);
  let { palette } = orgTheme.contentTheme;
  let { palette: sideNavePalette } = orgTheme.sidenavTheme;

  if (!theme) {
    // return defaultTheme;
    theme = {
      primaryColor: palette.primary.main,
      secondaryColor: palette.secondary.main,
    }
  }

  const primaryColor = !theme.primaryColor
    ? { main: palette.primary.main }
    : { main: theme.primaryColor }
  const secondaryColor = !theme.secondaryColor
    ? { main: palette.secondary.main }
    : { main: theme.secondaryColor }

  // Setting main palette
  palette.primary = primaryColor
  palette.secondary = secondaryColor

  sideNavePalette.primary = primaryColor
  sideNavePalette.secondary = secondaryColor

  let muiTheme = createTheme(orgTheme.contentTheme);

  document.documentElement.style.setProperty('--primary-main', muiTheme.palette.primary.main);
  document.documentElement.style.setProperty('--primary-dark', muiTheme.palette.primary.dark);
  document.documentElement.style.setProperty('--primary-light', muiTheme.palette.primary.light);

  document.documentElement.style.setProperty('--secondary-main', muiTheme.palette.secondary.main);
  document.documentElement.style.setProperty('--secondary-dark', muiTheme.palette.secondary.dark);
  document.documentElement.style.setProperty('--secondary-light', muiTheme.palette.secondary.light);

  document.documentElement.style.setProperty('--spacing', `${muiTheme.spacing(1)}px`);

  let primaryHSL = Color(muiTheme.palette.primary.main).hsl();
  var palettePrimary = materialpalette({ h: primaryHSL.color[0], s: primaryHSL.color[1], l: primaryHSL.color[2] })

  Object.keys(palettePrimary).forEach(shade => {
    let { h, s, l } = palettePrimary[shade];
    palette.primary[shade] = "#" + colorConvert.hsl.hex(h, s, l)
    document.documentElement.style.setProperty(`--primary-${shade}`,
      palette.primary[shade]
    );
  })

  // console.log(JSON.stringify(palette.primary, null, 4));

  let secondaryHSL = Color(muiTheme.palette.primary.main).hsl();
  var paletteSecondary = materialpalette({
    h: secondaryHSL.color[0],
    s: secondaryHSL.color[1],
    l: secondaryHSL.color[2]
  })

  Object.keys(paletteSecondary).forEach(shade => {
    let { h, s, l } = paletteSecondary[shade];
    palette.primary[shade] = "#" + colorConvert.hsl.hex(h, s, l)
    document.documentElement.style.setProperty(`--secondary-${shade}`,
      palette.primary[shade]
    );
  })
  return orgTheme;
};

export default themes;
