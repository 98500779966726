import FetchAction from "./FetchAction";
import UpdateAction from "./UpdateAction";
import * as API from "rest/API"
import AppEntities from './AppEntities'
import { store as reduxStore } from "./ReduxStore";


const GenericAction = (entityName, redux) => {

  const entityFetch = () => new FetchAction(AppEntities[entityName], API.fetcher(entityName), undefined, undefined, `${entityName}`);
  const entityUpdateAll = () => new UpdateAction(AppEntities[entityName], API.updater(entityName));
  const entityDelete = () => new UpdateAction(AppEntities[entityName], API.deleteAll(entityName));

  const entityFetchCurrent = () => new FetchAction(`CURRENT_${AppEntities[entityName]}`, API.fetcherCurrent(entityName), undefined, undefined, `${entityName}_current` );
  const entityUpdate = () => new UpdateAction(`CURRENT_${AppEntities[entityName]}`, API.updater(entityName));
  const entityPatch = () => new UpdateAction(`CURRENT_${AppEntities[entityName]}`, API.patcher(entityName));
  const entityCreate = () => new UpdateAction(`CURRENT_${AppEntities[entityName]}`, API.creator(entityName));

  const entityUpdate_publicApi = () => new UpdateAction(`CURRENT_${AppEntities[entityName]}`, API.updater(entityName, API.PUBLIC_API_NAME));
  const entityCreate_publicApi = () => new UpdateAction(`CURRENT_${AppEntities[entityName]}`, API.creator(entityName, API.PUBLIC_API_NAME));

  const entityAction = () => new UpdateAction(`CURRENT_${AppEntities[entityName]}`, API.onEntityAction(entityName));

  return class Actions {

    static fetch(state, stateFilter, ...args) {
      return entityFetch().doFetch(state, stateFilter, ...args)
    }

    static fetchCurrent(state, stateFilter, ...args) {
      let toReturn = entityFetchCurrent().doFetch(state, stateFilter, ...args);
      return toReturn;
    }

    static setCurrentId(currentId) {
      reduxStore.dispatch({
        type: "SET_DATA",
        name: `${entityName}_currentId`,
        value: currentId
      })
    };

    static setCurrentPage(page) {
      reduxStore.dispatch({
        type: "SET_DATA",
        name: `${entityName}_currentPage`,
        value: page
      })
    };

    static invalidateEntity(entityName) {
      reduxStore.dispatch({
        type: `${entityName}`,
        status: 'invalid'
      })
    }

    static refreshEntity(entityName) {
      reduxStore.dispatch({
        type: `${entityName}`,
        status: 'refresh'
      })
    }

    static setMaxPerPage(maxPerPage, customisedInvalidateCall = true) {
      reduxStore.dispatch({
        type: "SET_DATA",
        name: `${entityName}_maxPerPage`,
        value: maxPerPage
      })

      Actions.invalidate({ searchParams: false , customisedInvalidateCall});
    };

    static setSearchParams(params, customisedInvalidateCall = true) {
      reduxStore.dispatch({
        type: "SET_DATA",
        name: `${entityName}_searchParams`,
        value: params
      })

      Actions.invalidate({ searchParams: false, current: false, currentPage: false, customisedInvalidateCall });
    };
    static refreshCurrent() {
      reduxStore.dispatch({
        type: `CURRENT_${AppEntities[entityName]}`,
        status: 'refresh'
      })
    }

    static invalidateCurrent() {
      reduxStore.dispatch({
        type: `CURRENT_${AppEntities[entityName]}`,
        status: 'invalid'
      })
    }

    static invalidate(options) {
      options = Object.assign({
        searchParams: true,
        all: true,
        currentId: true,
        currentPage: true,
        current: true,
      }, options);
      
      let { searchParams, all, currentId, currentPage, current, customisedInvalidateCall } = options;

      if (customisedInvalidateCall){
        currentPage && reduxStore.dispatch({
          type: "UNSET",
          name: `${entityName}_currentPage`
        })

        currentId && reduxStore.dispatch({
          type: "UNSET",
          name: `${entityName}_currentId`
        })

        searchParams && reduxStore.dispatch({
          type: "UNSET",
          name: `${entityName}_searchParams`
        })

        current && reduxStore.dispatch({
          type: `CURRENT_${AppEntities[entityName]}`,
          status: 'invalid'
        })
      }

      all && reduxStore.dispatch({
        type: AppEntities[entityName],
        status: 'invalid'
      })
    }

    static createPublic(callback, entity) {
      entityCreate_publicApi().doUpdate(true, callback, entity)
    }

    static updatePublic(callback, entity) {
      entityUpdate_publicApi().doUpdate(true, callback, entity)
    }

    static create(callback, entity, invalidate = true) {
      entityCreate().doUpdate(invalidate, callback, entity)
    }

    static update(callback, entity, invalidate = false) {
      entityUpdate().doUpdate(invalidate, callback, entity)
    }

    static patch(callback, entity, invalidate = true) {
      entityPatch().doUpdate(invalidate, callback, entity)
    }

    static updateAll(callback, entities, invalidate = true) {
      entityUpdateAll().doUpdate(invalidate, callback, entities)
    }

    static delete(callback, ids) {

      reduxStore.dispatch({
        type: "UNSET",
        name: `${entityName}_searchParams`
      })

      entityDelete().doUpdate(true, callback, ids)
    }

    static doEntityAction(callback, action, invalidate = true) {
      entityAction().doUpdate(invalidate, callback, action)
    }
  }
}

export default GenericAction;
