import { createSelector } from 'reselect';

import Actions from './actions';
import { AppEntityConfig } from './AppEntities';
import { currentOrganization, currentOrganizationState } from './selectors/user.selector';

const GenericSelectors = (entityName) => {

  const EntityActions = Actions[entityName];
  const config = AppEntityConfig(entityName);
  const entityState = (state) => state && state[entityName];

  // private selectors
  const currentState = (state) => state && state[`${entityName}_current`];
  const currentId = (state) => state.data && state.data[`${entityName}_currentId`];
  const currentPage = (state) => (state.data && state.data[`${entityName}_currentPage`]) || 0;
  const maxPerPage = (state) => (state.data && state.data[`${entityName}_maxPerPage`]) || config.maxPerPage;
  const searchParams = (state) => (state.data && state.data[`${entityName}_searchParams`]) || {};

  const selectAllStats = createSelector(
    [entityState],
    (entityState) => {
      if (!entityState)
        return null;

      const { count, unread } = entityState;
      return { count, unread };
    }
  )

  const selectAll = createSelector(
    [currentOrganization, currentOrganizationState, entityState, currentPage, maxPerPage, searchParams],
    (currentOrganization, currentOrganizationState, entityState, currentPage, maxPerPage, searchParams) => {
      if (!currentOrganization)
        return undefined

      if (!EntityActions.fetch(entityState, () => {

        let uptodate = entityState.lastUpdated !== undefined &&
          entityState.lastUpdated >= currentOrganizationState.lastUpdated

        if (uptodate && config.paging && !config.infiniteScroll) {
          uptodate = entityState[entityName] && entityState[entityName][currentPage]
        }

        if (uptodate && config.paging && config.infiniteScroll) {
          uptodate = entityState[entityName] && entityState.currentPage === currentPage
        }

        return uptodate;

      }, { currentPage, maxPerPage, ...searchParams })) {
        return (config.paging && config.infiniteScroll) ? entityState[entityName] : undefined;
      }
      return (config.paging && !config.infiniteScroll) ? entityState[entityName][currentPage] : entityState[entityName]
    }
  )

  const current = createSelector(
    [currentOrganization, currentState, selectAll, currentId],
    (currentOrganization, currentState, allEntities, currentId) => {
      if (!currentOrganization)
        return null;
      let currentEntity = undefined
      if (config.loadCurrentFromRedux) {
        currentEntity = allEntities && allEntities.find(entity => entity.id === currentId);
      }
      if (!currentEntity) {
        if (!currentId || currentState.isFetching)
          return undefined

        if (!EntityActions.fetchCurrent(currentState, () => {
          return currentState.lastUpdated !== undefined && currentState.entityId === currentId;
        }, currentId))
          return currentState[entityName];

        currentEntity = currentState[entityName];
      }

      return currentEntity;
    }
  )
  const hasFetchedBefore = createSelector(
    [currentOrganization, entityState],
    (currentOrganization, entityState) => {
      if (!currentOrganization)
        return true;

      return typeof entityState.lastUpdated !== 'undefined';
    }
  );

  const isFetching = createSelector(
    [currentOrganization, entityState],
    (currentOrganization, entityState) => {
      if (!currentOrganization)
        return true;

      return entityState.isFetching;
    }
  );

  const isFetchingCurrent = createSelector(
    [currentOrganization, currentState],
    (currentOrganization, currentState) => {
      if (!currentOrganization)
        return true;

      return currentState.isFetching;
    }
  );


  return {
    selectAllStats,
    selectAll,
    current,
    currentPage,
    maxPerPage,
    searchParams,
    currentId,
    hasFetchedBefore,
    isFetchingCurrent,
    isFetching
  }
}

export default GenericSelectors
