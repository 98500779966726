import Utils from "./Utils";
import {moment} from './moment';

function getComparator(sortCols, sortTypes, reverse) {
    return function (a, b) {
        JSON.stringify(sortCols + ", " + sortTypes);

        let toReturn = sortCols.reduce((result, sortCol, i) => {
            if (result != 0) return result;

            let aValue = typeof sortCol === "function" ? sortCol(a) : a[sortCol];
            let bValue = typeof sortCol === "function" ? sortCol(b) : b[sortCol];

            return sortTypes[i](
                aValue, bValue
            )
        }, 0)

        return reverse ? toReturn * -1 : toReturn;
    }
}

const forType = (type) => {
    switch (type) {
        case 'period':
            return (a, b) => {
                let d1 = moment(a, Utils.PERIOD_FORMAT);
                let d2 = moment(b, Utils.PERIOD_FORMAT);
                return d2.isSame(d1) ? 0 : (d2.isBefore(d1) ? 1 : -1)
            }
        case 'date':
            return (a, b) => {
                let d1 = moment(a);
                let d2 = moment(b);
                return d2.isSame(d1) ? 0 : (d2.isBefore(d1) ? 1 : -1)
            }
        case 'number':
            return (a, b) => a == b ? 0 : (a < b ? -1 : 1);
        case 'string':
            return (a, b) => a == b ? 0 : (a < b ? -1 : 1)
    }
}

export {
    forType,
    getComparator
}

