import React, { useState } from 'react'
import moment from 'moment'
import { Utils, fromUtc } from "c1-webutils"
import Platform from 'platform'
import { TenHeart, JackHeart, QueenHeart, KingHeart, AceHeart, CardBack, RoyalFlush } from '../icons'
import BusinessIcon from "assets/images/business.png"
import TechnologyIcon from "assets/images/technology.png"
import ArtIcon from "assets/images/arts.png"
import MediaIcon from "assets/images/marketing.png"
import EducationIcon from "assets/images/education.png"
import { Storage } from "aws-amplify";

const { config } = require('../../package.json');

let singletonInstances = {}

export function getLevelCards() {
  const levelCards = {
    0: { icon: CardBack },
    1: { icon: TenHeart },
    2: { icon: JackHeart },
    3: { icon: QueenHeart },
    4: { icon: KingHeart },
    5: { icon: AceHeart },
    6: { icon: RoyalFlush }
  }

  const allLevels = Utils.getLevels();
  for (let level of Object.keys(allLevels))
    levelCards[level] = Object.assign(levelCards[level], allLevels[level]);

  return levelCards;
}

export function singleton(instances) {
  Object.keys(instances).forEach(key => singletonInstances[key] = instances[key]);
}

export function get(key) {
  return singletonInstances[key];
}

export function backend() {
  return config.backend
}

export function isCloudDeployment() {
  return config['isCloudDeployment']
}

export function bind(instance, ...functions) {
  functions.forEach(func => instance[func] = instance[func].bind(instance));
}

// Returns if a value is an array
export function isArray(value) {
  return Array.isArray(value);
}


// Returns if a value is a function
export function isFunction(value) {
  return typeof value === 'function';
}

// Returns if a value is an object
export function isObject(value) {
  return value && typeof value === 'object';
}

// Returns if a value is really a number
export function isNumber(value) {
  const result = typeof value === 'number' && isFinite(value);
  return result;
}

// Returns if a value is a string
export function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

// convert HTML to text.
export function stripHtml(html) {
  if (!html) return ""
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export function generateImageURL(key, width = 50, height = 50, params = "") {
  return `https://connect1.imgix.net/${key}?w=${width}&h=${height}&${params}`;
}

// Hook
export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export const currentDate = (org) => {
  return org && org.date && fromUtc(org.date) || Utils.currentDate();
}

export const findHouseholdIndex = (family, userId) => {
  if (!family)
    return -1

  const householdIndex = family.households.findIndex(h => h.guardians.findIndex((g) => (g.cognitoId === userId || g.id === userId)) > -1)

  return householdIndex;
}

export const findHouseholdAndGuardian = (family, userId) => {
  if (!family)
    return

  let guardian = null
  let household = null

  for (let i = 0; i < family.households.length; i++) {
    guardian = family.households[i].guardians.find((g) => (g.cognitoId === userId || g.id === userId))
    if (guardian) {
      household = family.households[i]
      break
    }
  }

  return { guardian, household };
}

export const findGuardianIndex = (household, userId) => {
  if (!household)
    return -1

  const guardianIndex = household.guardians.findIndex((g) => (g.cognitoId === userId || g.id === userId))

  return guardianIndex;
}

export const findGuardian = (family, userId) => {
  if (!family)
    return

  let guardian = null;
  family.households.forEach((h) => {
    guardian = h.guardians.find((g) => (g.cognitoId === userId || g.id === userId));
  });

  return guardian;
}

export const findAnyGuardian = (family) => {
  let guardian = [];
  family.households.forEach((h) => {
    if (h.guardians)
      guardian.push(h.guardians[0])
  });

  return guardian[0];
}

export const getAllGuardians = (family) => {
  let guardians = [];
  family && family.households && family.households.forEach((h) => {
    if (h.guardians)
      guardians = guardians.concat(h.guardians.map(g => {
        g.familyId = family.id
        g.avatarKey = family.avatarKey
        return g
      }));
  })
  return guardians;
}

export const hasWorkflow = (family) => {
  let flag = false;
  family &&
    family.households.forEach(hh => {
      hh.guardians &&
        hh.guardians.forEach(g => {
          const wf =
            g.workflow && g.workflow.find(w => w.type == "registration");
          if (wf) flag = true;
        });
    });
  return flag;
};

export const copyToClipboard = (textToCopy) => {
  // navigator clipboard api needs a secure context (https)
  const MOBILE = ['iOS', 'Android']
  const os = Platform.os.family
  if (os && MOBILE.includes(os)) {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        method: 'copy',
        params: textToCopy
      }),
    );
  } else {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
      } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand('copy') ? res() : rej();
          textArea.remove();
        });
      }
    } catch (e) {
      console.log(e)
    }
  }
}

export const getContrastYIQ = (hexcolor) => {
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? 'black' : 'white';
}

export const hasActiveWorkflow = (org) => {
  const cDate = moment(currentDate(org));

  const orgWorkflow = org.workflow && org.workflow.find((w) => {
    if (w.type == "registration") {
      const { start, end } = w.attributes;
      if (cDate.isAfter(moment(start)) && cDate.isBefore(moment(end)))
        return w;
    }
    return false
  });

  return orgWorkflow;
};

export const _category = {
  business: { label: 'Business', icon: BusinessIcon },
  technology: { label: 'Technology', icon: TechnologyIcon },
  arts: { label: 'Art / Recreation', icon: ArtIcon },
  marketing: { label: 'Media / Marketing', icon: MediaIcon },
  education: { label: 'Education / Science', icon: EducationIcon },
}

export const parseTalents = talents => {
  if (!talents) {
    return 'N/A'
  }
  let index = -1
  let talentTypes = [...talents].reduce((acc, cur) => {
    const category = _category[cur.type]
    if (!acc.find(a => a.type === cur.type))
      acc.push({ ...category, type: cur.type })
    return acc
  }, [])
  talentTypes = talentTypes.length > 3
    ? [talentTypes[0], talentTypes[1], talentTypes[2], { label: talentTypes.length - 3 + '+' }]
    : talentTypes
  return (talentTypes && talentTypes.length > 0) ? talentTypes : 'N/A'
}

export const downloadFile = async (apiMethod, name, type = 'text/csv;charset=utf-8;', delayedDownload) => {
  const zipApplicationType = "application/zip";
  let result = await apiMethod();

  if (result) {
    let url = null;
    if (type == "application/json") {
      const { key } = result;
      const downloadUrl = await Storage.get(key, { level: 'private' });
      url = new window.URL(downloadUrl);
    } else {
      if (type === zipApplicationType) {
        const bstr = atob(result);
        let n = bstr.length;
        let u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        result = u8arr;
      }
      url = window.URL.createObjectURL(new Blob([result]), { type });
    }

    if (!delayedDownload) {
      const a = document.createElement('a');
      a.href = url;
      a.download = name;

      setTimeout(function () {
        URL.revokeObjectURL(a.href);
        a.remove();
      }, 4E4) // 40s
      setTimeout(function () {
        a.click()
      }, 0)
    } else {
      delayedDownload(result)
    }
  }
}

export const generateFamiliesFileName = ({ startDate, endDate, byUser, grades }) => {
  let fileName = `${byUser ? 'family-users' : 'families'}-volunteering-data`
  if (grades?.length > 0) {
    fileName += `-grades${grades.join(',')}-`
  }
  fileName += `-${startDate}--${endDate}.csv`
  return fileName
}

export const generateRoleName = (roles) => {
  let roleName = ""

  if (roles.includes('parent')) {
    roleName += "parent "
  }
  if (roles.includes("school") || roles.includes("faculty")) {
    roleName += "school "
  }
  if (roles.includes("creator")) {
    roleName += "creator "
  }
  if (roles.includes("admin")) {
    roleName += "admin"
  }
  return roleName.trim()
}

export const levelLabels = [
  'Ante Up (0)',
  '10 Hearts (1-10)',
  'Jack (11-20)',
  'Queen (21-30)',
  'King (31-40)',
  'Ace (41-50)',
  'Flush (> 50)',
];

export const checkPasswordValidity = (value) => {
  if (!value)
    return "Password is required."
    
  const isNonWhiteSpace = /^\S*$/;
  if (!isNonWhiteSpace.test(value)) {
    return "Password must not contain Whitespaces.";
  }

  const isContainsUppercase = /^(?=.*[A-Z]).*$/;
  if (!isContainsUppercase.test(value)) {
    return "Password must have at least one Uppercase Character.";
  }

  const isContainsLowercase = /^(?=.*[a-z]).*$/;
  if (!isContainsLowercase.test(value)) {
    return "Password must have at least one Lowercase Character.";
  }

  const isContainsNumber = /^(?=.*[0-9]).*$/;
  if (!isContainsNumber.test(value)) {
    return "Password must contain at least one Digit.";
  }

  // const isContainsSymbol =
  //   /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
  // if (!isContainsSymbol.test(value)) {
  //   return "Password must contain at least one Special Symbol.";
  // }

  const isValidLength = /^.{8,50}$/;
  if (!isValidLength.test(value)) {
    return "Password must be atleast 8 Characters Long.";
  }

  return null;
}
