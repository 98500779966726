import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Popover
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import CodeIcon from '@material-ui/icons/Code';
import StoreIcon from '@material-ui/icons/Store';
import MessageIcon from '@material-ui/icons/Message';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreIcon from '@material-ui/icons/MoreVert';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import gradients from 'utils/gradients';
import VisibilitySenor from 'react-visibility-sensor';
import { hasSeen } from "rest/API";
import EntityActions from 'redux/actions';
import MedalIcon from 'icons/Medal'
const NotificationActions = EntityActions.notification

const useStyles = makeStyles(theme => ({
  root: { flex: 1 },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  avatarBlue: {
    backgroundImage: gradients.blue
  },
  avatarGreen: {
    backgroundImage: gradients.green
  },
  avatarOrange: {
    backgroundImage: gradients.orange
  },
  avatarIndigo: {
    backgroundImage: gradients.indigo
  },
  arrowForwardIcon: {
    color: theme.palette.icon
  }
}));

const NotificationList = props => {
  const { notifications, className, currentUser, ...rest } = props;

  const classes = useStyles();
  const [markedSeen, setMarkedSeen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(null)
  const handleClick = (event, notification) => {
    setSelected(notification)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const onSeen = async (isVisible, notification) => {
    if (isVisible && !notification.seen) {
      // setMarkedSeen(notification)
      await hasSeen(notification.id);
      // markSeen(notification.id);
    }
  }
  const onDelete = () => {
    NotificationActions.delete((res, err) => {
      console.log('Remove Notification')
    }, { notificationId: selected.id, action: 'remove' })
  }

  const onUnsubscribe = () => {
    NotificationActions.delete((res, err) => {
      console.log('Remove Notification')
    }, { notificationId: selected.id, action: 'unsubscribe', userId: currentUser.id })
  }

  const avatars = {
    order: (
      <Avatar className={classes.avatarBlue}>
        <PaymentIcon />
      </Avatar>
    ),
    user: (
      <Avatar className={classes.avatarOrange}>
        <PeopleIcon />
      </Avatar>
    ),
    project: (
      <Avatar className={classes.avatarGreen}>
        <StoreIcon />
      </Avatar>
    ),
    feature: (
      <Avatar className={classes.avatarIndigo}>
        <CodeIcon />
      </Avatar>
    ),
    'RECOGNITION': (
      <Avatar className={classes.avatarIndigo}>
        <MedalIcon primary="#fff" width={24} height={24} />
      </Avatar>
    ),
    default: (
      <Avatar className={classes.avatarIndigo}>
        <MessageIcon />
      </Avatar>
    )
  };
  const open = Boolean(anchorEl);


  return (
    <Box>
      <List
        {...rest}
        className={clsx(classes.root, className)}
        disablePadding
      >
        {notifications.map((notification, i) => (
          <VisibilitySenor key={notification.id} onChange={visible => onSeen(visible, notification)}>
            <ListItem

              className={classes.listItem}
              component={RouterLink}
              divider={i < notifications.length - 1}
              key={notification.id}
              to={notification.actionUrl || "#"}
            >
              <ListItemAvatar>
                <Badge color="primary" variant="dot" invisible={notification.seen}>
                  {notification.type === 'RECOGNITION'
                    ? avatars['RECOGNITION']
                    : notification.avatarKey
                      ? <Avatar src={notification.avatarKey} />
                      : avatars['default']
                  }
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary={notification.message}
                secondary={moment(notification.createdOn).fromNow()}
              />
              <ListItemSecondaryAction >

                <IconButton size="small" onClick={(e) => handleClick(e, notification)}>
                  <MoreIcon size="16px" />
                </IconButton>

              </ListItemSecondaryAction>
            </ListItem>
          </VisibilitySenor>
        ))}
        <Popover
          id={open ? 'popover-' : undefined}
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <List disablePadding>
            <ListItem divider button onClick={onDelete}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Delete this notification" />
            </ListItem>
            <ListItem button onClick={onUnsubscribe}>
              <ListItemIcon>
                <NotificationsOffIcon />
              </ListItemIcon>
              <ListItemText primary="Turn off notification for this topic" />
            </ListItem>
          </List>
        </Popover>
      </List >
    </Box>
  );
};

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired
};

export default NotificationList;
