import RemoteEntityReducer from "../RemoteEntityReducer";
import { cloneDeep } from 'lodash'
const entityReducer = new RemoteEntityReducer((state, action) => {

    if (!action.response)
        throw new Error("Cannot reduce with no user");

    state.imageStatus = undefined
    state.organizations = cloneDeep(action.response)

    // console.log("Recieved user organization : " + JSON.stringify(action.response));
    return Object.assign({}, state);

}, (state, action) => {
    // console.log("In Organization entity updater, action -> " + JSON.stringify(action));
    const isSuccess = (action.status === 'update_complete')
    if (!action.reducerType)
        return Object.assign({}, state);
    if (action.reducerType === 'OrgsImages' && action.status === 'update_inprocess') {
        state.imageStatus = action.status
        return Object.assign({}, state)
    }
    if (action.reducerType === 'OrgsImages' && isSuccess) {
        if (action.response) {
            state.imageStatus = action.status
            state.organizations = action.response.map(org => Object.assign({}, org))
        }
        return Object.assign({}, state)
    }

});

const main = (state = { organizations: [] }, action) => {
    var newState = Object.assign({}, state);

    switch (action.type) {
        case 'ORGANIZATIONS':
            return entityReducer.doReduction(newState, action);
        case 'UPDATE_ORG_IMAGES':
            newState = updateOrgImage(newState, action);
            return newState
        case 'REFRESH_ORGANIZATIONS':
            return Object.assign(newState, { refresh: true })
        default:
            return state;
    }
}

const updateOrgImage = (newState, action) => {
    let organizations = newState.organizations
    const images = action.value
    organizations = organizations.map(org => {
        const orgImage = images.find(image => image.orgId === org.id)
        if (orgImage)
            org.image = orgImage.image

        org = Object.assign({}, org)
        return org
    })
    newState.organizations = organizations
    return newState
}

export default main