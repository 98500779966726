export const prodIdPrefix = '1'
export const testIdPrefix = '8'

class SecurityUtils {
    static hasRole(clientId, role, user) {
        if (!user || !clientId) return true;

        let orgUserAuth = this.getOrgUserAuth(clientId, user)
        const userRoles = orgUserAuth && orgUserAuth.roles && orgUserAuth.roles.map(r => r.toLowerCase())
        return (userRoles && (userRoles.includes(role) || userRoles.includes(role.toLowerCase()))) || false;
    }

    static hasOrgAccess(clientId, user) {
        return (this.getOrgUserAuth(clientId, user) && true) || false
    }

    static getOrgUserAuth(clientId, user) {
        if (!user || !clientId) return;

        let userAuth = user.authorizations || user.orgs;

        try {
            if (!userAuth) {
                let attributes = user['attributes'] || user['UserAttributes']

                userAuth = attributes['custom:auth'];
                if (!userAuth && attributes instanceof Array) {
                    userAuth = attributes.find(attribute => attribute['Name'] == 'custom:auth')
                    userAuth = userAuth && userAuth['Value'];
                }

                if (!userAuth) return;

                userAuth = JSON.parse(userAuth)
            }
        } catch (e) {
            console.error(e, e);
        }
        //client side..

        return (userAuth && userAuth.find(auth => auth.org == clientId));
    }

    static toTestId(id) {
        return testIdPrefix + id.substring(1);
    }
}

module.exports = SecurityUtils;
