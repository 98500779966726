import AppEntities from './AppEntities'
import GenericActions from './GenericActions';

let actions = {}

Object.keys(AppEntities).forEach(entityName => actions[entityName] = GenericActions(entityName));

export default actions;



