import { API, Storage } from "aws-amplify";
import { AppEntityConfig } from "redux/AppEntities";
import { Utils } from "c1-webutils";
import { orgId } from "utils/auth";
// import { Application } from 'utils/WebUtils';

Storage.configure({ level: "public" });

export const PRIVATE_API_NAME = "c1_api";
export const PUBLIC_API_NAME = "c1_public_api";

export const fetcher = entityName =>
  async function (state, args) {
    let clientId = orgId();
    let config = AppEntityConfig(entityName);
    let getAllPath = config.getAllPath;

    if (config.paging) {
      let { currentPage, maxPerPage } = args;

      // Start index of items to show on screen
      args.start = currentPage * maxPerPage;

      // End index of items to show on screen.
      args.end = args.start + maxPerPage - 1;
    }

    Object.keys(args).forEach(arg => {
      if (args[arg] instanceof Date) {
        args[arg] = args[arg].toISOString();
      }
    });

    return invokeApi(GET, `/private/${clientId}/${getAllPath}`, {
      queryStringParameters: args
    });
  };

export const fetcherCurrent = entityName =>
  async function (state, entityId, params) {
    let clientId = orgId();
    let getPath = AppEntityConfig(entityName).getPath;

    const encEntityId = encodeURIComponent(entityId);

    return invokeApi(
      GET,
      `/private/${clientId}/${getPath}/${encEntityId}`,
      params
    );
  };

const prefix = apiName => "private"; // apiName === PUBLIC_API_NAME ? 'public' : 'private'

export const creator = (entityName, apiName) =>
  async function (state, updates) {
    let clientId = orgId();
    let updatePath = AppEntityConfig(entityName).updatePath;

    return invokeApi(
      POST,
      `/${prefix(apiName)}/${clientId}/${updatePath}`,
      {
        body: updates
      },
      apiName
    );
  };

export const updater = (entityName, apiName) =>
  async function (state, updates) {
    let clientId = orgId();
    let updatePath = AppEntityConfig(entityName).updatePath;

    return invokeApi(
      PUT,
      `/${prefix(apiName)}/${clientId}/${updatePath}`,
      {
        body: updates
      },
      apiName
    );
  };

export const patcher = (entityName, apiName) =>
  async function (state, updates) {
    let clientId = orgId();
    let patchPath = AppEntityConfig(entityName).updatePath;

    return invokeApi(
      PATCH,
      `/${prefix(apiName)}/${clientId}/${patchPath}`,
      {
        body: updates
      },
      apiName
    );
  };

export const deleteAll = entityName =>
  async function (state, ids) {
    let clientId = orgId();
    let updatePath = AppEntityConfig(entityName).updatePath;

    return invokeApi(DELETE, `/private/${clientId}/${updatePath}`, {
      body: ids
    });
  };

export const onEntityAction = entityName =>
  async function (state, action) {
    let clientId = orgId(state);

    let { url, method, params } = action;

    return invokeApi(
      method || POST,
      `/private/${clientId}/${url}`,
      method !== GET
        ? {
          body: params
        }
        : {
          queryStringParameters: params
        }
    );
  };

/**
 *  Volunteer Program Status For Users
 */
export async function getMyFamilyPledges(state, params) {
  let clientId = orgId();
  return invokeApi(GET, `/private/${clientId}/events/my-family/pledges`);
}

export async function getCommunityReport() {
  let clientId = orgId();
  return invokeApi(GET, `/private/${clientId}/orgUsers/community-report`);
}

export async function getCommunityTalentMap() {
  const clientId = orgId();
  let url = `/private/${clientId}/report/communityTalentMap`;

  return invokeApi(GET, url);
}

export async function hasSeen(notificationId) {
  const clientId = orgId();
  const url = `/private/${clientId}/notifications/${notificationId}/viewed`;
  return invokeApi(PATCH, url);
}

export async function getFamiliesSummary() {
  const clientId = orgId();
  return invokeApi(GET, `/private/${clientId}/families?summary=true`);
}

export async function postFamilies(params) {
  const clientId = orgId();

  return invokeApi(POST, `/private/${clientId}/families`, { body: params });
}

export async function getAllFamilies() {
  const clientId = orgId();

  return invokeApi(GET, `/private/${clientId}/families`);
}

/*
INVITATION API
*/

export async function getInvitations(params) {
  return invokeApi(
    GET,
    `/public/invitations/${params.clientId}?email=${params.email}`,
    null,
    PUBLIC_API_NAME
  );
}

export async function sendInvitation(body) {
  const clientId = orgId();
  return invokeApi(PUT, `/private/invitations/${clientId}`, { body });
}

/*
INVITATION API ENDS
/*




/**
 * Organizations API's
 */

export async function getUser(username) {
  const clientId = orgId();

  return invokeApi(GET, `/private/${clientId}/orgUser/${username}`);
}

export async function createUsers(params) {
  const clientId = orgId();

  return invokeApi(POST, `/private/${clientId}/orgUsers`, { body: params });
}

export async function confirmUser(params) {
  const clientId = orgId();

  return invokeApi(POST, `/private/${clientId}/orgUser/confirm`, {
    body: params
  });
}

export async function downloadUsers() {
  const clientId = orgId();

  return invokeApi(GET, `/private/${clientId}/orgUsers/download`, {
    responseType: "text/csv"
  });
}

export async function downloadFamilies({ startDate, endDate, byUser, grades }) {
  const clientId = orgId();

  return invokeApi(GET, `/private/${clientId}/families/download?startDate=${startDate}&endDate=${endDate}&byUser=${byUser}&grades=${grades}`, {
    responseType: "text/csv"
  });
}

export async function downloadSchoolReport() {
  const clientId = orgId();

  return invokeApi(GET, `/private/${clientId}/report/downloadSchoolReport`, {
    responseType: "text/csv"
  });
}

export async function downloadSchoolEventReport() {
  const clientId = orgId();

  return invokeApi(GET, `/private/${clientId}/report/downloadSchoolEventReport`, {
    responseType: "text/csv"
  });
}

export async function downloadYearOverYearReport() {
  const clientId = orgId();

  return invokeApi(GET, `/private/${clientId}/report/downloadYearOverYearReport`, {
    responseType: "text/csv"
  });
}

export async function downloadAgreements(identityId) {
  const clientId = orgId();

  return invokeApi(GET, `/private/${clientId}/orgUsers/download/agreements`, {
    queryStringParameters: { identityId }
  });
}

export async function downloadMedical(identityId) {
  const clientId = orgId();

  return invokeApi(GET, `/private/${clientId}/orgUsers/download/medical`, {
    queryStringParameters: { identityId }
  });
}

export async function sendEmail(params) {
  const clientId = orgId();

  return invokeApi(
    POST,
    `/private/${clientId}/email`,
    {
      body: params
    },
    PRIVATE_API_NAME
  );
}

export async function previewEmail(params) {
  const clientId = orgId();

  return invokeApi(
    GET,
    `/private/${clientId}/email`,
    {
      queryStringParameters: params
    },
    PRIVATE_API_NAME
  );
}

export async function providePaymentInfo(params) {
  const clientId = orgId();

  return invokeApi(
    POST,
    `/private/${clientId}/payment`,
    {
      body: params
    },
    PRIVATE_API_NAME
  );
}

export async function registerUser(params) {
  return invokeApi(
    POST,
    `/public/registerUser`,
    {
      body: { user: params.user }
    },
    PUBLIC_API_NAME
  );
}

export async function checkIfUserExists(email) {
  return invokeApi(
    GET,
    `/public/checkIfUserExists/${email}`,
    null,
    PUBLIC_API_NAME
  );
}

export async function validateToken(token) {
  return invokeApi(
    POST,
    `/public/validateToken`,
    {
      body: { token }
    },
    PUBLIC_API_NAME
  );
}


export async function getAllOrgs() {
  return await invokeApi(GET, `/private/orgs`, null, PRIVATE_API_NAME);
}

export async function getOrgById(params) {
  const { orgId } = params;

  return await invokeApi(GET, `/public/org/${orgId}`, null, PUBLIC_API_NAME);
}

export async function getOrgByCode(params) {
  const { code, email } = params;

  return await invokeApi(
    GET,
    `/public/school/${code}?email=${email}`,
    null,
    PUBLIC_API_NAME
  );
}

export async function registerOrganization(params) {
  const result = await invokeApi(
    POST,
    `/public/register`,
    {
      body: { org: params.org }
    },
    PUBLIC_API_NAME
  );

  return result;
}

export async function deleteOrganization(orgId, params) {
  return invokeApi(DELETE, `/admin/${orgId}/org`, {
    queryStringParameters: params
  });
}

export async function executeJobs(orgId) {
  return invokeApi(POST, `/admin/${orgId}/execute-job`);
}

export async function newOrganization(state, org) {
  return invokeApi(POST, `/private/${org.id}/org`, {
    body: org
  });
}

export async function updateOrganization(state, org) {
  return invokeApi(PUT, `/private/${org.id}/org`, {
    body: org
  });
}

export async function resetOrganization() {
  const clientId = orgId();

  return invokeApi(GET, `/private/${clientId}/org/reset`, null, PRIVATE_API_NAME);
}

export async function getFamiliesAndFaculties() {
  const clientId = orgId();
  return invokeApi(GET, `/private/${clientId}/orgUsers/all`, null, PRIVATE_API_NAME);
}

export async function patchOrganization(state, org) {
  return invokeApi(PATCH, `/private/${org.id}/org`, {
    body: org
  });
}

export async function getUserOrganizations(state, orgs) {
  let promises = orgs.map(org => {
    return invokeApi(GET, `/private/${org.org}/org`);
  });

  let results = await Promise.all(promises);
  results = results.filter(element => element != null);
  return Promise.resolve(results);
}

export async function getCurrentOrganization(state, org) {
  return invokeApi(GET, `/public/${org}/org`, {});
}

export async function addPledge(params) {
  const clientId = orgId();
  const { eventId, pledges } = params;

  return await invokeApi(PATCH, `/private/${clientId}/event/${eventId}/pledges`,
    { body: { pledges } },
    PRIVATE_API_NAME,
    true
  );
}

export async function deletePledge(params) {
  const clientId = orgId();
  const { eventId, pledges } = params;

  return await invokeApi(DELETE, `/private/${clientId}/event/${eventId}/pledges`,
    { body: { pledges } },
    PRIVATE_API_NAME,
    true
  );
}

export async function lookupUser(searchTerms, roles) {
  const clientId = orgId();

  let url = `/private/${clientId}/users?`;

  const queryStringParameters = {
    searchTerms: searchTerms.join(','),
  }

  if (roles && roles.length > 0) {
    queryStringParameters.roles = roles.join(',');
  }

  return await invokeApi(GET, url, {
    queryStringParameters
  });
}

export async function lookupOrgUser(query, roles) {
  const clientId = orgId();

  let url = `/private/${clientId}/orgUsers`;

  return await invokeApi(GET, url, {
    queryStringParameters: { query, roles }
  });
}

export async function updateOrgUser(body) {
  const clientId = orgId();
  return invokeApi(PUT, `/private/${clientId}/orgUser`, { body });
}

export async function getImpersonatedUser(username) {
  let url = `/private/user/${username}`;

  return await invokeApi(GET, url, null, PRIVATE_API_NAME);
}

export async function handleInvite(familyId, params) {
  const clientId = orgId();
  const url = `/private/${clientId}/family/internal/invite`;
  return await invokeApi(PATCH, url, params, PRIVATE_API_NAME);
}

export async function deleteInvitation(params) {
  const clientId = orgId();
  const url = `/private/${clientId}/family/internal/invite`
  return await invokeApi(DELETE, url, { body: params }, PRIVATE_API_NAME);
}

export async function updateAdjustments(params) {
  const clientId = orgId();
  const url = `/private/${clientId}/orgUsers/adjustments`;
  return await invokeApi(PATCH, url, { body: params }, PRIVATE_API_NAME);
}

export async function inviteSchoolCommunity(params) {
  const clientId = orgId();
  const url = `/private/${clientId}/family/community-invite`;
  return await invokeApi(PATCH, url, { body: params }, PRIVATE_API_NAME);
}

export async function removeFamily(params) {
  const clientId = orgId();
  const url = `/private/${clientId}/removeFamily`
  return await invokeApi(DELETE, url, { body: params }, PRIVATE_API_NAME);
}

export async function mergeRequest(familyId, body) {
  const clientId = orgId();
  const url = `/private/${clientId}/family/${familyId}/merge`
  return await invokeApi(POST, url, { body }, PRIVATE_API_NAME);
}

export async function linkUser(params) {
  return await invokeApi(POST, '/public/link', { body: params }, PUBLIC_API_NAME)
}
export async function unlinkUser(params) {
  return await invokeApi(POST, '/public/unlink', { body: params }, PUBLIC_API_NAME)
}


const GET = async (apiName, path, init) => API.get(apiName, path, init);
const POST = async (apiName, path, init) => API.post(apiName, path, init);
const PUT = async (apiName, path, init) => API.put(apiName, path, init);
const PATCH = async (apiName, path, init) => API.patch(apiName, path, init);
const DELETE = async (apiName, path, init) => API.del(apiName, path, init);

// const HEAD = async (apiName, path, init) => API.head(apiName, path, init);

async function invokeApi(
  method,
  path,
  myInit,
  apiName = PRIVATE_API_NAME,
  processError = true
) {
  myInit = myInit || {};
  myInit.response = true;

  myInit.headers = myInit.headers || {};
  try {
    const response = await method(apiName, path, myInit);
    return response.data;
  } catch (error) {
    if (processError) {
      let err =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        error;
      if (typeof err === "string")
        try {
          err = JSON.parse(err);
        } catch (e) {
          throw { errorMessage: err };
        }
      throw err;
    }

    throw error;
  }
}
