import React from 'react'
import { Auth } from "aws-amplify";
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'classnames'
import ReactGA from 'react-ga';

const DATA = {
    google: {
        id: 'google',
        icon: "https://s3.amazonaws.com/content.connect1.io/public/google-logo.png",
        label: 'Sign in with Google',
        provider: 'Google'
    },
    apple: {
        id: "apple",
        icon: "https://cdn.iconscout.com/icon/free/png-256/apple-853-675472.png",
        label: "Sign in with Apple",
        provider: "SignInWithApple"
    },
    facebook: {
        id: "facebook",
        icon: "https://icons-for-free.com/download-icon-facebook+logo+media+network+social+icon-1320192913497992427_512.png",
        label: "Sign in with Facebook",
        provider: "Facebook"
    }
}
const useStyles = makeStyles(theme => ({
    btnSocial: {
        justifyContent: 'flex-start',
        borderRadius: 4,
        backgroundColor: '#4285F4',
        color: '#fff',
        cursor: 'pointer',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#3f7ce8'
        }
    },
    google: {
        backgroundColor: '#4285F4',
        "&:hover": {
            backgroundColor: '#3f7ce8'
        }
    },
    apple: {
        backgroundColor: 'rgba(0,0,0,0.85)',
        "&:hover": {
            backgroundColor: 'rgba(0,0,0,0.95)'
        }
    },
    btnSocialIcon: {
        display: 'flex',
        backgroundColor: 'white',
        borderRadius: 4,
        padding: 8,
        // marginRight: 16,
        minHeight: 24,
        minWidth: 24,
        '& img': {
            width: 24,
            height: 24
        }
    }
}))
export default function SocialButton({ name = 'google' }) {
    const classes = useStyles();
    const source = DATA[name]
    const handleSignIn = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Social Sign In',
            label: source?.id || "unknown",
            value: 1
        });
        await Auth.federatedSignIn({ provider: source.provider });
    };

    return (
        <Box
            id={"btn-" + source.id}
            className={clsx(classes.btnSocial, { [classes[name]]: name })}
            width={"100%"}
            onClick={handleSignIn}
            variant="contained"
            mb={1}
        >
            <Box display={"flex"} alignItems="center" p={"2px"} >
                <Box className={classes.btnSocialIcon}>
                    <img src={source.icon} />
                </Box>
                <Box display={'flex'} justifyContent="center" width={'100%'}>
                    <Typography color="inherit" align="center">
                        {source.label}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}