import React from 'react'
import styles from "../styles/authentication.style";
import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Divider, Typography, LinearProgress } from '@material-ui/core'
import { AuthContext } from 'containers/auth/Authenticator'
import swal from 'sweetalert'
import ImageLoader from "components/image/ImageLoader";
import { STAGES } from '../SignUp'
import UserActions from "redux/actions/user.actions";
import Form from './forms/SchooleCodeForm'
import { alpha } from '@material-ui/core/styles'


const useStyles = makeStyles(styles)
export default function SchoolCode({ onStepChange, onRegister, data }) {
    const classes = useStyles()
    const formRef = React.useRef(null)
    const [school, setSchool] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const { onAuthStateChange, authData, authenticated } = React.useContext(AuthContext)

    const hasValidData = !!(authenticated && authData?.attributes?.given_name && authData?.attributes?.family_name && school)

    const handleCodeChange = () => {
        if (school)
            setSchool(null)
    }

    const handleSubmit = () => {
        const authStep = localStorage.getItem('c1-auth-step')
        if (!school) {
            const formData = formRef.current.getFormData();
            if (formData) {
                setLoading(true)
                UserActions.getOrgByCode({ code: formData.code }, (res, err) => {
                    setLoading(false)
                    if (err)
                        swal("Error", err.errorMessage, "error");
                    if (res == null)
                        swal("Not Found", "Unfortunately we couldn't find a school with the given code", "error");
                    if (res) {
                        setSchool(res)
                    }
                })
            }
        }
        if (school) {
            const { role } = data

            if (hasValidData) {
                setLoading(true)
                const params = {
                    username: authData.username,
                    givenName: authData.attributes.given_name,
                    familyName: authData.attributes.family_name,
                    authorization: [{
                        org: school.id,
                        roles: role
                    }]
                }
                onRegister && onRegister(params, null, (success) => {
                    setLoading(false)
                    if (success) {
                        const finalStageData = {
                            heading: 'Success',
                            description: `Congratulations! You have successfully completed sign up for ${school.name}. Please go back to login and sign in again to access the portal.`
                        }
                        onAuthStateChange && onAuthStateChange('FinalStage', null, finalStageData)
                    }                    
                })
            } else {
                let stepData = { org: school };
                if (authData && authData.attributes)
                    stepData.user = {
                        givenName: authData.attributes.given_name,
                        familyName: authData.attributes.family_name,
                        email: authData.attributes.email,
                        username: authData.username
                    }
                if (data.role)
                    stepData.role = role
                onStepChange && onStepChange({ nextStep: STAGES.UserDetails, data: stepData, authStep: authStep ? null : 'SchoolCode' })
            }
        }
    }

    return (
        <Box pt={3} pb={1} maxWidth={450}>
            <Box mb={2} px={4}>
                <h2 className={classes.heading}>{"Enter School Code"}</h2>
                {!school && <Typography color="textSecondary" variant='body2'>
                    {`Please enter your school code below.`}
                </Typography>
                }
            </Box>
            <Box px={4} mb={4} minHeight={250}>
                <Box mb={2}>
                    <Form ref={formRef} onCodeChange={handleCodeChange} />
                </Box>
                {school && <Box mb={2}>
                    <SchoolCard school={school} />
                </Box>}
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    If you are a parent and have <span style={{ textDecoration: 'underline' }}>not</span> received a school code from your school, contact your school leader(s).
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    By signing up you agree to accept our <a href="https://www.connect1.io/terms-of-use">Terms of Service</a> and <a href="https://www.connect1.io/privacy-policy">Privacy Policy</a>.
                </Typography>
            </Box>
            {loading && <LinearProgress />}
            <Divider />
            <Box px={3} pt={1} display="flex" justifyContent={"space-between"}>
                <Button id="btn-back" onClick={() => onAuthStateChange('SignIn')}>{'Back'}</Button>
                <Button id="btn-submit" color="primary" onClick={handleSubmit} >{school ? hasValidData ? 'submit' : 'Continue' : 'Find School'}</Button>
            </Box>
        </Box>
    )
}

const useStyle2 = makeStyles(theme => ({
    schoolCard: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: '1px solid rgba(0,0,0,.3)',
        borderRadius: '10px',
        overflow: 'hidden',
        padding: '8px',
        marginBottom: 16,
        minHeight: 60,
        minWidth: 350,
        backgroundColor: alpha(theme.palette.primary.main, .1),
        '& img': {
            paddingRight: theme.spacing(1)
        },
        [theme.breakpoints.down('sm')]:{
            minWidth: 'auto'
        }
    },
    schoolTitle: {
        textTransform: 'capitalize'
    }
}))
const SchoolCard = ({ school }) => {
    const classes = useStyle2();
    const address = school && school.address ? school.address?.street + ", " + school.address?.city + ", " + school.address?.state : "";
    return (
        <Box id="school-card" className={classes.schoolCard}>
            {school.logo && school.logo.key && (
                <ImageLoader
                    imageKey={school.logo.key}
                    params={`h=80&w=80`}
                    alt={"header"}
                />
            )}
            <Box pl={school.logo && school.logo.key ? 0 : 2}>
                <Typography id="school-name" className={classes.schoolTitle} variant="h6">{school.name}</Typography>
                <Typography id="school-address" variant="body2">{address}</Typography>
            </Box>
        </Box>
    )
}