import $ from "jquery";

import { withStyles} from '@material-ui/core/styles';
import React from 'react';
import ReactQuill from 'react-quill';
import { bind } from 'utils/WebUtils';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import classNames from 'classnames'

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';

import shortid from 'shortid'

const editorOptions = {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }]
        ]
    }
};

const styles = theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
    },
    label: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        background: 'white'
    },
    labelError: {
        color: theme.palette.error.main
    },
    labelFocused: {
        color: theme.palette.primary['700']
    }
})
class CustomReactQuill extends React.Component {
    constructor(props) {
        super(props);

        let { theme } = props;

        this.cssFocused = { "border": `2px solid ${theme.palette.primary.main}` }
        this.cssBlurred = { "border": "1px solid #ccc" }
        this.cssError = { "border": `1px solid ${theme.palette.error.main}` }

        this.state = {
            focused: false,
            css: this.cssBlurred
        }

        this.id = shortid.generate();

        bind(this, 'onChange', 'getStateName', 'setFocus')
    }

    componentDidMount() {
        $(`#${this.id} .ql-toolbar`).css("border", "unset");
        $(`#${this.id} .ql-container`).css("border", "unset");
        this.rootElement = $(`#${this.id} .quill`)
            .css(Object.assign({
                "border-radius": "5px"
            }, this.cssBlurred))
    }

    componentDidUpdate(prevProps, prevState) {
        let { css: preCss } = prevState;
        let { css, focus } = this.state;

        let stateName = this.getStateName()
        let error = this.props.self.state[`${stateName}State`] === 'error'

        if (error && css !== this.cssError) {
            this.setState({ css: this.cssError })
        } else if (!error && css === this.cssError) {
            this.setState({ css: focus ? this.cssFocused : this.cssBlurred })
        }

        if (css !== preCss) {
            this.rootElement.css(css);
        }
    }

    setFocus(focus) {
        this.setState({ focus, css: focus ? this.cssFocused : this.cssBlurred })
    }

    getStateName() {
        let {
            name,
            discriminator,
        } = this.props;

        return discriminator ? `${name}_${discriminator}` : name;
    }


    onChange(value) {
        let { onChange, name, self, discriminator } = this.props;

        let stateName = this.getStateName()

        self.setState({
            [stateName]: value,
        }, () => onChange && onChange({ name, discriminator, value }))
    }

    render() {

        const { name,
            placeholder,
            self,
            classes,
            onChange,
            label,
            id,
            ...rest
        } = this.props;

        let {
            focus
        } = this.state

        let stateName = this.getStateName()

        let value = self.state[stateName] || ' '
        let error = self.state[`${stateName}State`] === 'error'
        let errorMessage = self.state[`${stateName}Error`]

        const labelClasses = classNames({
            [classes.label]: true,
            [" " + classes.labelError]: error,
            [" " + classes.labelFocused]: focus
        });


        return <div id={this.id} className={classes.root} >
            <FormControl variant="outlined" fullWidth className={classes.formControl} error={error}>
                {<InputLabel className={labelClasses} shrink={true} >{label}</InputLabel>}
                <ReactQuill
                    id={id}
                    theme="snow"
                    name={name}
                    modules={editorOptions.modules}
                    placeholder={placeholder}
                    value={value}
                    onChange={value => this.onChange(value)}
                    onFocus={() => this.setFocus(true, error)}
                    onBlur={() => this.setFocus(false, error)}
                    fullWidth
                />
                {error && <FormHelperText>{errorMessage(this.props)}</FormHelperText>}
            </FormControl>
        </div >

    }
}

export default withStyles(styles, { withTheme: true })(CustomReactQuill);
